import React, { Component } from "react";
import "./dMStep.css";
import { Link } from "react-router-dom";

export default class dMSteps extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="menu_steps">
          <div>
            <div
              id="step_1"
              className={
                window.location.pathname === "/startApp"
                  ? "step_menu active"
                  : "step_menu"
              }
            >
              <Link to="/startApp">
                <h6>Start App </h6>
              </Link>
              <i className="fa fa-angle-right" />
            </div>
            <div
              id="step_2"
              className={
                window.location.pathname === "/choose-design"
                  ? "step_menu active"
                  : "step_menu"
              }
            >
              <Link to="/choose-design">
                <h6>Choose Design </h6>
              </Link>
              <i className="fa fa-angle-right" />
            </div>
            <div
              id="step_3"
              className={
                window.location.pathname === "/create-app"
                  ? "step_menu active"
                  : "step_menu"
              }
            >
              <Link to="/create-app">
                <h6>Create App </h6>
              </Link>
              <i className="fa fa-angle-right" />
            </div>
            <div
              id="step_4"
              className={
                window.location.pathname === "/welcome-screen"
                  ? "step_menu active"
                  : "step_menu"
              }
            >
              <Link to="/welcome-screen">
                <h6>Welcome Screen </h6>
              </Link>
              <i className="fa fa-angle-right" />
            </div>
            <div
              id="step_5"
              className={
                window.location.pathname === "/quizlist"
                  ? "step_menu active"
                  : "step_menu"
              }
            >
              <Link to="/quizlist">
                <h6>Gamify </h6>
              </Link>
              <i className="fa fa-angle-right" />
            </div>
            <div
              id="step_6"
              className={
                window.location.pathname === "/monitize"
                  ? "step_menu active"
                  : "step_menu"
              }
            >
              <Link to="/monitize">
                <h6>Monitize </h6>
              </Link>
              <i className="fa fa-angle-right" />
            </div>
            <div
              id="step_7"
              className={
                window.location.pathname === "/communicate"
                  ? "step_menu active"
                  : "step_menu"
              }
            >
              <Link to="/communicate">
                <h6>Communicate </h6>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
