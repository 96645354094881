import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DateRangeFilter from "./DateRangeFilter";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
// import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import axios from "axios";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import { CSVLink } from "react-csv";
import HttpClient from "./../../utils/HttpClient";
import Css from "./subscription.css";

class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Subscription", link: "#" },
        { title: "Add & Manage Subscription", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      no_list: "",

      image_select: false,
      img_url: "",
      title: "",
      points: [""],
      id: "",
      // duration: [
      //   {
      //     startDate: new Date(),
      //     endDate: addDays(new Date(), 7),
      //     key: "selection",
      //   },
      // ],
      duration: "",
      seller_comission: "",
      type: "",
      desc: "",
      flat_comission: "",
      per_comission: "",
      startdate: "",
      enddate: "",
      range: null,
      obj: null,
      selectcountry: "",
      country: [],
      currency: "",
      durationPeriod: [
        {period: "1"},
        {period: "2"},
        {period: "3"},
        {period: "4"},
        {period: "5"},
        {period: "6"},
        {period: "7"},
        {period: "8"},
        {period: "9"},
        {period: "10"},
        {period: "11"},
        {period: "12"},
        {period: "13"},
        {period: "14"},
        {period: "15"},
        {period: "16"},
        {period: "17"},
        {period: "18"},
        {period: "19"},
        {period: "20"},
        {period: "21"},
        {period: "22"},
        {period: "23"},
        {period: "24"},
        {period: "25"},
        {period: "26"},
        {period: "27"},
        {period: "28"},
        {period: "29"},
        {period: "30"},
        {period: "31"},
        {period: "32"},
        {period: "33"},
        {period: "34"},
        {period: "35"},
        {period: "36"},
        {period: "37"},
        {period: "38"},
        {period: "39"},
        {period: "40"},
        {period: "41"},
        {period: "42"},
        {period: "43"},
        {period: "44"},
        {period: "45"},
        {period: "46"},
        {period: "47"},
        {period: "48"},
      ],
      currencyTyp: [
        {
          name: "INR"
        },
        {
          name: "USD"
        },
        {
          name: "GBP"
        },
        {
          name: "EUR"
        },
        {
          name: "CAD"
        },
        {
          name: "cdd"
        }
      ],
    };
    this.handleDefault = this.handleDefault.bind(this);
  }

  componentDidMount = async () => {
    this.checkUser();
    this.getCountry();
  };
  getCountry = () => {
    let response = axios.get('https://countriesnow.space/api/v0.1/countries/capital').then((response) => {
      console.log("response", response);
      if (response.data.data.length > 0) {

        this.setState({
          country: response.data.data
        })

      }
    });
  };

  handleDefault(date) {
    this.setState({ default_date: date });
  }

  onChangee = (ranges) => {
    // ranges ...
    // alert("changed check the console log");
    console.log("Range", ranges);
    this.setState({
      startdate: ranges.startDate,
      enddate: ranges.endDate,
      range: ranges,
    });
  };

  fetchData = async () => {
    let result = await HttpClient.requestData("subscription", "GET");
    console.log("object", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      // console.log(this.state.pointarr);
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,

          title: element.name,
          // month: element.duration + " " + element.type,
          month: element.duration + " " + "Month",
          price: (element.currency == "INR"
          ? "₹"
          : element.currency == "CAD"
          ? "$"
          : element.currency == "USD"
          ? "$"
          : element.currency == "	GBP"
          ? "£"
          : "€") + element.price,
          type: element.type,

          seller_comission_type: element.seller_comission_type,
          seller_comission: element.seller_commission_value,
          no_list: element.no_of_listing,
          status: element.status ? (
            <Button
              className="btn-icon"
              color="success"
              onClick={(e) => this.status(index, element._id)}
              disabled={element.purchaseCount > 0 ? true : false}
            >
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-wink-outline mr-2"></i>
              </span>{" "}
              Active
            </Button>
          ) : (
            <Button
              className="btn-icon"
              color="danger"
              onClick={(e) => this.status(index, element._id)}
            >
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-cry-outline mr-2"></i>
              </span>{" "}
              Deactive
            </Button>
          ),
          action: (
            <>
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                disabled={element.purchaseCount > 0 ? true : false}
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>{" "}
              <button
                title="Edit"
                className="btn btn-primary"
                disabled={element.purchaseCount > 0 ? true : false}
                onClick={() => {
                  this.edit(element, index);
                  window.scrollTo(0,0);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    let result = await HttpClient.requestData(
      "subscription/" + item._id,
      "DELETE"
    );
    console.log(result);
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      title: item.name,
      price: item.price,
      edit_item_id: item._id,
      seller_comission: item.seller_commission_value,
      duration: item.duration,
      id: item.id,
      type: item.type,
      desc: item.description,
      currency: item.currency,
      selectcountry: item.country.label
      // no_list: item.no_of_listing,
      // seller_comission: item.seller_comission_type,
      // flat_comission:
      //   item.seller_comission_type == "Flat comission"
      //     ? item.seller_commission_value
      //     : "",
      // per_comission:
      //   item.seller_comission_type == "% comission"
      //     ? item.seller_commission_value
      //     : "",

      // seller_commission_value:seller_commission_value
    });
    setTimeout(() => {
      console.log("objecttt", this.state.obj);
    }, 1000);
  };
  status = async (index, id) => {
    let sendData = {};
    let result = await HttpClient.requestData(
      "subscription/set-status/" + id,
      "PUT",
      sendData
    );
    if (result && result.status) {
      this.fetchData();
    }
  };
  submit = async () => {
    if (
      this.state.title != "" &&
      this.state.price != "" &&
      // this.state.duration != "" &&
      this.state.seller_comission != ""
      // this.state.type != "" &&
      // this.state.no_list != ""
    ) {
      if (this.state.price.length > 5) {
        this.setState({
          alert: true,
          message: "Price must be maximum 5 digits",
          type: "warning",
        });
        return;
      }

      if (this.state.flat_comission.length > 5) {
        this.setState({
          alert: true,
          message: "Flat Comission must be maximum 5 digits",
          type: "warning",
        });
        return;
      }
      if (this.state.per_comission.length > 3) {
        this.setState({
          alert: true,
          message: "% Comission must be maximum 3 digits",
          type: "warning",
        });
        return;
      }
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          name: this.state.title,
          // seller_comission_type: this.state.seller_comission,
          // start_date: this.state.startdate,
          // end_date: this.state.enddate,
          // range: this.state.range,
          duration: this.state.duration,
          type: this.state.type,
          price: Number(this.state.price),
          description: this.state.desc,
          // no_of_listing: this.state.no_list,
          // seller_commission_value:
          //   this.state.seller_comission == "Flat comission"
          //     ? this.state.flat_comission
          //     : this.state.per_comission,
          seller_commission_value: this.state.seller_comission,
          country: { label: this.state.selectcountry, value: this.state.selectcountry },
          currency: this.state.currency,
          type: "yearly"
        };
        console.log("Dataa", data);
        result = await HttpClient.requestData("subscription", "POST", data);
      } else {
        data = {
          name: this.state.title,
          // seller_comission_type: this.state.seller_comission,
          // start_date: this.state.startdate,
          // end_date: this.state.enddate,
          // range: this.state.range,
          duration: this.state.duration,
          type: this.state.type,
          price: Number(this.state.price),
          description: this.state.desc,
          // no_of_listing: this.state.no_list,
          // seller_commission_value:
          //   this.state.seller_comission == "Flat comission"
          //     ? this.state.flat_comission
          //     : this.state.per_comission,
          seller_commission_value: this.state.seller_comission,
          country: { label: this.state.selectcountry, value: this.state.selectcountry },
          currency: this.state.currency,
          type: "yearly"
        };
        console.log(data);
        result = await HttpClient.requestData(
          "subscription/" + this.state.edit_item_id,
          "PUT",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Subscription Updated Successfully"
            : "Subscription Added Successfully",
          type:"success",
          image_select: false,
          img_url: "",
          title: "",
          seller_comission: "",
          duration: "",
          price: "",
          desc: "",
          no_list: "",
          selectcountry:"",
          // obj: null,
        });
          // console.log(this.state.type)
        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (e) => {
    let file = e.target.files[0].type;
    let fileType = file.split("/")[0];
    // fileType = fileType[0];
    console.log("e", file.split("/")[0]);

    if (fileType == "image" || fileType == "video") {
      // return false;
      this.setState({ fileType: fileType });
      let dataSend = { buckate: true, buckate_name: "appIcon" };

      let result = await HttpClient.newFileUpload(
        "image-upload/movie",
        e.target.files[0],
        dataSend
      );
      console.log("result", result);
      if (result && result.status) {
        this.setState({ img_url: result.url, image_select: true });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",

          width: 150,
        },
        // {
        //   label: "Image",
        //   field: "img",
        //
        //   width: 270,
        // },
        {
          label: "Title",
          field: "title",

          width: 270,
        },
        {
          label: "Duration",
          field: "month",

          width: 270,
        },
        {
          label: "Price",
          field: "price",

          width: 270,
        },
        // {
        //   label: "No. of List",
        //   field: "no_list",

        //   width: 270,
        // },
        // {
        //   label: "Month",
        //   field: "month",

        //   width: 270,
        // },
        // {
        //   label: "Description",
        //   field: "desc",
        //
        //   width: 270,
        // },
        // {
        //   label: "Seller Comission Type",
        //   field: "seller_comission_type",

        //   width: 270,
        // },
        {
          label: "Seller Commission(%)",
          field: "seller_comission",

          width: 270,
        },

        {
          label: "Status",
          field: "status",

          width: 100,
        },
        {
          label: "Action",
          field: "action",

          width: 100,
        },
      ],
      rows: this.state.data,
    };

    const header = [

      { label: "Sl.", key: "sl" },
      { label: "Title", key: "title" },
      { label: "Duration", key: "month" },
      { label: "Price", key: "price" },
      { label: "Seller Commission Type(%)", key: "seller_comission_type" },
    ]
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type === "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Name
                      </Label>
                      <Col md={10}>
                        <input
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder="Enter Subscription Name"
                          onChange={(val) => {
                            this.setState({ title: val.target.value });
                          }}
                          value={this.state.title}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Price
                      </Label>
                      <Col md={10}>
                        <input
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          type="number"
                          placeholder="Enter Subscription Price"
                          onChange={(val) => {
                            this.setState({ price: val.target.value });
                          }}
                          value={this.state.price}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Description
                      </Label>
                      <Col md={10}>
                        {/* <CKEditor
                          editor={ClassicEditor}
                          data={
                            this.state.desc == ""
                              ? "<p>Enter Subscription description here...</p>"
                              : this.state.desc
                          }
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            console.log({ event, editor, data });
                            this.setState({
                              desc: data.replace(/<[^>]+>/g, ""),
                            });
                          }}
                          value={this.state.desc}
                        /> */}
                        <textarea
                          id="question-input1"
                          className="form-control"
                          placeholder="Enter Subscription Description"
                          onChange={(val) => {

                            this.setState({
                              desc: val.target.value,
                            });
                          }}
                          value={this.state.desc}
                        >

                        </textarea>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Duration (in Month)
                      </Label>
                      <Col md={10}>
                        {/* <input
                          id="question-input1"
                          className="form-control"
                          rows="1"
                          placeholder=""
                          onChange={(val) => {
                            this.setState({ duration: val.target.value });
                          }}
                          value={this.state.duration}
                        /> */}
                        <select
                          className="form-control"
                          onChange={(val) => {
                            this.setState({
                              duration: val.target.value
                            });


                          }}
                          value={this.state.duration}
                        >
                          <option value="">Select Duration</option>
                          {this.state.durationPeriod.map((item, index) => {
                            return (<option value={item.period} key={index}>{item.period}</option>)
                          })}

                        </select>
                        {/* <DateRangePicker
                         onChange={this.handleSelect}
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          months={2}
                          ranges={this.state.duration}
                          direction="horizontal"
                        /> */}
                        {/* <DateRangeFilter
                          onChange={this.onChangee}
                          recieved={this.state.obj}
                          edit={this.state.edit_enable}
                        /> */}
                      </Col>
                    </FormGroup>

                    {/* <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        No of List
                      </Label>
                      <Col md={10}>
                        <input
                          id="question-input1"
                          className="form-control"
                          rows="1"
                          placeholder=""
                          onChange={(val) => {
                            this.setState({ no_list: val.target.value });
                          }}
                          value={this.state.no_list}
                        />
                      </Col>
                    </FormGroup> */}

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Country
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={(val) => {
                            this.setState({
                              selectcountry: val.target.value
                            });


                          }}
                          value={this.state.selectcountry}
                        >
                          <option value="">Select  Country</option>
                          {this.state.country.map((item, index) => {
                            return (<option value={item.name} key={index}>{item.name}</option>)
                          })}

                        </select>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Currency
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={(val) => {
                            this.setState({
                              currency: val.target.value
                            });


                          }}
                          value={this.state.currency}
                        >
                          <option value="">Select Currency</option>
                          {this.state.currencyTyp.map((item, index) => {
                            return (<option value={item.name} key={index}>{item.name}</option>)
                          })}

                        </select>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Seller Commission(%)
                      </Label>
                      <Col md={10}>
                        {/* <select
                          className="form-control"
                          onChange={(val) => {
                            this.setState({
                              seller_comission: val.target.value,
                            });
                          }}
                          value={this.state.seller_comission}
                        >
                          <option value="">Select Seller Comission</option>
                          {/* <option value="Flat comission">Flat comission</option> */}
                        {/* <option value="% comission">% comission</option>
                        </select> */}
                        <input
                          id="question-input1"
                          className="form-control"
                          rows="1"
                          type="number"
                          placeholder="Enter Seller Commission(%)"
                          onChange={(val) => {
                            this.setState({
                              seller_comission: val.target.value,
                            });
                          }}
                          value={this.state.seller_comission}
                        />
                      </Col>
                    </FormGroup>

                    {this.state.seller_comission == "Flat comission" ? (
                      <FormGroup row>
                        <Label
                          htmlFor="example-search-input"
                          className="col-md-2 col-form-label"
                        >
                          Flat comission
                        </Label>
                        <Col md={10}>
                          <input
                            id="question-input1"
                            className="form-control"
                            rows="1"
                            type="number"
                            placeholder=""
                            onChange={(val) => {
                              this.setState({
                                flat_comission: val.target.value,
                              });
                            }}
                            value={this.state.flat_comission}
                          />
                        </Col>
                      </FormGroup>
                    ) : this.state.seller_comission == "% comission" ? (
                      <FormGroup row>
                        <Label
                          htmlFor="example-search-input"
                          className="col-md-2 col-form-label"
                        >
                          % comission
                        </Label>
                        <Col md={10}>
                          <input
                            id="question-input1"
                            className="form-control"
                            rows="1"
                            type="number"
                            placeholder=""
                            onChange={(val) => {
                              this.setState({
                                per_comission: val.target.value,
                              });
                            }}
                            value={this.state.per_comission}
                          />
                        </Col>
                      </FormGroup>
                    ) : (
                      ""
                    )}

                    {/* <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Type
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={(val) => {
                            this.setState({
                              type: val.target.value,
                            });
                          }}
                          value={this.state.type}
                        >
                          <option value="">Select Type</option>
                          <option value="year">Yearly</option>
                          <option value="month">Monthly</option>
                        </select>
                      </Col>
                    </FormGroup> */}

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={3}>
                {this.state.data.length > 0 ?
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"SubscriptionPlan.csv"}
                    className="btn btn-success">
                    Export Report in CSV
                  </CSVLink> : null}
              </Col>
            </Row>&nbsp;
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
