import React, { Component } from "react";
import "./ind432a.css";

export default class design_3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backgroundImage: this.props.backgroundImage,
      item: this.props.item,
      user: this.props.item.user,
      changeBGColor: false,
      iconBGColor: "",
    };
  }

  render() {
    return (
      <div
        className="designed_area w-100 h-100 relative"
        style={{
          backgroundImage: `url(${this.props.item.backgroundImage})`,
        }}
      >
        <div className="d-flex w-100 h-100">
          <div
            className="my-auto mx-auto icon_contains relative"
            // title="Click to change background color"
            style={{
              background: this.props.item.iconbgcolor,
              width: "auto",
              maxWidth: "60%",
            }}
            onClick={() => this.setState({ changeBGColor: false })}
          >
            <div className="d-flex flex-wrap">
              {this.props.item.icons.map((item, index) => {
                return (
                  <a
                    href="#"
                    className="icon_div"
                    style={{ backgroundColor: "#f1f5f7" }}
                  >
                    {/* <i className="ri-facebook-box-fill icon_item"></i> */}
                    <img
                      src={item.iconUrl}
                      style={{
                        height: 40,
                        width: 40,
                        filter: this.props.iconColor,
                      }}
                    />
                  </a>
                );
              })}
            </div>
          </div>
          {/* bg-color change modal */}
          {this.state.changeBGColor ? (
            <div
              className={
                this.state.changeBGColor ? "modal fade show" : "modal fade"
              }
              id={"colorBGModal"}
              style={{
                display: this.state.changeBGColor ? "block" : "none",
              }}
            >
              <div className="modal-dialog mt-5 pt-5" role="document">
                <div className="modal-content">
                  <div className="modal-header py-2">
                    <h6 className="modal-title">Change Background Color</h6>
                  </div>
                  <div className="modal-body">
                    <label for="color-picker">
                      <p className="mb-0 mr-3 text-dark">
                        Pick colors to change the background
                      </p>
                    </label>
                    <input
                      name="color-picker"
                      aria-label="icon-color-picker"
                      type="color"
                      value={this.state.iconBGColor}
                      onChange={(e) =>
                        this.setState({
                          iconBGColor: e.target.value,
                        })
                      }
                    />
                    <button
                      type="button"
                      className="btn btn-primary btn-sm pull-right"
                      onClick={() => this.setState({ changeBGColor: false })}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <input
          type="file"
          className="page_input_file"
          title="Choose Background Image"
          onChange={(e)=>{
            this.props.changeBackground(e.target.files[0])
            // console.log('e.target.files[0]',e.target.files[0])
          }}
        />
      </div>
    );
  }
}
