import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import { CSVLink } from "react-csv";
import HttpClient from "./../../utils/HttpClient";
import { useState, useEffect } from "react";

const ShowMore = ({ data }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    console.log(show);
  }, [show]);
  return (
    <>
      {data.length > 20 ? (
        <div>
          {show == true ? (
            <>
              {data.slice(0, 20)}{" "}
              <button
                onClick={() => setShow(false)}
                className="btn btn-success btn-sm"
              >
                Read More
              </button>
            </>
          ) : (
            <>{data}</>
          )}
        </div>
      ) : (
        <div>{data}</div>
      )}
    </>
  );
};
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Category", link: "#" },
        { title: "Add & Manage Category", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      desc: "",
      duration: "",
      price: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      category_type: "",
      description: "",
      size: [{ name: "" }],
    };
  }

  componentDidMount = async () => {
    this.fetchData();
  };

  fetchData = async () => {
    let result = await HttpClient.requestData("category/Category", "GET");
    console.log("category", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        // let rmvtag = element.content.replace(/(<([^>]+)>)/ig, '');
        // let a = rmvtag.replace(/&nbsp;/g, '');
        let rows = {
          sl: i,
          name: element.name,
          date: moment(element.created_on).format("MMMM Do YYYY"),
          desc: <ShowMore data={element.content.replace(/<[^>]+>/g, "")} />,
          action: (
            <div className="d-flex">
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                  window.scrollTo(0, 0);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </div>
          ),
          status: element.status ? (
            <Button
              className="btn-icon"
              color="success"
              onClick={(e) => this.status(index, element._id)}
            >
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-wink-outline mr-2"></i>
              </span>{" "}
              Active
            </Button>
          ) : (
            <Button
              className="btn-icon"
              color="danger"
              onClick={(e) => this.status(index, element._id)}
            >
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-cry-outline mr-2"></i>
              </span>{" "}
              Deactive
            </Button>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteapi(item),
        },
        {
          label: "No",
          // onClick: () => alert("Click No"),
        },
      ],
    });
  };

  deleteapi = async (item) => {
    let sendData = {};
    let result = await HttpClient.requestData(
      "category/Category/" + item._id,
      "DELETE",
      sendData
    );
    if (result && result.status) {
      this.fetchData();
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      name: item.name,
      description: item.content,
      edit_item_id: item._id,
    });
  };
  submit = async () => {
    if (this.state.name != "") {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          name: this.state.name,
          content: this.state.description,
        };
        console.log("data", data);
        result = await HttpClient.requestData(
          "category/Category",
          "POST",
          data
        );
      } else {
        data = {
          name: this.state.name,
          content: this.state.description,
        };
        console.log(data);
        result = await HttpClient.requestData(
          "category/Category/" + this.state.edit_item_id,
          "PUT",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Category Updated Successfully"
            : "Category Added Successfully",
          type: "success",
          name: "",
          description: "",
          edit_enable: false,
          edit_item_id: "",
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message: result.error,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({ category_type: event.target.value });
  };

  imageUpload = async (e) => {
    console.log("e", e.target.files);
    let file = e.target.files;
    //   if(file.lenght>0)
    //   {
    let dataSend = { buckate: true, buckate_name: "appIcon" };

    let result = await HttpClient.newFileUpload(
      "image-upload/category",
      e.target.files[0],
      dataSend
    );
    // console.log("result", result);
    if (result && result.status) {
      this.setState({ img_url: result.url, image_select: true });
    }

    //   }
  };

  status = async (index, id) => {
    let sendData = {};
    let result = await HttpClient.requestData(
      "category/set-status/" + id,
      "PUT",
      sendData
    );
    if (result && result.status) {
      this.fetchData();
    }
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 100,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Date",
          field: "date",
          sort: "asc",
          width: 270,
        },

        {
          label: "Description",
          field: "desc",
          sort: "asc",
          width: 270,
        },

        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 270,
        },
      ],
      rows: this.state.data,
    };

    const header = [
      { label: "Sl.", key: "sl" },
      { label: "Name", key: "name" },
      { label: "Date", key: "date" },
      { label: "Description", key: "desc" },
    ];

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Name
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.name}
                          placeholder="Enter Category"
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ name: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      {/* <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Description
                      </Label>
                      <Col md={10}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={this.state.description}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            console.log({ event, editor, data });
                            this.setState({
                              description: data,
                            });
                          }}
                          value={this.state.description}
                        />
                      </Col> */}
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Description
                      </Label>
                      <Col md={10}>
                        <textarea
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder="Enter description here..."
                          onChange={(val) => {
                            this.setState({ description: val.target.value });
                          }}
                          value={this.state.description}
                        ></textarea>
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={3}>
                {this.state.data.length > 0 ? (
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"Category.csv"}
                    className="btn btn-success"
                  >
                    Export Report in CSV
                  </CSVLink>
                ) : null}
              </Col>
            </Row>
            &nbsp;
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable
                    responsive
                    responsiveLg
                    bordered
                    autoWidth={true}
                    fixed={true}
                    data={data}
                  />
                  {/* <MDBDataTable
                    striped
                    bordered
                    hover
                    data={data}
                    scrollX
                  /> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
