import React, { Component } from "react";
import ReactLoading from "react-loading";
import "../../App.css"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import { CSVLink } from "react-csv";
import HttpClient from "./../../utils/HttpClient";
import Details from "./details";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Approve Seller", link: "#" },
        { title: "Manage Approve Seller", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},

      image_select: false,
      img_url: "",
      title: "",
      points: [""],
      id: "",
      duration: 0,
      seller_comission: "",
      seller_status_data: [
        { value: "false", label: "Not Approve" },
        { value: "true", label: "Approved" },
      ],
      seller_status: "",
      type: "",
      loading: false,
      desc: "",
      from_date: "",
      to_date: "",
    };
    this.handleDefault = this.handleDefault.bind(this);
  }

  componentDidMount = async () => {
    this.checkUser();
    this.fetchData();
  };

  handleDefault(date) {
    this.setState({ default_date: date });
  }
  status = async (item, id) => {
    let sendData = {};
    // alert(id);
    let result = await HttpClient.requestData(
      "user/set-status/" + id,
      "PUT",
      sendData
    );
    console.log("result", result);
    if (result && result.status) {
      this.fetchData();
    }
  };
  fetchData = async (status) => {
    let data = {
      user_category: "User",
      datefrom: this.state.from_date,
      dateto: this.state.to_date,
      sel_status: status
    };

    this.state.loading = true;
    this.setState({});
    console.log("sel_status", data);
    let result = await HttpClient.requestData("seller-requests", "POST", data);
    console.log("seller-requests", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.state.loading = false;
      this.setState({});
      let arr = result.data.reverse();
      this.setState({ result: arr });
      // console.log(this.state.pointarr);
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,

          username: element.name,
          email: element.email,
          address: element.address,
          phone: element.phone,
          govt_name: element.govt_id_name,
          govt_id: element.govt_id,
          subscription_status: element.subscription_history.length && element.subscription_history[0].status ? (
            <Button
              className="btn-icon"
              color="primary"
            >
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-wink-outline mr-2"></i>

              </span>{" "}
              Active
            </Button>
          ) : (
            <Button
              className="btn-icon"
              color="danger"
            >
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-wink-outline mr-2"></i>

              </span>{" "}
              Deactive
            </Button>
          ),
          subscription: element.subscription_history.length ? (element.subscription_history[0].status && element.subscription_history[0].subscription_data != undefined ? element.subscription_history[0].subscription_data.name : "") : "",
          image: (
            <a href={HttpClient.IMG_URL + element.image} target="_blank">
              <img
                src={HttpClient.IMG_URL + element.image}
                alt="img"
                style={{ width: "100px", height: "100px", borderRadius: "6px" }}
              />
            </a>
          ),
          approve: element.seller_status ? (
            <Button
              className="btn-icon"
              color="primary"
              onClick={(e) => this.approve(index, element._id)}
            >
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-wink-outline mr-2"></i>
              </span>{" "}
              Approved
            </Button>
          ) : (
            <Button
              className="btn-icon"
              color="success"
              onClick={(e) => this.approve(index, element._id)}
            >
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-wink-outline mr-2"></i>
              </span>{" "}
              Approve
            </Button>
          ),
          view: (
            <button
              title="View Details"
              className="btn btn-success mr-2"
              onClick={() => {
                this.setState({
                  activeid: element,
                });
                setTimeout(() => {
                  this.setState({
                    ismodalopen: true,
                    idneed: element._id,
                  });
                }, 200);
              }}
            >
              View Details
            </button>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  approve = async (item, id) => {
    let sendData = {};
    console.log(id);
    let result = await HttpClient.requestData(
      "approve-seller-requests/" + id,
      "PUT",
      sendData
    );
    console.log("result", result);
    if (result && result.status) {
      this.fetchData(false);
      this.setState({
        alert: true,
        message: "Seller Approve Successfully.",
        type: "success",
      })
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 2000);
    }
  };

  disapprove = async (item, id) => {
    let sendData = {};
    // alert(id);
    let result = await HttpClient.requestData(
      "user/set-status/" + id,
      "PUT",
      sendData
    );
    console.log("result", result);
    if (result && result.status) {
      this.fetchData();
    }
  };
  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    console.log(sendData);
    let result = await HttpClient.requestData(
      "admin/ecom_banner/delete",
      "POST",
      sendData
    );
    console.log(result);
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Succfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      title: item.name,
      price: item.price,
      edit_item_id: item._id,
      seller_comission: item.seller_comission,
      duration: item.duration,
      id: item.id,
      type: item.type,
    });
  };

  submit = async () => {
    if (
      this.state.title != "" &&
      this.state.price != "" &&
      this.state.duration != "" &&
      this.state.seller_comission != "" &&
      this.state.type != ""
    ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          name: this.state.title,
          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData("subscription", "POST", data);
      } else {
        data = {
          name: this.state.title,
          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData(
          "subscription/" + this.state.edit_item_id,
          "PUT",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Subscription Updated Successfully"
            : "Subscription Added Succfully",

          type: "success",

          image_select: false,
          img_url: "",
          title: "",
          type: "",
          seller_comission: "",
          duration: "",
          price: "",
          desc: "",
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (e) => {
    let file = e.target.files[0].type;
    let fileType = file.split("/")[0];
    // fileType = fileType[0];
    console.log("e", file.split("/")[0]);

    if (fileType == "image" || fileType == "video") {
      // return false;
      this.setState({ fileType: fileType });
      let dataSend = { buckate: true, buckate_name: "appIcon" };

      let result = await HttpClient.newFileUpload(
        "image-upload/movie",
        e.target.files[0],
        dataSend
      );
      console.log("result", result);
      if (result && result.status) {
        this.setState({ img_url: result.url, image_select: true });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  onCloseCallBack = (val) => {
    console.log("object", val);
    this.setState({
      ismodalopen: val,
    });
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",

          width: 150,
        },
        // {
        //   label: "Image",
        //   field: "img",
        //
        //   width: 270,
        // },
        {
          label: "Seller Name",
          field: "username",

          width: 170,
        },
        {
          label: "Email",
          field: "email",

          width: 170,
        },
        {
          label: "Phone",
          field: "phone",

          width: 170,
        },
        {
          label: "Address",
          field: "address",

          width: 170,
        },
        {
          label: "Govt.Id Name",
          field: "govt_name",

          width: 170,
        },
        {
          label: "Govt.Id Number",
          field: "govt_id",

          width: 170,
        },
        {
          label: "Image",
          field: "image",

          width: 170,
        },
        {
          label: "Approve Status",
          field: "approve",

          width: 170,
        },
        {
          label: "Subscription Status",
          field: "subscription_status",

          width: 170,
        },
        {
          label: "Subscription Plan",
          field: "subscription",

          width: 170,
        },
        {
          label: "View Details",
          field: "view",
          width: 100,
        },

        // {
        //   label: "Disapprove",
        //   field: "disapprove",

        //   width: 100,
        // },
      ],
      rows: this.state.data,
    };
    const header = [
      { label: "Sl.", key: "sl" },
      { label: "Seller Name", key: "username" },
      { label: "Email", key: "email" },
      { label: "Phone", key: "phone" },
      { label: "Address", key: "address" },
      { label: "Govt.Id Name", key: "govt_name" },
      { label: "Govt.Id Number", key: "govt_id" },
    ];
    return (
      <React.Fragment>

        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row className="mb-3">
              <Col md={3}>
                <select
                  className="form-control"
                  onChange={(val) => {
                    console.log(val.target.value);
                    this.setState({ seller_status: val.target.value });
                    if (val.target.value == "Approved") {
                      this.fetchData(true);
                    } else if (val.target.value == "Not Approve") {
                      this.fetchData(false);
                    }

                  }}
                  value={this.state.datevalue}
                >
                  <option value="">Select Status</option>
                  {this.state.seller_status_data.length > 0 &&
                    this.state.seller_status_data.map((it, index) => {
                      return <option value={it.label} key={index}>{it.label}</option>;
                    })}
                </select>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        From Date
                      </Label>
                      <Col md={3}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.from_date}
                          id="example-text-input"
                          placeholder="Enter From Date"
                          onChange={(val) => {
                            this.setState({ from_date: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        To Date
                      </Label>
                      <Col md={3}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.to_date}
                          id="example-text-input"
                          placeholder="Enter To Date"
                          onChange={(val) => {
                            this.setState({
                              to_date: val.target.value,
                              enable: true,
                            });
                          }}
                        />
                      </Col>
                      <Col md={2}>
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.fetchData();
                          }}
                          disabled={!this.state.enable}
                        >
                          Search
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </Col>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={3}>
                {this.state.data.length > 0 ? (
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"ApproveSeller.csv"}
                    className="btn btn-success"
                  >
                    Export Report in CSV
                  </CSVLink>
                ) : null}
              </Col>
            </Row>
            &nbsp;
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  <MDBDataTableV5
                    responsive
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                    scrollX
                  />
                </div>
                {this.state.ismodalopen ? (
                  <Details
                    status={true}
                    onCloseCallBack={this.onCloseCallBack}
                    data={this.state.activeid}
                    id={this.state.idneed}
                  />
                ) : null}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
