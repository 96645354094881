import React, { Component } from "react";
import "./ind432a.css";

export default class design_1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // backgroundImage: this.props.backgroundImage,
      // item: this.props.item,
      // user: this.props.item.user,
      loop: [1,2,3]
    };
  }

  render() {
    return (
      <div className="designed_area w-100 h-100 bg-light relative">
        <div className="d-flex h-100 w-100">
          <div className="left_aside_icon">
            <div className="d-flex flex-column">
              {this.state.loop.map((item, index) => {
                return (
                  <div className="d-flex flex-column mb-3">
                    <span className="m-auto text-center">
                      <i className="ri-twitter-line font-size-24"></i>
                      {/* <img src={item.iconUrl} style={{height:40,width:40}} /> */}
                    </span>
                    <span className="m-auto text-center font-weight-bold">
                      Icon name
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="right_aside_panel">
            {this.state.loop.map((item, index) => {
              return (
                <div
                  className="panel_item d-flex w-100 py-5"
                  style={{
                    backgroundImage: `url('https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg')`,
                  }}
                >
                  <div className="text_block py-2 px-2">
                    <h5 className="text-white">App Title</h5>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
