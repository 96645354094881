import React, { useState, useEffect } from "react";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

//import ReactJsAlert from "reactjs-alert";

function Details(props) {
  console.log("objecttt porps----", props.data);
  const [data, setdata] = useState({});
  const [processing_fee, setprocessing_fee] = useState(0);
  const [name, setname] = useState("");
  const [image, setimage] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const onCloseMethod = async () => {
    props.onCloseCallBack(false);
  };

  const fetchData = async () => {
    let result = await HttpClient.requestData("servicedetails", "GET");
    // console.log("abc", result.processing_fees.processing_fees);
    if (result && result.status) {
      // setprocessing_fee(result.processing_fees.processing_fees);
      console.log(processing_fee);
    }
  };

  // const { selectedOption } = this.state;
  return (
    <div
      className={props.status ? "modal show fade" : "modal fade"}
      id="add-new-task-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="add-new-task-modalLabel"
      aria-hidden="true"
      style={{ display: props.status ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="add-new-task-modalLabel">
              View Details
            </h4>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => {
                onCloseMethod();
              }}
            >
              ×
            </button>
          </div>
          <div
            className="modal-body"
            style={{ height: "300px", overflowY: "auto" }}
          >
            <div className="px-2"></div>

            {props.data.servicecart_data.map((item, index) => {
              return (
                <>
                  <div className="d-md-flex  my-2  ">
                    <div className="order_pic ">
                      <img
                        src={HttpClient.IMG_URL + item.image}
                        alt="img"
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "6px",
                        }}
                      />
                    </div>
                    <div className="px-3 py-2">
                      <h4>{item.servicename}</h4>
                      <h5>Order Id: {item.order_id}</h5>
                      <h6>₹ {item.price_inr}</h6>
                      {/* <h6>{item.currency ? item.currency : ""}</h6> */}
                      <h6>
                        Seller Name:{" "}
                        {item.seller_data[0].firstName +
                          " " +
                          item.seller_data[0].lastName}
                      </h6>
                      <h6>Seller Email: {item.seller_data[0].email}</h6>
                      <h6>
                        Booking Date: {moment(item.booking_date).format("lll")}
                      </h6>
                      <h6>Additional Notes: {item.addn_note != "" ? item.addn_note : "No notes"}</h6>
                      {/* <h5>{item.seller_data.addn_note}</h5>
                      <p>Quantity: {item.qty} </p> */}
                    </div>
                  </div>
                </>
              );
            })}
            <hr />
            {/* <div className="d-md-flex  my-2  ">
              <div className="order_pic ">
                <img
                  src={HttpClient.IMG_URL + props.data.servicecart_data.image}
                  alt="img"
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "6px",
                  }}
                />
              </div>
              <div className="px-3 py-2">
                <h4>{props.data.servicecart_data.servicename}</h4>
                <h5>Order Id: {props.data.servicecart_data.order_id}</h5>
                <h6>{props.data.servicecart_data.price}</h6>
                <h6>{props.data.servicecart_data.currency ? props.data.servicecart_data.currency : ""}</h6>
                <h6>
                  {props.data.servicecart_data.seller_data.firstName + " " + props.data.servicecart_data.seller_data.lastName}
                </h6>
                <h6>{props.data.servicecart_data.seller_data.email}</h6>
                <h6>{moment(props.data.servicecart_data.booking_date).format("ll")}</h6>
                <h5>{item.seller_data.addn_note}</h5>
                <p>Quantity: {item.qty} </p>
              </div>
            </div> */}

            <div className="form-group row">
              <div className="col-md-4">
                <label htmlFor="task-title">User Name</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={
                    props.data.firstname + " " + props.data.lastname
                  }
                  readOnly
                />
              </div>

              <div className="col-md-8">
                <label htmlFor="task-title">Email</label>

                <input
                  style={{ height: "37px" }}
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.email ? props.data.email : ""}
                  readOnly
                />
              </div>

              <div className="col-md-4">
                <label htmlFor="task-title">Country</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={
                    props.data.country
                      ? props.data.country
                      : ""
                  }
                  readOnly
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="task-title">State</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={
                    props.data.state ? props.data.state : ""
                  }
                  readOnly
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="task-title">Total</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={
                    props.data.total_inr ? props.data.total_inr.toFixed(2) : ""
                  }
                  readOnly
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="task-title">Sub Total</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={
                    props.data.subtotal_inr ? props.data.subtotal_inr : ""
                  }
                  readOnly
                />
              </div>
              {/* <div className="col-md-4">
                <label htmlFor="task-title">order id</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={data ? data._id : ""}
                  readOnly
                />
              </div> */}

              {/* <div className="col-md-4">
                <label htmlFor="task-title">Date</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={moment(
                    props.data.servicecart_data.length > 0 &&
                      props.data.servicecart_data[0].booking_date
                  ).format("MMMM Do YYYY")}
                  readOnly
                />
              </div> */}

              <div className="col-md-4">
                <label htmlFor="task-title">Payment Mode</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.paymenttype ? props.data.paymenttype : ""}
                  readOnly
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="task-title">Tip Amount</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.servicecart_data.length ? props.data.servicecart_data[0].tip : 0}
                  readOnly
                />
              </div>
              <div className="col-md-8">
                <label htmlFor="task-title">Payment Geteway Id</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.tokenid ? props.data.tokenid : ""}
                  readOnly
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="task-title">Processing Fees</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.servicecart_data[0]?.processing && props.data.servicecart_data[0]?.processing + "%"}
                  readOnly
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="task-title">Discount</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.coupon ? props.data.coupon.discount_value : 0}
                  readOnly
                />
              </div>
            </div>

            <div className="text-right">
              <button
                type="button"
                className="btn btn-warning mr-3"
                data-dismiss="modal"
                onClick={() => {
                  onCloseMethod();
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}
export default Details;
