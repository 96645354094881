import React, { Component } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledAlert,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardFooter,
  CardDeck,
  CardColumns,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from "reactstrap";

import { Link } from "react-router-dom";
import classnames from "classnames";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// import images
import img1 from "../../assets/images/small/img-1.jpg";
import img2 from "../../assets/images/small/img-2.jpg";
import img3 from "../../assets/images/small/img-3.jpg";
import img4 from "../../assets/images/small/img-4.jpg";
import img5 from "../../assets/images/small/img-5.jpg";
import img6 from "../../assets/images/small/img-6.jpg";
import img7 from "../../assets/images/small/img-7.jpg";
import dumy from "../../assets/images/dumyy.jpg"

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Profile", link: "#" },
        { title: "Edit Profile", link: "#" },
      ],
      activeTab: "1",
      plans: [],
      activePlan: [],
      userId: "",
      userDetails: {},
      alert: false,
      message: "",
      type: "",

      name_old: "",
      email_old: "",
      mobile_old: "",

      arr: [],
      name: "",
      email: "",
      mobile: "",
      address: "",
      edit_enable: true,
      _id: "",
      new_pass: "",
      con_new_pass: "",
      oldpassword: "",
      imageurl: "",
      password: "",
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount = () => {
    this.checkUser();
    this.fetchUser();
  };

  fetchUser = async () => {
    let sendData = {};
    // console.log("sendData", sendData);
    let result = await HttpClient.requestData(
      "get-profile/" + this.state.userId,
      "GET",
      sendData
    );
    console.log("userdata", result);
    if (result && result.status) {
      this.setState({
        userDetails: result.data,
        // image: result.data.image,
        name: result.data.fullname,
        email: result.data.email,
        mobile: result.data.mobile,
        _id: result.data._id,
        arr: [
          {
            preview: result.data.image!= null ? HttpClient.IMG_URL + result.data.image : dumy,
          },
        ],
        password: result.data.password,

        // name_old: result.data.name,
        // email_old: result.data.email,
        // mobile_old: result.data.mobile,
        address: result.data.address ? result.data.address : "",
      });
    } else {
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    // console.log("profile", user);
    if (user != null && Object.keys(user).length > 0) {
      this.setState({ userId: user._id });
      console.log(this.state.userId);
      this.fetchUser();
    } else {
    }
  };

  formValid = async (item) => {
    let sendData = {
      owner_id: Number(this.state.userId),
      sub_id: Number(item.id),
    };
    let result = await HttpClient.requestData(
      "app-owner/subscription/buy-now",
      "POST",
      sendData
    );
    if (result && result.status > 0) {
      this.setState({
        alert: true,
        message: "Plan Subscribed Successfully",
        type: "success",
      });
      this.fetchActivePlans();
      this.fetchPlans();
    } else {
      this.setState({ alert: true, message: result.error, type: "danger" });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (e) => {
    console.log("e", e.target.files);
    let file = e.target.files;

    let obj = {
      preview: URL.createObjectURL(e.target.files[0]),
    };
    let arrray = [obj];
    this.setState({
      arr: arrray,
      image_select: true,
      imageurl: e.target.files[0],
    });

    // console.log("imfgdsstes", this.state.arr);
    // }

    //   }
  };

  submit = async () => {
    if (
      this.state.name != "" &&
      this.state.email != "" &&
      this.state.mobile != "" &&
      this.state.image != ""
    ) {
      let data = null;
      let result = null;

      data = new FormData();

      data.append("fullname", this.state.name);
      if (this.state.imageurl != "") {
        data.append("image", this.state.imageurl);
      }

      if (this.state.mobile != "") {
        data.append("mobile", this.state.mobile);
      }

      if (this.state.address != "") {
        data.append("address", this.state.address);
      }

      data.append("email", this.state.email);
      data.append("password", this.state.password);
      console.log("update data",data);
      result = await HttpClient.fileUplode(
        "update-profile/" + this.state._id,
        "PUT",
        data
      );
        console.log("update profile---",result);
      if (result && result.status) {
        reactLocalStorage.setObject("userData", result.data);
        // window.location.href="/";

        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Profile Updated Successfully"
            : "Profile Added Successfully",
          type: "success",
        });

        this.fetchUser();
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  submitPass = async () => {
    if (this.state.new_pass != "" && this.state.con_new_pass != "") {
      if (this.state.new_pass == this.state.con_new_pass) {
        let data = null;
        let result = null;
        // return false;
        data = {
          new_password: this.state.new_pass,
          old_password: this.state.oldpassword,
          cnf_password: this.state.con_new_pass,
        };
        console.log("data", data);
        result = await HttpClient.requestData(
          "update-password/" + this.state._id,
          "PUT",
          data
        );

        if (result && result.status) {
          reactLocalStorage.setObject("userData", result.data);
          this.setState({
            alert: true,
            message: this.state.edit_enable
              ? "Password Updated Successfully"
              : "Password Added Successfully",
            type: "success",
            new_pass: "",
            oldpassword: "",
            con_new_pass: "",
          });

          this.fetchUser();
        } else {
          this.setState({
            alert: true,
            message: result.message,
            type: "danger",
          });
        }
      } else {
        this.setState({
          alert: true,
          message: "Password Not Matched.",
          type: "warning",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Enter Password And Confirm Password",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col lg={12}>
                {this.state.alert ? (
                  <UncontrolledAlert
                    color={this.state.type}
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.type == "warning" ? (
                      <i className="mdi mdi-alert-outline mr-2"></i>
                    ) : this.state.type == "success" ? (
                      <i className="mdi mdi-check-all mr-2"></i>
                    ) : this.state.type == "danger" ? (
                      <i className="mdi mdi-block-helper mr-2"></i>
                    ) : null}
                    {this.state.message}
                  </UncontrolledAlert>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col xs={4} className="mb-6">
                <Card>
                  {/* {console.log("gerrtrwt", this.state.arr.length)} */}
                  {this.state.arr.length > 0 &&
                    this.state.arr.map((item, index) => {
                      return (
                        <CardImg
                          key={index}
                          top
                          className="img-fluid"
                          src={item.preview}
                          alt="Skote"
                          style={{
                            alignSelf: "center",
                            width: 200,
                            height: 200,
                            borderRadius: 100,
                            marginTop: 20,
                          }}
                        />
                      );
                    })}

                  <CardBody>
                    <CardTitle className="mt-0">Profile Details</CardTitle>
                    <div>
                      <small class="text-muted">Name: </small>
                      <p>{this.state.name}</p>
                      <hr />
                      <small class="text-muted">Mobile Number: </small>
                      <p>{this.state.mobile}</p>
                      <hr /> <small class="text-muted">Email address: </small>
                      <p>{this.state.email}</p>
                      <hr />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={8} className="mb-6">
                <Card>
                  <CardBody>
                    <h4 className="card-title">Edit Profile</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Name
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          defaultValue="Artisanal kale"
                          id="example-text-input"
                          value={this.state.name}
                          onChange={(val) => {
                            if (
                              val.target.value.match("^[a-zA-Z0-9 ]*$") != null
                            ) {
                              this.setState({ name: val.target.value });
                            }
                          }}
                          placeholder={"Enter name"}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Email
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          defaultValue="Artisanal kale"
                          id="example-text-input"
                          value={this.state.email}
                          onChange={(val) => {
                            this.setState({ email: val.target.value });
                          }}
                          placeholder={"Enter email"}
                          readOnly
                        />
                      </Col>
                    </FormGroup>{" "}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Mobile
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          defaultValue="Artisanal kale"
                          id="example-text-input"
                          value={this.state.mobile}
                          onChange={(val) => {
                            this.setState({ mobile: val.target.value });
                          }}
                          placeholder={"Enter mobile"}
                        />
                      </Col>
                    </FormGroup>{" "}
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Address
                      </Label>
                      <Col md={10}>
                        <textarea
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder="Enter your question here..."
                          onChange={(val) => {
                            this.setState({ address: val.target.value });
                          }}
                          value={this.state.address}
                        ></textarea>
                      </Col>
                    </FormGroup>{" "}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Profile Image
                      </Label>
                      <Col md={10}>
                        <div className="custom-file">
                          {/* <CustomInput
                            type="file"
                            className="custom-file-input"
                            id="customFile"
                            onChange={(e) => {
                              this.imageUpload(e);
                            }}
                          /> */}
                          <input
                            type="file"
                            className="custom-file-input"
                            id="customFile"
                            onChange={(e) => {
                              this.imageUpload(e);
                            }}
                          />
                          <Label
                            className="custom-file-label"
                            htmlFor="customFile"
                          >
                            Choose file
                          </Label>
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          // type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Update"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Edit Password</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Old Password
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="password"
                          id="example-text-input"
                          value={this.state.oldpassword}
                          onChange={(val) => {
                            this.setState({ oldpassword: val.target.value });
                          }}
                          placeholder={"Old Password"}
                        />
                      </Col>
                    </FormGroup>{" "}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Enter New Password
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="password"
                          id="example-text-input"
                          value={this.state.new_pass}
                          onChange={(val) => {
                            this.setState({ new_pass: val.target.value });
                          }}
                          placeholder={"Enter New Password"}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Confirm New Password
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="password"
                          id="example-text-input"
                          value={this.state.con_new_pass}
                          onChange={(val) => {
                            this.setState({ con_new_pass: val.target.value });
                          }}
                          placeholder={"Confirm New Password"}
                        />
                      </Col>
                    </FormGroup>{" "}
                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submitPass();
                          }}
                        >
                          {this.state.edit_enable
                            ? "Update Password"
                            : "Update Password"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Pricing;
