import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    FormGroup,
    Label,
    Input,
    CustomInput,
    // Button,
    UncontrolledAlert,
    InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import { CSVLink } from "react-csv";
import HttpClient from "./../../utils/HttpClient";
import index from "../ChooseDesign";
class FormElements extends Component {
    fileObj = [];
    fileArray = [];

    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Service Country Sales Report", link: "#" },
                { title: "Product Country Sales Report", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            name: "",
            email: "",
            mobile: "",
            password: "",
            data: [],
            alert: false,
            message: "",
            type: "",
            result: [],
            edit_enable: false,
            edit_item_id: "",
            image_select: false,
            img_url: "",
            userId: "",
            userDetails: {},
            image_select: false,
            img_url: "",
            title: "",
            points: [""],
            id: "",
            duration: 0,
            seller_comission: "",
            type: "",
            desc: "",
            modal: false,
            arr: [],
            order: "",
            result1: [],
            cart: [],
            filter: "",
            ismodalopen: false,
            activeid: {},
            from_date: "",
            to_date: "",
            enable: false,
        };

    }





    submit = async () => {
        let data = {
            start_date: this.state.from_date,
            end_date: this.state.to_date,
        };
        console.log(data);
        let result = await HttpClient.requestData("service-sales-report", "POST", data);
        console.log("result", result);
        if (result && result.status) {
            let data = [];
            let i = 1;
            // if (apidata.length > 0) {
            result.data.forEach((element, index) => {
                let rows = {
                    sl: i,

                    total: "₹" + element.salesValue,
                    country: element._id ? element._id.country : "",
                    date: moment(element._id.date).format("MMMM Do YYYY"),

                    status: (
                        <button
                            title="Delete"
                            className={
                                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
                            }
                        >
                            {element.status == "cancel" ? "Cancelled" : "Confirmed"}
                        </button>
                    ),
                    view: (
                        <button
                            title="Delete"
                            className="btn btn-success mr-2"
                            onClick={() => {
                                this.setState({
                                    activeid: element,
                                });
                                setTimeout(() => {
                                    this.setState({
                                        ismodalopen: true,
                                    });
                                }, 200);
                            }}
                        >
                            View Details
                        </button>
                    ),
                };
                i++;
                data.push(rows);
            });
            this.setState({
                data: data,
            });
        } else {
            this.setState({
                data: [],
            });
        }
    };



    render() {
        const data = {
            columns: [
                {
                    label: "Sl.",
                    field: "sl",
                    width: 150,
                },
                // {
                //     label: "User Name",
                //     field: "username",

                //     width: 270,
                // },

                // {
                //     label: "Order Id",
                //     field: "order_id",
                //     width: 270,
                // },
                {
                    label: "Total Sales Value",
                    field: "total",
                    width: 270,
                },
                {
                    label: "Country",
                    field: "country",

                    width: 270,
                },
                {
                    label: "Booking Date",
                    field: "date",

                    width: 270,
                },
                // {
                //     label: "Discount",
                //     field: "discount",

                //     width: 270,
                // },
                // {
                //     label: "Total Amount",
                //     field: "seller_comission",

                //     width: 270,
                // },
                // {
                //     label: "User Name",
                //     field: "username",

                //     width: 270,
                // },
                // {
                //   label: "Points",
                //   field: "points",

                //   width: 270,
                // },
                // {
                //     label: "Status",
                //     field: "status",

                //     width: 100,
                // },

                // {
                //     label: "Status",
                //     field: "status",
                //     width: 100,
                // },
                // {
                //     label: "View Details",
                //     field: "view",
                //     width: 100,
                // },
            ],
            rows: this.state.data,
        };
        const header = [

            { label: "Sl.", key: "sl" },
            { label: "Total sales value", key: "total" },
            { label: "Country", key: "country" },
            { label: "Booking Date", key: "date" },
        ]
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.state.breadcrumbItems[0].title}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <FormGroup row>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                From Date
                                            </Label>
                                            <Col md={3}>
                                                <Input
                                                    className="form-control"
                                                    type="date"
                                                    value={this.state.from_date}
                                                    id="example-text-input"
                                                    placeholder="Enter From Date"
                                                    onChange={(val) => {
                                                        this.setState({ from_date: val.target.value });
                                                    }}
                                                />
                                            </Col>
                                            <Label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                To Date
                                            </Label>
                                            <Col md={3}>
                                                <Input
                                                    className="form-control"
                                                    type="date"
                                                    value={this.state.to_date}
                                                    id="example-text-input"
                                                    placeholder="Enter To Date"
                                                    onChange={(val) => {
                                                        this.setState({
                                                            to_date: val.target.value,
                                                            enable: true,
                                                        });
                                                    }}
                                                />
                                            </Col>
                                            <Col md={2}>
                                                <Button
                                                    color="primary"
                                                    type="button"
                                                    className="waves-effect waves-light mr-1"
                                                    onClick={() => {
                                                        this.submit();
                                                    }}
                                                    disabled={!this.state.enable}
                                                >
                                                    Search
                                                    <i className="ri-arrow-right-line align-middle ml-1"></i>
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={3}>
                                {this.state.data.length > 0 ?
                                    <CSVLink
                                        data={this.state.data}
                                        headers={header}
                                        filename={"SalesReport.csv"}
                                        className="btn btn-success">
                                        Export Report in CSV
                                    </CSVLink> : null}
                            </Col>
                        </Row>&nbsp;

                        <Row>
                            <Col xl={12}>
                                <div className="card p-3">

                                    <MDBDataTableV5
                                        hover
                                        entriesOptions={[5, 20, 25]}
                                        entries={5}
                                        pagesAmount={4}
                                        data={data}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FormElements;
