import React, { Component } from "react";
import ReactTags from "react-tag-autocomplete";
import ReactLoading from "react-loading";
// import "../../App.css";
import "./../../App.css"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import { CSVLink } from "react-csv";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
import EcomPreview from "./E-ComPreview/index";
import Select from "react-select";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import index from "../ChooseDesign";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";

class FormElements extends Component {
  fileObj = [];
  fileArray = [];
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Product", link: "#" },
        { title: "Add & Manage Product", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      //   this page states
      category: [],
      categoryAll: [],
      subCategory: [],
      subCategoryAll: [],

      cat_id: "",
      pruduct_type: "",
      sub_cat_id: "",

      // selectedFiles: [],
      images: "",
      uploaded_images: [],

      selling_price: 0,
      selling_pricetax: 0,
      mrp: 0,
      purchase_price: "",
      discount: "",
      availableSize: [],
      availableColor: [],

      optionGroupSize: [{ value: "Red", code: "" }],
      selectedMultiSize: [],

      showSelectColor: false,
      showSelect: false,
      colorRgb: "#4667CC",
      simple_color1: false,
      color: [{ code: "#4667CC", active: false }],
      description: "",
      delivery_type: "",
      delivery_amount: "",
      delivery_time: "",
      preview: [],
      multiImages: [],
      refresh: false,
      highlightArray: [{ highlight: "" }],
      highlight: [],
      highlightArray: [],
      suggestions: [],
      tags: [],
      fieldValue: null,
      delimiters: [],
      loading: false,
      currencyTyp: [
        {
          name: "INR",
        },
        {
          name: "USD",
        },
        {
          name: "GBP",
        },
        {
          name: "EUR",
        },
        {
          name: "CAD",
        },
        // {
        //   name: "cdd",
        // },
      ],
      currency: "",
      offerpercentage: 0,
    };

    this.handleMulti = this.handleMulti.bind(this);
    this.onDragRgb = this.onDragRgb.bind(this);
  }
  onDragRgb(index, c1) {
    // alert(c1);
    this.state.color[index].code = c1;
    this.setState({ colorRgb: c1 });
  }

  handleMulti = (selectedMultiSize) => {
    this.setState({ selectedMultiSize });
    setTimeout(() => {
      console.log("selectedMulti", selectedMultiSize);
    });
  };

  componentDidMount = async () => {
    this.checkUser();
    this.fetchCat();
  };

  fetchCat = async () => {
    let result = await HttpClient.requestData("category/Category", "GET");
    if (result && result.status > 0) {
      this.setState({ categoryAll: result.data });
    }
    console.log("result cat", result);
    // let resultsub = await HttpClient.requestData("admin/sub-category", "GET");
    // console.log("resultsub", resultsub);
    // if (resultsub && resultsub.status > 0) {
    //   this.setState({ subCategoryAll: resultsub.data });
    // }
  };

  fetchData = async () => {
    let result = await HttpClient.requestData("product/Product", "GET");
    console.log("admin/product", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let tax_percent = element.tax.replace("%", "");
        let b = Number(element.selling_price) * (Number(tax_percent) / 100);
        let selling_priceWithTax = (element.selling_price + b).toFixed(2);
        console.log('selling_priceWithTax',selling_priceWithTax);
        let rows = {
          sl: i,
          name: element.name,
          img: element.image ? (
            <div>
              <img
                src={HttpClient.IMG_URL + element.image[0]}
                alt="images"
                className="rounded avatar-md card-img"
              />
            </div>
          ) : null,
          category: element.category_data.length
            ? element.category_data[0].name
            : "",
          // selling_price: selling_priceWithTax ? selling_priceWithTax : "",
          selling_price: element.selling_price ? element.selling_price : "",
          ot: element.offer + "%+" + element.tax,
          mrp: element.mrp ? element.mrp : "",
          delevery_amount: element.delivery_amount
            ? element.delivery_amount
            : "",
          date: moment(element.created_on).format("MMMM Do YYYY"),
          status: element.adminStatus ? (
            <Button
              className="btn-icon"
              color="success"
              onClick={(e) => this.status(index, element._id)}
            >
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-wink-outline mr-2"></i>
              </span>{" "}
              Active
            </Button>
          ) : (
            <Button
              className="btn-icon"
              color="danger"
              onClick={(e) => this.status(index, element._id)}
            >
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-cry-outline mr-2"></i>
              </span>{" "}
              Inactive
            </Button>
          ),
          action: (
            <div className="d-flex">
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                  window.scrollTo(0, 0);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </div>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
      // callOffer(
      //   val.target.value,
      //   this.state.offerpercentage
      // );
    } else {
      this.setState({
        data: [],
      });
    }
  };

  deleteapi = async (item) => {
    let sendData = {};
    let result = await HttpClient.requestData(
      "product/Product/" + item._id,
      "DELETE",
      sendData
    );
    if (result && result.status) {
      this.fetchData();
    }
  };

  delete = async (item, index) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteapi(item),
        },
        {
          label: "No",
          // onClick: () => alert("Click No"),
        },
      ],
    });
  };
  edit = async (item, index) => {
    console.log("item", item);
    let names = item.hasOwnProperty("highlights") && item.highlights != null && item.highlights.map(function (item) {
      return item['name'];
    });
    console.log("item highlight", names);
    let arr = [];
    item.image.forEach((element) => {
      let obj = {
        preview: HttpClient.IMG_URL + element,
      };
      arr.push(obj);
    });
    this.state.tax = item.tax;
    let a = item.mrp * (item.offer / 100);
    console.log("fgfg", item);
    this.state.selling_price = item.mrp - a;
    let tax_percent = item.tax.replace("%", "");
    let b = item.selling_price * (tax_percent / 100);
    console.log("bdd",item.mrp - a, (tax_percent / 100));
    this.setState({
      edit_enable: true,
      name: item.name,
      // img_url: item.img,
      // image_select: true,
      edit_item_id: item._id,
      // cat_id: item.catID,
      description: item.description,
      // selling_pricetax: (item.selling_price + b).toFixed(2),
      selling_pricetax:( item.mrp - a) *(tax_percent / 100) +  item.mrp - a ,
      mrp: item.mrp,
      uploaded_images: arr,
      multiImages: item.image,
      delivery_type: item.delivery,
      delivery_time: item.delivery_time,
      currency: item.currency,
      tags: names != false ? names : [],
      // tags: item.highlights,
      // tags: item.hasOwnProperty("highlights") ? item.highlights : [{}],
      delivery_amount: item.delivery_amount,

      offerpercentage: item.offer,
      selling_price: item.mrp- a,
    });
  };
  status = async (index, id) => {
    let sendData = {};
    let result = await HttpClient.requestData(
      "product/set-status/" + id,
      "PUT",
      sendData
    );
    if (result && result.status) {
      this.fetchData();
    }
  };

  getTax = async (currency) => {
    let data = {
      currency: currency,
    };
    console.log("data", data);
    let result = await HttpClient.requestData(
      "currency/tax-rates",
      "POST",
      data
    );
    if (result && result.status) {
      console.log("result", result.data);
      this.state.tax = result.data.tax_rate;

      this.setState({});
      this.callOffer(
        this.state.offerpercentage != "" ? this.state.offerpercentage : 0
      );
    }
  };

  submit = async () => {
    if (
      this.state.name != "" &&
      // this.state.cat_id != "" &&
      this.state.description != "" &&
      this.state.mrp != "" &&
      // this.state.offerpercentage != "" &&
      this.state.currency != "" &&
      this.state.delivery_type != ""
      // this.state.tags.length > 0
    ) {
      if (this.state.uploaded_images.length > 0) {
        if (
          this.state.mrp > 0 &&
          this.state.offerpercentage > -1 &&
          this.state.selling_price > 0
        ) {
          // alert("hji");
          this.setState({ loading: true });

          let data = null;
          let result = null;

          if (this.state.edit_enable) {
            let data = {
              name: this.state.name,
              description: this.state.description,
              mrp: parseInt(this.state.mrp),
              selling_price: parseInt(this.state.selling_pricetax),
              selling_priceWith_tax: parseInt(this.state.selling_price),
              image: this.state.multiImages,
              catID: this.state.cat_id,
              delivery: this.state.delivery_type,
              delivery_amount: this.state.delivery_amount
                ? this.state.delivery_amount
                : 0,
              delivery_time: this.state.delivery_time,
              currency: this.state.currency,
              tax_rate: parseInt(this.state.tax),
              offer: parseInt(this.state.offerpercentage),
              highlights: JSON.stringify(this.state.fieldValue),
            };
            console.log("product data edit----", data);
            // return false
            this.state.loading = true;
            this.setState({});
            result = await HttpClient.requestData(
              "product/Product/" + this.state.edit_item_id,
              "PUT",
              data
            );
          } else {
            let data = {
              name: this.state.name,
              description: this.state.description,
              mrp: parseInt(this.state.mrp),
              selling_price: parseInt(this.state.selling_pricetax),
              selling_pricetax: this.state.selling_pricetax,
              image: this.state.multiImages,
              catID: this.state.cat_id,
              delivery: this.state.delivery_type,
              delivery_amount: this.state.delivery_amount,
              delivery_time: this.state.delivery_time,
              currency: this.state.currency,
              tax_rate: parseInt(this.state.tax),
              offer: parseInt(this.state.offerpercentage),
              highlights: JSON.stringify(this.state.fieldValue),
            };

            console.log("product data----", data);
            // return false
            this.state.loading = true;
            this.setState({});
            result = await HttpClient.requestData(
              "product/Product",
              "POST",
              data
            );
          }
          console.log("resultt", result);

          if (result && result.status) {
            this.setState({
              alert: true,
              message: this.state.edit_enable
                ? "Product Updated Successfully"
                : "Product Added Successfully",
              type: "success",
              name: "",
              cat_id: "",
              sub_cat_id: "",
              description: "",
              delivery_amount: "",
              delivery_time: "",
              selling_price: 0,
              mrp: 0,
              tax: 0,
              offerpercentage: 0,
              delivery_type: "",
              currency: "",
              uploaded_images: [],
              tags: [],
              // images: {},
              edit_enable: false,
              edit_item_id: "",
              loading: false,
            });

            this.fetchData();
          } else {
            this.setState({
              alert: true,
              message:
                typeof result.error == "string"
                  ? result.error
                  : result.error[0].msg,
              type: "danger",
            });
          }
        } else {
          this.setState({
            alert: true,
            message: "Please Enter correct mrp/percentage/selling price",
            type: "warning",
          });
        }
      } else {
        this.setState({
          alert: true,
          message: "Please Select Atleast One Image.",
          type: "warning",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  handleAcceptedFiles = (files) => {
    if (files.length > 0) {
      const imageArr = Array.from(files);
      console.log("imageArr", imageArr);
      const imageUrlArray = [];
      imageArr.forEach((file) => {
        // imageUrlArray.push({ preview: URL.createObjectURL(file) });
        this.state.uploaded_images.push({ preview: URL.createObjectURL(file) });
      });

      this.setState({
        uploaded_images: this.state.uploaded_images,
      });
      this.imageUploadApi(imageArr);
      this.setState({
        refresh: !this.state.refresh,
      });
    }
  };

  async remove_image(item, index) {
    this.setState({
      images: {},
    });
    this.state.uploaded_images.pop(index);
    this.state.multiImages.pop(index);
    this.setState({});
  }

  // deleteFile = (e) => {
  //   const s = preview.filter((item, index) => index !== e);
  //   setpreview(s);
  //   console.log(s);
  // };

  ImagesUrl = (url) => {
    let tempUrlArr = this.state.multiImages;
    tempUrlArr.push(url);

    this.setState({
      multiImages: tempUrlArr,
    });
    console.log("multiImages", this.state.multiImages);
  };

  imageUploadApi = async (files) => {
    console.log("files", files);
    for (const file of files) {
      let data = new FormData();
      data.append("image", file);
      this.state.loading = true;
      this.setState({});
      let result = await HttpClient.fileUplode(
        "product-uploadurl",
        "POST",
        data
      );
      console.log("reuslkyet", result);

      if (result && result.status) {
        this.ImagesUrl(result.data);
        this.setState({ loading: false })
        window.scrollTo(300, 200)
      }
    }
  };

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  handleChange = async (event) => {
    // let filterSub = this.state.categoryAll.filter(
    //   (item) => item._id == event.target.value
    // );
    this.setState({ cat_id: event.target.value });

    // let catSize = this.state.categoryAll.filter(
    //   (item) => item.id == event.target.value
    // );
    // let optionAppowner = {};
    // optionAppowner.label = "Sizes";
    // optionAppowner.options = [];
    // catSize[0].size.map((item, index) => {
    //   optionAppowner.options.push({
    //     label: item.name,
    //     value: item.name,
    //   });
    // });

    // let newOptions = [];
    // newOptions.push(optionAppowner);

    // this.setState({ optionGroupSize: newOptions, showSelect: true });

    // setTimeout(() => {
    //   console.log("optionGroupSize", this.state.optionGroupSize);
    // }, 200);
  };
  onDelete(i) {
    // const tags = this.state.tags.slice(0)
    // tags.splice(i, 1)
    // this.setState({ tags })
    // console.log(this.state.tags);
    this.state.tags.splice(i, 1);
    this.setState({ tags: this.state.tags });
    console.log(this.state.tags);
  }

  onAddition(tag) {
    console.log(tag);
    this.state.tags.push(tag);
    this.setState({ tags: this.state.tags });
    console.log("tags", this.state.tags);
  }
  handleChangeTag = (tags) => {
    this.setState({ tags });
    console.log("selected tags", tags);
    let arr = [];
    tags != null && tags.map((item) => {
      let obj = {
        name: item,
      }
      arr.push(obj);
    })
    this.state.fieldValue = arr;
    this.setState({});
    console.log("fieldvalue---", this.state.fieldValue);
    // let ob = tags.reduce((a, v) => ({ ...a, [v]: v }), {});
    // arr.push(ob);
    // this.state.fieldValue = arr;
    // this.setState({});
    // console.log("ob", ob);
    // console.log("arr", this.state.fieldValue);
  };

  handleChangedelivery = async (event) => {
    this.setState({ delivery_type: event.target.value });
  };

  handleChangedeliverytime = async (event) => {
    this.setState({ delivery_time: event.target.value });
  };
  callOffer = async (mrp, val) => {
    console.log("offer---", mrp, val);
    this.state.offerpercentage = val;
    this.setState({});
    let a =
      mrp *
      ((this.state.offerpercentage == "" ? 0 : this.state.offerpercentage) /
        100);
    console.log("fgfg", a);
    console.log("abv", mrp);
    this.state.selling_price = (mrp - a).toFixed(2);
    this.setState({});
    console.log("tax", this.state.tax);
    if (typeof this.state.tax == "string") {
      console.log("IF");
      let tax_percent = this.state.tax.replace("%", "");
      console.log("taxper", tax_percent);
      console.log("selling_price", this.state.selling_price);
      let b = Number(this.state.selling_price) * (Number(tax_percent) / 100);
      console.log("fjkj", b);

      // this.state.selling_pricetax = Number(
      //   this.state.selling_price + b
      // ).toFixed(2);
      this.state.selling_pricetax = (
        Number(this.state.selling_price) + Number(b)
      ).toFixed(2);

      this.setState({});
      console.log("selling_pricetax", this.state.selling_pricetax);
    } else {
      console.log("ELSE");
      let tax_percent = this.state.tax;
      console.log("taxper", tax_percent);
      let b = Number(this.state.selling_price) * (Number(tax_percent) / 100);
      console.log("fjkj", b);
      console.log("sellub", this.state.selling_price);

      this.state.selling_pricetax = (
        Number(this.state.selling_price) + b
      ).toFixed(2);

      this.setState({});
      console.log("selling", this.state.selling_pricetax);
    }
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "Name",
          },
        },
        {
          label: "Name",
          field: "name",

          width: 270,
        },
        {
          label: "Image",
          field: "img",

          width: 270,
        },
        // {
        //   label: "Type",
        //   field: "product_type",
        //
        //   width: 270,
        // },

        // {
        //   label: "Category",
        //   field: "category",

        //   width: 270,
        // },
        // {
        //   label: "Sub Category",
        //   field: "subcategory",
        //
        //   width: 270,
        // },
        {
          label: "MRP",
          field: "mrp",

          width: 270,
        },
        {
          label: "Offer & Tax",
          field: "ot",

          width: 270,
        },
        {
          label: "Selling Price with Tax",
          field: "selling_price",

          width: 270,
        },
        {
          label: "Delivery Amount",
          field: "delevery_amount",

          width: 270,
        },

        // {
        //   label: "Purchase Price",
        //   field: "purchase_price",
        //
        //   width: 270,
        // },
        {
          label: "Date",
          field: "date",
          width: 270,
        },
        {
          label: "Status",
          field: "status",

          width: 100,
        },
        {
          label: "Action",
          field: "action",

          width: 100,
        },
      ],
      rows: this.state.data,
    };

    const header = [
      { label: "Sl.", key: "sl" },
      { label: "Name", key: "name" },
      { label: "Category", key: "category" },
      { label: "MRP", key: "mrp" },
      { label: "Offer & Tax", key: "ot" },
      { label: "Selling Price with Tax", key: "selling_price" },
      { label: "Delivery Amount", key: "delevery_amount" },
      { label: "Date", key: "date" },
    ];

    return (
      <React.Fragment>
        {this.state.loading ? (
          <ReactLoading
            type="spokes"
            color="black"
            height={"5%"}
            width={"5%"}
            className="loader"
          />
        ) : (
          <div className="page-content">
            <Container fluid>
              <Breadcrumbs
                title={this.state.breadcrumbItems[0].title}
                breadcrumbItems={this.state.breadcrumbItems}
              />
              <Row>
                {/* <BaseInfo /> */}
                <Col xs={12}>
                  <Card>
                    <CardBody>
                      {this.state.alert ? (
                        <UncontrolledAlert
                          color={this.state.type}
                          className="alert-dismissible fade show"
                          role="alert"
                        >
                          {this.state.type == "warning" ? (
                            <i className="mdi mdi-alert-outline mr-2"></i>
                          ) : this.state.type == "success" ? (
                            <i className="mdi mdi-check-all mr-2"></i>
                          ) : this.state.type == "danger" ? (
                            <i className="mdi mdi-block-helper mr-2"></i>
                          ) : null}
                          {this.state.message}
                        </UncontrolledAlert>
                      ) : null}
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Product Name
                        </Label>
                        <Col md={10}>
                          <Input
                            className="form-control"
                            type="text"
                            value={this.state.name}
                            id="example-text-input"
                            placeholder="Enter Product Name"
                            onChange={(val) => {
                              this.setState({ name: val.target.value });
                            }}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label
                          htmlFor="example-search-input"
                          className="col-md-2 col-form-label"
                        >
                          Description
                        </Label>
                        <Col md={10}>
                          <textarea
                            //editor={ClassicEditor}
                            className="form-control"
                            type="text"
                            placeholder="Enter Description"
                            // data={this.state.description}
                            // onChange={(event, editor) => {
                            //   const data = editor.getData();
                            //   console.log({ event, editor, data });
                            //   this.setState({
                            //     description: data.replace(/<[^>]+>/g, ""),
                            //   });
                            // }}
                            onChange={(val) => {
                              // console.log("hkk", val.target.value)
                              this.setState({ description: val.target.value });
                            }}
                            value={this.state.description}
                          ></textarea>
                        </Col>
                      </FormGroup>

                      {/* <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Category
                        </Label>
                        <Col md={10}>
                          <select
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.cat_id}
                          >
                            <option value="">Select Category</option>
                            {this.state.categoryAll.map((item, index) => {
                              return (
                                <option value={item._id} key={index}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </Col>
                      </FormGroup> */}

                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Currency
                        </Label>
                        <Col md={10}>
                          <select
                            className="form-control"
                            onChange={(val) => {
                              this.state.currency = val.target.value;
                              this.setState({});
                              this.getTax(this.state.currency);
                              this.setState({ offerpercentage: 0 });
                              this.setState({ mrp: "" });
                            }}
                            value={this.state.currency}
                          >
                            <option value="">Select Currency</option>
                            {this.state.currencyTyp.map((item, index) => {
                              return (
                                <option value={item.name} key={index}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </Col>
                      </FormGroup>
                      {this.state.currency != "" ? (
                        <>
                          <FormGroup row>
                            <Label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Tax(%)
                            </Label>
                            <Col md={10}>
                              <Input
                                className="form-control"
                                type="text"
                                value={this.state.tax}
                                placeholder="Tax"
                                id="example-text-input"
                                onChange={(val) => {
                                  this.setState({ tax: val.target.value });
                                }}
                                disabled
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Product Price
                            </Label>
                            <Col md={10}>
                              <Input
                                className="form-control"
                                type="number"
                                // inputmode="numeric"
                                value={this.state.mrp}
                                placeholder="Product Price"
                                id="example-text-input"
                                onChange={(val) => {
                                  // this.state.mrp = val.target.value;
                                  // this.setState({});
                                  this.setState({ mrp: val.target.value });
                                  this.callOffer(
                                    val.target.value,
                                    this.state.offerpercentage
                                  );
                                  console.log("mrp", this.state.mrp);
                                  if (this.state.mrp == "") {
                                    this.state.offerpercentage = 0;
                                    this.state.selling_price = 0;
                                    this.state.selling_pricetax = 0;
                                    this.setState({});
                                  }
                                }}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Offer(%)
                            </Label>
                            <Col md={10}>
                              <Input
                                className="form-control"
                                type="number"
                                // inputmode="numeric"
                                value={this.state.offerpercentage}
                                placeholder="Offer Percentage"
                                id="example-text-input"
                                onChange={(val) => {
                                  this.callOffer(
                                    this.state.mrp,
                                    val.target.value
                                  );
                                }}
                                readOnly={this.state.mrp == "" ? true : false}
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Selling Price
                            </Label>
                            <Col md={10}>
                              <Input
                                className="form-control"
                                type="number"
                                // inputmode="numeric"
                                value={this.state.selling_price}
                                placeholder="Enter Product Price"
                                id="example-text-input"
                                // onChange={(val) => {
                                //   this.setState({
                                //     selling_price: val.target.value,
                                //   });
                                // }}
                                disabled
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Selling Price with tax
                            </Label>
                            <Col md={10}>
                              <Input
                                className="form-control"
                                type="number"
                                // inputmode="numeric"
                                value={this.state.selling_pricetax}
                                placeholder="Selling Price with tax"
                                id="example-text-input"
                                readOnly
                              />
                            </Col>
                          </FormGroup>
                        </>
                      ) : null}

                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Delivery Type
                        </Label>
                        <Col md={10}>
                          <select
                            className="form-control"
                            onChange={this.handleChangedelivery}
                            value={this.state.delivery_type}
                          >
                            <option value="">Select Delivery Type</option>
                            <option value="Free Delivery">Free Delivery</option>
                            <option value="Paid Delivery">Paid Delivery</option>
                            {/* <option value="Ready to Dispatch">
                            Ready to Dispatch
                          </option>
                          <option value="1 business day">1 business day</option>
                          <option value="1-4 Business days"> */}
                            {/* 1-4 Business days */}
                            {/* </option> */}
                          </select>
                        </Col>
                      </FormGroup>
                      {this.state.delivery_type == "Paid Delivery" ? (
                        <FormGroup row>
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Delivery Amount
                          </Label>
                          <Col md={10}>
                            <Input
                              className="form-control"
                              type="number"
                              // inputmode="numeric"
                              value={this.state.delivery_amount}
                              placeholder="Enter Delivery Amount"
                              id="example-text-input"
                              onChange={(val) => {
                                this.setState({
                                  delivery_amount: val.target.value,
                                });
                              }}
                            />
                          </Col>
                        </FormGroup>
                      ) : null}

                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Delivery Time
                        </Label>
                        <Col md={10}>
                          <select
                            className="form-control"
                            onChange={this.handleChangedeliverytime}
                            value={this.state.delivery_time}
                          >
                            <option value="">Select Delivery Time</option>

                            <option value="Ready to Dispatch">
                              Ready to Dispatch
                            </option>
                            <option value="1 business day">
                              1 business day
                            </option>
                            <option value="1-4 Business days">
                              1-4 Business days
                            </option>
                          </select>
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Image
                          <span
                            className="required"
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            [300*300]
                          </span>
                        </Label>
                        <Col md={10}>
                          <Dropzone
                            onDrop={(acceptedFiles) =>
                              this.handleAcceptedFiles(acceptedFiles)
                            }
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick mt-2"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {this.state.uploaded_images.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          // alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Button
                                          className="btn-icon"
                                          color="danger"
                                          style={{ marginRight: 25 }}
                                          onClick={(e) =>
                                            this.remove_image(f, i)
                                          }
                                        >
                                          {" "}
                                          <span className="btn-icon-label">
                                            <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                          </span>{" "}
                                          Remove
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              );
                            })}
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Highlights
                        </Label>
                        <Col md={10}>
                          {/* <ReactTags
                            autocomplete="off"
                            className="react-tags__search-input"
                            placeholderText="Add new Highlights..."
                            onInput={(query) => {
                              // console.log("query", query);
                              let dom = {
                                id: this.state.suggestions.length + 1,
                                name: query,
                              };
                              console.log(dom);
                              this.state.suggestions.push(dom);

                              this.setState({});
                            }}
                            tags={this.state.tags}
                            suggestions={this.state.suggestions}
                            onDelete={this.onDelete.bind(this)}
                            onAddition={this.onAddition.bind(this)}
                          /> */}
                          <ReactTagInput
                            tags={this.state.tags}
                            placeholder="Type and press enter"
                            //   maxTags={10}
                            editable={true}
                            readOnly={false}
                            removeOnBackspace={true}
                            onChange={this.handleChangeTag}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-0">
                        <div className="button-items pt-4">
                          <Button
                            color="primary"
                            type="button"
                            className="waves-effect waves-light mr-1"
                            onClick={() => {
                              this.submit();
                            }}
                          >
                            {this.state.edit_enable ? "Update" : "Submit"}{" "}
                            <i className="ri-arrow-right-line align-middle ml-1"></i>
                          </Button>
                        </div>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={4}>
                  {/* <div>
                    <EcomPreview />
                  </div> */}
                </Col>
              </Row>
              <Row>
                <Col xl={3}>
                  {this.state.data.length > 0 ? (
                    <CSVLink
                      data={this.state.data}
                      headers={header}
                      filename={"Products.csv"}
                      className="btn btn-success"
                    >
                      Export Report in CSV
                    </CSVLink>
                  ) : null}
                </Col>
              </Row>
              &nbsp;
              <Row>
                <Col xl={12}>
                  <div className="card p-3">
                    {/* <MDBDataTable responsive bordered data={data} /> */}
                    <MDBDataTableV5
                      hover
                      entriesOptions={[5, 20, 25]}
                      entries={5}
                      pagesAmount={4}
                      data={data}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default FormElements;
