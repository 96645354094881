import React, { Component } from "react";
import { Label, CustomInput, UncontrolledAlert, Row, Col } from "reactstrap";
// import Select from "react-dropdown-select";
import Select from "react-select";
import HttpClient from "./../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";

const options = [
  {
    id: 1,
    name: "User Name",
  },
  {
    id: 2,
    name: "Cheery Name",
  },
  {
    id: 3,
    name: "Ervin Howell",
  },
];

const optionGroup = [
  {
    label: "Picnic",
    options: [
      { label: "Mustard", value: "Mustard" },
      { label: "Ketchup", value: "Ketchup" },
      { label: "Relish", value: "Relish" },
    ],
  },
  {
    label: "Camping",
    options: [
      { label: "Tent", value: "Tent" },
      { label: "Flashlight", value: "Flashlight" },
      { label: "Toilet Paper", value: "Toilet Paper" },
    ],
  },
];

export default class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showGroupModal: false,
      loop: [1, 2, 3, 4, 5, 6],
      selectValues: [],
      allUser: [],
      user_id: "",
      userDetails: {},
      optionGroup: [],
      selectedMulti: [],
      grpName: "",
      grpSubject: "",
      img_url: "",
      image_select: false,
      allGroup:[],

      alert: false,
      message: "",
      type: "",
    };
    this.handleMulti = this.handleMulti.bind(this);
  }
  handleMulti = (selectedMulti) => {
    this.setState({ selectedMulti });
  };
  setValues = (selectValues) => this.setState({ selectValues });
  componentDidMount = async () => {
    await this.checkUser();
  };
  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        user_id: user.id,
        userDetails: user,
      });
      this.fetchUser();
      this.fetchGroup(user.id);

    } else {
    }
  };

  fetchGroup = async (user_id) => {
    let result = await HttpClient.requestData(
      "app-owner/group/all-group",
      "POST",
      {owner_id:user_id}
    );
    console.log("group", result);
    if (result && result.status) {
      let data = result.data;
      this.setState({allGroup:data})
    }
  }

  fetchUser = async () => {
    let result = await HttpClient.requestData(
      "app-owner/group/user-list",
      "GET"
    );
    // console.log("group", result);
    if (result && result.status) {
      let data = result.data;
      data = data.filter((it) => it.id != this.state.user_id);
      console.log("group", data);
      this.setState({ allUser: data });
      let appOwners = data.filter((it) => it.appOwner == true);
      let appusers = data.filter(
        (it) => typeof it.appOwner == "undefined" || it.appOwner == false
      );

      let optionAppowner = {};
      optionAppowner.label = "App Owners";
      optionAppowner.options = [];
      appOwners.map((item, index) => {
        optionAppowner.options.push({
          label: item.name,
          value: item.id,
          appOwner: true,
          admin: false,
          image: item.image,
        });
      });

      let optionAppusers = {};
      optionAppusers.label = "App Users";
      optionAppusers.options = [];
      appusers.map((item, index) => {
        optionAppusers.options.push({
          label: item.name,
          value: item.id,
          appOwner: true,
          admin: false,
          image: item.image,
        });
      });

      let newOptions = [];
      newOptions.push(optionAppowner);
      newOptions.push(optionAppusers);
      this.setState({ optionGroup: newOptions });
      console.log("newOptions", newOptions);
    }
  };

  imageUpload = async (e) => {
    console.log("e", e.target.files);
    let file = e.target.files;
    //   if(file.lenght>0)
    //   {
    let dataSend = { buckate: true, buckate_name: "appIcon" };

    let result = await HttpClient.newFileUpload(
      "image-upload/createPost",
      e.target.files[0],
      dataSend
    );
    console.log("result", result);
    if (result && result.status) {
      this.setState({ img_url: result.url, image_select: true });
    }

    //   }
  };

  createGrp = async () => {
    console.log("selectedGrp", this.state.selectedMulti);
    let grpUsers = this.state.selectedMulti;
    if (grpUsers.length > 0) {
      if (
        this.state.grpName != "" &&
        this.state.grpSubject != "" &&
        this.state.img_url != ""
      ) {
        let grpAdmin = {
          label: this.state.userDetails.name,
          value: this.state.userDetails.id,
          appOwner: true,
          admin: false,
          image: this.state.userDetails.image,
        };
        grpUsers.push(grpAdmin);
        let data = {
          name: this.state.grpName,
          subject: this.state.grpSubject,
          members: grpUsers,
          owner_id: this.state.user_id,
          image:this.state.img_url
        };
        let result = await HttpClient.requestData(
          "app-owner/group",
          "POST",
          data
        );
        console.log('result',result);
        if(result && result.status)
        {
          this.setState({
            alert: true,
            message: "Group Created Successfully",
            type: "success",
            // showGroupModal: !this.state.showGroupModal,
            grpName:'',
            grpSubject:'',
            selectedMulti:[],
            img_url:'',

          });
          setTimeout(()=>{
            this.setState({
              showGroupModal: !this.state.showGroupModal,
            });
            this.fetchGroup(this.state.user_id)
          },1000)
        }
        else
        {
          this.setState({
            alert: true,
            message:
              typeof result.error == "string"
                ? result.error
                : result.error[0].msg,
            type: "danger",
          });
        }
      } else {
        this.setState({
          alert: true,
          message: "Please Fill Up All Details.",
          type: "warning",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Select Group Members.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <div className="bg-white p-3 rounded">
                  <div className="d-flex mb-3">
                    <h4 className="font-size-18 my-auto">Our User's List</h4>
                  </div>
                  {this.state.allUser.map((item, index) => {
                    return (
                      <div className="mb-4 media pb-3 shadow-sm" key={index}>
                        <img
                          className="avatar-sm mr-3 rounded-circle"
                          src={item.image}
                          alt="img"
                        />
                        <div className="media-body">
                          <h4 className="mt-0 font-size-14">{item.name}</h4>
                          <h5 className="mt-0 font-size-14">
                            Position placeholder
                          </h5>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="bg-white p-3 rounded">
                  <div className="d-flex mb-3">
                    <h4 className="font-size-18 my-auto">Our Group's List</h4>
                    <button
                      type="button"
                      className="my-auto ml-auto mr-2 btn btn-primary"
                      onClick={() => this.setState({ showGroupModal: true })}
                    >
                      Create Group{" "}
                      <i className="ri-add-fill align-middle ml-1"></i>
                    </button>
                  </div>
                  {this.state.allGroup.map((item, index) => {
                    return (
                      <div className="mb-4 media pb-3 shadow-sm" key={index}>
                        <img
                          className="avatar-sm mr-3 rounded-circle"
                          src={item.image}
                          alt="img"
                        />
                        <div className="media-body">
                          <h4 className="mt-0 font-size-14">{item.name}</h4>
                          <h5 className="mt-0 font-size-14">
                          {item.subject}
                          </h5>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* create group modal */}

            <div
              className={
                this.state.showGroupModal ? "modal fade show" : "modal fade"
              }
              id="addIdeaModal"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="addIdeaModalLabel"
              style={{ display: this.state.showGroupModal ? "block" : "none" }}
            >
              <div
                className="modal-dialog"
                role="document"
                style={{ marginTop: "7%" }}
              >
                <div className="modal-content">
                  <div className="modal-header bg-light">
                    <h5 className="modal-title" id="addIdeaModalLabel">
                      Create Group
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => this.setState({ showGroupModal: false })}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <div className="row mb-3">
                      <Row>
                        <Col xl={12}>
                          {this.state.alert ? (
                            <UncontrolledAlert
                              color={this.state.type}
                              className="alert-dismissible fade show"
                              role="alert"
                            >
                              {this.state.type == "warning" ? (
                                <i className="mdi mdi-alert-outline mr-2"></i>
                              ) : this.state.type == "success" ? (
                                <i className="mdi mdi-check-all mr-2"></i>
                              ) : this.state.type == "danger" ? (
                                <i className="mdi mdi-block-helper mr-2"></i>
                              ) : null}
                              {this.state.message}
                            </UncontrolledAlert>
                          ) : null}
                        </Col>
                      </Row>
                      <div className="col-md-6 col-12">
                        <Label for="icon_url"> Group Name</Label>
                        <input
                          type="text"
                          name="name"
                          size={40}
                          className="form-control"
                          placeholder="Enter Group name"
                          onChange={(val) => {
                            this.setState({ grpName: val.target.value });
                          }}
                        />
                      </div>
                      <div className="col-md-6 col-12">
                        <Label for="icon_url">Group Subject</Label>
                        <input
                          type="text"
                          name="your-subject"
                          size={40}
                          className="form-control"
                          placeholder="Group Subject"
                          onChange={(val) => {
                            this.setState({ grpSubject: val.target.value });
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <Label for="icon_url">Upload Group Image</Label>
                          <div className="custom-file">
                            <CustomInput
                              type="file"
                              className="custom-file-input"
                              id="customimage"
                              onChange={(e) => {
                                this.imageUpload(e);
                              }}
                            />
                            <Label
                              className="custom-file-label"
                              htmlFor="customimage"
                            >
                              Choose image
                            </Label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-12 mb-3">
                        <Label for="icon_url">Select Group Members</Label>
                        <Select
                          isMulti={true}
                          options={this.state.optionGroup}
                          values={this.state.selectedMulti}
                          onChange={
                            // (values) => this.setValues(values)
                            this.handleMulti
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        this.setState({
                          showGroupModal: !this.state.showGroupModal,
                        });
                      }}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        this.createGrp();
                      }}
                    >
                      Create Group
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
