import React, { useState, useEffect } from "react";
import HttpClient from "./../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link } from "react-router-dom";
import moment from "moment";
//import ReactJsAlert from "reactjs-alert";


function removeTags(str) {
  if ((str===null) || (str===''))
      return false;
  else
      str = str.toString();
        
  // Regular expression to identify HTML tags in 
  // the input string. Replacing the identified 
  // HTML tag with a null string.
  return str.replace( /(<([^>]+)>)/ig, '');
}
function Payment(props) {
  console.log("shop details---", props.data.shop_data);
  const shopDetails = props.data.shop_data
  const [tax, settax] = useState("");

  const [arr, setarr] = useState([]);

  const [profile, setprofile] = useState("");

  const [getKyc, setgetKyc] = useState([]);
  const [result, setresult] = useState([]);
  const [data, setdata] = useState([]);

  const userData = reactLocalStorage.getObject("userData");
  useEffect(() => {
    fetchsellerShp();
  }, [props.data]);

  const fetchsellerShp = async () => {
    let result = await HttpClient.requestData("sellerlist", "GET");
    console.log("seller single list", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      if (result.data.length > 0) {
        let arr = result.data.filter((it) => it._id == props.sellerid);
        console.log("arr", arr[0].shop_data);
        // setresult(arr[0].shop_data.length > 0 ? arr[0].shop_data[0] : null);
        setresult(arr[0].shop_data);
      }
    } else {
    }
  };

  const onCloseMethod = async () => {
    props.onCloseCallBack(false);
  };
  const onImageId = async (event) => {
    console.log("ss", event);

    let val = validation(event);
    console.log("img val", val);
    console.log("object", event.target.files[0]);

    if (val == true) {
      setprofile(event.target.files[0]);
      if (event.target.files[0] !== undefined) {
        setarr([
          {
            preview: URL.createObjectURL(event.target.files[0]),
          },
        ]);
      } else {
        setarr([]);
      }
    }
    // setImageValue("");
    // console.log("profile", profile);

    console.log("arr", arr);
  };

  const validation = (event) => {
    if (event.target.files[0] !== undefined) {
      let extension = event.target.files[0].name.split(".").pop();
      console.log("extension", extension);
      if (extension === "png" || extension === "jpg" || extension === "jpeg") {
        return true;
      } else {
        //   alert("File does not support. You must use .png or .jpg format");
        // settype("warning");
        // setstatus(true);
        // settitle("File does not support. You must use .png or .jpg format");
        window.alert("File does not support. You must use .png or .jpg ");
        return false;
      }
    } else {
    }
  };

  const pay = async () => {
    let data = new FormData();
    // var today = new Date(),
    //   date =
    //     today.getFullYear() +
    //     "-" +
    //     (today.getMonth() + 1) +
    //     "-" +
    //     today.getDate();
    data.append("id", props.data._id);
    data.append("seller_id", props.data.seller_id);
    data.append("txnid", tax);
    data.append("image", profile);
    let result = await HttpClient.fileUplode("paycomission", "POST", data);
    console.log("pay", result);
    if (result && result.status === true) {
      props.onCloseCallBack(false);
      props.sucess(result.status);
    }
  };
  // const { selectedOption } = this.state;
  return (
    <div
      className={props.status ? "modal show fade" : "modal fade"}
      id="add-new-task-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="add-new-task-modalLabel"
      aria-hidden="true"
      style={{ display: props.status ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="add-new-task-modalLabel">
              Seller Shop
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => {
                onCloseMethod();
              }}
            >
              ×
            </button>
          </div>
          {shopDetails.length
            ? shopDetails.map((item, index) => {
                return (
                  <div className="modal-body">
                    <div className="px-2"></div>

                    {/* <div className="form-group">
                  <label htmlFor="task-title">Booking Type</label>
                 
                  <input
                    type="text"
                    className="bg-white font-18 font-weight-semibold form-control text-dark"
                    id="task-title"
                    value={booking_type}
                    readOnly
                  />
                </div> */}
                    <div className="form-group row">
                      <div className="col-md-4">
                        <label htmlFor="task-title">Shop Image</label>
                        <img
                          src={HttpClient.IMG_URL + item.banner_img}
                          alt=""
                          height="50px"
                          width="50px"
                        />
                        {/* <input
                      type="text"
                      className="bg-white font-18 font-weight-semibold form-control text-dark"
                      id="task-title"
                      value={getKyc ? getKyc.name : ""}
                      readOnly
                      // onChange={(val) => settax(val.target.value)}
                    /> */}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="task-title">Banner Image</label>
                        <img
                          src={HttpClient.IMG_URL + item.shop_img}
                          alt=""
                          height="50px"
                          width="50px"
                        />
                        {/* <input
                      type="text"
                      className="bg-white font-18 font-weight-semibold form-control text-dark"
                      id="task-title"
                      value={getKyc ? getKyc.name : ""}
                      readOnly
                      // onChange={(val) => settax(val.target.value)}
                    /> */}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-4">
                        <label htmlFor="task-title">Shop Name</label>
                        <input
                          type="text"
                          className="bg-white font-18 font-weight-semibold form-control text-dark"
                          id="task-title"
                          value={item ? item.name : ""}
                          readOnly
                          // onChange={(val) => settax(val.target.value)}
                        />
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="task-title">Shop Title</label>
                        <input
                          type="text"
                          className="bg-white font-18 font-weight-semibold form-control text-dark"
                          id="task-title"
                          value={item ? item.title : ""}
                          readOnly
                          // onChange={(val) => settax(val.target.value)}
                        />
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="task-title">Tags</label>
                        <input
                          type="text"
                          className="bg-white font-18 font-weight-semibold form-control text-dark"
                          id="task-title"
                          value={item ? item.tags : ""}
                          readOnly
                          // onChange={(val) => settax(val.target.value)}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-6">
                        <label htmlFor="task-title">Description</label>

                        <textarea
                          type="text"
                          className="bg-white font-18 font-weight-semibold form-control text-dark"
                          id="task-title"
                          value={item ? removeTags(item.description) : ""}
                          readOnly
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="task-title">Personalization</label>

                        <textarea
                          type="text"
                          className="bg-white font-18 font-weight-semibold form-control text-dark"
                          id="task-title"
                          value={item ? removeTags(item.personalization) : ""}
                          readOnly
                        />
                      </div>
                      {/* <div className="col-md-4">
                    <label htmlFor="task-title">Transaction Image</label>
    
                    <input
                      type="file"
                      onChange={onImageId}
                      className="bg-white font-18 font-weight-semibold form-control text-dark"
                    />
                  </div>
                  <div className="col-md-4">
                    {arr.map((item, index) => {
                      console.log("object", item);
                      return (
                        <img
                          className="avatar ml-3 img-fluid img-thumbnail"
                          key={index}
                          alt="img"
                          style={{ width: "54px", height: "54px" }}
                          src={item.preview}
                        />
                      );
                    })}
                  </div> */}
                    </div>

                    <div className="text-right">
                      <button
                        type="button"
                        className="btn btn-warning mr-3"
                        data-dismiss="modal"
                        onClick={() => {
                          onCloseMethod();
                        }}
                      >
                        Cancel
                      </button>
                      {/* <button
                    type="button"
                    className="btn btn-primary"
                    // className="close"
    
                    onClick={pay}
                    data-dismiss="modal"
                  >
                    Delete Permanently
                  </button>&nbsp;
                  <button
                    type="button"
                    className="btn btn-primary"
                    // className="close"
    
                    onClick={pay}
                    data-dismiss="modal"
                  >
                    Delete Temporary
                  </button> */}
                      {/* {this.state.verifyotp == this.props.otp ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      // className="close"
    
                      onClick={() => {
                        this.AddDriver();
                      }}
                      data-dismiss="modal"
                    >
                      verify
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      // className="close"
    
                      onClick={() => {
                        this.AddDriver();
                      }}
                      data-dismiss="modal"
                    >
                      verify
                    </button>
                  )} */}
                    </div>
                  </div>
                );
              })
            : (
              <div className="modal-body">
                <p>No Shop Data Found</p>
              </div>
            )}
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}
export default Payment;
