import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  // Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import { CSVLink } from "react-csv";
import HttpClient from "./../../utils/HttpClient";
import index from "../ChooseDesign";
class FormElements extends Component {
  fileObj = [];
  fileArray = [];

  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Total Order Revenue By Date", link: "#" },
        { title: "Total Order Revenue By Date", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      image_select: false,
      img_url: "",
      title: "",
      points: [""],
      id: "",
      duration: 0,
      seller_comission: "",
      type: "",
      desc: "",
      modal: false,
      arr: [],
      order: "",
      result1: [],
      cart: [],
      filter: "",
      ismodalopen: false,
      activeid: {},
      from_date: "",
      to_date: "",
      enable: false,
      last_month: false,
      yesterday: false,
      today: false,
      data1: [],
      datevalue: "",
      product_order: "",
      product_revenue: "",
      service_order: "",
      service_revenue: "",
      //   datefilter: ["last_month", "yesterday", "today"],
      datefilter: [
        { label: "Last Month", value: "last_month" },
        { label: "Yesterday", value: "yesterday" },
        { label: "Today", value: "today" },
      ],
    };
  }

  // componentDidMount = async () => {
  //   const entries = document.querySelector('[data-test="mdb-datatable-entries"]');
  //   const row = entries.parentNode.parentNode.parentNode;

  //   row.style.display = 'none';
  // };

  submit = async (last, yes, tod) => {
    let data = {
      datefrom: this.state.from_date,
      dateto: this.state.to_date,
      last_month: last,
      yesterday: yes,
      today: tod,
    };
    console.log(data);
    let result = await HttpClient.requestData(
      "orders-and-rev-by-date",
      "POST",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      let data = [];
      // let i = 1;
      // if (apidata.length > 0) {

      let arr = result.products.filter((it) => it._id == "true");
      this.setState({ product_order: arr[0].numberOfOrders });
      this.setState({ product_revenue: arr[0].totalRevenue });

      arr.forEach((element, index) => {
        let rows = {
          // sl: i,

          numberofordertrue: element.numberOfOrders,
          totalrevenuetrue: "₹" + element.totalRevenue.toFixed(2),
        };

        data.push(rows);
      });
      let arr1 = result.services.filter((it) => it._id == "accept");
      this.setState({ service_order: arr1[0].numberOfOrders });
      this.setState({ service_revenue: arr1[0].totalRevenue });
      let data1 = [];
      arr1.forEach((element, index) => {
        let rows1 = {
          // sl: i,

          numberofordertrue: element.numberOfOrders,
          totalrevenuetrue: "₹" + element.totalRevenue.toFixed(2),
        };

        data1.push(rows1);
      });
      this.setState({
        data: data,
        data1: data1,
      });
    } else {
      this.setState({
        data: [],
        data1: [],
      });
    }
  };

  render() {
    const data = {
      columns: [
        // {
        //   label: "Sl.",
        //   field: "sl",
        //   width: 150,
        // },

        {
          label: "Product order",
          field: "numberofordertrue",
          width: 270,
        },
        {
          label: "Product Revenue",
          field: "totalrevenuetrue",
          width: 270,
        },
      ],
      rows: this.state.data,
    };
    const data1 = {
      columns: [
        // {
        //   label: "Sl.",
        //   field: "sl",
        //   width: 150,
        // },

        {
          label: "Service order",
          field: "numberofordertrue",
          width: 270,
        },
        {
          label: "Service Revenue",
          field: "totalrevenuetrue",
          width: 270,
        },
      ],
      rows: this.state.data1,
    };
    const header = [
      { label: "Sl.", key: "sl" },
      { label: "Total sales value", key: "total" },
      { label: "Country", key: "country" },
      { label: "Booking Date", key: "date" },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Col md={3}>
                      <select
                        className="form-control"
                        onChange={(val) => {
                          console.log("val.target.value", val.target.value);
                          if (val.target.value == "last_month") {
                            this.state.last_month = true;
                            this.state.yesterday = false;
                            this.state.today = false;
                            this.setState({
                              datevalue: val.target.value,
                            });
                            this.submit(
                              this.state.last_month,
                              this.state.yesterday,
                              this.state.today
                            );
                          } else if (val.target.value == "yesterday") {
                            this.state.last_month = false;
                            this.state.yesterday = true;
                            this.state.today = false;
                            this.setState({
                              datevalue: val.target.value,
                            });
                            this.submit(
                              this.state.last_month,
                              this.state.yesterday,
                              this.state.today
                            );
                          } else {
                            this.state.last_month = false;
                            this.state.yesterday = false;
                            this.state.today = true;
                            this.setState({
                              datevalue: val.target.value,
                            });
                            this.submit(
                              this.state.last_month,
                              this.state.yesterday,
                              this.state.today
                            );
                          }
                        }}
                        value={this.state.datevalue}
                      >
                        <option value="">Select Type</option>
                        {this.state.datefilter.length > 0 &&
                          this.state.datefilter.map((it, index) => {
                            return (
                              <option value={it.value} key={index}>
                                {it.label}
                              </option>
                            );
                          })}
                      </select>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* <Row>
                            <Col xl={3}>
                                {this.state.data.length > 0 ?
                                    <CSVLink
                                        data={this.state.data}
                                        headers={header}
                                        filename={"SalesReport.csv"}
                                        className="btn btn-success">
                                        Export Report in CSV
                                    </CSVLink> : null}
                            </Col>
                        </Row>&nbsp; */}
            {this.state.datevalue == "" ? null : (
              <>
                <Row>
                  <Col xl={12}>
                    {/* <div className="card p-3">
                      <MDBDataTableV5
                        searching={false} 
                        paging={false}
                        info={false}
                        data={data1}
                      />
                    </div> */}
                    <MDBTable style={{backgroundColor: "white"}}>
                      <MDBTableHead>
                        <tr>
                          <th>Service Order</th>
                          <th>Service Revenue</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        <tr>
                          <td>{this.state.service_order ? this.state.service_order : 0}</td>
                          <td>{this.state.service_revenue ? "₹ " + this.state.service_revenue : 0}</td>
                        </tr>
                      </MDBTableBody>
                    </MDBTable>
                  </Col>
                </Row>
                <Row>
                  <Col xl={12}>
                    <div className="card p-3">
                      {/* <MDBDataTableV5
                        searching={false}
                        paging={false}
                        info={false}
                        data={data}
                      /> */}
                      <MDBTable>
                      <MDBTableHead>
                        <tr>
                          <th>Product Order</th>
                          <th>Product Revenue</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        <tr>
                          <td>{this.state.product_order ? this.state.product_order : 0}</td>
                          <td>{this.state.product_revenue ? "₹ " + this.state.product_revenue : 0}</td>
                        </tr>
                      </MDBTableBody>
                    </MDBTable>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
