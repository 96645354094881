import React, { Component } from "react";

import HttpClient from "./../../utils/HttpClient";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";
import SalesAnalytics from "./SalesAnalytics";
import EarningReports from "./EarningReports";
import Sources from "./Sources";
import RecentlyActivity from "./RecentlyActivity";
import RevenueByLocations from "./RevenueByLocations";
import ChatBox from "./ChatBox";
import LatestTransactions from "./LatestTransactions";
import AcceptService from "./acceptservice";
import AcceptPrduct from "./acceptproduct";
import Totalwebsiteprofit from "./totalwebsiteprofit";
import SellerComission from "./sellercomission";
import TotalWebsiteRevenue from "./totalwebsiterevenue";
import AdminWallet from "./AdminWallet";
import ProcessingFees from "./processingFees"
import Sources1 from "./Sources1";
import LatestTransactions1 from "./LatestTransactions copy";

import {
    Button,
    Card,
    CardBody,
    Col,
    Row,
    Container,
    FormGroup,
    Label,
    Input,
    CustomInput,
    // Button,
    UncontrolledAlert,
    InputGroup,
} from "reactstrap";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Astrophy", link: "#" },
                { title: "Dashboard", link: "#" },
            ],
            reports: [
                {
                    icon: "ri-stack-line",
                    title: "Number of Sales",
                    value: "1452",
                    rate: "2.4%",
                    desc: "From previous period",
                },
                {
                    icon: "ri-store-2-line",
                    title: "Sales Revenue",
                    value: "$ 38452",
                    rate: "2.4%",
                    desc: "From previous period",
                },
                {
                    icon: "ri-briefcase-4-line",
                    title: "Average Price",
                    value: "$ 15.4",
                    rate: "2.4%",
                    desc: "From previous period",
                },
            ],
            activeuser: 0,
            approvedseller: 0,
            category: 0,
            service: 0,
            shop: 0,
            acceptserviceorder: 0,
            acceptservicerev: 0,
            acceptproductorder: 0,
            acceptproductrev: 0,
            total_rev: 0,
            website_profit: 0,
            last_month: false,
            yesterday: false,
            today: true,
            datevalue: "today",
            // datefilter: ["last_month", "yesterday", "today", "custom"],
            datefilter: [
                {label: "Last Month", value: "last_month"},
                {label: "Yesterday", value: "yesterday"},
                {label: "Today", value: "today"},
                {label: "Custom", value: "custom"},
            ],
            to_date: "",
            from_date: "",
            processing_fee: 0,
        };
    }

    componentDidMount() {
        this.dasboardData();
        this.dasboardDataa();
        this.dasboardDataa1();
    }

    dasboardData = async () => {
        // console.log("hiiu");

        let data = {
            datefrom: this.state.from_date,
            dateto: this.state.to_date,
            last_month: this.state.last_month,
            yesterday: this.state.yesterday,
            today: this.state.today,
        };
        console.log("data1", data);
        let result = await HttpClient.requestData("summary-stats", "POST", data);

        console.log("summary-stats", result);
        if (result && result.status) {
            this.setState({
                user: result.active_users,
                shop: result.active_shops,
                category: result.serv_category,
                service: result.active_shop_services,

                seller: result.approved_sellers,
            });
        } else {
        }
    };

    dasboardDataa = async () => {
        let data = {
            datefrom: this.state.from_date,
            dateto: this.state.to_date,
            last_month: this.state.last_month,
            yesterday: this.state.yesterday,
            today: this.state.today,
        };

        console.log("dash", data);
        let result = await HttpClient.requestData(
            "orders-and-rev-by-date",
            "POST",
            data
        );

        console.log("orders-and-rev-by-date", result);
        if (result && result.status) {
            if (result.services.length > 0) {
                let a = result.services.filter((it) => it._id == "accept");
                this.setState({
                    // result: result,
                    // total: result.total_bookings,
                    // pending: result.pending_bookings,
                    // accept: result.accepted_bookings,
                    // reject: result.rejected_bookings,
                    // user: result.user_count,
                    // seller: result.seller_count,
                    acceptserviceorder: a.length > 0 ? a[0].numberOfOrders : 0,
                    acceptservicerev: a.length > 0 ? a[0].totalRevenue : 0,
                    // acceptproductorder: b[0].numberOfOrders,
                    // acceptproductrev: b[0].totalRevenue,
                });
            }
            if (result.products.length > 0) {
                let b = result.products.filter((it) => it._id == "true");
                // console.log("b", b);
                this.setState({
                    // result: result,
                    // total: result.total_bookings,
                    // pending: result.pending_bookings,
                    // accept: result.accepted_bookings,
                    // reject: result.rejected_bookings,
                    // user: result.user_count,
                    // seller: result.seller_count,
                    // acceptserviceorder: a[0].numberOfOrders,
                    // acceptservicerev: a[0].totalRevenue,
                    acceptproductorder: b[0].numberOfOrders,
                    acceptproductrev: b[0].totalRevenue,
                });
            }

            console.log(this.state.accept);
        } else {
        }
    };

    dasboardDataa1 = async () => {
        let data = {
            datefrom: this.state.from_date,
            dateto: this.state.to_date,
            last_month: this.state.last_month,
            yesterday: this.state.yesterday,
            today: this.state.today,
        };
        console.log("dash part",data);
        let result = await HttpClient.requestData(
            "website-revenue-and-profit",
            "POST",
            data
        );

        console.log("website-revenue-and-profit", result);
        if (result && result.status) {
            let sellerComission = Math.abs((result.total_seller_commission - ( result.total_seller_commission * result.processingfees.processing_fees / 100).toFixed(2)));
            this.setState({
                total_rev: result.total_revenue,
                website_profit: result.website_profit,
                seller_comission: sellerComission,
                website_rev: result.total_revenue,
                processing_fee: result.processingfees.processing_fees,
            });
            // console.log(this.state.accept)
        } else {
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title="Dashboard"
                            breadcrumbItems={this.state.breadcrumbItems}
                        />
                        <Row className="mb-3">
                            <Col md={3}>
                                <select
                                    className="form-control"
                                    onChange={(val) => {
                                        console.log("val.target.value", val.target.value);
                                        if (val.target.value == "last_month") {
                                            this.state.last_month = true;
                                            this.state.yesterday = false;
                                            this.state.today = false;
                                            this.setState({
                                                datevalue: val.target.value,
                                            });
                                            this.dasboardData(
                                                this.state.last_month,
                                                this.state.yesterday,
                                                this.state.today
                                            );
                                            this.dasboardDataa(
                                                this.state.last_month,
                                                this.state.yesterday,
                                                this.state.today
                                            );
                                            this.dasboardDataa1(
                                                this.state.last_month,
                                                this.state.yesterday,
                                                this.state.today
                                            );
                                        } else if (val.target.value == "yesterday") {
                                            this.state.last_month = false;
                                            this.state.yesterday = true;
                                            this.state.today = false;
                                            this.setState({
                                                datevalue: val.target.value,
                                            });
                                            this.dasboardData(
                                                this.state.last_month,
                                                this.state.yesterday,
                                                this.state.today
                                            );
                                            this.dasboardDataa(
                                                this.state.last_month,
                                                this.state.yesterday,
                                                this.state.today
                                            );
                                            this.dasboardDataa1(
                                                this.state.last_month,
                                                this.state.yesterday,
                                                this.state.today
                                            );
                                        } else {
                                            this.state.last_month = false;
                                            this.state.yesterday = false;
                                            this.state.today = true;
                                            this.setState({
                                                datevalue: val.target.value,
                                            });
                                            this.dasboardData(
                                                this.state.last_month,
                                                this.state.yesterday,
                                                this.state.today
                                            );
                                            this.dasboardDataa(
                                                this.state.last_month,
                                                this.state.yesterday,
                                                this.state.today
                                            );
                                            this.dasboardDataa1(
                                                this.state.last_month,
                                                this.state.yesterday,
                                                this.state.today
                                            );
                                        }
                                    }}
                                    value={this.state.datevalue}
                                >
                                    <option value="">Select Type</option>
                                    {this.state.datefilter.length > 0 &&
                                        this.state.datefilter.map((it, index) => {
                                            return <option value={it.value}>{it.label}</option>;
                                        })}
                                </select>
                            </Col>
                        </Row>
                        {this.state.datevalue == "custom" ? (
                            <FormGroup row>
                                <Label
                                    htmlFor="example-text-input"
                                    className="col-md-2 col-form-label"
                                >
                                    From Date
                                </Label>
                                <Col md={3}>
                                    <Input
                                        className="form-control"
                                        type="date"
                                        value={this.state.from_to_date}
                                        id="example-text-input"
                                        placeholder="Enter From Date"
                                        onChange={(val) => {
                                            this.setState({ to_date: val.target.value });
                                        }}
                                    />
                                </Col>
                                <Label
                                    htmlFor="example-text-input"
                                    className="col-md-2 col-form-label"
                                >
                                    To Date
                                </Label>
                                <Col md={3}>
                                    <Input
                                        className="form-control"
                                        type="date"
                                        value={this.state.from_date}
                                        id="example-text-input"
                                        placeholder="Enter To Date"
                                        onChange={(val) => {
                                            this.setState({
                                                from_date: val.target.value,
                                                enable: true,
                                            });
                                        }}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Button
                                        color="primary"
                                        type="button"
                                        className="waves-effect waves-light mr-1"
                                        onClick={() => {
                                            this.state.last_month = false;
                                            this.state.yesterday = false;
                                            this.state.today = false;
                                            this.setState({});
                                            this.dasboardData();
                                            this.dasboardDataa();
                                            this.dasboardDataa1();
                                        }}
                                        disabled={!this.state.enable}
                                    >
                                        Search
                                        <i className="ri-arrow-right-line align-middle ml-1"></i>
                                    </Button>
                                </Col>
                            </FormGroup>
                        ) : null}

                        <Row>
                            {/* <Col xl={8}>
                                <Row>
                                    <MiniWidgets reports={this.state.reports} />
                                </Row>
                                
                                <RevenueAnalytics/>
                            </Col> */}
                            {/* 
                            <Col xl={4}>

                                <SalesAnalytics name={"Sales"}/>

                                <EarningReports/>

                            </Col> */}
                        </Row>

                        <Row>
                            {/* sources */}
                            <Sources totaluser={this.state.user ? this.state.user : 0} />

                            {/* recent activity */}
                            <RecentlyActivity
                                totalseller={this.state.seller ? this.state.seller : 0}
                            />

                            {/* revenue by locations */}
                            <RevenueByLocations
                                totalshop={this.state.shop ? this.state.shop : 0}
                            />
                        </Row>

                        <Row>
                            {/* chat box */}
                            <SalesAnalytics
                                totalcategory={this.state.category ? this.state.category : 0}
                            />

                            {/* latest transactions */}
                            <LatestTransactions
                                totalservice={this.state.service ? this.state.service : 0}
                            />

                            <SellerComission
                                totalsellercomission={
                                    this.state.seller_comission ? this.state.seller_comission : 0
                                }
                            />
                        </Row>

                        <Row>
                            {/* chat box */}
                            <Totalwebsiteprofit
                                profit={
                                    this.state.website_profit ? (this.state.website_profit).toFixed(2) : 0
                                }
                            />
                            <TotalWebsiteRevenue
                                rev={this.state.website_rev ? (this.state.website_rev).toFixed(2) : 0}
                            />
                            <ProcessingFees
                                processing_fee={this.state.processing_fee ? this.state.processing_fee : 0}
                            />
                        </Row>
                        <Row>
                            <AcceptService
                                order={
                                    this.state.acceptserviceorder
                                        ? this.state.acceptserviceorder
                                        : 0
                                }
                                rev={
                                    this.state.acceptservicerev ? this.state.acceptservicerev : 0
                                }
                            />
                            {/* chat box */}
                            <AcceptPrduct
                                order={
                                    this.state.acceptproductorder
                                        ? this.state.acceptproductorder
                                        : 0
                                }
                                rev={
                                    this.state.acceptproductrev ? this.state.acceptproductrev : 0
                                }
                            />
                             <AdminWallet
                                rev={this.state.website_rev ? (this.state.website_rev).toFixed(2) : 0}
                            />
                            {/* latest transactions */}
                        </Row>
                        {/* <Row>

                            <Sources1 />

                            <LatestTransactions1 />

                        </Row> */}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Dashboard;
