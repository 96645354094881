import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
import Select from "react-select";
import ProgressBar from "@ramonak/react-progress-bar";

// import EcomPreview from "./E-ComPreview/index";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Video", link: "#" },
        { title: "Add & Manage Video", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      //   this page states
      category: [],
      categoryAll: [],

      selectedFiles: [],
      images: [],
      uploaded_images: [],

      optionGroup: [],
      selectedMulti: [],

      optionGroupALL: [],
      selectedMultiAll: [],

      optionGroupCat: [],
      selectedMultiCat: [],

      director: "",
      desc: "",
      season: "",
      trailer: "",
      showSelect: true,
      submit: false,
      uploading_trailer: false,
      trailerPlaylists: "",
      cat_id: "",
      cast: "",
      progressBarParsent: 0,
      progressBarParsentVideo: 0,
      uploading_movie: false,

      videoUrl: "",
      url: "",

      videoUpload: false,
      types: [],

      optionGroupFilter: [],
      selectedMultiFilter: [],
      selectedType: "",
      normalId: "",
    };
    this.handleMulti = this.handleMulti.bind(this);
    this.handleMultiAll = this.handleMultiAll.bind(this);
    this.handleMultiCat = this.handleMultiCat.bind(this);
    this.handleMultiFilter = this.handleMultiFilter.bind(this);
  }
  handleMulti = (selectedMulti) => {
    this.setState({ selectedMulti });
  };

  handleMultiAll = (selectedMultiAll) => {
    let typesNew = [];
    if (selectedMultiAll && selectedMultiAll.length > 0) {
      selectedMultiAll.map((item, index) => {
        let findNew = this.state.types.filter((ig) => ig.id == item.value);
        if (findNew.length > 0) {
          typesNew.push(findNew[0]);
        }
      });
      let showVideo = typesNew.filter((ikk) => ikk.type == true);
      if (showVideo.length > 0) {
        this.setState({ videoUpload: true });
      } else {
        this.setState({ videoUpload: false });
      }
      this.setState({ selectedMultiAll });
    }
  };

  handleMultiCat = (selectedMultiCat) => {
    this.setState({ selectedMultiCat });
  };

  handleMultiFilter = (selectedMultiFilter) => {
    console.log("selectedMultiFilter", selectedMultiFilter);
    this.setState({ selectedMultiFilter });
    if (Object.keys(selectedMultiFilter).length > 0) {
      this.setState({ selectedType: selectedMultiFilter.value });
      this.fetchData(selectedMultiFilter.value);
    }
  };

  componentDidMount = async () => {
    this.checkUser();
    this.fetchCat();
    this.fetchgenre();
    this.fetchType();
  };

  fetchCat = async () => {
    let result = await HttpClient.requestData("admin/jdcategory", "GET");
    if (result && result.status > 0) {
      this.setState({ categoryAll: result.data });

      let optionAppowner = {};
      optionAppowner.label = "Categories";
      optionAppowner.options = [];
      result.data.map((item, index) => {
        optionAppowner.options.push({
          label: item.name,
          value: item.id,
        });
      });

      let newOptions = [];
      newOptions.push(optionAppowner);

      this.setState({ optionGroupCat: newOptions });
    }
  };

  fetchType = async () => {
    let result = await HttpClient.requestData("admin/type", "GET");
    console.log("admin/type", result);

    if (result && result.status > 0) {
      this.setState({ types: result.data });
      let optionAppowner = {};
      optionAppowner.label = "Types";
      optionAppowner.options = [];
      result.data.map((item, index) => {
        optionAppowner.options.push({
          label: item.name,
          value: item.id,
        });
      });

      let newOptions = [];
      newOptions.push(optionAppowner);

      this.setState({
        optionGroupALL: newOptions,
        optionGroupFilter: newOptions,
      });
    }
  };

  fetchgenre = async () => {
    let result = await HttpClient.requestData("admin/gener", "GET");
    console.log("admin/genre", result);

    if (result && result.status > 0) {
      let optionAppowner = {};
      optionAppowner.label = "Categories";
      optionAppowner.options = [];
      result.data.map((item, index) => {
        optionAppowner.options.push({
          label: item.name,
          value: item.id,
        });
      });

      let newOptions = [];
      newOptions.push(optionAppowner);

      this.setState({ optionGroup: newOptions });
    }
  };

  uploadVideo = async (video, type) => {
    if (type == "Trailer") {
      this.setState({ uploading_trailer: true });
    } else {
      this.setState({ uploading_movie: true });
    }

    let dataSend = { buckate: false };
    let result = await HttpClient.uploadFileRequest(
      "video-upload/video",
      video,
      (data, data1, data2) => {
        console.log("data, data1, data2", data, data1, data2);

        if (type == "Trailer") {
          this.setState({ progressBarParsent: data });
        } else {
          this.setState({ progressBarParsentVideo: data });
        }
      }
    );
    if (result && result.status) {
      if (type == "Trailer") {
        this.setState({
          trailerPlaylists: result.playlists,
          trailer: result.data.url,
          uploading_trailer: false,
        });
      } else {
        this.setState({
          videoUrl: result.playlists,
          url: result.data.url,
          uploading_movie: false,
        });
      }
    }
    console.log("result", result);
  };

  fetchData = async (type) => {
    console.log("call");
    let result = await HttpClient.requestData(
      "admin/movies/get-all",
      "POST",
      type == "" ? {} : { type: type }
    );
    console.log("call", result);

    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let category = "";

        if (element.cat_id) {
          category = element.cat_id.reduce(
            (acc, curr) => `${acc}${curr.label} | `,
            ""
          );
          category = category.substring(0, category.length - 2);
          console.log("category", category);
        }
        let rows = {
          sl: i,
          type: element.typeData ? element.typeData.name : "",
          name: element.name,
          img:
            element.img.length > 0 ? (
              <div>
                <img
                  src={element.img[0].preview}
                  alt="images"
                  className="rounded avatar-md card-img"
                />
              </div>
            ) : null,
          director: element.director ? element.director : "",
          category: element.category ? category : "",
          cast: element.cast ? element.cast : "",
          year: element.year ? element.year : "",

          discount: element.discount ? element.discount : "",
          status: (
            <button
              title="Delete"
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                this.status(element, index);
              }}
            >
              {element.status ? "Active" : "Not Active"}
            </button>
          ),
          episodes: (
            <>
              {element.typeData && element.typeData.type == false ? (
                <button
                  title="Episodes"
                  className="btn btn-success"
                  onClick={() => {
                    this.props.history.push("episodes/" + element.id);
                  }}
                >
                  Episodes
                </button>
              ) : null}
            </>
          ),
          action: (
            <>
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData(
      "admin/movies/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData(this.state.selectedType);
      }
    }
  };

  edit = async (item, index) => {
    let allDf = [];
    let setSelect = this.state.types.filter((ikk) => ikk.id == item.type);
    setSelect.map((ifg, indd) => {
      allDf.push({ label: ifg.name, value: ifg.id });
    });
    this.setState({ selectedMultiAll: allDf });
    let showVideo = setSelect.filter((ikk) => ikk.type == true);
    if (showVideo.length > 0) {
      this.setState({ videoUpload: true });
    } else {
      this.setState({ videoUpload: false });
    }
    // console.log("setSelect", setSelect);

    this.setState({
      edit_enable: true,
      name: item.name,
      uploaded_images: item.img,
      image_select: true,
      edit_item_id: item._id,
      director: item.director,
      desc: item.desc,
      showSelect: false,
      selectedMulti: item.category,
      selectedMultiCat: typeof item.cat_id == "string" ? [] : item.cat_id,
      cast: item.cast,
      year: item.year,
      url: item.url,
      videoUrl: item.videoUrl,
      url: item.url,
      trailer: item.trailer,
      trailerPlaylists: item.trailerUrl,
      normalId: item.id,
    });
    setTimeout(() => {
      this.setState({ showSelect: true });
    }, 200);
    this.handleMulti(item.category);
  };

  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/movies/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Succfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData(this.state.selectedType);
    }
  };

  submit = async () => {
    if (this.state.videoUpload && this.state.url == "") {
      this.setState({
        alert: true,
        message: "Please Upload Video.",
        type: "warning",
      });
      return false;
    }

    if (
      this.state.name != "" &&
      this.state.director != "" &&
      this.state.uploaded_images.length > 0 &&
      this.state.cast != "" &&
      this.state.trailer != "" &&
      this.state.selectedMulti.length > 0 &&
      this.state.selectedMultiCat.length > 0
    ) {
      if (this.state.uploaded_images.length > 0) {
        this.setState({ loading: true });

        let data = null;
        let result = null;
        if (this.state.edit_enable) {
          data = {
            name: this.state.name,
            director: this.state.director,
            desc: this.state.desc,
            duration: this.state.duration,
            img: this.state.uploaded_images,
            genre: this.state.selectedMulti,
            edit_id: this.state.edit_item_id,
            trailer: this.state.trailer,
            trailerUrl: this.state.trailerPlaylists,
            cat_id: this.state.selectedMultiCat,
            cast: this.state.cast,
            year: this.state.year,
            types: this.state.selectedMultiAll,
            videoUrl: this.state.videoUrl,
            url: this.state.url,
            category: this.state.selectedMulti,
            id: this.state.normalId,
          };
          result = await HttpClient.requestData(
            "admin/movies/update",
            "POST",
            data
          );
        } else {
          data = {
            name: this.state.name,
            director: this.state.director,
            desc: this.state.desc,
            duration: this.state.duration,
            img: this.state.uploaded_images,
            category: this.state.selectedMulti,
            trailer: this.state.trailer,
            trailerUrl: this.state.trailerPlaylists,
            cat_id: this.state.selectedMultiCat,
            cast: this.state.cast,
            year: this.state.year,
            types: this.state.selectedMultiAll,
            videoUrl: this.state.videoUrl,
            url: this.state.url,
            genre: this.state.selectedMulti,
          };
          result = await HttpClient.requestData(
            "admin/movies/all-type",
            "POST",
            data
          );
        }

        // return false;

        if (result && result.status) {
          this.setState({
            alert: true,
            message: this.state.edit_enable
              ? "Video Updated Successfully"
              : "Video Added Succfully",
            type: "success",
            name: "",
            cat_id: "",
            sub_cat_id: "",
            desc: "",
            pruduct_type: "",
            sell_price: "",
            purchase_price: "",
            discount: "",
            uploaded_images: [],
            images: [],
            selectedFiles: [],
            edit_enable: false,
            edit_item_id: "",
            selectedMulti: [],
            selectedMultiAll: [],
            selectedMultiCat: [],
            uploadVideo: false,
            url: "",
            trailer: "",
            cast: "",
            year: "",
            director: "",
          });
          // this.fileInputTrl.value = "";
          // this.fileInputVideo.value = "";
          this.fetchData(this.state.selectedType);
        } else {
          this.setState({
            alert: true,
            message:
              typeof result.error == "string"
                ? result.error
                : result.error[0].msg,
            type: "danger",
          });
        }
      } else {
        this.setState({
          alert: true,
          message: "Please Select Atleast One Image.",
          type: "warning",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (file, index) => {
    // console.log("e", file);

    let dataSend = { buckate: false };

    let result = await HttpClient.newFileUpload(
      "image-upload/movie",
      file,
      dataSend
    );
    console.log("result", result);
    if (result && result.status) {
      this.state.uploaded_images.push({ preview: result.url });
      if (this.state.images.length - 1 == index) {
        setTimeout(() => {
          this.setState({ loading: false });
          console.log("this.state.uploaded_images", this.state.uploaded_images);
        }, 200);
      }
      this.setState({});
    }
  };

  handleAcceptedFiles = (files) => {
    let data = [];
    let file = {
      preview: URL.createObjectURL(files[0]),
      formattedSize: this.formatBytes(files[0].size),
      // image:files,
    };
    let file2 = {
      image: files,
    };

    let file3 = files;
    console.log("data", file);
    this.state.selectedFiles.push(file);
    this.state.images.push(files[0]);
    this.imageUpload(files[0]);
    this.setState({});
  };

  async remove_image(item, index) {
    this.state.selectedFiles.pop(index);
    this.state.images.pop(index);
    this.state.uploaded_images.pop(index);
    this.setState({});
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData(this.state.selectedType);
  };

  handleChange = async (event) => {
    let filterSub = this.state.subCategoryAll.filter(
      (item) => item.category_id == event.target.value
    );
    this.setState({ cat_id: event.target.value, subCategory: filterSub });
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Type",
          field: "type",
          sort: "asc",
          width: 270,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Image",
          field: "img",
          sort: "asc",
          width: 270,
        },
        {
          label: "Director",
          field: "director",
          sort: "asc",
          width: 270,
        },

        {
          label: "Category",
          field: "category",
          sort: "asc",
          width: 270,
        },
        // {
        //   label: "Cast",
        //   field: "cast",
        //   sort: "asc",
        //   width: 270,
        // },
        // {
        //   label: "Year",
        //   field: "year",
        //   sort: "asc",
        //   width: 270,
        // },

        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 100,
        },
        {
          label: "Episodes",
          field: "episodes",
          sort: "asc",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              {/* <BaseInfo /> */}
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Types
                      </Label>
                      <Col md={10}>
                        {this.state.showSelect ? (
                          <Select
                            isMulti={true}
                            options={this.state.optionGroupALL}
                            values={this.state.selectedMultiAll}
                            onChange={
                              // (values) => this.setValues(values)
                              this.handleMultiAll
                            }
                            value={this.state.selectedMultiAll}
                          />
                        ) : null}
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Name
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.name}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ name: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Description
                      </Label>
                      <Col md={10}>
                        <textarea
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder=""
                          onChange={(val) => {
                            this.setState({ desc: val.target.value });
                          }}
                          value={this.state.desc}
                        ></textarea>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Director
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.director}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ director: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Category
                      </Label>
                      <Col md={10}>
                        {this.state.showSelect ? (
                          <Select
                            isMulti={true}
                            options={this.state.optionGroupCat}
                            values={this.state.selectedMultiCat}
                            onChange={
                              // (values) => this.setValues(values)
                              this.handleMultiCat
                            }
                            value={this.state.selectedMultiCat}
                          />
                        ) : null}
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Genre
                      </Label>
                      <Col md={10}>
                        {this.state.showSelect ? (
                          <Select
                            isMulti={true}
                            options={this.state.optionGroup}
                            values={this.state.selectedMulti}
                            onChange={
                              // (values) => this.setValues(values)
                              this.handleMulti
                            }
                            value={this.state.selectedMulti}
                          />
                        ) : null}
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Cast
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.cast}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ cast: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Year
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.year}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ year: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Upload Trailer
                      </Label>
                      <Col md={6}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {
                            this.uploadVideo(val.target.files[0], "Trailer");
                          }}
                          // ref={(ref) => (this.fileInputTrl = ref)}
                        />
                      </Col>
                      <Col md={4}>
                        {this.state.uploading_trailer ? (
                          <>
                            <ProgressBar
                              completed={this.state.progressBarParsent}
                            />
                            {this.state.progressBarParsent == 100
                              ? "please wait untill transcoding is done"
                              : null}
                          </>
                        ) : (
                          <>
                            {this.state.trailer != "" ? (
                              <video
                                width="100%"
                                controls
                                className="rounded-lg"
                              >
                                <source
                                  src={this.state.trailer}
                                  type="video/mp4"
                                />
                              </video>
                            ) : null}
                          </>
                        )}
                      </Col>
                    </FormGroup>

                    {this.state.videoUpload ? (
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Upload Video
                        </Label>
                        <Col md={6}>
                          <Input
                            className="form-control"
                            type="file"
                            id="example-text-input"
                            onChange={(val) => {
                              this.uploadVideo(val.target.files[0], "Video");
                            }}
                            ref={(ref) => (this.fileInputVideo = ref)}
                          />
                        </Col>
                        <Col md={4}>
                          {this.state.uploading_movie ? (
                            <>
                              <ProgressBar
                                completed={this.state.progressBarParsentVideo}
                              />
                              {this.state.progressBarParsentVideo == 100
                                ? "please wait untill transcoding is done"
                                : null}
                            </>
                          ) : (
                            <>
                              {this.state.url != "" ? (
                                <video
                                  width="100%"
                                  controls
                                  className="rounded-lg"
                                >
                                  <source
                                    src={this.state.url}
                                    type="video/mp4"
                                  />
                                </video>
                              ) : null}
                            </>
                          )}
                        </Col>
                      </FormGroup>
                    ) : null}

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Thumbnail
                      </Label>
                      <Col md={10}>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            this.handleAcceptedFiles(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {this.state.uploaded_images.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        style={{ height: 120, width: 100 }}
                                        data-dz-thumbnail=""
                                        height="100"
                                        className="avatar-sm rounded bg-light"
                                        // alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Button
                                        className="btn-icon"
                                        color="danger"
                                        style={{ marginRight: 25 }}
                                        onClick={(e) => this.remove_image(f, i)}
                                      >
                                        {" "}
                                        <span className="btn-icon-label">
                                          <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                        </span>{" "}
                                        Remove
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                          disabled={this.state.submit}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                {/* <div>
                    <EcomPreview />
                  </div> */}
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <FormGroup row>
                    <Label
                      htmlFor="example-text-input"
                      className="col-md-2 col-form-label"
                    >
                      Types
                    </Label>
                    <Col md={10}>
                      {this.state.showSelect ? (
                        <Select
                          isMulti={false}
                          options={this.state.optionGroupFilter}
                          values={this.state.selectedMultiFilter}
                          onChange={
                            // (values) => this.setValues(values)
                            this.handleMultiFilter
                          }
                          value={this.state.selectedMultiFilter}
                        />
                      ) : null}
                    </Col>
                  </FormGroup>
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
