import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Details from "./details";
import SellerDetails from "./sellerdetails";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  // Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import { CSVLink } from "react-csv";
import HttpClient from "../../utils/HttpClient";
import index from "../ChooseDesign";
class FormElements extends Component {
  fileObj = [];
  fileArray = [];

  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Service Booking Reports", link: "#" },
        { title: "Service Booking History", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      image_select: false,
      img_url: "",
      title: "",
      points: [""],
      id: "",
      duration: 0,
      seller_comission: "",
      type: "",
      desc: "",
      modal: false,
      arr: [],
      order: "",
      result1: [],
      cart: [],
      filter: "",
      ismodalopen: false,
      activeid: {},
      from_date: "",
      to_date: "",
      enable: false,
    };
    this.toggle = this.toggle.bind(this);
    this.handleDefault = this.handleDefault.bind(this);
  }

  onCloseCallBack = (val) => {
    console.log("object", val);
    this.setState({
      ismodalopen: val,
    });
  };

  onCloseCallBackseller = (val) => {
    console.log("object", val);
    this.setState({
      ismodalopen1: false,
    });
  };
  handleChange = async (e) => {
    console.log(this.state.data);
    console.log(this.state.filter);
    let cart = this.state.data.filter(
      (item) => item.cart_data == this.state.filter
    );
    console.log(cart, "x");
    this.state.cart = cart;
    this.setState({});
  };
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  componentDidMount = async () => {
    this.checkUser();
    this.fetchData();
    console.log("pqr", this.state.result1);
  };

  handleDefault(date) {
    this.setState({ default_date: date });
  }

  fetchData = async () => { };

  submit = async () => {
    let data = {
      datefrom: this.state.from_date,
      dateto: this.state.to_date,
    };
    console.log(data);
    let result = await HttpClient.requestData(
      "servicedetailsrepo",
      "POST",
      data
    );
    console.log("result", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      // console.log("xyz", this.state.result);
      this.setState({ result1: result.user_data });
      // console.log("xyz", this.state.result1);

      // console.log(this.state.pointarr);
      this.state.result.forEach((element, index) => {
        let fname = element.user_data.length > 0 && element.user_data[0].firstName;
        let lname = element.user_data.length > 0 && element.user_data[0].lastName;
        let rows = {
          sl: i,

          username: fname +
            " " +
            lname,
          order_id: element._id,
          email: element.user_data.length > 0 ? element.user_data[0].email : "",
          //total: element.total,
          // servicename: element.servicecart_data ? element.servicecart_data.servicename : "",
          date: moment(element.booking_date).format("MMMM Do YYYY"),
          status: (
            <button
              title="Delete"
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
            >
              {element.status ? "Confirmed" : "Pending"}
            </button>
          ),
          view: (
            <button
              title="View Details"
              className="btn btn-success mr-2"
              onClick={() => {
                this.setState({
                  activeid: element,
                  ismodalopen: true,
                  idneed: element._id
                });

              }}
            >
              View Details
            </button>
          ),
          sellerview: (
            <button
              title="Delete"
              className="btn btn-success mr-2"
              onClick={() => {
                this.setState({
                  activeid: element,
                  ismodalopen1: true,
                  idneed: element._id
                });


              }}
            >
              View
            </button>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  imageUpload = async (e) => {
    let file = e.target.files[0].type;
    let fileType = file.split("/")[0];
    // fileType = fileType[0];
    console.log("e", file.split("/")[0]);

    if (fileType == "image" || fileType == "video") {
      // return false;
      this.setState({ fileType: fileType });
      let dataSend = { buckate: true, buckate_name: "appIcon" };

      let result = await HttpClient.newFileUpload(
        "image-upload/movie",
        e.target.files[0],
        dataSend
      );
      console.log("result", result);
      if (result && result.status) {
        this.setState({ img_url: result.url, image_select: true });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          width: 150,
        },

        {
          label: "Order Date",
          field: "date",
          width: 150,
        },


        {
          label: "Order Id",
          field: "order_id",
          width: 270,
        },
        {
          label: "User Name",
          field: "username",

          width: 270,
        },
        {
          label: "Email",
          field: "email",
          width: 250,
        },



        // {
        //     label: "Discount",
        //     field: "discount",
        //
        //     width: 270,
        // },
        // {
        //     label: "Total Amount",
        //     field: "seller_comission",
        //
        //     width: 270,
        // },
        // {
        //     label: "User Name",
        //     field: "username",
        //
        //     width: 270,
        // },
        // {
        //   label: "Points",
        //   field: "points",

        //   width: 270,
        // },
        // {
        //     label: "Status",
        //     field: "status",
        //
        //     width: 100,
        // },

        // {
        //   label: "Status",
        //   field: "status",
        //   width: 100,
        // },
        {
          label: "View Details",
          field: "view",
          width: 100,
        },
        // {
        //   label: "Seller Details",
        //   field: "sellerview",
        //   width: 100,
        // },
      ],
      rows: this.state.data,
    };

    const header = [

      { label: "Sl.", key: "sl" },
      { label: "Order Id", key: "order_id" },
      { label: "Service Name", key: "servicename" },
      { label: "Total", key: "total" },
      { label: "Date", key: "date" },
    ]
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        From Date
                      </Label>
                      <Col md={3}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.from_date}
                          id="example-text-input"
                          placeholder="Enter From Date"
                          onChange={(val) => {
                            this.setState({ from_date: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        To Date
                      </Label>
                      <Col md={3}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.to_date}
                          id="example-text-input"
                          placeholder="Enter To Date"
                          onChange={(val) => {
                            this.setState({
                              to_date: val.target.value,
                              enable: true,
                            });
                          }}
                        />
                      </Col>
                      <Col md={2}>
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                          disabled={!this.state.enable}
                        >
                          Search
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </Col>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xl={3}>
                {this.state.data.length > 0 ?
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"ServiceBooking.csv"}
                    className="btn btn-success">
                    Export Report in CSV
                  </CSVLink> : null}
              </Col>
            </Row>&nbsp;

            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {this.state.ismodalopen ? (
                    <Details
                      status={true}
                      onCloseCallBack={this.onCloseCallBack}
                      data={this.state.activeid}
                      id={this.state.idneed}
                    />
                  ) : null}
                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                  />
                </div>
              </Col>
              {/* {this.state.ismodalopen1 ? (
                <SellerDetails
                  status={true}
                  onCloseCallBackseller={this.onCloseCallBackseller}
                  data={this.state.activeid}
                  id={this.state.idneed}
                />
              ) : null} */}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
