import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import imgRct from "../../assets/images/im-image-section.png";

const BaseInfo = () => {
  const [modalOn, setModalOn] = useState(false);
  const [supportOn, setSupportOn] = useState(false);
  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-4">
              <div className="media">
                <div className="avatar-sm mr-3">
                  <span className="avatar-title bg-light rounded-circle text-primary font-size-24">
                    <i className="ri-checkbox-circle-line" />
                  </span>
                </div>
                <div className="align-self-center overflow-hidden media-body">
                  <h5>
                    <button
                      data-toggle="modal"
                      onClick={() => setModalOn(true)}
                      className="border-0 bg-white text-left px-0"
                    >
                      Video Tutorial
                      <p className="small text-muted pt-1 mb-0">
                        Click for a video tutorial
                      </p>
                    </button>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mt-4 mt-md-0 media">
                <div className="avatar-sm mr-3">
                  <span className="avatar-title bg-light rounded-circle text-primary font-size-24">
                    <i className="ri-exchange-line" />
                  </span>
                </div>
                <div className="align-self-center overflow-hidden media-body">
                  <h5>
                    <Link className="text-dark" to="/faq">
                      Guide
                      <p className="small text-muted pt-1 mb-0">
                        Click here for quick guide
                      </p>
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mt-4 mt-md-0 media">
                <div className="avatar-sm mr-3">
                  <span className="avatar-title bg-light rounded-circle text-primary font-size-24">
                    <i className="ri-money-dollar-circle-line" />
                  </span>
                </div>
                <div className="align-self-center overflow-hidden media-body">
                  <h5>
                    <button
                      data-toggle="modal"
                      onClick={() => setSupportOn(true)}
                      className="border-0 bg-white text-left px-0"
                    >
                      Support
                      <p className="small text-muted pt-1 mb-0">
                        Click here for quick support
                      </p>
                    </button>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* tutorial modal */}
        {modalOn ? (
          <div
            className={modalOn ? "modal fade show" : "modal fade"}
            id="tutorial_modal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="tutorial_modalLabel"
            aria-hidden="true"
            style={{ display: modalOn ? "block" : "none" }}
          >
            <div
              className="modal-dialog"
              role="document"
              style={{ maxWidth: "600px" }}
            >
              <div className="modal-content">
                <div className="modal-header py-2 bg-soft-secondary">
                  <h6 className="text-dark mb-0">
                    View tutorial : How to make application
                  </h6>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setModalOn(false)}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body p-2">
                  <div className="videoArea">
                    <iframe
                      width={"100%"}
                      height={350}
                      src="https://www.youtube.com/embed/RZ5wKYbOM_I"
                      title="YouTube video player"
                      frameBorder={0}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* tutorial modal */}
        {supportOn ? (
          <div
            className={supportOn ? "modal fade show" : "modal fade"}
            id="supportMod"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="supportModLabel"
            aria-hidden="true"
            style={{ display: supportOn ? "block" : "none" }}
          >
            <div
              className="modal-dialog"
              role="document"
              style={{ maxWidth: "600px" }}
            >
              <div className="modal-content back_overlay">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setSupportOn(false)}
                >
                  <span aria-hidden="true">×</span>
                </button>
                <div className="back_layout"></div>
                <div className="d-flex flex-column relative w-100 justify-content-center px-5 py-5">
                  <h1 className="spt_title">
                    Get a quick support for your Application
                  </h1>
                  <p className="text-white my-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex
                    est et doloribus eum a laudantium suscipit exercitationem
                    nesciunt amet veritatis.
                  </p>
                  <div className="d-flex mt-4">
                    <input
                      type="text"
                      className="border-0 form-control-lg mr-2"
                      placeholder="Enter email address"
                    />
                    <button className="btn btn-warning font-size-17 font-weight-bold px-3">
                      Enquiry
                    </button>
                  </div>
                </div>
                <div className="back_layImg">
                  <img src={imgRct} alt="alt" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default BaseInfo;
