import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Images
import img1 from "../../assets/images/companies/img-1.png";
import img2 from "../../assets/images/companies/img-2.png";
import img3 from "../../assets/images/companies/img-3.png";

class Sources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <Col lg={4}>
          <Card className="bg-success">
            <CardBody>
              <div>
                <div
                  className="text-center"
                  onClick={() =>
                    (window.location.href =
                      "https://admin.astrophy.com/userlist")
                  }
                >
                  <p className="mb-2 text-white">Total User</p>
                  <h4 className="text-white">{this.props.totaluser}</h4>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

export default Sources;
