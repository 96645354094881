import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
// import Details from "./details";
import { confirmAlert } from "react-confirm-alert";
import ReactLoading from "react-loading";
import "../../App.css";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  // Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "../../utils/HttpClient";
import { CSVLink } from "react-csv";
import index from "../ChooseDesign";
import { useState, useEffect } from "react";

const ShowMore = ({ data }) => {
  const [show, setShow] = useState(true);
  // let data = "fglk;hjfdgl;khjfdglikjhcccccccccccccccccccccccc"
  useEffect(() => {
    console.log(show);
  }, [show]);
  return (
    <>
      {data.length > 20 ? (
        <div>
          {show == true ? (
            <>
              {data.slice(0, 20)}{" "}
              <button
                onClick={() => setShow(false)}
                className="btn btn-success btn-sm"
              >
                Read More
              </button>
            </>
          ) : (
            <>{data}</>
          )}
        </div>
      ) : (
        <div>{data}</div>
      )}
    </>
  );
};
class FormElements extends Component {
  fileObj = [];
  fileArray = [];

  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "All Service Review", link: "#" },
        { title: "View All Service Review", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      image_select: false,
      img_url: "",
      title: "",
      points: [""],
      id: "",
      duration: 0,
      seller_comission: "",
      type: "",
      desc: "",
      modal: false,
      arr: [],
      order: "",
      result1: [],
      cart: [],
      filter: "",
      ismodalopen: false,
      activeid: {},
      categoryAll: [],
      subcatarr: [],
      loading: false,
    };
  }

  componentDidMount = async () => {
    this.fetchData();
  };

  fetchData = async (id) => {
    this.state.loading = true;
    this.setState({});
    let result = await HttpClient.requestData("service-reviews", "GET");
    console.log("abc", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      this.state.loading = false;
      this.setState({});
      this.setState({ result: result.data });

      this.state.result.forEach((element, index) => {
        let fname = element.user_data && element.user_data.firstName;
        let lname = element.user_data && element.user_data.lastName;
        let rows = {
          sl: i,

          date: moment(element.rev_date).format("MMMM Do YYYY"),

          username: fname + " " + lname,
          email: element.user_data ? element.user_data.email : "",
          orderid: element.order_id,
          catname: element.service_data ? element.service_data.cat_name : "",
          subcatname: element.service_data
            ? element.service_data.subcat_name
            : "",
          name: element.service_data ? element.service_data.name : "",
          comment: <ShowMore data={element.comment.replace(/<[^>]+>/g, "")} />,

          price: element.service_data ? element.service_data.price : "",
          image: element.service_data ? (
            <img
              src={HttpClient.IMG_URL + element.service_data.image[0]}
              alt="img"
              style={{ width: "100px", height: "100px", borderRadius: "6px" }}
            />
          ) : null,

          rating: element.rating,

          status: (
            <button
              title={
                element.status
                  ? "Deactivate(it will temporary suspend the seller shop)"
                  : "activate"
              }
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                if (element.status) {
                  this.deactivatestatus(element._id);
                } else {
                  this.activatestatus(element._id);
                }
              }}
            >
              {element.status ? "Activated" : "Dectivated"}
            </button>
          ),
          action: (
            <>
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>
            </>
          ),
          view: (
            <button
              title="Delete"
              className="btn btn-success mr-2"
              onClick={() => {
                this.setState({
                  activeid: element,
                });
                setTimeout(() => {
                  this.setState({
                    ismodalopen: true,
                    idneed: element._id,
                  });
                }, 200);
              }}
            >
              View Details
            </button>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    confirmAlert({
      title: "Confirm to delete Review",
      message: "Are you sure want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteapi(item),
        },
        {
          label: "No",
          // onClick: () => alert("Click No"),
        },
      ],
    });
  };

  deleteapi = async (item) => {
    let result = await HttpClient.requestData(
      "service-reviews/" + item._id,
      "DELETE"
    );
    console.log(result);
    if (result && result.status) {
      this.setState({
        alert: true,
        message: "Review Deleted Successfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      title: item.name,
      price: item.price,
      edit_item_id: item._id,
      seller_comission: item.seller_comission,
      duration: item.duration,
      id: item.id,
      type: item.type,
    });
  };
  deactivatestatus = async (id) => {
    console.log("id", id);
    let result = await HttpClient.requestData("shop-deactivate/" + id, "PUT");
    if (result && result.status) {
      console.log(result);
      this.setState({
        alert: true,
        message: "Status Updated Succfully",
        type: "success",
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };

  activatestatus = async (id) => {
    console.log("id", id);
    let result = await HttpClient.requestData("shop-activate/" + id, "PUT");
    if (result && result.status) {
      console.log(result);
      this.setState({
        alert: true,
        message: "Status Updated Succfully",
        type: "success",
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };

  onCloseCallBack = (val) => {
    console.log("object", val);
    this.setState({
      ismodalopen: val,
    });
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          width: 100,
        },
        {
          label: "User Name",
          field: "username",
          width: 150,
        },
        {
          label: "Email",
          field: "email",
          width: 150,
        },
        {
          label: "Order Id",
          field: "orderid",
          width: 150,
        },

        {
          label: "Category Name",
          field: "catname",
          width: 150,
        },
        {
          label: "SubCategory Name",
          field: "subcatname",
          width: 150,
        },
        {
          label: "Service Name",
          field: "name",
          width: 150,
        },

        {
          label: "Price",
          field: "price",
          width: 150,
        },
        {
          label: "Image",
          field: "image",
          width: 150,
        },
        {
          label: "Comment",
          field: "comment",
          width: 150,
        },
        {
          label: "Rating",
          field: "rating",
          width: 150,
        },

        {
          label: "Date",
          field: "date",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          width: 100,
        },
      ],
      rows: this.state.data,
    };

    const header = [
      { label: "Sl.", key: "sl" },
      { label: "User Name", key: "username" },
      { label: "Email", key: "email" },
      { label: "Order Id", key: "orderid" },
      { label: "Categoryname", key: "catname" },
      { label: "Subcategory Name", key: "subcatname" },
      { label: "Service Name", key: "name" },
      { label: "Price", key: "price" },
      { label: "Comment", key: "comment" },
      { label: "Rating", key: "rating" },
      { label: "Date", key: "date" },
    ];
    return (
      <React.Fragment>
        {this.state.loading ? (
             <ReactLoading
             type="spokes"
             color="black"
             height={"5%"}
             width={"5%"}
             className="loader"
           />
        ) : (
            <div className="page-content">
            <Container fluid>
              <Breadcrumbs
                title={this.state.breadcrumbItems[0].title}
                breadcrumbItems={this.state.breadcrumbItems}
              />
              {/* <Row>
                <Col xs={12}>
                  <Card>
                    <CardBody></CardBody>
                  </Card>
                </Col>
              </Row> */}
  
              <Row>
                <Col xl={12}>
                  <div className="card p-3">
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}
                    <Row>
                      <Col xl={3}>
                        {this.state.data.length > 0 ? (
                          <CSVLink
                            data={this.state.data}
                            headers={header}
                            filename={"ServiceBookingHistory.csv"}
                            className="btn btn-success"
                          >
                            Export Report in CSV
                          </CSVLink>
                        ) : null}
                      </Col>
                    </Row>
                    &nbsp;
                    {/* <MDBDataTable responsive bordered data={data} /> */}
                    <Row>
                      <Col xl={12}>
                        <div className="card p-3">
                          <MDBDataTableV5
                            responsive
                            hover
                            scrollX
                            entriesOptions={[5, 20, 25]}
                            entries={5}
                            pagesAmount={4}
                            data={data}
                          />
                        </div>
                      </Col>
                    </Row>
                    {/* {this.state.ismodalopen ? (
                                          <Details
                                              status={true}
                                              onCloseCallBack={this.onCloseCallBack}
                                              data={this.state.activeid}
                                              id={this.state.idneed}
                                          />
                                      ) : null} */}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}
        
      </React.Fragment>
    );
  }
}

export default FormElements;
