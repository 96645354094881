import React, { Component } from "react";
import ReactLoading from "react-loading";
import "../../App.css";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
// import Firebase from "./firebase1";
import SweetAlert from "react-bootstrap-sweetalert";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import { CSVLink } from "react-csv";
import HttpClient from "./../../utils/HttpClient";
import { Link } from "react-router-dom";
import Details from "./details";

// import img from "../../assets/images/pro.jpeg";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Service Refund List", link: "#" },
        { title: "Manage Service Refund List", link: "#" },
      ],
      modal: false,
      ismodalopen: false,
      ismodalopen1: false,
      customchk: true,
      toggleSwitch: true,
      order_id: 0,
      userimage: "",
      username: "",
      chatlist: [],
      data: [],
      sellerdetails: {},
      loading: false,
    };
  }

  async componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.state.loading = true;
    this.setState({});
    let result = await HttpClient.requestData(
      "approved-service-refunds",
      "GET"
    );
    console.log("approved-service-refunds", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      this.state.loading = false;
      this.setState({});
      if (result.data.length > 0) {
        let arr = result.data.filter(
          (it) => it.admin_status == "refund_initiated"
        );
        this.setState({ result: arr });
      }

      console.log("this.state.pointarr", this.state.result);
      this.state.result?.forEach((element, index) => {
        let name = element.firstname + " " + element.lastname;
        let rows = {
          sl: i,
          date: moment(element.request_date).format("MMMM Do YYYY"),
          username: name,
          useremail:
            element.user_details.length > 0
              ? element.user_details[0].email
              : "",
          country: element.country,
          address: element.address,
          orderId: element.order_id,
          refundAmount: "₹" + element.refund_amount,

          rfint: (
            <button
              title={
                element.admin_status == "refund_initiated"
                  ? "Refund Initiated"
                  : "Refund Initiate"
              }
              className={
                element.admin_status == "refund_initiated"
                  ? "btn btn-danger mr-2"
                  : "btn btn-success mr-2"
              }
              onClick={() => {
                this.refundInitiate(element._id);
              }}
            >
              {element.admin_status == "refund_initiated"
                ? "Refund Initiated"
                : "Refund Initiate"}
            </button>
          ),
          view: (
            <button
              title="View Details"
              className="btn btn-success mr-2"
              onClick={() => {
                this.setState({
                  activeid: element,
                });
                setTimeout(() => {
                  this.setState({
                    ismodalopen: true,
                    idneed: element._id,
                  });
                }, 200);
              }}
            >
              View Details
            </button>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  onCloseCallBack = (val) => {
    console.log("object", val);
    this.setState({
      ismodalopen: val,
    });
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },

        {
          label: "Name",
          field: "username",
          sort: "asc",
          width: 270,
        },

        {
          label: "Email",
          field: "useremail",
          sort: "asc",
          width: 270,
        },
        {
          label: "Country",
          field: "country",
          sort: "asc",
          width: 270,
        },
        {
          label: "Order Id",
          field: "orderId",
          sort: "asc",
          width: 270,
        },
        {
          label: "Date",
          field: "date",
          sort: "asc",
          width: 270,
        },
        {
          label: "Refund amount",
          field: "refundAmount",
          sort: "asc",
          width: 270,
        },

        {
          label: "Refund Process",
          field: "rfint",
          sort: "asc",
          width: 100,
        },
        {
          label: "View Details",
          field: "view",
          width: 100,
        },
      ],
      rows: this.state.data,
    };

    return (
      <React.Fragment>
        {this.state.loading ? (
            <ReactLoading
            type="spokes"
            color="black"
            height={"5%"}
            width={"5%"}
            className="loader"
          />
        ) : (
            <div className="page-content">
            <Container fluid>
              {this.state.alert ? (
                <UncontrolledAlert
                  color={this.state.type}
                  className="alert-dismissible fade show"
                  role="alert"
                >
                  {this.state.type == "warning" ? (
                    <i className="mdi mdi-alert-outline mr-2"></i>
                  ) : this.state.type == "success" ? (
                    <i className="mdi mdi-check-all mr-2"></i>
                  ) : this.state.type == "danger" ? (
                    <i className="mdi mdi-block-helper mr-2"></i>
                  ) : null}
                  {this.state.message}
                </UncontrolledAlert>
              ) : null}
              <Breadcrumbs
                title={this.state.breadcrumbItems[0].title}
                breadcrumbItems={this.state.breadcrumbItems}
              />
  
              <Row>
                <Col xs={12}>
                  {this.state.confirm_both ? (
                    <SweetAlert
                      title="Are you sure?"
                      warning
                      showCancel
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      onConfirm={() =>
                        this.delete(
                          this.state.delete_item,
                          this.state.delete_index
                        )
                      }
                      onCancel={() =>
                        this.setState({
                          confirm_both: false,
                        })
                      }
                    >
                      You won't be able to revert this!
                    </SweetAlert>
                  ) : null}
                  {this.state.alert ? (
                    <UncontrolledAlert
                      color={this.state.type}
                      className="alert-dismissible fade show"
                      role="alert"
                    >
                      {this.state.type == "warning" ? (
                        <i className="mdi mdi-alert-outline mr-2"></i>
                      ) : this.state.type == "success" ? (
                        <i className="mdi mdi-check-all mr-2"></i>
                      ) : this.state.type == "danger" ? (
                        <i className="mdi mdi-block-helper mr-2"></i>
                      ) : null}
                      {this.state.message}
                    </UncontrolledAlert>
                  ) : null}
                </Col>
              </Row>
              {/* <Row>
                <Col xl={3}>
                  {this.state.data.length > 0 ?
                    <CSVLink
                      data={this.state.data}
                      headers={header}
                      filename={"ApprovedServiceByPersonnel.csv"}
                      className="btn btn-success">
                      Export Report in CSV
                    </CSVLink> : null}
                </Col>
              </Row>&nbsp; */}
              <Row>
                <Col xl={12}>
                  <div className="card p-3">
                    <MDBDataTable
                      responsive
                      bordered
                      data={data}
                      disableRetreatAfterSorting={true}
                    />
                  </div>
                </Col>
              </Row>
              {this.state.ismodalopen ? (
                <Details
                  status={true}
                  onCloseCallBack={this.onCloseCallBack}
                  data={this.state.activeid}
                  id={this.state.idneed}
                />
              ) : null}
            </Container>
          </div>
        )}
        
      </React.Fragment>
    );
  }
}

export default FormElements;
