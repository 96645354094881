import React, { Component } from "react";
import ReactLoading from "react-loading";
import "../../App.css"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import SellerShop from "./sellerShop"
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Seller List", link: "#" },
        { title: "View SellerList", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      title: "",
      points: [""],
      id: "",
      duration: 0,
      seller_comission: "",
      desc: "",
      priority: "",
      activeid: {},
      ismodalopen: false,
      device: "All",
      loading: false,
      devicefilter: ["App", "Web", "All"],
    };
    this.handleDefault = this.handleDefault.bind(this);
  }

  componentDidMount = async () => {
    this.checkUser();
    this.fetchData();
  };

  handleDefault(date) {
    this.setState({ default_date: date });
  }

  // handlePriority(data){
  //   console.log("priority......",data.target.value)
  //   this.setState({priority:data.target.value})
  // }

  onCloseCallBack = (val) => {
    console.log("object", val);
    this.setState({
      ismodalopen: val,
    });
  };

  sucess = (val) => {
    if (val) {
      this.fetchData();
    }
  };

  fetchData = async (val) => {

    let data = {

      device_type: this.state.device == "All" ? "" : val

    };
    this.state.loading = true;
    this.setState({});
    console.log("data", data);
    let result = await HttpClient.requestData("sellerlist", "POST", data);
    console.log("seller List", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      let processing = result.processing_fees.processing_fees
      this.state.loading = false;
      this.setState({});
      this.setState({ result: result.data });
      // console.log(this.state.pointarr);
      this.state.result.forEach((element, index) => {
        // let newValue = Math.abs(element.settled_and_claimable_commission - element.commission_refunded).toFixed(2);
        let total_earning = (element.settled_and_claimable_commission - ((element.settled_and_claimable_commission * processing) / 100)).toFixed(2);
        let rows = {
          sl: i,

          username: element.firstName + " " + element.lastName,
          devtype: element.device_type,
          email: element.email,
          currency: element.currency ? element.currency : "",
          date: moment(element.start).format("MMMM Do YYYY"),
          total_earning: total_earning,
          refundamt: (element.commission_refunded).toFixed(2),
          subscription_status: element.subsciption_history.length ? (
            <Button
              className="btn-icon"
              color="success"
            >
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-wink-outline mr-2"></i>
              </span>{" "}
             Subscribed
            </Button>
          ) : (
            <Button
              className="btn-icon"
              color="danger"
            >
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-cry-outline mr-2"></i>
              </span>{" "}
              Not Subscribed
            </Button>
          ),
          // paid_amount: element.paid_amount,
          status: element.status ? (
            <Button
              className="btn-icon"
              color="danger"
              onClick={(e) => this.status(index, element._id)}
            >
              {" "}
              <span className="btn-icon-label"> 
                <i className="mdi mdi-emoticon-cry-outline mr-2"></i>
              </span>{" "}
             Inactive
            </Button>
          ) : (
            <Button
              className="btn-icon"
              color="success"
              onClick={(e) => this.status(index, element._id)}
            >
              {" "}
              <span className="btn-icon-label">
              <i className="mdi mdi-emoticon-wink-outline mr-2"></i>
              </span>{" "}
              Active
            </Button>
          ),
          view: (
            <Link
              to={{
                pathname:
                  "/seller-single/" + element._id + "?id=" + element._id,
                data: element, // your data array of objects
              }}
            >
              <Button
                className="btn-icon"
                color="primary"
              // onClick={(e) => this.status(index, element._id)}
              >
                {" "}
                <span className="btn-icon-label">
                  <i className="mdi mdi-emoticon-wink-outline mr-2"></i>
                </span>{" "}
                View Comission
              </Button>
            </Link>
          ),
          view_history: (
            <Link
              to={{
                pathname:
                  "/payment-history/" + element._id + "?id=" + element._id,
                data: element, // your data array of objects
              }}
            >
              <Button
                className="btn-icon"
                color="primary"
              // onClick={(e) => this.status(index, element._id)}
              >
                {" "}
                <span className="btn-icon-label">
                  {/* <i className="mdi mdi-emoticon-wink-outline mr-2"></i> */}
                </span>{" "}
                Payment History
              </Button>
            </Link>
          ),
          seller_shop: (
            <Button
              className="btn-icon"
              color="primary"
              disabled={element.subsciption_history.length ? false : true}
              onClick={() => {
                this.setState({
                  activeid: element,
                });
                setTimeout(() => {
                  this.setState({
                    ismodalopen: true,
                    sellerid: element._id
                  });
                }, 200);
              }}
            >
              {" "}
              <span className="btn-icon-label">
                {/* <i className="mdi mdi-emoticon-wink-outline mr-2"></i> */}
              </span>{" "}
              Seller Shop
            </Button>
          ),
          seller_service: (
            <Link
              to={{
                pathname:
                  "/seller-services/" + element._id,
                data: element, // your data array of objects
              }}
            >
              <Button
                className="btn-icon"
                color="primary"
              // onClick={(e) => this.status(index, element._id)}
              >
                {" "}
                <span className="btn-icon-label">
                  {/* <i className="mdi mdi-emoticon-wink-outline mr-2"></i> */}
                </span>{" "}
                Seller Services
              </Button>
            </Link>
          ),
          seller_commission: (
            <Link
              to={{
                pathname:
                  "/seller-commission/" + element._id,
                data: element, // your data array of objects
              }}
            >
              <Button
                className="btn-icon"
                color="primary"
              // onClick={(e) => this.status(index, element._id)}
              >
                {" "}
                <span className="btn-icon-label">
                  {/* <i className="mdi mdi-emoticon-wink-outline mr-2"></i> */}
                </span>{" "}
                View Commission
              </Button>
            </Link>
          ),
          withdrawl_commission : (
            <Link
            to={{
              pathname:
                "/withdrawl-commission/" + element._id,
              data: element, // your data array of objects
            }}
          >
            <Button
              className="btn-icon"
              color="primary"
            // onClick={(e) => this.status(index, element._id)}
            >
              {" "}
              <span className="btn-icon-label">
                {/* <i className="mdi mdi-emoticon-wink-outline mr-2"></i> */}
              </span>{" "}
              Approved Request
            </Button>
          </Link>
          ),
          seller_most_sell: (
            <Link
              to={{
                pathname:
                  "/seller-sellyesterday/" + element._id + "?id=" + element._id,
                data: element, // your data array of objects
              }}
            >
              <Button
                className="btn-icon"
                color="primary"
              // onClick={(e) => this.status(index, element._id)}
              >
                {" "}
                <span className="btn-icon-label">
                  {/* <i className="mdi mdi-emoticon-wink-outline mr-2"></i> */}
                </span>{" "}
                Most Sell
              </Button>
            </Link>
          )
          // priority: (
          //   <Input
          //     className="form-control"
          //     type="text"
          //     value={this.state.priority}
          //     onChange={this.handlePriority}
          //   />
          // ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    console.log(sendData);
    let result = await HttpClient.requestData(
      "admin/ecom_banner/delete",
      "POST",
      sendData
    );
    console.log(result);
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Succfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      title: item.name,
      price: item.price,
      edit_item_id: item._id,
      seller_comission: item.seller_comission,
      duration: item.duration,
      id: item.id,
      type: item.type,
    });
  };
  status = async (item, id) => {
    let sendData = {};
    let result = await HttpClient.requestData(
      "user/set-status/" + id,
      "PUT",
      sendData
    );
    console.log("result", result);
    if (result && result.status) {
      this.fetchData();
    }
  };
  submit = async () => {
    if (
      this.state.title != "" &&
      this.state.price != "" &&
      this.state.duration != "" &&
      this.state.seller_comission != "" &&
      this.state.type != ""
    ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          name: this.state.title,
          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData("subscription", "POST", data);
      } else {
        data = {
          name: this.state.title,
          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData(
          "subscription/" + this.state.edit_item_id,
          "PUT",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Subscription Updated Successfully"
            : "Subscription Added Succfully",

          type: "success",

          image_select: false,
          img_url: "",
          title: "",
          type: "",
          seller_comission: "",
          duration: "",
          price: "",
          desc: "",
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (e) => {
    let file = e.target.files[0].type;
    let fileType = file.split("/")[0];
    // fileType = fileType[0];
    console.log("e", file.split("/")[0]);

    if (fileType == "image" || fileType == "video") {
      // return false;
      this.setState({ fileType: fileType });
      let dataSend = { buckate: true, buckate_name: "appIcon" };

      let result = await HttpClient.newFileUpload(
        "image-upload/movie",
        e.target.files[0],
        dataSend
      );
      console.log("result", result);
      if (result && result.status) {
        this.setState({ img_url: result.url, image_select: true });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",

          width: 100,
        },
        // {
        //   label: "Image",
        //   field: "img",
        //
        //   width: 200,
        // },
        {
          label: "User Name",
          field: "username",

          width: 190,
        },
        {
          label: "Email",
          field: "email",

          width: 200,
        },
        {
          label: "Device Type",
          field: "devtype",

          width: 270,
        },
        {
          label: "Currency",
          field: "currency",

          width: 270,
        },
        {
          label: "Date",
          field: "date",

          width: 150,
        },
        {
          label: "Total Earning",
          field: "total_earning",

          width: 130,
        },
        {
          label: "Refund Amount",
          field: "refundamt",

          width: 130,
        },
        // {
        //   label: "Paid Amount",
        //   field: "paid_amount",

        //   width: 150,
        // },

        {
          label: "Status",
          field: "status",

          width: 150,
        },
        {
          label: "Subscription Status",
          field: "subscription_status",

          width: 150,
        },
        // {
        //   label: "View",
        //   field: "view",

        //   width: 200,
        // },
        // {
        //   label: "Payment History",
        //   field: "view_history",

        //   width: 200,
        // },
        {
          label: "Seller Shop",
          field: "seller_shop",

          width: 170,
        },
        {
          label: "Seller Services",
          field: "seller_service",

          width: 170,
        },
        {
          label: "Seller Commission",
          field: "seller_commission",

          width: 170,
        },
        {
          label: "Withdrawl Commission",
          field: "withdrawl_commission",

          width: 170,
        },
        
      ],
      rows: this.state.data,
    };

    const header = [

      { label: "Sl.", key: "sl" },
      { label: "User Name", key: "username" },
      { label: "Email", key: "email" },
      { label: "Date", key: "date" },
      { label: "Total Earning", key: "total_earning" },
      { label: "Paid Amount", key: "paid_amount" },
    ]
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {this.state.ismodalopen ? (
              <SellerShop
                status={true}
                onCloseCallBack={this.onCloseCallBack}
                sucess={this.sucess}
                data={this.state.activeid}
                sellerid={this.state.sellerid}
              />
            ) : null}
            <Row>
              <Col xl={3}>
                {this.state.data.length > 0 ?
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"SellerList.csv"}
                    className="btn btn-success">
                    Export Report in CSV
                  </CSVLink> : null}
              </Col>
            </Row>&nbsp;

            <Row className="mb-3">
              <Col md={3}>
                <select
                  className="form-control"
                  onChange={(val) => {
                    this.state.device = val.target.value;
                    this.setState({
                      
                    })
                    this.fetchData(val.target.value);
                  }}
                  value={this.state.device}
                >
                  <option value="">Select Type</option>
                  {this.state.devicefilter.length > 0 &&
                    this.state.devicefilter.map((it, index) => {
                      return <option value={it} key={index}>{it}</option>;
                    })}
                </select>
              </Col>
            </Row>
            {this.state.loading ? (
               <ReactLoading
               type="spokes"
               color="black"
               height={"5%"}
               width={"5%"}
               className="loader"
             />
            ) : (
               <Row>

               <Col xl={12}>
                 <div className="card p-3">
                   {/* <MDBDataTable responsive bordered data={data} /> */}
                   <MDBDataTableV5
                     hover
                     scrollX
                     entriesOptions={[5, 20, 25]}
                     entries={5}
                     pagesAmount={4}
                     data={data}
                     disableRetreatAfterSorting={true}
                   />
                 </div>
               </Col>
             </Row>
            )}
           
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
