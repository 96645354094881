import React, { Component } from "react";
import Preview from "../Component/preview";
import classnames from "classnames";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Tooltip,
  Card,
  CardBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Input,
  Form,
  Button,
  FormGroup,
  InputGroup,
  UncontrolledAlert,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "./../../utils/HttpClient";
import BaseInfo from "../ChooseDesign/baseInfo";
import Breadcrumbs from "../../components/Common/Breadcrumb";


export default class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      breadcrumbItems: [
        { title: "Loyalty ", link: "#" },
        { title: "Add & Manage Loyalty", link: "#" },
    ],
      activeTab: 1,
      tooltipOpen: false,
      monthDate: new Date(),
      monitize: [
        {
          loyaltyName: "",
          loyaltyPoints: "",
          sheduleDate: new Date(),
          message: "",
          expireDate: new Date(),
          type: "Loyalty Card",
          loyaltyType: "",
          rule: "",
          bonusPoints: "",
          bPoints: "",
          giftImage: "",
        },
      ],
      monitizeObj: {
        loyaltyName: "",
        loyaltyPoints: "",
        sheduleDate: new Date(),
        message: "",
        expireDate: new Date(),
        type: "Loyalty Card",
        loyaltyType: "",
        rule: "",
        bonusPoints: "",
        bPoints: "",
        giftImage: "",
      },
      monitizeScratch: [
        {
          scratchName: "",
          giftName: "",
          message: "",
          winningRatio: "",
          loyaltyPoint: "",
          giftImage: "",
          type: "Scratch Card",
        },
      ],
      monitizeScratchObj: {
        scratchName: "",
        giftName: "",
        message: "",
        winningRatio: "",
        loyaltyPoint: "",
        giftImage: "",
        type: "Scratch Card",
      },
      userId: "",
      userDetails: {},
      data: [],
      result: [],
      loyaltyType: [
        { name: "Kyc", value: "Kyc" },
        { name: "Products", value: "Products" },
        { name: "Services", value: "Services" },
        { name: "Share", value: "Share" },
        { name: "Quiz", value: "Quiz" },
        { name: "Events", value: "Events" },
      ],
      kycType: [{ name: "Complete", value: "Complete" }],
      ProductsType: [
        { name: "First purchase", value: "First purchase" },
        { name: "5 products", value: "5 products" },
        { name: "10 products", value: "10 products" },
        { name: "$100", value: "$100" },
      ],
      ServicesType: [
        { name: "First booking", value: "First booking" },
        { name: "5 bookings", value: "5 bookings" },
        { name: "10 bookings", value: "10 bookings" },
        { name: "$100", value: "$100" },
      ],
      ShareType: [
        { name: "1 share", value: "1 share" },
        { name: "2 share", value: "2 share" },
      ],
      QuizType: [
        { name: "Complete", value: "Complete" },
        { name: "Participation", value: "Participation" },
      ],
      EventsType: [{ name: "Participation", value: "Participation" }],
      loyaltyName: "",
      sletedFile: {},
      edit_id: "",
      edit_status: false,
      alert: false,
      message: "",
      type: "",
      Scratchdata: [],
    };
    this.toggleTab.bind(this);
    // this.handleMonthChange = this.handleMonthChange.bind(this);
  }

  async componentDidMount() {
    this.checkUser();
  }

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({ userId: user.id, userDetails: user });
      this.fetch();
    } else {
    }
  };

  handleMonthChange(date, type, indexx, field) {
    if (type == "loyalty") {
      if (field == "sheduleDate") {
        this.state.monitize[indexx].sheduleDate = date;
        this.setState({});
      } else {
        this.state.monitize[indexx].expireDate = date;
        this.setState({});
      }
    } else {
      if (field == "sheduleDate") {
        this.state.monitizeScratch[indexx].sheduleDate = date;
        this.setState({});
      } else {
        this.state.monitizeScratch[indexx].expireDate = date;
        this.setState({});
      }
    }

    // this.setState({ monthDate: date });
  }
  blankChk = (index, type) => {
    let data = [];
    if (type == "loyalty") {
      data = this.state.monitize;
    } else {
      data = this.state.monitizeScratch;
    }
    if (
      data[index].loyaltyNumber != "" &&
      data[index].expireDate != "" &&
      data[index].sheduleDate != ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  dateChk = (index, type) => {
    let data = [];
    if (type == "loyalty") {
      data = this.state.monitize;
    } else {
      data = this.state.monitizeScratch;
    }
    if (data[index].expireDate == data[index].sheduleDate) {
      return false;
    } else {
      const startDate = moment(data[index].sheduleDate);
      const timeEnd = moment(data[index].expireDate);
      const diff = timeEnd.diff(startDate);
      const diffDuration = moment.duration(diff);
      if (diffDuration.days() > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  edit = (element) => {
    if(this.state.activeTab == '1')
    {
      this.state.monitize[0].loyaltyName = element.loyaltyName;
      this.state.monitize[0].loyaltyPoints = element.loyaltyPoints;
      this.state.monitize[0].sheduleDate = new Date(element.sheduleDate);
      this.state.monitize[0].message = element.message;
      this.state.monitize[0].expireDate = new Date(element.expireDate);
      this.state.monitize[0].type = element.type;
      this.state.monitize[0].loyaltyType = element.loyaltyType;
      this.state.monitize[0].rule = element.rule;
      this.state.monitize[0].bonusPoints = element.bonusPoints;
      this.state.monitize[0].bPoints = element.bPoints;
      this.state.monitize[0].giftImage = element.giftImage;
      this.setState({
        edit_id: element._id,
        edit_status: true,
      });
    }
    else
    {
      console.log('element',element.winningRatio);
      this.state.monitizeScratch[0].scratchName = element.scratchName;
      this.state.monitizeScratch[0].giftName = element.giftName;
      this.state.monitizeScratch[0].message = element.message;
      this.state.monitizeScratch[0].winningRatio = Number(element.winningRatio);
      this.state.monitizeScratch[0].loyaltyPoint = Number(element.loyaltyPoint);
      this.state.monitizeScratch[0].giftImage = element.giftImage;
      this.state.monitizeScratch[0].type = element.type;
      this.setState({
        edit_id: element._id,
        edit_status: true,
      });
    }
   
  };
  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeTab: tab,
        });
      }
    }
  }

  imageUpload = async (file) => {
    // alert("upload");
    let dataSend = { buckate: true, buckate_name: "appIcon" };
    let result = await HttpClient.newFileUpload(
      "image-upload/monitize",
      file,
      dataSend
    );

    return result;
  };

  submitFrom = async () => {
    let data = null;
    let result = null;
    if (this.state.activeTab == "1") {
      if (
        this.state.monitize[0].loyaltyName != "" &&
        this.state.monitize[0].loyaltyPoints != "" &&
        this.state.monitize[0].sheduleDate != "" &&
        this.state.monitize[0].expireDate != "" &&
        this.state.monitize[0].bonusPoints != "" &&
        this.state.monitize[0].type != ""
      ) {
        if (this.state.edit_status) {
          data = {
            data:
              this.state.activeTab == "1"
                ? this.state.monitize
                : this.state.monitizeScratch,
            edit_id: this.state.edit_id,
          };
          result = await HttpClient.requestData(
            "admin/loyalty/update",
            "POST",
            data
          );
        } else {
          data = {
            data:
              this.state.activeTab == "1"
                ? this.state.monitize
                : this.state.monitizeScratch,
          };
          console.log('data Loyalty',data);
          result = await HttpClient.requestData(
            "admin/loyalty",
            "POST",
            data
          );
        }
      } else {
        this.setState({
          alert: true,
          message: "Please Fillup All Details",
          type: "danger",
        });
      }
      if (result && result.status) {
        this.fetch();
        // alert("Successfully Submited.");
        this.setState({
          alert: true,
          message: this.state.edit_status
            ? "Successfully Submited."
            : "Successfully Updated.",
          type: "success",
        });

        this.setState({
          monitize: [
            {
              loyaltyName: "",
              loyaltyPoints: "",
              sheduleDate: new Date(),
              message: "",
              expireDate: new Date(),
              type: "Loyalty Card",
              loyaltyType: "",
              rule: "",
              bonusPoints: "",
              bPoints: "",
            },
          ],
          monitizeScratch: [
            {
              loyaltyNumber: "",
              sheduleDate: new Date(),
              message: "",
              expireDate: new Date(),
              type: "Scratch Card",
            },
          ],
        });
      } else {
        this.setState({
          alert: true,
          message: "Something Went Worng.",
          type: "danger",
        });
      }
    } else {
      // alert();
      if (
        this.state.monitizeScratch[0].scratchName != "" &&
        this.state.monitizeScratch[0].giftName != "" &&
        this.state.monitizeScratch[0].winningRatio != "" &&
        this.state.monitizeScratch[0].loyaltyPoint != ""
      ) {
        if (this.state.edit_status) {
          data = {
            data: this.state.monitizeScratch,
            owner_id: this.state.userId,
            edit_id: this.state.edit_id,
          };
          result = await HttpClient.requestData(
            "admin/scrach-card/update",
            "POST",
            data
          );
        } else {
          data = {
            data: this.state.monitizeScratch,
            owner_id: this.state.userId,
          };
          result = await HttpClient.requestData(
            "admin/scrach-card",
            "POST",
            data
          );
        }
        console.log("result", result);
        if (result && result.status) {
          this.fetch();
          // alert("Successfully Submited.");
          this.setState({
            alert: true,
            message: this.state.edit_status
              ? "Successfully Submited."
              : "Successfully Updated.",
            type: "success",
          });

          this.setState({
            monitizeScratch: [
              {
                scratchName: "",
                giftName: "",
                message: "",
                winningRatio: "",
                loyaltyPoint: "",
                giftImage: "",
                type: "Scratch Card",
              },
            ],
          });
        } else {
          this.setState({
            alert: true,
            message: "Something Went Worng.",
            type: "danger",
          });
        }
      } else {
        this.setState({
          alert: true,
          message: "Please Fillup All Details",
          type: "danger",
        });
      }
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  async fetch() {
    let sendData = {
      owner_id: this.state.userId,
    };
    let result = null;
    if (this.state.activeTab == "1") {
      console.log('result',this.state.activeTab);
      result = await HttpClient.requestData(
        "admin/loyalty/fetch-all",
        "POST",
        sendData
      );
    } else {
      console.log('result',this.state.activeTab);
      result = await HttpClient.requestData(
        "admin/scrach-card/fetch-all",
        "POST",
        sendData
      );
    }
    
    if (result && result.status > 0) {
      let data = [];
      let Scratchdata = [];

      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = null;
        if (this.state.activeTab == "1") {
          rows = {
            sl: i,
            name: element.loyaltyName,
            type: element.loyaltyType,
            card: element.loyaltyPoints,
            mssg: element.message,
            start: moment(element.sheduleDate).format("L"),
            expire: moment(element.expireDate).format("L"),
            giftImg: (<img src={element.giftImage} style={{height:80,width:80,borderRadius:10}} />),
            action: (
              <>
                <button
                  title="Delete"
                  className="btn btn-danger mr-2"
                  onClick={() => {
                    this.del(element);
                  }}
                >
                  <i className="fa fa-trash" />
                </button>
                <button
                  title="Edit"
                  className="btn btn-primary"
                  onClick={() => {
                    this.edit(element);
                  }}
                >
                  <i className="fa fa-edit" />
                </button>
              </>
            ),
          };
        } else {
          rows = {
            sl: i,
            name: element.scratchName,
            giftname: element.giftName,
            winRation: element.winningRatio,
            loyalty: element.loyaltyPoint,
            giftImg: (<img src={element.giftImage} style={{height:80,width:80,borderRadius:10}}/>),
            action: (
              <>
                <button
                  title="Delete"
                  className="btn btn-danger mr-2"
                  onClick={() => {
                    this.del(element);
                  }}
                >
                  <i className="fa fa-trash" />
                </button>
                <button
                  title="Edit"
                  className="btn btn-primary"
                  onClick={() => {
                    this.edit(element);
                  }}
                >
                  <i className="fa fa-edit" />
                </button>
              </>
            ),
          };
        }

        i++;
        if (this.state.activeTab == "1") {
          data.push(rows);
        } else {
          Scratchdata.push(rows);
        }
      });
      this.setState({
        data: data,
        Scratchdata: Scratchdata,
      });
    } else {
      this.setState({
        data: [],
        Scratchdata: [],
      });
    }
  }

  del = async (item) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData(
      "admin/loyalty/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({});
        let data = [];
        let i = 1;
        this.state.result.forEach((element, index) => {
          let rows = {
            sl: i,
            type: element.type,
            card: element.loyaltyNumber,
            mssg: element.message,
            start: moment(element.sheduleDate).format("L"),
            expire: moment(element.expireDate).format("L"),
            action: (
              <>
                <button
                  title="Delete"
                  className="btn btn-danger mr-2"
                  onClick={() => {
                    this.del(element);
                  }}
                >
                  <i className="fa fa-trash" />
                </button>
                <button title="Edit" className="btn btn-primary">
                  <i className="fa fa-edit" />
                </button>
              </>
            ),
          };
          i++;
          data.push(rows);
        });
        this.setState({ data: data });
      }
    }
  };

  renderType = (loyaltyType, index) => {
    if (loyaltyType == "Kyc") {
      return (
        <select
          className="form-control"
          onChange={(val) => {
            this.handleChangeDrop(val.target.value, index);
          }}
          value={this.state.monitize[index].rule}
        >
          <option value="">Select Kyc type</option>
          {this.state.kycType.map((item, index) => {
            return (
              <option value={item.value} key={index}>
                {item.name}
              </option>
            );
          })}
        </select>
      );
    } else if (loyaltyType == "Products") {
      return (
        <select
          className="form-control"
          onChange={(val) => {
            this.handleChangeDrop(val.target.value, index);
          }}
          value={this.state.monitize[index].rule}
        >
          <option value="">Select Products type</option>
          {this.state.ProductsType.map((item, index) => {
            return (
              <option value={item.value} key={index}>
                {item.name}
              </option>
            );
          })}
        </select>
      );
    } else if (loyaltyType == "Services") {
      return (
        <select
          className="form-control"
          onChange={(val) => {
            this.handleChangeDrop(val.target.value, index);
          }}
          value={this.state.monitize[index].rule}
        >
          <option value="">Select Services type</option>
          {this.state.ServicesType.map((item, index) => {
            return (
              <option value={item.value} key={index}>
                {item.name}
              </option>
            );
          })}
        </select>
      );
    } else if (loyaltyType == "Share") {
      return (
        <select
          className="form-control"
          onChange={(val) => {
            this.handleChangeDrop(val.target.value, index);
          }}
          value={this.state.monitize[index].rule}
        >
          <option value="">Select Share type</option>
          {this.state.ShareType.map((item, index) => {
            return (
              <option value={item.value} key={index}>
                {item.name}
              </option>
            );
          })}
        </select>
      );
    } else if (loyaltyType == "Quiz") {
      return (
        <select
          className="form-control"
          onChange={(val) => {
            this.handleChangeDrop(val.target.value, index);
          }}
          value={this.state.monitize[index].rule}
        >
          <option value="">Select Quiz type</option>
          {this.state.QuizType.map((item, index) => {
            return (
              <option value={item.value} key={index}>
                {item.name}
              </option>
            );
          })}
        </select>
      );
    } else if (loyaltyType == "Events") {
      return (
        <select
          className="form-control"
          onChange={(val) => {
            this.handleChangeDrop(val.target.value, index);
          }}
          value={this.state.monitize[index].rule}
        >
          <option value="">Select Events type</option>
          {this.state.EventsType.map((item, index) => {
            return (
              <option value={item.value} key={index}>
                {item.name}
              </option>
            );
          })}
        </select>
      );
    }
  };

  handleChangeDrop = (value, index) => {
    this.state.monitize[index].rule = value;
    this.setState({});
  };

  render() {
    const cardData = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Type",
          field: "type",
          sort: "asc",
          width: 150,
        },
        {
          label: "Point",
          field: "card",
          sort: "asc",
          width: 150,
        },
        {
          label: "Message",
          field: "mssg",
          sort: "asc",
          width: 270,
        },
        {
          label: "Start date",
          field: "start",
          sort: "asc",
          width: 150,
        },
        {
          label: "Expire date",
          field: "expire",
          sort: "asc",
          width: 150,
        },
        {
          label: "Gift Image",
          field: "giftImg",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    const cardDataScratch = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Scratch Name",
          field: "name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Gift Name",
          field: "giftname",
          sort: "asc",
          width: 150,
        },
        {
          label: "Win Ratio",
          field: "winRation",
          sort: "asc",
          width: 150,
        },
        {
          label: "Point",
          field: "loyalty",
          sort: "asc",
          width: 270,
        },
        {
          label: "Gift Image",
          field: "giftImg",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.Scratchdata,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>

          <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xl={12}>
                {/* <BaseInfo /> */}
                {this.state.alert ? (
                  <UncontrolledAlert
                    color={this.state.type}
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.type == "warning" ? (
                      <i className="mdi mdi-alert-outline mr-2"></i>
                    ) : this.state.type == "success" ? (
                      <i className="mdi mdi-check-all mr-2"></i>
                    ) : this.state.type == "danger" ? (
                      <i className="mdi mdi-block-helper mr-2"></i>
                    ) : null}
                    {this.state.message}
                  </UncontrolledAlert>
                ) : null}
              </Col>
              <Col xl={12}>
                {/* <div>
                  <h3 className="title-editor py-1 text-center">
                    Monetization{" "}
                    <i
                      className="fa fa-info-circle display_tooltip"
                      id="TooltipInfo"
                    />
                    <Tooltip
                      placement="top"
                      isOpen={this.state.tooltipOpen}
                      target="TooltipInfo"
                      toggle={this.toggle}
                      autohide={false}
                    >
                      Monetize your app with scratch card and loyalty card
                    </Tooltip>
                  </h3>
                </div> */}
                <div>
                  <Card>
                    <CardBody>
                      <div
                        id="basic-pills-wizard"
                        className="twitter-bs-wizard"
                      >
                        <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === 1,
                              })}
                              onClick={() => {
                                this.toggleTab(1);
                                setTimeout(() => {
                                  this.setState({edit_status:false,edit_id:""})
                                  this.fetch();
                                }, 200);
                              }}
                            >
                              <span className="step-number">01</span>
                              <span className="step-title">Loyalty Card</span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === 2,
                              })}
                              onClick={() => {
                                this.toggleTab(2);
                                setTimeout(() => {
                                  this.setState({edit_status:false,edit_id:""})
                                  this.fetch();
                                }, 200);
                              }}
                            >
                              <span className="step-number">02</span>
                              <span className="step-title">Scratch Card</span>
                            </NavLink>
                          </NavItem>
                        </ul>
                        <TabContent
                          activeTab={this.state.activeTab}
                          className="twitter-bs-wizard-tab-content"
                        >
                          <TabPane tabId={1}>
                            <Form>
                              {this.state.monitize.map((item, index) => {
                                return (
                                  <Row>
                                    <Col lg="6">
                                      <FormGroup>
                                        <Label for="loyalty-input1">
                                          Loyalty Name
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="text"
                                          value={
                                            this.state.monitize[index]
                                              .loyaltyName
                                          }
                                          id="loyalty-input"
                                          placeholder="Enter loyalty name..."
                                          onChange={(val) => {
                                            this.state.monitize[
                                              index
                                            ].loyaltyName = val.target.value;
                                            this.setState({});
                                          }}
                                        />
                                      </FormGroup>
                                      <FormGroup>
                                        <Label for="message-input1">
                                          Describe message (Optional)
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="text"
                                          value={
                                            this.state.monitize[index].message
                                          }
                                          id="message-input1"
                                          placeholder="Enter message here..."
                                          onChange={(val) => {
                                            this.state.monitize[index].message =
                                              val.target.value;
                                            this.setState({});
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                      <FormGroup>
                                        <Label for="loyalty-input1">
                                          Loyalty points
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="number"
                                          min={1}
                                          value={
                                            this.state.monitize[index]
                                              .loyaltyPoints
                                          }
                                          id="loyalty-input"
                                          placeholder="Enter Loyalty points..."
                                          onChange={(val) => {
                                            this.state.monitize[
                                              index
                                            ].loyaltyPoints = val.target.value;
                                            this.setState({});
                                          }}
                                        />
                                      </FormGroup>
                                      <FormGroup>
                                        <Label for="message-input1">
                                          Gift Image
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="file"
                                          value={""}
                                          id="message-input1"
                                          placeholder="Select Gift Image..."
                                          onChange={async (val) => {
                                            let result = await this.imageUpload(
                                              val.target.files[0]
                                            );
                                            // console.log('result',result)
                                            if (result && result.status) {
                                              this.state.monitize[
                                                index
                                              ].giftImage = result.url;
                                            }
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>

                                    <Col lg="6">
                                      <FormGroup>
                                        <Label>Start Date</Label>
                                        <InputGroup>
                                          <DatePicker
                                            selected={
                                              this.state.monitize[index]
                                                .sheduleDate
                                            }
                                            className="form-control"
                                            onChange={(e) =>
                                              this.handleMonthChange(
                                                e,
                                                "loyalty",
                                                index,
                                                "sheduleDate"
                                              )
                                            }
                                            showMonthDropdown
                                          />
                                        </InputGroup>
                                      </FormGroup>
                                      <FormGroup>
                                        <Label>Expire Date</Label>
                                        <InputGroup>
                                          <DatePicker
                                            selected={
                                              this.state.monitize[index]
                                                .expireDate
                                            }
                                            className="form-control"
                                            onChange={(e) =>
                                              this.handleMonthChange(
                                                e,
                                                "loyalty",
                                                index,
                                                "expireDate"
                                              )
                                            }
                                            showMonthDropdown
                                          />
                                        </InputGroup>
                                      </FormGroup>
                                    </Col>

                                    <Col lg="6">
                                      <FormGroup>
                                        <Label for="message-input1">
                                          Loyalty type
                                        </Label>

                                        <select
                                          className="form-control"
                                          onChange={(val) => {
                                            this.state.monitize[
                                              index
                                            ].loyaltyType = val.target.value;
                                            this.setState({
                                              loyaltyName: val.target.value,
                                            });
                                          }}
                                          value={
                                            this.state.monitize[index]
                                              .loyaltyType
                                          }
                                        >
                                          <option value="">
                                            Select Loyalty type
                                          </option>
                                          {this.state.loyaltyType.map(
                                            (item, index) => {
                                              return (
                                                <option
                                                  value={item.value}
                                                  key={index}
                                                >
                                                  {item.name}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                      </FormGroup>

                                      <FormGroup>
                                        <Label for="message-input1">
                                          Rules
                                        </Label>

                                        {this.state.monitize[index]
                                          .loyaltyType != ""
                                          ? this.renderType(
                                              this.state.monitize[index]
                                                .loyaltyType,
                                              index
                                            )
                                          : null}
                                      </FormGroup>
                                    </Col>

                                    <Col lg="6">
                                      <FormGroup>
                                        <Label for="message-input1">
                                          Bonus points
                                        </Label>

                                        <select
                                          className="form-control"
                                          onChange={(val) => {
                                            this.state.monitize[
                                              index
                                            ].bonusPoints = val.target.value;
                                            this.setState({});
                                          }}
                                          value={
                                            this.state.monitize[index]
                                              .bonusPoints
                                          }
                                        >
                                          <option value="">
                                            Select Bonus points
                                          </option>
                                          <option value="Yes">Yes</option>
                                          <option value="No">No</option>
                                        </select>
                                      </FormGroup>

                                      {this.state.monitize[index].bonusPoints ==
                                        "Yes" &&
                                      this.state.monitize[index].bonusPoints !=
                                        "" ? (
                                        <FormGroup>
                                          <Label for="message-input1">
                                            Bonus points
                                          </Label>

                                          <select
                                            className="form-control"
                                            onChange={(val) => {
                                              this.state.monitize[
                                                index
                                              ].bPoints = val.target.value;
                                              this.setState({});
                                            }}
                                            value={
                                              this.state.monitize[index].bPoints
                                            }
                                          >
                                            <option value="">
                                              Select points
                                            </option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                          </select>
                                        </FormGroup>
                                      ) : null}
                                    </Col>
                                  </Row>
                                );
                              })}
                              <Row>
                                <Col lg="12">
                                  <FormGroup className="mb-0">
                                    <div className="button-items pt-4">
                                      <Button
                                        color="primary"
                                        type="button"
                                        className="waves-effect waves-light mr-1"
                                        onClick={() => {
                                          if (this.state.edit_status) {
                                            this.updateFrom();
                                          } else {
                                            this.submitFrom();
                                          }
                                        }}
                                      >
                                        {this.state.edit_status
                                          ? "Update"
                                          : "Live"}{" "}
                                        Now{" "}
                                        <i className="ri-arrow-right-line align-middle ml-1"></i>
                                      </Button>
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Form>
                            {/* <h4>Your Loyalty Cards</h4>
                            <MDBDataTable responsive bordered data={cardData} /> */}
                          </TabPane>
                          <TabPane tabId={2}>
                            <Form>
                              {this.state.monitizeScratch.map((item, index) => {
                                return (
                                  <Row>
                                    <Col lg="6">
                                      <FormGroup>
                                        <Label for="scratch-input1">
                                          Scratch Card Name
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="text"
                                          value={
                                            this.state.monitizeScratch[index]
                                              .scratchName
                                          }
                                          id="scratch-input"
                                          placeholder="Enter scratch card name..."
                                          onChange={(val) => {
                                            this.state.monitizeScratch[
                                              index
                                            ].scratchName = val.target.value;
                                            this.setState({});
                                          }}
                                        />
                                      </FormGroup>
                                      <FormGroup>
                                        <Label for="message-input1">
                                          Describe message (Optional)
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="text"
                                          value={
                                            this.state.monitizeScratch[index]
                                              .message
                                          }
                                          id="message-input1"
                                          placeholder="Enter message here..."
                                          onChange={(val) => {
                                            this.state.monitizeScratch[
                                              index
                                            ].message = val.target.value;
                                            this.setState({});
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                      <FormGroup>
                                        <Label for="loyalty-input1">
                                          Gift Name
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="text"
                                          value={
                                            this.state.monitizeScratch[index]
                                              .giftName
                                          }
                                          id="loyalty-input"
                                          placeholder="Enter Gift Name..."
                                          onChange={(val) => {
                                            this.state.monitizeScratch[
                                              index
                                            ].giftName = val.target.value;
                                            this.setState({});
                                          }}
                                        />
                                      </FormGroup>
                                      <FormGroup>
                                        <Label for="message-input1">
                                          Gift Image
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="file"
                                          value={""}
                                          id="message-input1"
                                          placeholder="Select Gift Image..."
                                          onChange={async (val) => {
                                            let result = await this.imageUpload(
                                              val.target.files[0]
                                            );
                                            if (result && result.status) {
                                              this.state.monitizeScratch[
                                                index
                                              ].giftImage = result.url;
                                            }
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>

                                    <Col lg="6">
                                      <FormGroup>
                                        <Label for="loyalty-input1">
                                          Winning Percentage
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="number"
                                          value={
                                            this.state.monitizeScratch[index]
                                              .winningRatio
                                          }
                                          id="loyalty-input"
                                          placeholder="Enter Winning Percentage..."
                                          onChange={(val) => {
                                            this.state.monitizeScratch[
                                              index
                                            ].winningRatio = val.target.value;
                                            this.setState({});
                                          }}
                                        />
                                      </FormGroup>
                                      <FormGroup>
                                        <Label for="loyalty-input1">
                                          Loyalty Point
                                        </Label>
                                        <Input
                                          className="form-control"
                                          type="number"
                                          value={
                                            this.state.monitizeScratch[index]
                                              .loyaltyPoint
                                          }
                                          id="loyalty-input"
                                          placeholder="Enter Loyalty Point..."
                                          onChange={(val) => {
                                            this.state.monitizeScratch[
                                              index
                                            ].loyaltyPoint = val.target.value;
                                            this.setState({});
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                );
                              })}
                              <Row>
                                <Col lg="12">
                                  <FormGroup className="mb-0">
                                    <div className="button-items pt-4">
                                      <Button
                                        color="primary"
                                        type="button"
                                        className="waves-effect waves-light mr-1"
                                        onClick={() => {
                                          this.submitFrom();
                                        }}
                                      >
                                        Live Now{" "}
                                        <i className="ri-arrow-right-line align-middle ml-1"></i>
                                      </Button>
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Form>
                            {/* <h4>Your Scratch Cards</h4>
                            <MDBDataTable responsive bordered data={cardData} /> */}
                          </TabPane>
                        </TabContent>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Col>
              {/* app preview here */}
              {/* <Col xl={4}>
                <div></div>
              </Col> */}
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <h4>Your Scratch Cards</h4>

                  <MDBDataTable
                    responsive
                    bordered
                    data={
                      this.state.activeTab == "1" ? cardData : cardDataScratch
                    }
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
