import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Tooltip,
  Label,
  Input,
  Collapse,
  Card,
  CardBody,
  CardHeader,
  Button,
  UncontrolledAlert,
  Spinner,
  InputGroupAddon,
  InputGroup,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import ColorPicker from "@vtaits/react-color-picker";
import DMSteps from "../Component/dMSteps";
import "./design.css";
import AddImageBox from "./addImageBox";
import AddTextBox from "./addTextBox";
import Preview from "../Component/preview";
import AddIconsBox from "./addIconsBox";
import AddIcons from "./addIcon";

import Design_1 from "../AllDesignes/design_1";
import Design_2 from "../AllDesignes/design_2";
import Design_3 from "../AllDesignes/design_3";
import Design_4 from "../AllDesignes/design_4";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "./../../utils/HttpClient";
import AllDesignes from "../AllDesignes";
import MyColorPicker from "../MyColorPicker";
import BaseInfo from "../ChooseDesign/baseInfo";

export default class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      col1: true,
      col2: false,
      col3: false,
      showImageBox: false,
      showTextBox: false,
      showIconsBox: false,
      showIcons: false,
      tooltipOpen: false,
      customIcon: true,
      chooseDesign: false,
      // page states
      userId: "",
      userDetails: {},
      result: {},
      design_num: 2,
      refreshStatus: false,
      design4: {
        backgroundImage:
          "https://png.pngtree.com/thumb_back/fw800/back_our/20190619/ourmid/pngtree-blue-stage-lighting-material-background-image_137923.jpg",
        backgroundImageFile: {},
        backgroundImageSelect: false,
        user: [
          {
            avatar:
              "https://i.pinimg.com/originals/7c/c7/a6/7cc7a630624d20f7797cb4c8e93c09c1.png",
            title: "Enter Title",
            name: "User Name",
            position: "Position",
            avatarSelect: false,
            avatarFile: {},
            text: "",
          },
        ],
        icons: [],
        color: "#00c8c0",
        iconbgcolor: "#00000029",
        textcolor: "#fff",
        textPosition: {},
        titlecolor: "#fff",
        titlebg: "#343a40",
        namecolor: "#000",
        namebg: "#eff2f7",
      },
      design2: {
        backgroundImage:
          "https://png.pngtree.com/thumb_back/fw800/back_our/20190619/ourmid/pngtree-blue-stage-lighting-material-background-image_137923.jpg",
        backgroundImageFile: {},
        backgroundImageSelect: false,
        user: [
          {
            avatar:
              "https://i.pinimg.com/originals/7c/c7/a6/7cc7a630624d20f7797cb4c8e93c09c1.png",
            bgImage:
              "https://png.pngtree.com/thumb_back/fw800/back_our/20190619/ourmid/pngtree-blue-stage-lighting-material-background-image_137923.jpg",
            title: "Enter Title",
            name: "User Name",
            position: "Position",
            avatarSelect: false,
            avatarFile: {},
            bgSelect: false,
            bgFile: {},
            text: "",
          },
        ],
        icons: [],
        color: "#00c8c0",
        titlecolor: "#fff",
        titlebg: "#343a40",
        namecolor: "#000",
        namebg: "#eff2f7",
        iconbgcolor: "#00000029",
        textcolor: "#fff",
      },
      design3: {
        backgroundImage:
          "https://png.pngtree.com/thumb_back/fw800/back_our/20190619/ourmid/pngtree-blue-stage-lighting-material-background-image_137923.jpg",
        backgroundImageFile: {},
        backgroundImageSelect: false,
        user: [],
        icons: [],
        color: "#00c8c0",
        iconbgcolor: "#00000029",
        textcolor: "#fff",
      },
      design1: {
        backgroundImage:
          "https://png.pngtree.com/thumb_back/fw800/back_our/20190619/ourmid/pngtree-blue-stage-lighting-material-background-image_137923.jpg",
        backgroundImageFile: {},
        backgroundImageSelect: false,
        user: [
          {
            bgImage:
              "https://cdn.searchenginejournal.com/wp-content/uploads/2019/08/c573bf41-6a7c-4927-845c-4ca0260aad6b-760x400.jpeg",
            title: "Enter Title",
            text: "",
            icons: [],
            bal: "bal"
          },
          {
            bgImage:
              "https://cdn.searchenginejournal.com/wp-content/uploads/2019/08/c573bf41-6a7c-4927-845c-4ca0260aad6b-760x400.jpeg",
            title: "Enter Title",
            icons: [],
            text: ""
          },
          {
            bgImage:
              "https://cdn.searchenginejournal.com/wp-content/uploads/2019/08/c573bf41-6a7c-4927-845c-4ca0260aad6b-760x400.jpeg",
            title: "Enter Title",
            icons: [],
            text: ""
          },
        ],
        icons: [],
        color: "#00c8c0",
        iconbgcolor: "#00000029",
        textcolor: "#fff",
        textPosition: {},
        fontValue: "",
        fontFamily: "",
      },

      edit_id: "",
      edit_enable: false,
      alert: false,
      message: "",
      type: "",
      refresh: false,
      activeIndex: 1,
      textPosition: {},
      color: "#00c8c0",
      activeText: "",
      hexColor: "#000000",
      textColor: "",
      fontValue: "",
      fontFamily: "",
      activeTextMain: "",
      activePanelIcon: [],
      activePanelIndex: 0,
      ckediter: false
    };
    this.onDrag = this.onDrag.bind(this);
    this.onDragRgb = this.onDragRgb.bind(this);
    this.onDragCust = this.onDragCust.bind(this);
  }
  //Color Picker
  onDragRgb(c1) {
    if (this.state.design_num == 4) {
      this.state.design1.color = c1;
      this.setState({});
    }
    if (this.state.design_num == 3) {
      this.state.design1.color = c1;
      this.setState({});
    }
    if (this.state.design_num == 2) {
      this.state.design1.color = c1;
      this.setState({});
    }
    if (this.state.design_num == 1) {
      this.state.design1.color = c1;
      this.setState({});
    }
  }
  onDrag(c1) {
    this.setState({ colorRgb: c1 });
  }
  onDragCust(c1) {
    this.setState({ colorCust: c1 });
  }

  handlePosition = (e) => {
    let textPosition = this.state.textPosition;
    textPosition[e.target.name] = e.target.value;
    this.setState({
      textPosition,
    });
    if (this.state.design_num == 1) {
      this.state.design1.textPosition = textPosition;
      this.setState({});
    }
  };
  callBackDesign = (val) => {
    this.setState({
      chooseDesign: val,
    });
  };
  t_col1 = () => {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false,
    });
  };
  t_col2 = () => {
    this.setState({
      col2: !this.state.col2,
      col1: false,
      col3: false,
    });
  };
  t_col3 = () => {
    this.setState({
      col3: !this.state.col3,
      col1: false,
      col2: false,
    });
  };
  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };
  callBackImage = (val) => {
    this.setState({
      showImageBox: val,
    });
  };
  callBackText = (val) => {
    this.setState({
      showTextBox: val,
    });
  };
  callTesxt = (text) => { };

  callBackIcons = (val) => {
    this.setState({
      showIconsBox: val,
    });
  };

  callBackIconsNew = (val, index) => {
    console.log('val bal', val);
    console.log('index bal', index);

    this.setState({
      showIconsNew: val,
      // activePanelIcon: this.state.design1.user[index].icon,
      // activePanelIndex: index
    });
  };

  submitChange = (title, name, designation,text) => {
    if (this.state.design_num == 4) {
      this.state.design4.user[0].title = title;
      this.state.design4.user[0].name = name;
      this.state.design4.user[0].position = designation;
      this.state.design4.user[0].text = text;
      this.setState({
        refreshStatus: !this.state.refreshStatus,
      });
    }
    if (this.state.design_num == 2) {
      let lenght = this.state.design2.user.length;
      this.state.design2.user[this.state.activeIndex].title = title;
      this.state.design2.user[this.state.activeIndex].name = name;
      this.state.design2.user[this.state.activeIndex].position = designation;
      this.state.design2.user[this.state.activeIndex].text = text;
      this.setState({
        refreshStatus: !this.state.refreshStatus,
      });
    }
    if (this.state.design_num == 1) {
      let lenght = this.state.design1.user.length;
      this.state.design1.user[this.state.activeIndex].text = title;
      this.setState({
        refreshStatus: !this.state.refreshStatus,
        activeTextMain: title,
      });
    }
  };

  componentDidMount = async () => {
    await this.checkUser();
    await this.getIcons();
    // await this.checkExesisting();
  };

  getIcons = async () => {
    let result = await HttpClient.requestData(
      "app-owner/application/icon",
      "GET"
    );
    if (result && result.status) {
      let data = result.data;
      console.log("re icons", data);
      this.setState({ icons: data });
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
      this.checkExesisting(user.id);
    } else {
    }
  };

  checkExesisting = async (id) => {
    let sendData = { owner_id: id };
    let result = await HttpClient.requestData(
      "app-owner/application/check-pending",
      "POST",
      sendData
    );
    console.log("result check-pending", result);
    if (result && result.status > 0) {
      this.setState({
        result: result.data[0],
        design_num: result.data[0].design_num ? result.data[0].design_num : 2,
        edit_enable: true,
        edit_id: result.data[0]._id,
        refresh: !this.state.refresh,
        refreshStatus: !this.state.refreshStatus,
      });
      console.log("rresult.data[0].design_num", result.data[0]);
      if (result.data[0].design_num) {
        if (result.data[0].design_num == "2") {
          let data = result.data[0];
          console.log("result.data[0]", result.data[0]);
          if (typeof data.design != "undefined") {
            if (data.design.backgroundImage) {
              this.state.design2.backgroundImage = data.design.backgroundImage;
            }

            let newUser = [];
            if (data.design.content) {
              data.design.content.map((item, index) => {
                item.avatarSelect = false;
                item.bgSelect = false;
                newUser.push(item);
              });
              this.state.design2.user = newUser;
            }

            let newIcons = [];
            if (data.design.icons) {
              data.design.icons.map((item, index) => {
                newIcons.push(item);
              });
              this.state.design2.icons = newIcons;
            }
          }
          if (typeof data.design.color != "undefined") {
            this.state.design2.color = data.design.color;
          }
          if (typeof data.design.titlecolor != "undefined") {
            this.state.design2.titlecolor = data.design.titlecolor;
          }
          if (typeof data.design.titlebg != "undefined") {
            this.state.design2.titlebg = data.design.titlebg;
          }
          if (typeof data.design.namecolor != "undefined") {
            this.state.design2.namecolor = data.design.namecolor;
          }

          if (typeof data.design.namebg != "undefined") {
            this.state.design2.namebg = data.design.namebg;
          }

          if (typeof data.design.iconbgcolor != "undefined") {
            this.state.design2.iconbgcolor = data.design.iconbgcolor;
          }

          if (typeof data.design.textcolor != "undefined") {
            this.state.design2.textcolor = data.design.textcolor;
          }

          if (typeof data.design.fontFamily != "undefined") {
            this.state.design2.fontFamily = data.design.fontFamily;
          }

          if (typeof data.design.fontValue != "undefined") {
            this.state.design2.fontValue = data.design.fontValue;
          }

          this.setState({});
        }
        if (result.data[0].design_num == "1") {
          let data = result.data[0];
          console.log("result.data[0]", data.design.fontFamily);
          if (typeof data.design != "undefined") {
            this.state.design1.backgroundImage = data.design.backgroundImage;
            let newUser = [];

            if (Object.keys(data.design.textPosition).length > 0) {
              this.setState({ textPosition: data.design.textPosition });
            }

            data.design.content.map((item, index) => {
              item.avatarSelect = false;
              item.bgSelect = false;
              item.icons = [];
              item.test = "sfgfsg";
              newUser.push(item);
            });

            let newIcons = [];
            data.design.icons.map((item, index) => {
              newIcons.push(item);
            });
            this.state.design1.user = newUser;
            this.state.design1.icons = newIcons;
          }
          if (
            typeof data.color != "undefined" &&
            typeof data.iconbgcolor != "undefined" &&
            typeof data.textcolor != "undefined"
          ) {
            this.state.design1.color = data.color;
            this.state.design1.iconbgcolor = data.iconbgcolor;
            this.state.design1.textcolor = data.textcolor;
          }
          if (typeof data.design.fontFamily != "undefined") {
            this.state.design1.fontFamily = data.design.fontFamily;
          }

          if (typeof data.design.fontValue != "undefined") {
            this.state.design1.fontValue = data.design.fontValue;
          }
          this.setState({});
          setTimeout(() => {
            console.log("this.state.design1", this.state.design1);
          }, 200);
        }

        if (result.data[0].design_num == "4") {
          let data = result.data[0];
          console.log("result.data[0]", result.data[0]);
          if (typeof data.design != "undefined") {
            if (data.design.backgroundImage) {
              this.state.design4.backgroundImage = data.design.backgroundImage;
            }

            let newUser = [];
            if (data.design.content) {
              data.design.content.map((item, index) => {
                item.avatarSelect = false;
                item.bgSelect = false;
                newUser.push(item);
              });
              this.state.design4.user = newUser;
            }

            let newIcons = [];
            if (data.design.icons) {
              data.design.icons.map((item, index) => {
                newIcons.push(item);
              });
              this.state.design4.icons = newIcons;
            }
          }

          if (typeof data.design.color != "undefined") {
            this.state.design4.color = data.design.color;
          }
          if (typeof data.design.titlecolor != "undefined") {
            this.state.design4.titlecolor = data.design.titlecolor;
          }
          if (typeof data.design.titlebg != "undefined") {
            this.state.design4.titlebg = data.design.titlebg;
          }
          if (typeof data.design.namecolor != "undefined") {
            this.state.design4.namecolor = data.design.namecolor;
          }

          if (typeof data.design.namebg != "undefined") {
            this.state.design4.namebg = data.design.namebg;
          }

          if (typeof data.design.iconbgcolor != "undefined") {
            this.state.design4.iconbgcolor = data.design.iconbgcolor;
          }

          if (typeof data.design.textcolor != "undefined") {
            this.state.design4.textcolor = data.design.textcolor;
          }

          if (typeof data.design.fontFamily != "undefined") {
            this.state.design4.fontFamily = data.design.fontFamily;
          }

          if (typeof data.design.fontValue != "undefined") {
            this.state.design4.fontValue = data.design.fontValue;
          }

          this.setState({});
        }

        if (result.data[0].design_num == "3") {
          let data = result.data[0];
          console.log("result.data[0]", result.data[0]);
          if (typeof data.design != "undefined") {
            if (data.design.backgroundImage) {
              this.state.design3.backgroundImage = data.design.backgroundImage;
            }

            let newUser = [];
            if (data.design.content) {
              data.design.content.map((item, index) => {
                item.avatarSelect = false;
                item.bgSelect = false;
                newUser.push(item);
              });
              this.state.design3.user = newUser;
            }

            let newIcons = [];
            if (data.design.icons) {
              data.design.icons.map((item, index) => {
                newIcons.push(item);
              });
              this.state.design3.icons = newIcons;
            }
          }

          if (typeof data.design.color != "undefined") {
            this.state.design3.color = data.design.color;
          }
          if (typeof data.design.titlecolor != "undefined") {
            this.state.design3.titlecolor = data.design.titlecolor;
          }
          if (typeof data.design.titlebg != "undefined") {
            this.state.design3.titlebg = data.design.titlebg;
          }
          if (typeof data.design.namecolor != "undefined") {
            this.state.design3.namecolor = data.design.namecolor;
          }

          if (typeof data.design.namebg != "undefined") {
            this.state.design3.namebg = data.design.namebg;
          }

          if (typeof data.design.iconbgcolor != "undefined") {
            this.state.design3.iconbgcolor = data.design.iconbgcolor;
          }

          if (typeof data.design.textcolor != "undefined") {
            this.state.design3.textcolor = data.design.textcolor;
          }

          if (typeof data.design.fontFamily != "undefined") {
            this.state.design3.fontFamily = data.fontFamily;
          }

          if (typeof data.design.fontValue != "undefined") {
            this.state.design3.fontValue = data.fontValue;
          }

          this.setState({});
        }
      }
    }
  };

  imageUpload = async (file) => {
    // alert("upload");
    let dataSend = { buckate: true, buckate_name: "appIcon" };
    let result = await HttpClient.newFileUpload(
      "image-upload/appIcon",
      file,
      dataSend
    );

    return result;
  };

  saveChange = async () => {
    // return false;
    if (this.state.edit_enable) {
      let sendData = {};

      sendData.edit_id = this.state.edit_id;
      let design = {};
      if (this.state.design_num == 4) {
        if (this.state.design4.icons.length == 0) {
          this.setState({
            alert: true,
            message: "Please Select Icons",
            type: "danger",
          });

          setTimeout(() => {
            this.setState({
              alert: false,
              message: "",
              type: "",
            });
          }, 3000);

          return false;
        }

        if (this.state.design4.backgroundImageSelect) {
          let logoUpload = await this.imageUpload(
            this.state.design4.backgroundImageFile
          );
          if (logoUpload && logoUpload.status) {
            design.backgroundImage = logoUpload.url;
          }
        }
        let user = [];
        let userDetails = {};
        if (this.state.design4.user[0].avatarSelect) {
          let logoUploadNew = await this.imageUpload(
            this.state.design4.user[0].avatarFile
          );
          console.log("logoUploadNew", logoUploadNew);
          if (logoUploadNew && logoUploadNew.status) {
            userDetails.avatar = logoUploadNew.url;
            this.state.design4.user[0].avatar = logoUploadNew.url;
            this.setState({});
          }
        }
        userDetails.title = this.state.design4.user[0].title;
        userDetails.position = this.state.design4.user[0].position;
        userDetails.name = this.state.design4.user[0].name;
        // console.log("userDetails", userDetails);
        // return false;

        user.push(userDetails);
        design.content = this.state.design4.user;
        design.icons = this.state.design4.icons;
        design.iconbgcolor = this.state.design4.iconbgcolor;
        design.textcolor = this.state.design4.textcolor;
        design.textPosition = this.state.textPosition;

        design.titlecolor = this.state.design4.titlecolor;
        design.titlebg = this.state.design4.titlebg;
        design.namecolor = this.state.design4.namecolor;
        design.namebg = this.state.design4.namebg;
        design.fontFamily = this.state.design4.fontFamily;
        design.fontValue = this.state.design4.fontValue;
      }
      if (this.state.design_num == 2) {
        if (this.state.design2.icons.length == 0) {
          this.setState({
            alert: true,
            message: "Please Select Icons",
            type: "danger",
          });

          setTimeout(() => {
            this.setState({
              alert: false,
              message: "",
              type: "",
            });
          }, 3000);

          return false;
        }

        design.backgroundImage = this.state.design2.backgroundImage;
        design.content = this.state.design2.user;
        design.icons = this.state.design2.icons;

        design.textPosition = this.state.textPosition;

        design.titlecolor = this.state.design2.titlecolor;
        design.titlebg = this.state.design2.titlebg;
        design.namecolor = this.state.design2.namecolor;
        design.namebg = this.state.design2.namebg;

        design.iconbgcolor = this.state.design2.iconbgcolor;
        design.textcolor = this.state.design2.textcolor;

        design.fontFamily = this.state.design2.fontFamily;
        design.fontValue = this.state.design2.fontValue;
      }

      if (this.state.design_num == 1) {
        if (this.state.design1.icons.length == 0) {
          this.setState({
            alert: true,
            message: "Please Select Icons",
            type: "danger",
          });

          setTimeout(() => {
            this.setState({
              alert: false,
              message: "",
              type: "",
            });
          }, 3000);

          return false;
        }
        sendData.color = this.state.design1.color;
        sendData.iconbgcolor = this.state.design1.iconbgcolor;
        sendData.textcolor = this.state.design1.textcolor;

        design.backgroundImage = this.state.design1.backgroundImage;
        design.content = this.state.design1.user;
        design.icons = this.state.design1.icons;
        design.fontFamily = this.state.design1.fontFamily;
        design.fontValue = this.state.design1.fontValue;
        design.textPosition = this.state.textPosition;
      }

      if (this.state.design_num == 3) {
        if (this.state.design3.icons.length == 0) {
          this.setState({
            alert: true,
            message: "Please Select Icons",
            type: "danger",
          });

          setTimeout(() => {
            this.setState({
              alert: false,
              message: "",
              type: "",
            });
          }, 3000);

          return false;
        }

        if (this.state.design3.backgroundImageSelect) {
          let logoUpload3 = await this.imageUpload(
            this.state.design3.backgroundImageFile
          );
          if (logoUpload3 && logoUpload3.status) {
            design.backgroundImage = logoUpload3.url;
          }
        } else {
          design.backgroundImage = this.state.design3.backgroundImage;
        }

        // design.backgroundImage = this.state.design3.backgroundImage;
        design.content = this.state.design3.user;
        design.icons = this.state.design3.icons;
        design.iconbgcolor = this.state.design3.iconbgcolor;
        design.textcolor = this.state.design3.textcolor;
        design.fontFamily = this.state.design3.fontFamily;
        design.fontValue = this.state.design3.fontValue;
      }

      sendData.design = design;
      sendData.complete = true;
      sendData.step = 2;
      sendData.status = true;
      sendData.design_num = this.state.design_num;


      console.log(this.state.design1);
      console.log("sendData", sendData);

      // return false;
      let result = await HttpClient.requestData(
        "app-owner/application/update",
        "POST",
        sendData
      );
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          appBgImg: result.data.bgImage,
          appLogo: result.data.logo,
          title: result.data.title,
          alert: true,
          message: this.state.edit_enable
            ? "Application Updated Successfully"
            : "Application Added Successfully",
          type: "success",
          loading: false,
          selectAppBgImg: {},
          selectedAppLogo: {},
          appBgImgSelect: false,
          appLogoSelect: false,
        });
        this.checkUser();
        setTimeout(() => {
          // this.setState({ showDesigns: true });
          window.location.href = "/welcome-screen";
        }, 400);
      } else {
        this.setState({
          loading: false,
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }

    } else {
      window.location.href = "/welcome-screen";
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  changeHandler = (val) => {
    this.setState({
      hexColor: val,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              {/* base information */}
              <BaseInfo />
              <Col xl={12}>
                {/* <DMSteps /> */}
                {this.state.alert ? (
                  <UncontrolledAlert
                    color={this.state.type}
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.type == "warning" ? (
                      <i className="mdi mdi-alert-outline mr-2"></i>
                    ) : this.state.type == "success" ? (
                      <i className="mdi mdi-check-all mr-2"></i>
                    ) : this.state.type == "danger" ? (
                      <i className="mdi mdi-block-helper mr-2"></i>
                    ) : null}
                    {this.state.message}
                  </UncontrolledAlert>
                ) : null}
              </Col>
              <Col xl={8}>
                <div className="row">
                  <div className="col-md-6 ">
                    <h5 className="title-editor py-1 text-center">
                      Step 1 - Create home screen{" "}

                    </h5>
                  </div>
                  <div className="col-md-6 ">
                    <h5 className="title-editor py-1 text-center">
                      Step 2 - Add text to each panel{" "}
                    </h5>
                  </div>
                </div>
                <Row>
                  <Col className="col-md-6 col-lg-6 col-xl-6">
                    <div className="actionBox">
                      {/* design component goes here */}
                      {this.state.design_num == 1 ? (
                        <Design_1
                          backgroundImage={this.state.design1.backgroundImage}
                          item={this.state.design1}
                          refreshStatus={!this.state.refreshStatus}
                          textPosition={this.state.textPosition}
                          callBackIcons={this.callBackIcons}
                          callBackIconsNew={(val, index) => {
                            console.log('Design_1 val', val);
                            console.log('Design_1 val', index);

                            this.setState({

                              activePanelIcon: this.state.design1.user[index].icon,
                              activePanelIndex: index
                            });
                            setTimeout(() => {
                              this.setState({ showIconsNew: val, });
                            }, 200)
                          }}
                          fontFamily={this.state.design1.fontFamily}
                          fontValue={this.state.design1.fontValue}
                          textColor={
                            this.state.design1.textcolor
                              ? this.state.design1.textcolor
                              : "#fff"
                          }
                          uploadback={async (file, indexx) => {
                            let dataSend = {
                              buckate: true,
                              buckate_name: "appIcon",
                            };

                            let result = await HttpClient.newFileUpload(
                              "image-upload/appIcon",
                              file,
                              dataSend
                            );
                            if (result) {
                              this.state.design1.user[indexx].bgImage =
                                result.url;

                              this.state.design1.user[indexx].bgSelect = true;
                              this.setState({
                                refreshStatus: !this.state.refreshStatus,
                              });
                            }
                          }}
                        />
                      ) : this.state.design_num == 2 ? (
                        <Design_2
                          item={this.state.design2}
                          refreshStatus={!this.state.refreshStatus}
                          textColor={
                            this.state.textColor ? this.state.textColor : "#fff"
                          }
                          cropCallBack={async (ind, image, seletedFile) => {
                            let dataSend = {
                              buckate: true,
                              buckate_name: "appIcon",
                            };
                            let result = await HttpClient.newFileUpload(
                              "image-upload/appIcon",
                              seletedFile,
                              dataSend
                            );
                            if (result) {
                              this.state.design2.user[ind].avatar = result.url;
                              this.state.design2.user[ind].avatarSelect = true;
                              this.setState({
                                refreshStatus: !this.state.refreshStatus,
                              });
                            }
                            this.state.design2.user[ind].crop = true;

                            this.setState({});
                          }}
                        />
                      ) : this.state.design_num == 3 ? (
                        <Design_3
                          backgroundImage={this.state.design3.backgroundImage}
                          item={this.state.design3}
                          refreshStatus={!this.state.refreshStatus}
                          iconColor={this.state.hexColor}
                          changeBackground={(image) => {
                            this.state.design3.backgroundImage = URL.createObjectURL(
                              image
                            );
                            this.state.design3.backgroundImageFile = image;
                            this.state.design3.backgroundImageSelect = true;
                            this.setState({
                              refreshStatus: !this.state.refreshStatus,
                            });
                          }}
                        />
                      ) : this.state.design_num == 4 ? (
                        <Design_4
                          backgroundImage={this.state.design4.backgroundImage}
                          item={this.state.design4}
                          refreshStatus={!this.state.refreshStatus}
                          textColor={
                            this.state.textColor ? this.state.textColor : "#fff"
                          }
                          changeBackground={(image) => {
                            this.state.design4.backgroundImage = URL.createObjectURL(
                              image
                            );
                            this.state.design4.backgroundImageFile = image;
                            this.state.design4.backgroundImageSelect = true;
                            this.setState({
                              refreshStatus: !this.state.refreshStatus,
                            });
                          }}
                          cropCallBack={async (ind, image, seletedFile) => {
                            let dataSend = {
                              buckate: true,
                              buckate_name: "appIcon",
                            };
                            let result = await HttpClient.newFileUpload(
                              "image-upload/appIcon",
                              seletedFile,
                              dataSend
                            );
                            if (result) {
                              this.state.design4.user[0].avatar = result.url;
                              this.state.design4.user[0].avatarSelect = false;
                              this.setState({
                                refreshStatus: !this.state.refreshStatus,
                              });
                            }
                            this.state.design4.user[0].crop = true;

                            this.setState({});
                          }}
                        />
                      ) : (
                        <Design_3
                          backgroundImage={this.state.design3.backgroundImage}
                          item={this.state.design3}
                          refreshStatus={!this.state.refreshStatus}
                          changeBackground={(image) => {
                            this.state.design3.backgroundImage = URL.createObjectURL(
                              image
                            );
                            this.state.design3.backgroundImageFile = image;
                            this.state.design3.backgroundImageSelect = true;
                            this.setState({
                              refreshStatus: !this.state.refreshStatus,
                            });
                          }}
                        />
                      )}
                      {/* <Design_1 />
                        <Design_2 />
                        <Design_3 />
                        <Design_4 /> */}
                    </div>
                  </Col>
                  <Col className="col-md-6 col-lg-6 col-xl-6">
                    <div className="actionBox bg-soft-secondary overflow-auto">
                      <div className="relative" style={{ zIndex: 1 }}>
                        <button
                          type="button"
                          className="btn-primary btn-full-width py-2 mb-2 "
                          onClick={() => this.callBackDesign(true)}
                        >
                          Change Design Template
                        </button>

                        {this.state.design_num != 3 ? (
                          <Card className="mb-0 ">
                            <p
                              className="text-dark mb-0"
                              onClick={this.t_col3}
                              style={{ cursor: "pointer" }}
                            >
                              <CardHeader id="gen-question-headingTwo">
                                <h5 className="font-size-14 m-0">
                                  <i
                                    className={
                                      this.state.col3
                                        ? "mdi mdi-chevron-up accor-arrow-icon"
                                        : "mdi mdi-chevron-right accor-arrow-icon"
                                    }
                                  ></i>{" "}
                                  Text Styling
                                </h5>
                              </CardHeader>
                            </p>
                            <Collapse isOpen={this.state.col3}>
                              <CardBody className="p-2">
                                <div className="row no-gutters">
                                  <div className="col-md-12 mb-2">
                                    <h6 className="m-0 font-size-15 text-dark">
                                      Text Position
                                    </h6>
                                  </div>
                                  <div className="col-md-6 mb-2">
                                    <label
                                      className="mb-0 text-muted"
                                      htmlFor={"left" /*+ index*/}
                                    >
                                      Left
                                    </label>
                                    <input
                                      className="w-100"
                                      type="text"
                                      placeholder="i.e. 25px or 25%"
                                      id={"left" /*+ index*/}
                                      name="left"
                                      value={this.state.textPosition.left}
                                      onChange={this.handlePosition}
                                    />
                                  </div>
                                  <div className="col-md-6 mb-2">
                                    <label
                                      className="mb-0 text-muted"
                                      htmlFor={"right" /*+ index*/}
                                    >
                                      Right
                                    </label>
                                    <input
                                      className="w-100"
                                      type="text"
                                      placeholder="i.e. 10px or 10%"
                                      id={"right" /*+ index*/}
                                      name="right"
                                      value={this.state.textPosition.right}
                                      onChange={this.handlePosition}
                                    />
                                  </div>
                                  <div className="col-md-6 mb-2">
                                    <label
                                      className="mb-0 text-muted"
                                      htmlFor={"top" /*+ index*/}
                                    >
                                      Top
                                    </label>
                                    <input
                                      className="w-100"
                                      type="text"
                                      placeholder="i.e. 50px or 50%"
                                      id={"top" /*+ index*/}
                                      name="top"
                                      value={this.state.textPosition.top}
                                      onChange={this.handlePosition}
                                    />
                                  </div>
                                  <div className="col-md-6 mb-2">
                                    <label
                                      className="mb-0 text-muted"
                                      htmlFor={"bottom" /*+ index*/}
                                    >
                                      Bottom
                                    </label>
                                    <input
                                      className="w-100"
                                      type="text"
                                      placeholder="i.e. 40px or 40%"
                                      id={"bottom" /*+ index*/}
                                      name="bottom"
                                      value={this.state.textPosition.bottom}
                                      onChange={this.handlePosition}
                                    />
                                  </div>
                                  <div className="col-md-6 my-2">
                                    <h6 className="mb-1 font-size-15 text-dark">
                                      Font Size
                                    </h6>
                                    <select
                                      className="w-100"
                                      name="f_size"
                                      onChange={(event) => {
                                        if (this.state.design_num == 1) {
                                          this.state.design1.fontValue =
                                            event.target.value;
                                          this.state.refreshStatus = !this.state
                                            .refreshStatus;
                                          this.setState({});
                                        } else if (this.state.design_num == 2) {
                                          this.state.design2.fontValue =
                                            event.target.value;
                                          this.state.refreshStatus = !this.state
                                            .refreshStatus;
                                          this.setState({});
                                        }
                                        if (this.state.design_num == 3) {
                                          this.state.design3.fontValue =
                                            event.target.value;
                                          this.state.refreshStatus = !this.state
                                            .refreshStatus;
                                          this.setState({});
                                        }
                                        if (this.state.design_num == 4) {
                                          this.state.design4.fontValue =
                                            event.target.value;
                                          this.state.refreshStatus = !this.state
                                            .refreshStatus;
                                          this.setState({});
                                        }
                                      }}
                                      value={
                                        this.state.design_num == 1
                                          ? this.state.design1.fontValue
                                          : this.state.design_num == 2
                                            ? this.state.design2.fontValue
                                            : this.state.design_num == 3
                                              ? this.state.design3.fontValue
                                              : this.state.design_num == 4
                                                ? this.state.design2.fontValue
                                                : ""
                                      }
                                    >
                                      <option value="14">14</option>
                                      <option value="15">15</option>
                                      <option value="16">16</option>
                                      <option value="17">17</option>
                                      <option value="18">18</option>
                                      <option value="20">20</option>
                                      <option value="22">22</option>
                                    </select>
                                  </div>
                                  <div className="col-md-6 my-2">
                                    <h6 className="mb-1 font-size-15 text-dark">
                                      Font Family
                                    </h6>
                                    <select
                                      className="w-100"
                                      name="f_family"
                                      // onChange={(event) =>
                                      //   this.setState({
                                      //     fontFamily: event.target.fontFamily,
                                      //   })
                                      // }
                                      // value={this.state.fontFamily}
                                      onChange={(event) => {
                                        if (this.state.design_num == 1) {
                                          this.state.design1.fontFamily =
                                            event.target.value;
                                          this.state.refreshStatus = !this.state
                                            .refreshStatus;
                                          this.setState({});
                                        } else if (this.state.design_num == 2) {
                                          this.state.design2.fontFamily =
                                            event.target.value;
                                          this.state.refreshStatus = !this.state
                                            .refreshStatus;
                                          this.setState({});
                                        }
                                        if (this.state.design_num == 3) {
                                          this.state.design3.fontFamily =
                                            event.target.value;
                                          this.state.refreshStatus = !this.state
                                            .refreshStatus;
                                          this.setState({});
                                        }
                                        if (this.state.design_num == 4) {
                                          this.state.design4.fontFamily =
                                            event.target.value;
                                          this.state.refreshStatus = !this.state
                                            .refreshStatus;
                                          this.setState({});
                                        }
                                      }}
                                      value={
                                        this.state.design_num == 1
                                          ? this.state.design1.fontFamily
                                          : this.state.design_num == 2
                                            ? this.state.design2.fontFamily
                                            : this.state.design_num == 3
                                              ? this.state.design3.fontFamily
                                              : this.state.design_num == 4
                                                ? this.state.design2.fontFamily
                                                : ""
                                      }
                                    >
                                      <option value="sans-serif">
                                        sans-serif
                                      </option>
                                      <option value="ui-serif">ui-serif</option>
                                      <option value="system-ui">
                                        system-ui
                                      </option>
                                      <option value="monospace">
                                        monospace
                                      </option>
                                      <option value="serif">serif</option>
                                    </select>
                                  </div>
                                </div>
                                {this.state.design_num == 1 ? (
                                  <div className="py-2">
                                    <label for="color-picker">
                                      <h6 className="font-size-15 mb-0 mr-3 text-dark">
                                        Pick colors to change text color
                                      </h6>
                                    </label>
                                    <input
                                      name="color-picker"
                                      aria-label="icon-color-picker"
                                      type="color"
                                      value={this.state.design1.textcolor}
                                      onChange={(e) => {
                                        this.state.design1.textcolor =
                                          e.target.value;
                                        this.setState({});
                                      }}
                                    />
                                  </div>
                                ) : this.state.design_num == 2 ? (
                                  <>
                                    <div className="py-2">
                                      <label for="color-picker">
                                        <p className="mb-0 mr-3 text-dark">
                                          Change title text color
                                        </p>
                                      </label>
                                      <input
                                        name="color-picker"
                                        aria-label="icon-color-picker"
                                        type="color"
                                        value={this.state.design2.titlecolor}
                                        onChange={(e) => {
                                          this.state.design2.titlecolor =
                                            e.target.value;
                                          this.setState({});
                                        }}
                                      />
                                    </div>
                                    <div className="py-2">
                                      <label for="color-picker">
                                        <p className="mb-0 mr-3 text-dark">
                                          Change name and postion text color
                                        </p>
                                      </label>
                                      <input
                                        name="color-picker"
                                        aria-label="icon-color-picker"
                                        type="color"
                                        value={this.state.design2.namecolor}
                                        onChange={(e) => {
                                          this.state.design2.namecolor =
                                            e.target.value;
                                          this.setState({});
                                        }}
                                      />
                                    </div>
                                    <div className="py-2">
                                      <label for="color-picker">
                                        <p className="mb-0 mr-3 text-dark">
                                          Change title background color
                                        </p>
                                      </label>
                                      <input
                                        name="color-picker"
                                        aria-label="icon-color-picker"
                                        type="color"
                                        value={this.state.design2.titlebg}
                                        onChange={(e) => {
                                          this.state.design2.titlebg =
                                            e.target.value;
                                          this.setState({});
                                        }}
                                      />
                                    </div>
                                    <div className="py-2">
                                      <label for="color-picker">
                                        <p className="mb-0 mr-3 text-dark">
                                          Change name and position background
                                          color
                                        </p>
                                      </label>
                                      <input
                                        name="color-picker"
                                        aria-label="icon-color-picker"
                                        type="color"
                                        value={this.state.design2.namebg}
                                        onChange={(e) => {
                                          this.state.design2.namebg =
                                            e.target.value;
                                          this.setState({});
                                        }}
                                      />
                                    </div>
                                  </>
                                ) : this.state.design_num == 4 ? (
                                  <>
                                    <div className="py-2">
                                      <label for="color-picker">
                                        <p className="mb-0 mr-3 text-dark">
                                          Change title text color
                                        </p>
                                      </label>
                                      <input
                                        name="color-picker"
                                        aria-label="icon-color-picker"
                                        type="color"
                                        value={this.state.design4.titlecolor}
                                        onChange={(e) => {
                                          this.state.design4.titlecolor =
                                            e.target.value;
                                          this.setState({});
                                        }}
                                      />
                                    </div>
                                    <div className="py-2">
                                      <label for="color-picker">
                                        <p className="mb-0 mr-3 text-dark">
                                          Change name and postion text color
                                        </p>
                                      </label>
                                      <input
                                        name="color-picker"
                                        aria-label="icon-color-picker"
                                        type="color"
                                        value={this.state.design4.namecolor}
                                        onChange={(e) => {
                                          this.state.design4.namecolor =
                                            e.target.value;
                                          this.setState({});
                                        }}
                                      />
                                    </div>
                                    <div className="py-2">
                                      <label for="color-picker">
                                        <p className="mb-0 mr-3 text-dark">
                                          Change title background color
                                        </p>
                                      </label>
                                      <input
                                        name="color-picker"
                                        aria-label="icon-color-picker"
                                        type="color"
                                        value={this.state.design4.titlebg}
                                        onChange={(e) => {
                                          this.state.design4.titlebg =
                                            e.target.value;
                                          this.setState({});
                                        }}
                                      />
                                    </div>
                                    <div className="py-2">
                                      <label for="color-picker">
                                        <p className="mb-0 mr-3 text-dark">
                                          Change name and position background
                                          color
                                        </p>
                                      </label>
                                      <input
                                        name="color-picker"
                                        aria-label="icon-color-picker"
                                        type="color"
                                        value={this.state.design4.namebg}
                                        onChange={(e) => {
                                          this.state.design4.namebg =
                                            e.target.value;
                                          this.setState({});
                                        }}
                                      />
                                    </div>
                                  </>
                                ) : null}
                              </CardBody>
                            </Collapse>
                          </Card>
                        ) : null}

                        <Card className="mb-0 ">
                          <p
                            className="text-dark mb-0"
                            onClick={this.t_col1}
                            style={{ cursor: "pointer" }}
                          >
                            <CardHeader id="gen-question-headingOne">
                              <h5 className="font-size-14 m-0">
                                <i
                                  className={
                                    this.state.col1
                                      ? "mdi mdi-chevron-up accor-arrow-icon"
                                      : "mdi mdi-chevron-right accor-arrow-icon"
                                  }
                                ></i>{" "}
                                Design Area
                              </h5>
                            </CardHeader>
                          </p>

                          {this.state.design_num == 4 ? (
                            <Collapse isOpen={this.state.col1}>
                              <CardBody className="p-2">
                                <div className="row no-gutters mb-1">
                                  <div className="col-md-12 mb-2">
                                    <button
                                      type="button"
                                      className="btn-success btn-full-width py-2 customUploadBtn"
                                    >
                                      Upload Background Image
                                      <input
                                        type="file"
                                        className="upload_input"
                                        onChange={(e) => {
                                          this.state.design4.backgroundImage = URL.createObjectURL(
                                            e.target.files[0]
                                          );
                                          this.state.design4.backgroundImageFile =
                                            e.target.files[0];
                                          this.state.design4.backgroundImageSelect = true;
                                          this.setState({
                                            refreshStatus: !this.state
                                              .refreshStatus,
                                          });
                                        }}
                                      />
                                    </button>
                                  </div>
                                  <div className="col-md-6">
                                    <button
                                      type="button"
                                      className="btn-warning btn-full-width py-2 customUploadBtn"
                                    // onClick={() => this.callBackImage(true)}
                                    >
                                      <input
                                        type="file"
                                        className="upload_input"
                                        onChange={(e) => {
                                          this.state.design4.user[0].avatar = URL.createObjectURL(
                                            e.target.files[0]
                                          );
                                          this.state.design4.user[0].avatarFile =
                                            e.target.files[0];
                                          this.state.design4.user[0].avatarSelect = true;
                                          this.setState({
                                            refreshStatus: !this.state
                                              .refreshStatus,
                                          });
                                          console.log(
                                            "this.state.design4",
                                            this.state.design4
                                          );
                                        }}
                                      />
                                      Add Image
                                    </button>
                                  </div>
                                  <div className="col-md-6">
                                    <button
                                      type="button"
                                      className="btn-info btn-full-width py-2"
                                      onClick={() => {
                                        this.setState({
                                          ckediter: false
                                        });
                                        setTimeout(() => {
                                        this.callBackText(true);
                                      }, 200);
                                      }}
                                    >
                                      Add Texts
                                    </button>
                                  </div>
                                  <div className="col-md-12">
                                            <button
                                              type="button"
                                              className="border btn-full-width btn-secondary py-1 rounded"
                                              onClick={() => {
                                                this.setState({
                                                  ckediter: true
                                                });
                                                setTimeout(() => {
                                                  this.setState({
                                                    activeIndex: 0,
                                                    activeText: this.state.design2
                                                      .user[0].text,
                                                    activeTextMain: this.state.design2
                                                      .user[0].text,
                                                  });
                                                  this.callBackText(true);
                                                }, 200);

                                               
                                              }}
                                            >
                                              Click to add text
                                        </button>
                                          </div>
                                </div>
                              </CardBody>
                            </Collapse>
                          ) : this.state.design_num == 2 ? (
                            <Collapse isOpen={this.state.col1}>
                              <CardBody className="p-2">
                                <div className="row no-gutters mb-1">
                                  <div className="col-md-12 mb-2">
                                    <button
                                      type="button"
                                      className="btn-success btn-full-width py-2 customUploadBtn"
                                    >
                                      Upload Background Image
                                      <input
                                        type="file"
                                        className="upload_input"
                                        onChange={async (e) => {
                                          let dataSend = {
                                            buckate: true,
                                            buckate_name: "appIcon",
                                          };

                                          let result = await HttpClient.newFileUpload(
                                            "image-upload/appIcon",
                                            e.target.files[0],
                                            dataSend
                                          );
                                          if (result) {
                                            this.state.design2.backgroundImage =
                                              result.url;

                                            this.state.design2.backgroundImageSelect = true;
                                            this.setState({
                                              refreshStatus: !this.state
                                                .refreshStatus,
                                            });
                                          }
                                        }}
                                      />
                                    </button>
                                  </div>
                                  {this.state.design2.user.map(
                                    (item, index) => {
                                      return (
                                        <>
                                          <div className="col-md-12">
                                            Icon Area {1 + index}
                                          </div>
                                          <div className="col-md-4">
                                            <button
                                              type="button"
                                              className="btn-warning btn-full-width py-2 customUploadBtn"
                                            // onClick={() => this.callBackImage(true)}
                                            >
                                              <input
                                                type="file"
                                                className="upload_input"
                                                onChange={async (e) => {
                                                  let lenght = this.state
                                                    .design2.user.length;

                                                  let dataSend = {
                                                    buckate: true,
                                                    buckate_name: "appIcon",
                                                  };

                                                  let result = await HttpClient.newFileUpload(
                                                    "image-upload/appIcon",
                                                    e.target.files[0],
                                                    dataSend
                                                  );
                                                  if (result) {
                                                    this.state.design2.user[
                                                      index
                                                    ].avatar = result.url;

                                                    this.state.design2.user[
                                                      index
                                                    ].avatarSelect = true;
                                                    this.setState({
                                                      refreshStatus: !this.state
                                                        .refreshStatus,
                                                    });
                                                  }
                                                }}
                                              />
                                              Add Image
                                            </button>
                                          </div>
                                          <div className="col-md-4">
                                            <button
                                              type="button"
                                              className="btn-warning btn-full-width py-2 customUploadBtn"
                                            >
                                              <input
                                                type="file"
                                                className="upload_input"
                                                onChange={async (e) => {
                                                  let lenght = this.state
                                                    .design2.user.length;

                                                  let dataSend = {
                                                    buckate: true,
                                                    buckate_name: "appIcon",
                                                  };

                                                  let result = await HttpClient.newFileUpload(
                                                    "image-upload/appIcon",
                                                    e.target.files[0],
                                                    dataSend
                                                  );
                                                  if (result) {
                                                    this.state.design2.user[
                                                      index
                                                    ].bgImage = result.url;

                                                    this.state.design2.user[
                                                      index
                                                    ].bgSelect = true;
                                                    this.setState({
                                                      refreshStatus: !this.state
                                                        .refreshStatus,
                                                    });
                                                  }
                                                }}
                                              />
                                              Add BG
                                            </button>
                                          </div>
                                          <div className="col-md-4">
                                            <button
                                              type="button"
                                              className="btn-info btn-full-width py-2"
                                              onClick={() => {
                                                this.setState({
                                                  ckediter: false
                                                });
                                                setTimeout(() => {
                                                this.callBackText(true);
                                                this.callTesxt(index);
                                                this.setState({
                                                  activeIndex: index,
                                                });
                                              }, 200);
                                              }}
                                            >
                                              Add Texts
                                            </button>
                                          </div>
                                          <div className="col-md-12">
                                            <button
                                              type="button"
                                              className="border btn-full-width btn-secondary py-1 rounded"
                                              onClick={() => {
                                                this.setState({
                                                  ckediter: true
                                                });
                                                setTimeout(() => {
                                                  this.setState({
                                                    activeIndex: index,
                                                    activeText: this.state.design2
                                                      .user[index].text,
                                                    activeTextMain: this.state.design2
                                                      .user[index].text,
                                                  });
                                                  this.callBackText(true);
                                                }, 200);

                                               
                                              }}
                                            >
                                              Click to add text
                                        </button>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                  <div
                                    className="col-md-12 mb-2"
                                    style={{ marginTop: 5 }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-success btn-full-width py-2 customUploadBtn"
                                      onClick={() => {
                                        let lenght = this.state.design2.user
                                          .length;
                                        if (
                                          this.state.design2.user[lenght - 1]
                                            .bgImage != ""
                                        ) {
                                          this.state.design2.user.push({
                                            avatar:
                                              "https://i.pinimg.com/originals/7c/c7/a6/7cc7a630624d20f7797cb4c8e93c09c1.png",
                                            bgImage:
                                              "https://png.pngtree.com/thumb_back/fw800/back_our/20190619/ourmid/pngtree-blue-stage-lighting-material-background-image_137923.jpg",
                                            title: "Enter Title",
                                            name: "User Name",
                                            position: "Position",
                                            avatarSelect: false,
                                            avatarFile: {},
                                            bgSelect: false,
                                            bgFile: {},
                                            text:''
                                          });
                                          this.setState({
                                            refreshStatus: !this.state
                                              .refreshStatus,
                                          });
                                        } else {
                                          alert("Please Fillup Details");
                                        }
                                      }}
                                    >
                                      Add More +
                                    </button>
                                  </div>
                                </div>
                              </CardBody>
                            </Collapse>
                          ) : this.state.design_num == 1 ? (
                            <Collapse isOpen={this.state.col1}>
                              <CardBody className="p-2">
                                {this.state.design1.user.map((item, index) => {
                                  return (
                                    <div className="row no-gutters mb-1">
                                      <div className="col-md-12">
                                        Panel {1 + index}
                                      </div>

                                      <div className="col-md-12">
                                        <button
                                          type="button"
                                          className="border btn-full-width btn-secondary py-1 rounded"
                                          onClick={() => {
                                            this.setState({
                                              activeIndex: index,
                                              activeText: this.state.design1
                                                .user[index].title,
                                              activeTextMain: this.state.design1
                                                .user[index].text,
                                            });
                                            this.callBackText(true);
                                          }}
                                        >
                                          Click to add text
                                        </button>
                                      </div>
                                    </div>
                                  );
                                })}
                              </CardBody>
                            </Collapse>
                          ) : this.state.design_num == 3 ? (
                            <Collapse isOpen={this.state.col1}>
                              <CardBody className="p-2">
                                <div className="row no-gutters mb-1">
                                  <div className="col-md-12 mb-2">
                                    <button
                                      type="button"
                                      className="btn-success btn-full-width py-2 customUploadBtn"
                                    >
                                      Upload Background Image
                                      <input
                                        type="file"
                                        className="upload_input"
                                        onChange={(e) => {
                                          this.state.design3.backgroundImage = URL.createObjectURL(
                                            e.target.files[0]
                                          );
                                          this.state.design3.backgroundImageFile =
                                            e.target.files[0];
                                          this.state.design3.backgroundImageSelect = true;
                                          this.setState({
                                            refreshStatus: !this.state
                                              .refreshStatus,
                                          });
                                        }}
                                      />
                                    </button>
                                  </div>

                                </div>
                              </CardBody>
                            </Collapse>
                          ) : null}
                        </Card>
                        <Card className="mb-0 ">
                          <p
                            className="text-dark mb-0"
                            onClick={this.t_col2}
                            style={{ cursor: "pointer" }}
                          >
                            <CardHeader id="gen-question-headingTwo">
                              <h5 className="font-size-14 m-0">
                                <i
                                  className={
                                    this.state.col2
                                      ? "mdi mdi-chevron-up accor-arrow-icon"
                                      : "mdi mdi-chevron-right accor-arrow-icon"
                                  }
                                ></i>{" "}
                                Icon Area
                              </h5>
                            </CardHeader>
                          </p>
                          <Collapse isOpen={this.state.col2}>
                            <CardBody className="p-2">
                              <button
                                type="button"
                                className="btn-primary btn-full-width py-2"
                                onClick={() => this.callBackIcons(true)}
                              >
                                Add Your Icons
                              </button>
                              <div className="py-2">
                                <label for="color-picker">
                                  <p className="mb-0 mr-3 text-dark">
                                    Pick colors to change icon background color
                                  </p>
                                </label>
                                <input
                                  name="color-picker"
                                  aria-label="icon-color-picker"
                                  type="color"
                                  value={
                                    this.state.design_num == 1
                                      ? this.state.design1.iconbgcolor
                                      : this.state.design_num == 2
                                        ? this.state.design2.iconbgcolor
                                        : this.state.design_num == 3
                                          ? this.state.design3.iconbgcolor
                                          : this.state.design_num == 4
                                            ? this.state.design2.iconbgcolor
                                            : ""
                                  }
                                  onChange={(e) => {
                                    if (this.state.design_num == 1) {
                                      this.state.design1.iconbgcolor =
                                        e.target.value;
                                      this.setState({
                                        textColor: e.target.value,
                                      });
                                    } else if (this.state.design_num == 2) {
                                      this.state.design2.iconbgcolor =
                                        e.target.value;
                                      this.setState({
                                        textColor: e.target.value,
                                      });
                                    }
                                    if (this.state.design_num == 3) {
                                      this.state.design3.iconbgcolor =
                                        e.target.value;
                                      this.setState({
                                        textColor: e.target.value,
                                      });
                                    }
                                    if (this.state.design_num == 4) {
                                      this.state.design4.iconbgcolor =
                                        e.target.value;
                                      this.setState({
                                        textColor: e.target.value,
                                      });
                                    }
                                  }}
                                />
                              </div>

                              <div className="py-2">
                                <label for="color-picker">
                                  <p className="mb-0 mr-3 text-dark">
                                    Pick colors to change icon label color
                                  </p>
                                </label>
                                <input
                                  name="color-picker"
                                  aria-label="icon-color-picker"
                                  type="color"
                                  value={
                                    this.state.design_num == 1
                                      ? this.state.design1.color
                                      : this.state.design_num == 2
                                        ? this.state.design2.color
                                        : this.state.design_num == 3
                                          ? this.state.design3.color
                                          : this.state.design_num == 4
                                            ? this.state.design2.color
                                            : ""
                                  }
                                  onChange={(e) => {
                                    if (this.state.design_num == 1) {
                                      this.state.design1.color = e.target.value;
                                      this.setState({
                                        textColor: e.target.value,
                                      });
                                    } else if (this.state.design_num == 2) {
                                      this.state.design2.color = e.target.value;
                                      this.setState({
                                        textColor: e.target.value,
                                      });
                                    }
                                    if (this.state.design_num == 3) {
                                      this.state.design3.color = e.target.value;
                                      this.setState({
                                        textColor: e.target.value,
                                      });
                                    }
                                    if (this.state.design_num == 4) {
                                      this.state.design4.color = e.target.value;
                                      this.setState({
                                        textColor: e.target.value,
                                      });
                                    }
                                  }}
                                />
                              </div>

                              <FormGroup className="my-3">
                                {/* <h6>Icon And Text Color</h6> */}

                                <MyColorPicker
                                  hexColor={this.state.hexColor}
                                  changeHandler={this.changeHandler}
                                />
                                {console.log(
                                  "cheery's color code",
                                  this.state.hexColor
                                )}

                                {this.state.simple_color1 ? (
                                  <ColorPicker
                                    saturationHeight={100}
                                    saturationWidth={100}
                                    value={
                                      this.state.design_num == 4
                                        ? this.state.design4.color
                                        : this.state.design_num == 3
                                          ? this.state.design3.color
                                          : this.state.design_num == 2
                                            ? this.state.design2.color
                                            : this.state.design_num == 1
                                              ? this.state.design1.color
                                              : this.state.color
                                    }
                                    onDrag={this.onDragRgb.bind(this)}
                                  />
                                ) : null}
                              </FormGroup>
                            </CardBody>
                          </Collapse>
                        </Card>

                        {/* for positioning the texts */}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xl={4}>
                {/* app preview here */}
                <div>
                  <Preview
                    save={this.saveChange}
                    design={this.state.design_num}
                    refresh={this.state.refreshStatus}
                    bgImage={
                      this.state.design_num == 2
                        ? this.state.design2.backgroundImage
                        : this.state.design_num == 3
                          ? this.state.design3.backgroundImage
                          : this.state.design_num == 4
                            ? this.state.design4.backgroundImage
                            : this.state.design_num == 1
                              ? this.state.design1.backgroundImage
                              : this.state.design4.backgroundImage
                    }
                    data={
                      this.state.design_num == 2
                        ? this.state.design2
                        : this.state.design_num == 3
                          ? this.state.design3
                          : this.state.design_num == 4
                            ? this.state.design4
                            : this.state.design_num == 1
                              ? this.state.design1
                              : this.state.design4
                    }
                  />
                </div>
              </Col>
            </Row>
          </Container>
          {/* upload images modal */}
          {this.state.showImageBox ? (
            <AddImageBox
              showImageBox={true}
              callBackImage={this.callBackImage}
            />
          ) : null}
          {/* add texts modal */}
          {this.state.showTextBox ? (
            <AddTextBox
              showTextBox={true}
              callBackText={this.callBackText}
              design={this.state.design_num}
              submitChange={this.submitChange}
              callTesxt={this.callTesxt}
              activeText={this.state.activeText}
              design4Data={
                this.state.design_num == 2
                  ? this.state.design2
                  : this.state.design4
              }
              activeTextMain={this.state.activeTextMain}
              activeIndex={this.state.activeIndex}
              ckediter={this.state.ckediter}
            />
          ) : null}
          {/* add icons modal */}
          {this.state.showIconsBox ? (
            <AddIconsBox
              showIconsBox={true}
              callBackIcons={this.callBackIcons}
              design={this.state.design_num}
              callBackSeleted={(items) => {
                console.log("items", items);
                if (this.state.design_num == 2) {
                  this.state.design2.icons = items;
                }
                if (this.state.design_num == 3) {
                  this.state.design3.icons = items;
                }
                if (this.state.design_num == 4) {
                  this.state.design4.icons = items;
                }
                if (this.state.design_num == 1) {
                  this.state.design1.icons = items;
                }
                this.setState({});
              }}
              icons={this.state.icons}
              data={
                this.state.design_num == 2
                  ? this.state.design2
                  : this.state.design_num == 3
                    ? this.state.design3
                    : this.state.design_num == 4
                      ? this.state.design4
                      : this.state.design_num == 1
                        ? this.state.design1
                        : this.state.design4
              }
            />
          ) : null}


          {/* add Icon new Modal */}


          {this.state.showIconsNew ? (
            <AddIcons
              showIconsBox={true}
              callBackIcons={
                (status, index) => { this.callBackIconsNew(status, index) }
              }
              design={this.state.design_num}
              callBackSeleted={(items, index) => {
                console.log("items", items);
                console.log("index", index);
                if (this.state.design_num == 2) {
                  this.state.design2.icons = items;
                }
                if (this.state.design_num == 3) {
                  this.state.design3.icons = items;
                }
                if (this.state.design_num == 4) {
                  this.state.design4.icons = items;
                }
                if (this.state.design_num == 1) {
                  this.state.design1.user[this.state.activePanelIndex].icon = items;
                }
                setTimeout(() => {
                  console.log('bal chal', this.state.design1);
                  this.setState({ refreshStatus: !this.state.refreshStatus });
                }, 300)

              }}
              icons={this.state.icons}
              data={
                this.state.design_num == 2
                  ? this.state.design2
                  : this.state.design_num == 3
                    ? this.state.design3
                    : this.state.design_num == 4
                      ? this.state.design4
                      : this.state.design_num == 1
                        ? this.state.design1
                        : this.state.design4
              }
              activeIndex={this.state.activePanelIndex}
            />
          ) : null}

          {/* choose design box */}
          {this.state.chooseDesign ? (
            <AllDesignes
              chooseDesign={true}
              callBackDesign={this.callBackDesign}
              callBackSelectedDesign={(val) => {
                this.setState({ design_num: val });
              }}
              design={this.state.design_num}
            />
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}
