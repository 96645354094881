import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import { CSVLink } from "react-csv";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
import Select from "react-select";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import index from "../ChooseDesign";

class FormElements extends Component {
  fileObj = [];
  fileArray = [];
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Home Banner", link: "#" },
        { title: "Add & Manage Home Banner", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      //   this page states
      category: [],
      categoryAll: [],
      subCategory: [],
      subCategoryAll: [],

      cat_id: "",
      pruduct_type: "",
      sub_cat_id: "",

      // selectedFiles: [],
      images: "",
      uploaded_images: [],

      selling_price: "",
      mrp: "",
      purchase_price: "",
      discount: "",
      availableSize: [],
      availableColor: [],

      optionGroupSize: [{ value: "Red", code: "" }],
      selectedMultiSize: [],

      showSelectColor: false,
      showSelect: false,
      colorRgb: "#4667CC",
      simple_color1: false,
      color: [{ code: "#4667CC", active: false }],
      description: "",
      delivery_type: "",
      delivery_time: "",
      preview: [],
      multiImages: [],
      refresh: false,
      content1: "",
      content2: "",
      link: "",
    };
    this.handleMulti = this.handleMulti.bind(this);
    this.onDragRgb = this.onDragRgb.bind(this);
  }
  onDragRgb(index, c1) {
    // alert(c1);
    this.state.color[index].code = c1;
    this.setState({ colorRgb: c1 });
  }

  handleMulti = (selectedMultiSize) => {
    this.setState({ selectedMultiSize });
    setTimeout(() => {
      console.log("selectedMulti", selectedMultiSize);
    });
  };

  componentDidMount = async () => {
    this.checkUser();
  };

  fetchData = async () => {
    let result = await HttpClient.requestData("cms/Banner", "GET");
    console.log("cms/Banner", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          bannerName: element.name,
          desc: element.description ? element.description : "",
          link: element.link,
          image: (
            <img
              src={HttpClient.IMG_URL + element.image}
              width="100"
              height="100"
              alt=""
            />
          ),
          action: (
            <div className="d-flex">
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                  window.scrollTo(0,0);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </div>
          ),
          status: element.status ? (
            <Button
              className="btn-icon"
              color="success"
              onClick={(e) => this.status(index, element._id)}
            >
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-wink-outline mr-2"></i>
              </span>{" "}
              Active
            </Button>
          ) : (
            <Button
              className="btn-icon"
              color="danger"
              onClick={(e) => this.status(index, element._id)}
            >
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-cry-outline mr-2"></i>
              </span>{" "}
              Deactive
            </Button>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  edit = async (item, index) => {
    console.log("edit---", item);
    this.setState({
      edit_enable: true,
      name: item.name ? item.name : "",
      content2: item.description ? item.description : "",
      link: item.link ? item.link : "",
      //   uploaded_images: arr,
      //   image: item.image,
      edit_item_id: item._id,
      uploaded_images: item.image
        ? [
          {
            preview: HttpClient.IMG_URL + item.image,
          },
        ]
        : [],
    });
  };

  delete = async (item, index) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteapi(item),
        },
        {
          label: "No",
          // onClick: () => alert("Click No"),
        },
      ],
    });
  };

  deleteapi = async (item) => {
    let sendData = {};
    let result = await HttpClient.requestData(
      "cms/Banner/" + item._id,
      "DELETE",
      sendData
    );
    if (result && result.status) {
      this.fetchData();
    }
  };

  status = async (index, id) => {
    let sendData = {};
    let result = await HttpClient.requestData(
      "cms/Banner/setStatus/" + id,
      "PUT",
      sendData
    );
    if (result && result.status) {
      this.fetchData();
    }
  };

  submit = async () => {
    if (
      this.state.name != "" &&
      this.state.content2 != "" &&
      this.state.link != ""
    ) {
      if (this.state.uploaded_images.length > 0) {
        // alert("hji");
        this.setState({ loading: true });

        let result = null;

        let data = new FormData();
        if (this.state.edit_enable == false) {
          data.append("name", this.state.name);
          data.append("description", this.state.content2.replace(/<[^>]+>/g, ""));
          data.append("link", this.state.link);
          data.append("image", this.state.images);

          // data.append("id", "6177aaf9424c0e2f74c8e1cd");
          result = await HttpClient.fileUplode("cms/Banner", "POST", data);
          console.log("associates", result);
        } else {
          data.append("name", this.state.name);
          data.append("description", this.state.content2.replace(/<[^>]+>/g, ""));
          data.append("link", this.state.link);
          data.append("image", this.state.images);

          // data.append("id", "6177aaf9424c0e2f74c8e1cd");
          result = await HttpClient.fileUplode(
            "cms/Banner/" + this.state.edit_item_id,
            "PUT",
            data
          );
          console.log("associates Update", result);
        }

        if (result && result.status) {
          this.setState({
            alert: true,
            message: this.state.edit_enable
              ? "Banner Updated Successfully"
              : "Banner Inserted Successfully",
            edit_enable: false,
            // message: "Associates Inserted Successfully",
            type: "success",
            uploaded_images: [],
            images: "",
            name: "",
            content2: "",
            link: "",
          });

          this.fetchData();
        } else {
          this.setState({
            alert: true,
            message:
              typeof result.error == "string"
                ? result.error
                : result.error[0].msg,
            type: "danger",
          });
        }
      } else {
        this.setState({
          alert: true,
          message: "Please Select Atleast One Image.",
          type: "warning",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  handleAcceptedFiles = (files) => {
    // console.log("object", event.target.files[0]);
    // setprofile(event.target.files[0]);
    let file = {
      preview: URL.createObjectURL(files[0]),
      formattedSize: this.formatBytes(files[0].size),
    };
    console.log("image", files[0]);
    let arr = [];
    arr.push(file);
    this.setState({
      images: files[0],
      uploaded_images: arr,
    });
    console.log("uploaded_images", this.state.uploaded_images);
  };

  async remove_image(item, index) {
    this.setState({
      images: {},
    });
    this.state.uploaded_images.pop(index);
    this.setState({});
  }

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          width: 150,
        },
        {
          label: "Banner Name",
          field: "bannerName",
          width: 270,
        },
        {
          label: "Description",
          field: "desc",
          width: 270,
        },

        {
          label: "Image",
          field: "image",
          width: 270,
        },
        {
          label: "Shop Now Link",
          field: "link",
          width: 270,
        },
        {
          label: "Action",
          field: "action",
          width: 270,
        },
        {
          label: "Status",
          field: "status",

          width: 100,
        },
      ],
      rows: this.state.data,
    };

    const header = [

      { label: "Sl.", key: "sl" },
      { label: "Banner Name", key: "bannerName" },
      { label: "Description", key: "desc" },
      { label: "Shop Now Link", key: "link" },
    ]
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              {/* <BaseInfo /> */}
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Banner Name
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.name}
                          placeholder="Enter Banner Name"
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ name: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Description
                      </Label>
                      <Col md={10}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={this.state.content2}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            console.log({ event, editor, data });
                            this.setState({
                              content2: data,
                            });
                          }}
                          value={this.state.content2}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Image
                      </Label>
                      <Col md={10}>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            this.handleAcceptedFiles(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {this.state.uploaded_images.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        // alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Button
                                        className="btn-icon"
                                        color="danger"
                                        style={{ marginRight: 25 }}
                                        onClick={(e) => this.remove_image(f, i)}
                                      >
                                        {" "}
                                        <span className="btn-icon-label">
                                          <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                        </span>{" "}
                                        Remove
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Shop Now Link
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.link}
                          placeholder="Enter Link"
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ link: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          {/* Submit */}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                {/* <div>
                    <EcomPreview />
                  </div> */}
              </Col>
            </Row>
            <Row>
              <Col xl={3}>
                {this.state.data.length > 0 ?
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"Homebanner.csv"}
                    className="btn btn-success">
                    Export Report in CSV
                  </CSVLink> : null}
              </Col>
            </Row>&nbsp;
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
