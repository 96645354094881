import React, { Component, useEffect, useState } from "react";
import "./ind432a.css";

function Design1(props) {
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     styleVal: this.props.valBR ? this.props.valBR : "",
  //   };
  // }

  // render() {
  //   const { styleVal } = this.state;
  const [bgImage, setbgImage] = useState(props.bgImage);
  const [logo, setlogo] = useState(props.logo);
  const [title, settitle] = useState(props.title);
  const [state, setstate] = useState(props.state);
  const [styleVal, setstyleVal] = useState(props.valBR ? props.valBR : "");
  const [data, setdata] = useState(props.data);

  useEffect(() => {
    console.log("props.data", props.data);
  }, [props.data]);

  return (
    <div
      className="designed_area w-100 h-100 bg-light relative"
      style={{ borderRadius: styleVal }}
    >
      <div className="d-flex h-100 w-100">
        <div
          className="left_aside_icon"
          style={{
            borderTopLeftRadius: styleVal,
            borderBottomLeftRadius: styleVal,
            background: props.data.iconbgcolor,
          }}
        >
          <div className="d-flex flex-column" style={{}}>
            {props.data.icons.map((item, index) => {
              return (
                <div className="d-flex flex-column mb-3 bg-light rounded">
                  <span className="m-auto text-center">
                    {/* <i className="ri-twitter-line font-size-24"></i>
                     */}
                    <img src={item.iconUrl} style={{ height: 40, width: 40 }} />
                  </span>
                  <span
                    className="m-auto text-center font-weight-bold"
                    style={{ color: props.data.color }}
                  >
                    {item.iconName}
                  </span>
                </div>
              );
            })}
 
          </div>
        </div>
        <div
          className="right_aside_panel"
          style={{
            borderTopRightRadius: styleVal,
            borderBottomRightRadius: styleVal,
            overflowY: "auto",
          }}
        >
          {props.data.user.map((item, index) => {
            return (
              <div
                className="panel_item d-flex w-100 py-5"
                style={{
                  backgroundImage: `url(${item.bgImage})`,
                }}
              >
                <div className="text_block py-2 px-0">
                  <h5
                    // className="text-white"
                    style={{
                      // left: props.data.textPosition.left,
                      // right: props.data.textPosition.right,
                      // top: props.data.textPosition.top,
                      // bottom: props.data.textPosition.bottom,
                      color: props.data.textcolor
                        ? props.data.textcolor
                        : "#fff",
                      fontSize:
                        props.data.fontValue != ""
                          ? Number(props.data.fontValue)
                          : 14,
                      fontFamily: props.data.fontFamily
                        ? props.data.fontFamily
                        : "",
                    }}
                  >
                    {item.title}
                  </h5>
                </div>
                {typeof (item.icon) != 'undefined' && item.icon.length > 0 ?
                  <div className="d-flex mb-2 mt-auto">
                    {item.icon.map((itemm, index) => {
                      return (

                        <div className="mx-2 text-center">

                          <img
                            src={itemm.iconUrl}
                            style={{ height: 30, width: 30 }}
                          />
                          <span
                            className="m-auto text-center font-weight-bold"
                            style={{ color: '#000' }}
                          >
                            {itemm.iconName}
                          </span>
                        </div>


                      )
                    })}

                  </div> : null}
              </div>
            );
          })}
          {/* <div
            className="panel_item d-flex w-100 py-5"
            style={{
              backgroundImage: `url('https://cdn.searchenginejournal.com/wp-content/uploads/2019/08/c573bf41-6a7c-4927-845c-4ca0260aad6b-760x400.jpeg')`,
            }}
          ></div>
          <div
            className="panel_item d-flex w-100 py-5"
            style={{
              backgroundImage: `url('https://i2.wp.com/background4free.com/download/yellow_green_orange_1857864913.jpg?q=100')`,
            }}
          ></div>
          <div
            className="panel_item d-flex w-100 py-5"
            style={{
              backgroundImage: `url('https://www.wpexplorer.com/wp-content/uploads/wordpress-image-optimization-guide.jpg')`,
            }}
          ></div> */}
        </div>
      </div>
    </div>
  );
}
export default Design1;
