import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import { CSVLink } from "react-csv";
import Details from "./details";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "User List", link: "#" },
        { title: "View UserList", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},

      image_select: false,
      img_url: "",
      title: "",
      points: [""],
      id: "",
      duration: 0,
      seller_comission: "",
      type: "",
      desc: "",
      device: "All",
      devicefilter: ["App", "Web", "All"],
      datefrom:"",
      dateto:"",
      searchEnable:false

    };
    this.handleDefault = this.handleDefault.bind(this);
  }

  componentDidMount = async () => {

    this.fetchData(this.state.device);
  };

  handleDefault(date) {
    this.setState({ default_date: date });
  }
  status = async (item, id) => {
    let sendData = {};
    // alert(id);
    let result = await HttpClient.requestData(
      "user/set-status/" + id,
      "PUT",
      sendData
    );
    console.log("result", result);
    if (result && result.status) {
      this.fetchData();
    }
  };
  fetchData = async (val) => {
    console.log("val", val);
    let data = {

      device_type: this.state.device == "All" ? "" : val

    }
    console.log("data", data);
    let result 
    if (this.state.searchEnable) {
      let dataSend ={
        datefrom:this.state.datefrom,
        dateto:this.state.dateto
      }
      console.log(dataSend);
      // return false
       result = await HttpClient.requestData("userlist", "POST", dataSend);
       if(result && result.status){
        
       }

    }else{
       result = await HttpClient.requestData("userlist", "POST", data);

    }
    console.log("userlist", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      // console.log(this.state.pointarr);
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,

          username: element.firstName + " " + element.lastName,
          devtype: element.device_type,
          email: element.email,
          date: moment(element.start).format("MMMM Do YYYY"),
          productTotalpurchase: "₹" + element.productTotalpurchase,
          productrefundedAmtTotal: "₹" + element.productrefundedAmtTotal,
          serviceTotalpurchase: "₹" + element.serviceTotalpurchase,
          servicerefundedAmtTotal: "₹" + element.servicerefundedAmtTotal,
          currency: element.currency ? element.currency : "",
          type: element.type === "User" ? "User" : "Seller",
          status: element.status ? (
            <Button
              className="btn-icon"
              color="danger"
              onClick={(e) => this.status(index, element._id)}
            >
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-wink-outline mr-2"></i>
              </span>{" "}
              Block
            </Button>
          ) : (
            <Button
              className="btn-icon"
              color="success"
              onClick={(e) => this.status(index, element._id)}
            >
              {" "}
              <span className="btn-icon-label">
                <i className="mdi mdi-emoticon-cry-outline mr-2"></i>
              </span>{" "}
              UnBlock
            </Button>
          ),
          view: (
            <button
              title="View Details"
              className="btn btn-success mr-2"
              onClick={() => {
                this.setState({
                  activeid: element,
                });
                setTimeout(() => {
                  this.setState({
                    ismodalopen: true,
                    idneed: element._id
                  });
                }, 200);
              }}
            >
              View Details
            </button>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    console.log(sendData);
    let result = await HttpClient.requestData(
      "admin/ecom_banner/delete",
      "POST",
      sendData
    );
    console.log(result);
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Succfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      title: item.name,
      price: item.price,
      edit_item_id: item._id,
      seller_comission: item.seller_comission,
      duration: item.duration,
      id: item.id,
      type: item.type,
    });
  };

  submit = async () => {
    if (
      this.state.title != "" &&
      this.state.price != "" &&
      this.state.duration != "" &&
      this.state.seller_comission != "" &&
      this.state.type != ""
    ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          name: this.state.title,
          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData("subscription", "POST", data);
      } else {
        data = {
          name: this.state.title,

          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData(
          "subscription/" + this.state.edit_item_id,
          "PUT",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Subscription Updated Successfully"
            : "Subscription Added Succfully",

          type: "success",

          image_select: false,
          img_url: "",
          title: "",
          type: "",
          seller_comission: "",
          duration: "",
          price: "",
          desc: "",
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (e) => {
    let file = e.target.files[0].type;
    let fileType = file.split("/")[0];
    // fileType = fileType[0];
    console.log("e", file.split("/")[0]);

    if (fileType == "image" || fileType == "video") {
      // return false;
      this.setState({ fileType: fileType });
      let dataSend = { buckate: true, buckate_name: "appIcon" };

      let result = await HttpClient.newFileUpload(
        "image-upload/movie",
        e.target.files[0],
        dataSend
      );
      console.log("result", result);
      if (result && result.status) {
        this.setState({ img_url: result.url, image_select: true });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
  };



  onCloseCallBack = (val) => {
    console.log("object", val);
    this.setState({
      ismodalopen: val,
    });
  };


  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",

          width: 150,
        },
        // {
        //   label: "Image",
        //   field: "img",
        //
        //   width: 270,
        // },
        {
          label: "User Name",
          field: "username",

          width: 270,
        },
        {
          label: "Email",
          field: "email",

          width: 270,
        },
        {
          label: "Date",
          field: "date",

          width: 270,
        },
        {
          label: "Device Type",
          field: "devtype",

          width: 270,
        },
        {
          label: "User Type",
          field: "type",

          width: 270,
        },
        {
          label: "Currency",
          field: "currency",

          width: 270,
        },
        {
          label: "Product Total Purchase",
          field: "productTotalpurchase",

          width: 270,
        },
        // {
        //   label: "Product refund Amount",
        //   field: "productrefundedAmtTotal",

        //   width: 270,
        // },
        {
          label: "Service Total Purchase",
          field: "serviceTotalpurchase",

          width: 270,
        },
        {
          label: "Service refund Amount",
          field: "servicerefundedAmtTotal",

          width: 270,
        },



        {
          label: "Status",
          field: "status",

          width: 100,
        },

        {
          label: "View Details",
          field: "view",

          width: 170,
        },


      ],
      rows: this.state.data,
    };

    const header = [
      { label: "Sl.", key: "sl" },
      { label: "User Name", key: "username" },
      { label: "Email", key: "email" },
      { label: "Date", key: "date" },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {this.state.alert ? (
              <UncontrolledAlert
                color={this.state.type}
                className="alert-dismissible fade show"
                role="alert"
              >
                {this.state.type == "warning" ? (
                  <i className="mdi mdi-alert-outline mr-2"></i>
                ) : this.state.type == "success" ? (
                  <i className="mdi mdi-check-all mr-2"></i>
                ) : this.state.type == "danger" ? (
                  <i className="mdi mdi-block-helper mr-2"></i>
                ) : null}
                {this.state.message}
              </UncontrolledAlert>
            ) : null}
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        From Date
                      </Label>
                      <Col md={3}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.datefrom}
                          id="example-text-input"
                          placeholder="Enter From Date"
                          onChange={(val) => {
                            this.setState({ datefrom: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        To Date
                      </Label>
                      <Col md={3}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.dateto}
                          id="example-text-input"
                          placeholder="Enter To Date"
                          onChange={(val) => {
                            this.setState({
                              dateto: val.target.value,
                              searchEnable: true,
                            });
                          }}
                        />
                      </Col>
                      <Col md={2}>
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.fetchData();
                          }}
                          disabled={!this.state.searchEnable}
                        >
                          Search
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </Col>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={3}>
                {this.state.data.length > 0 ?
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"UserSearchList.csv"}
                    className="btn btn-success">
                    Export Report in CSV
                  </CSVLink> : null}
              </Col>
            </Row>&nbsp;
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                    scrollX
                    disableRetreatAfterSorting={true}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
