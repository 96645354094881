import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  // Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "../../utils/HttpClient";
import { CSVLink } from "react-csv";
import index from "../ChooseDesign";
class FormElements extends Component {
  fileObj = [];
  fileArray = [];

  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Ticket Raise History", link: "#" },
        { title: "View Ticket Raise History", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      image_select: false,
      img_url: "",
      title: "",
      points: [""],
      id: "",
      duration: 0,
      seller_comission: "",
      type: "",
      desc: "",
      modal: false,
      arr: [],
      order: "",
      result1: [],
      cart: [],
      filter: "",
      ismodalopen: false,
      ismodalopen1: false,
      activeid: {},
    };
  }

  componentDidMount = async () => {
    this.fetchData();
  };

  fetchData = async () => {
    let result = await HttpClient.requestData("tickets", "GET");
    console.log("tickets ", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.setState({ result1: result.user_data });
      this.state.result.forEach((element, index) => {
        let fName = element.seller_data[0]
          ? element.seller_data[0].firstName
          : "";
        let lName = element.seller_data[0]
          ? element.seller_data[0].lastName
          : "";
        let rows = {
          sl: i,
          date: moment(element.on_date).format("MMMM Do YYYY"),
          name: fName + " " + lName,
          service_name: element.name,
          details: element.issue_for ? element.issue_for : "",
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          width: 100,
        },
        {
          label: "User/Client Name",
          field: "name",
          width: 150,
        },
        {
          label: "Service Name",
          field: "service_name",
          width: 150,
        },
        {
          label: "Issue",
          field: "details",
          width: 250,
        },
        {
          label: "Date",
          field: "date",
          width: 100,
        },
      ],
      rows: this.state.data,
    };

    const header = [
      { label: "Sl.", key: "sl" },
      { label: "User/Client Name", key: "user" },
      { label: "Service Name", key: "service_name" },
      { label: "Issue", key: "details" },
      { label: "Date", key: "date" },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {/* <Row>
              <Col xs={12}>
                <Card>
                  <CardBody></CardBody>
                </Card>
              </Col>
            </Row> */}

            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <Row>
                    <Col xl={3}>
                      {this.state.data.length > 0 ? (
                        <CSVLink
                          data={this.state.data}
                          headers={header}
                          filename={"TicketRaiseHistory.csv"}
                          className="btn btn-success"
                        >
                          Export Report in CSV
                        </CSVLink>
                      ) : null}
                    </Col>
                  </Row>
                  &nbsp;
                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  <Row>
                    <Col xl={12}>
                      <div className="card p-3">
                        <MDBDataTableV5
                          hover
                          scrollX
                          entriesOptions={[5, 20, 25]}
                          entries={5}
                          pagesAmount={4}
                          data={data}
                          disableRetreatAfterSorting={true}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
