import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import BookingDetails from "./bookingdetails";
import ReactLoading from "react-loading";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  // Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
// import "./loader.css";
import "../../App.css"
import { CSVLink } from "react-csv";
import HttpClient from "./../../utils/HttpClient";
import index from "../ChooseDesign";
class FormElements extends Component {
  fileObj = [];
  fileArray = [];

  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Product Order History", link: "#" },
        { title: "View Product Order History", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      image_select: false,
      img_url: "",
      title: "",
      points: [""],
      id: "",
      duration: 0,
      seller_comission: "",
      type: "",
      desc: "",
      modal: false,
      arr: [],
      order: "",
      result1: [],
      cart: [],
      filter: "",
      ismodalopen: false,
      loading: false,
      activeid: {},
    };
    this.toggle = this.toggle.bind(this);
    this.handleDefault = this.handleDefault.bind(this);
  }

  onCloseCallBack = (val) => {
    console.log("object", val);
    this.setState({
      ismodalopen: val,
    });
  };
  handleChange = async (e) => {
    console.log(this.state.data);
    console.log(this.state.filter);
    let cart = this.state.data.filter(
      (item) => item.cart_data == this.state.filter
    );
    console.log(cart, "x");
    this.state.cart = cart;
    this.setState({});
  };
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  componentDidMount = async () => {
    this.checkUser();
    this.fetchData();
    console.log("pqr", this.state.result1);
  };

  handleDefault(date) {
    this.setState({ default_date: date });
  }

  fetchData = async () => {
    this.state.loading = true;
    this.setState({});
    let result = await HttpClient.requestData("orderHistory", "GET");
    console.log("abc", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.state.loading = false;
      this.setState({});
      this.setState({ result: result.data });

      this.setState({ result1: result.user_data });

      this.state.result.forEach((element, index) => {
        let fname = element.firstname;
        let lname = element.lastname;
        let rows = {
          sl: i,
          date: moment(element.booking_date).format("MMMM Do YYYY"),

          order_id: element.order_id,

          user: fname + " " + lname,
          email: element.email ? element.email : "",
          phone: element.phone ? element.phone : "",
          currency: element.currency ? element.currency : "",
          price: "₹" + element.total_inr.toFixed(2),
          delivery_amount: element.cart_data.length ? element.cart_data[0].product_data[0].delivery_amount : 0,
          coupon_discount: element.coupon ? element.coupon.discount_value : 0,
          statuss:
            element.order_status == "processing" ? (
              // <button title="Processing" className="btn btn-secondary">
              //   Processing
              // </button>
              <h6 style={{color: "#007BFF"}}>Processing</h6>
            ) : element.order_status == "shipped" ? (
              // <button title="shipped" className="btn btn-success mr-2">
              //   Shipped
              // </button>
              <h6 style={{color: "#28A745"}}>Shipped</h6>
            ) : element.order_status == "cancel" ? (
              // <button title="cancel" className="btn btn-danger mr-2">
              //   Cancelled
              // </button>
              <h6 style={{color: "#DC3545"}}>Cancelled</h6>
            ) : null,
          shipped: (
            <button
              title="Shipped"
              className="btn btn-primary"
              onClick={() => this.shipped(element)}
            >
              Shipped
            </button>
          ),
          cancel: (
            <button
              title="Cancel"
              className="btn btn-danger mr-2"
              onClick={() => this.cancel(element)}
            >
              Cancel
            </button>
          ),
          status: (
            <button
              title={element.status ? "Confirmed" : "Pending"}
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
            >
              {element.status ? "Confirmed" : "Pending"}
            </button>
          ),
          view: (
            <button
              title="View Details"
              className="btn btn-success mr-2"
              onClick={() => {
                this.setState({
                  activeid: element,
                });
                setTimeout(() => {
                  this.setState({
                    ismodalopen: true,
                  });
                }, 200);
              }}
            >
              View Details
            </button>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    console.log(sendData);
    let result = await HttpClient.requestData(
      "admin/ecom_banner/delete",
      "POST",
      sendData
    );
    console.log(result);
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Succfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      title: item.name,
      price: item.price,
      edit_item_id: item._id,
      seller_comission: item.seller_comission,
      duration: item.duration,
      id: item.id,
      type: item.type,
    });
  };
  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/ecom_banner/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Succfully",
        type: "success",
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };
  shipped = async (item, index) => {
    let sendData = {
      id: item._id,
      order_status: "shipped",
      email: item.email,
    };
    console.log("sendData", sendData);
    let result = await HttpClient.requestData(
      "setOrderStatus",
      "POST",
      sendData
    );
    console.log("status", result);
    if (result && result.status) {
      this.setState({
        alert: true,
        message: "Status Updated Successfully",
        type: "success",
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };
  cancel = async (item, index) => {
    let sendData = {
      id: item._id,
      order_status: "cancel",
      email: item.email,
    };
    let result = await HttpClient.requestData(
      "setOrderStatus",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.setState({
        alert: true,
        message: "Status Updated Successfully",
        type: "success",
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };

  submit = async () => {
    if (
      this.state.title != "" &&
      this.state.price != "" &&
      this.state.duration != "" &&
      this.state.seller_comission != "" &&
      this.state.type != ""
    ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          name: this.state.title,
          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData("subscription", "POST", data);
      } else {
        data = {
          name: this.state.title,
          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData(
          "subscription/" + this.state.edit_item_id,
          "PUT",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Subscription Updated Successfully"
            : "Subscription Added Succfully",

          type: "success",

          image_select: false,
          img_url: "",
          title: "",
          type: "",
          seller_comission: "",
          duration: "",
          price: "",
          desc: "",
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (e) => {
    let file = e.target.files[0].type;
    let fileType = file.split("/")[0];
    // fileType = fileType[0];
    console.log("e", file.split("/")[0]);

    if (fileType == "image" || fileType == "video") {
      // return false;
      this.setState({ fileType: fileType });
      let dataSend = { buckate: true, buckate_name: "appIcon" };

      let result = await HttpClient.newFileUpload(
        "image-upload/movie",
        e.target.files[0],
        dataSend
      );
      console.log("result", result);
      if (result && result.status) {
        this.setState({ img_url: result.url, image_select: true });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          width: 150,
        },
        {
          label: "Order ID",
          field: "order_id",
          width: 270,
        },
        {
          label: "User Name",
          field: "user",
          width: 270,
        },
        {
          label: "Email",
          field: "email",
          width: 270,
        },
        {
          label: "Phone",
          field: "phone",
          width: 270,
        },
        {
          label: "Order Date",
          field: "date",
          width: 270,
        },

        
        {
          label: "Product Currency",
          field: "currency",
          width: 270,
        },
        {
          label: "Item Total",
          field: "price",

          width: 270,
        },
        {
          label: "Delivery Amount",
          field: "delivery_amount",

          width: 270,
        },
        {
          label: "Coupon Discount",
          field: "coupon_discount",

          width: 270,
        },
        {
          label: "Shipment Status",
          field: "statuss",
          width: 270,
        },
        // {
        //   label: "Status",
        //   field: "status",
        //   width: 270,
        // },
        {
          label: "Shipped",
          field: "shipped",
          width: 270,
        },
        {
          label: "Cancel",
          field: "cancel",
          width: 270,
        },
        // {
        //     label: "Discount",
        //     field: "discount",

        //     width: 270,
        // },
        // {
        //     label: "Total Amount",
        //     field: "seller_comission",

        //     width: 270,
        // },
        // {
        //     label: "User Name",
        //     field: "username",

        //     width: 270,
        // },
        // {
        //   label: "Points",
        //   field: "points",

        //   width: 270,
        // },
        // {
        //     label: "Status",
        //     field: "status",

        //     width: 100,
        // },

       
        {
          label: "View Details",
          field: "view",
          width: 270,
        },
      ],
      rows: this.state.data,
    };
    const header = [
      { label: "Sl.", key: "sl" },
      { label: "User Name", key: "user" },
      { label: "Email", key: "email" },
      { label: "Phone", key: "phone" },
      { label: "Order Date", key: "date" },
      { label: "Order ID", key: "order_id" },
      { label: "Currency", key: "currency" },
      { label: "Item Total", key: "price" },
      { label: "Delivery Amount", key: "delivery_amount" },
      { label: "Coupon Discount", key: "coupon_discount" },
      // { label: "Shipment Status", key: "statuss" },
      // { label: "Shipped", key: "shipped" },
      // { label: "Cancel", key: "cancel" },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {/* <Row>
              <Col xs={12}>
                <Card>
                  <CardBody></CardBody>
                </Card>
              </Col>
            </Row> */}
            {this.state.loading ? (
              <ReactLoading
                type="spokes"
                color="black"
                height={"5%"}
                width={"5%"}
                className="loader"
              />
            ) : (
              <>
                <Row>
                  <Col xl={3}>
                    {this.state.data.length > 0 ? (
                      <CSVLink
                        data={this.state.data}
                        headers={header}
                        filename={"OrderHistory.csv"}
                        className="btn btn-success"
                      >
                        Export Report in CSV
                      </CSVLink>
                    ) : null}
                  </Col>
                </Row>
                &nbsp;
                <Row>
                  <Col xl={12}>
                    <div className="card p-3">
                      {this.state.ismodalopen ? (
                        <BookingDetails
                          status={true}
                          onCloseCallBack={this.onCloseCallBack}
                          data={this.state.activeid}
                        />
                      ) : null}
                      {/* <MDBDataTable responsive bordered data={data} /> */}
                      <MDBDataTableV5
                        hover
                        entriesOptions={[5, 20, 25]}
                        entries={5}
                        pagesAmount={4}
                        data={data}
                        scrollX
                        disableRetreatAfterSorting={true}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
