import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import BookingDetails from "./bookingdetails";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  // Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import { CSVLink } from "react-csv";
import HttpClient from "./../../utils/HttpClient";
import index from "../ChooseDesign";
class FormElements extends Component {
  fileObj = [];
  fileArray = [];

  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: " Product Order Reports", link: "#" },
        { title: "Product Order History", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      image_select: false,
      img_url: "",
      title: "",
      points: [""],
      id: "",
      duration: 0,
      seller_comission: "",
      type: "",
      desc: "",
      modal: false,
      arr: [],
      order: "",
      result1: [],
      cart: [],
      filter: "",
      ismodalopen: false,
      activeid: {},
      from_date: "",
      to_date: "",
      enable: false,
    };
    this.toggle = this.toggle.bind(this);
    this.handleDefault = this.handleDefault.bind(this);
  }

  onCloseCallBack = (val) => {
    console.log("object", val);
    this.setState({
      ismodalopen: val,
    });
  };
  handleChange = async (e) => {
    console.log(this.state.data);
    console.log(this.state.filter);
    let cart = this.state.data.filter(
      (item) => item.cart_data == this.state.filter
    );
    console.log(cart, "x");
    this.state.cart = cart;
    this.setState({});
  };
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  componentDidMount = async () => {
    this.checkUser();
    this.fetchData();
    console.log("pqr", this.state.result1);
  };

  handleDefault(date) {
    this.setState({ default_date: date });
  }

  fetchData = async (apidata) => { };

  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    console.log(sendData);
    let result = await HttpClient.requestData(
      "admin/ecom_banner/delete",
      "POST",
      sendData
    );
    console.log(result);
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Succfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      title: item.name,
      price: item.price,
      edit_item_id: item._id,
      seller_comission: item.seller_comission,
      duration: item.duration,
      id: item.id,
      type: item.type,
    });
  };
  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/ecom_banner/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Succfully",
        type: "success",
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };

  submit = async () => {
    let data = {
      datefrom: this.state.from_date,
      dateto: this.state.to_date,
    };
    console.log(data);
    let result = await HttpClient.requestData("orderHistoryrepo", "POST", data);
    console.log("result", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      // if (apidata.length > 0) {
      result.data.forEach((element, index) => {
        let rows = {
          sl: i,

          username: element.firstname + " " + element.lastname,
          order_id: element.order_id,
          total: element.total_inr,
          date: moment(element.booking_date).format("MMMM Do YYYY"),

          status: (
            <button
              title="Delete"
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
            >
              {element.status == "cancel" ? "Cancelled" : "Confirmed"}
            </button>
          ),
          view: (
            <button
              title="View Details"
              className="btn btn-success mr-2"
              onClick={() => {
                this.setState({
                  activeid: element,
                });
                setTimeout(() => {
                  this.setState({
                    ismodalopen: true,
                  });
                }, 200);
              }}
            >
              View Details
            </button>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          width: 150,
        },
        // {
        //     label: "User Name",
        //     field: "username",

        //     width: 270,
        // },

        {
          label: "Order Id",
          field: "order_id",
          width: 270,
        },
        {
          label: "Total",
          field: "total",
          width: 270,
        },
        {
          label: "Booking Date",
          field: "date",

          width: 270,
        },
        // {
        //     label: "Discount",
        //     field: "discount",

        //     width: 270,
        // },
        // {
        //     label: "Total Amount",
        //     field: "seller_comission",

        //     width: 270,
        // },
        // {
        //     label: "User Name",
        //     field: "username",

        //     width: 270,
        // },
        // {
        //   label: "Points",
        //   field: "points",

        //   width: 270,
        // },
        // {
        //     label: "Status",
        //     field: "status",

        //     width: 100,
        // },

        {
          label: "Status",
          field: "status",
          width: 100,
        },
        {
          label: "View Details",
          field: "view",
          width: 100,
        },
      ],
      rows: this.state.data,
    };

    const header = [

      { label: "Sl.", key: "sl" },
      { label: "Order Id", key: "order_id" },
      { label: "Total", key: "total" },
      { label: "Booking Date", key: "date" },
    ]
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        From Date
                      </Label>
                      <Col md={3}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.from_date}
                          id="example-text-input"
                          placeholder="Enter From Date"
                          onChange={(val) => {
                            this.setState({ from_date: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        To Date
                      </Label>
                      <Col md={3}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.to_date}
                          id="example-text-input"
                          placeholder="Enter To Date"
                          onChange={(val) => {
                            this.setState({
                              to_date: val.target.value,
                              enable: true,
                            });
                          }}
                        />
                      </Col>
                      <Col md={2}>
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                          disabled={!this.state.enable}
                        >
                          Search
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </Col>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xl={3}>
                {this.state.data.length > 0 ?
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"ProductOrder.csv"}
                    className="btn btn-success">
                    Export Report in CSV
                  </CSVLink> : null}
              </Col>
            </Row>&nbsp;

            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {this.state.ismodalopen ? (
                    <BookingDetails
                      status={true}
                      onCloseCallBack={this.onCloseCallBack}
                      data={this.state.activeid}
                    />
                  ) : null}
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
