import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import { CSVLink } from "react-csv";
import HttpClient from "./../../utils/HttpClient";
class FormElements extends Component {
  constructor(props) {
    super(props);
   
    // this.handleChange = this.handleChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      breadcrumbItems: [
        { title: "User Query List", link: "#" },
        { title: "View User Query List", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},

      image_select: false,
      img_url: "",
      title: "",
      points: [""],
      id: "",
      duration: 0,
      seller_comission: "",
      type: "",
      desc: "",
      from_date: "",
      to_date: "",
    };
    this.handleDefault = this.handleDefault.bind(this);
  }

  componentDidMount = async () => {
    this.checkUser();
    this.fetchData();
  };

  handleDefault(date) {
    this.setState({ default_date: date });
  }

  handleChange(event) {
    console.log(event.target.value);
        this.setState({reply: event.target.value}); 
       }

  sendreply = async (id,mail, rply, index) => {
    if (rply != "") {
      let data = {
        reply: rply,
        to_email: mail
      }
      console.log("data",data);
      let result = await HttpClient.requestData("ContactusInfo/" + id, "PUT", data);
      console.log("object", result);
      // this.setState(()=>"")
      this.setState({reply: ''});
      if (result && result.status) {
        // this.state.result[index].rply = '';
        document.getElementById(`textfield1_${index}`).value = '';
        this.setState({
          alert: true,
          message: "Reply sent successfully",
          type: "success",
          reply: "",

        });
        
        this.fetchData();
      //   setTimeout(() => {
      //   this.setState({
      //     alert: true,
      //     message: "Reply sent successfully",
      //     type: "success",
      //     // reply: "",

      //   });
      // }, 2000); 

        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000); 
      }
    } else {
      this.setState({
        alert: true,
        message: "Please enter some reply",
        type: "warning",
        reply: null,

      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 1000);
    }
  }

  fetchData = async () => {
    let data = {

      user_category: "User",
      datefrom: this.state.from_date,
      dateto: this.state.to_date,
    }
    console.log('aaaa',data)
    let result = await HttpClient.requestData("user_queries", "POST", data);
    console.log("cms/ContactusInfo", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      let newArr = result.data.reverse()
      this.setState({ result: newArr });
      // console.log(this.state.pointarr);
      this.state.result.forEach((element, index) => {
        console.log("element", element.rply);
        let rows = {
          sl: i,

          type: element.user_category,
          name: element.name,
          gender: element.user_type.label,
          complain_email: element.email,
          question: element.question,
          desc: element.additional_details,
          reply: <textarea
            // type="text"
            // value={this.state.reply}
            id={"textfield1_"+index}
            onChange={(val) => {
              this.state.result[index].rply = val.target.value;
              this.setState({
                reply: val.target.value
              })
              
            }}
            value={element.rply ? '' : element.rply} 
            // onChange={this.handleChange.bind(this)}
          >
          </textarea>,
          send: <button
            className="btn btn-success mr-2"
            onClick={() => {
              this.sendreply(element._id,element.email, element.rply, index)
            }}
          >
            Send
          </button>,
          replyans: element.reply ? element.reply : "",

          view: (
            <button
              title="View Details"
              className="btn btn-success mr-2"
              onClick={() => {
                this.setState({
                  activeid: element,
                });
                setTimeout(() => {
                  this.setState({
                    ismodalopen: true,
                    idneed: element._id
                  });
                }, 200);
              }}
            >
              View Details
            </button>
          ),

        };

        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  // componentDidMount() {
  //   document.addEventListener(
  //     "textarea",
  //     () => {
  //       this.setState({});
  //     },
  //     true
  //   );
  // }

  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    console.log(sendData);
    let result = await HttpClient.requestData(
      "admin/ecom_banner/delete",
      "POST",
      sendData
    );
    console.log(result);
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Succfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      title: item.name,
      price: item.price,
      edit_item_id: item._id,
      seller_comission: item.seller_comission,
      duration: item.duration,
      id: item.id,
      type: item.type,
    });
  };
  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/ecom_banner/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Succfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };
  submit = async () => {
    if (
      this.state.title != "" &&
      this.state.price != "" &&
      this.state.duration != "" &&
      this.state.seller_comission != "" &&
      this.state.type != ""
    ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          name: this.state.title,
          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData("subscription", "POST", data);
      } else {
        data = {
          name: this.state.title,
          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData(
          "subscription/" + this.state.edit_item_id,
          "PUT",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Subscription Updated Successfully"
            : "Subscription Added Succfully",

          type: "success",

          image_select: false,
          img_url: "",
          title: "",
          type: "",
          seller_comission: "",
          duration: "",
          price: "",
          desc: "",
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (e) => {
    let file = e.target.files[0].type;
    let fileType = file.split("/")[0];
    // fileType = fileType[0];
    console.log("e", file.split("/")[0]);

    if (fileType == "image" || fileType == "video") {
      // return false;
      this.setState({ fileType: fileType });
      let dataSend = { buckate: true, buckate_name: "appIcon" };

      let result = await HttpClient.newFileUpload(
        "image-upload/movie",
        e.target.files[0],
        dataSend
      );
      console.log("result", result);
      if (result && result.status) {
        this.setState({ img_url: result.url, image_select: true });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",

          width: 150,
        },
        // {
        //     label: "Date",
        //     field: "date",

        //     width: 270,
        // },
        {
          label: "Gender",
          field: "gender",

          width: 270,
        },

        {
          label: "Name",
          field: "name",

          width: 270,
        },
        {
          label: "Email",
          field: "complain_email",

          width: 270,
        },
        {
          label: "Question",
          field: "question",

          width: 270,
        },
        {
          label: "Query",
          field: "desc",

          width: 270,
        },
        {
          label: "Reply",
          field: "reply",

          width: 270,
        },
        {
          label: "Send",
          field: "send",

          width: 270,
        },
        {
          label: "Replied Ans",
          field: "replyans",

          width: 270,
        },
        {
          label: "User Type",
          field: "type",

          width: 270,
        },


        // {
        //   label: "View Details",
        //   field: "view",

        //   width: 170,
        // },


      ],
      rows: this.state.data,
    };
    const header = [

      { label: "Sl.", key: "sl" },
      { label: "Gender.", key: "gender" },
      { label: "Name", key: "name" },
      { label: "Email", key: "complain_email" },
      { label: "Question", key: "question" },
      { label: "Query", key: "desc" },
      { label: "Replied Ans", key: "replyans" },
      { label: "User Type", key: "type" },
    ]
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {this.state.alert ? (
              <UncontrolledAlert
                color={this.state.type}
                className="alert-dismissible fade show"
                role="alert"
              >
                {this.state.type == "warning" ? (
                  <i className="mdi mdi-alert-outline mr-2"></i>
                ) : this.state.type == "success" ? (
                  <i className="mdi mdi-check-all mr-2"></i>
                ) : this.state.type == "danger" ? (
                  <i className="mdi mdi-block-helper mr-2"></i>
                ) : null}
                {this.state.message}
              </UncontrolledAlert>
            ) : null}
              <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        From Date
                      </Label>
                      <Col md={3}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.from_date}
                          id="example-text-input"
                          placeholder="Enter From Date"
                          onChange={(val) => {
                            this.setState({ from_date: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        To Date
                      </Label>
                      <Col md={3}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.to_date}
                          id="example-text-input"
                          placeholder="Enter To Date"
                          onChange={(val) => {
                            this.setState({
                              to_date: val.target.value,
                              enable: true,
                            });
                          }}
                        />
                      </Col>
                      <Col md={2}>
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.fetchData();
                          }}
                          disabled={!this.state.enable}
                        >
                          Search
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </Col>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={3}>
                {this.state.data.length > 0 ?
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"SellerContactDetails.csv"}
                    className="btn btn-success">
                    Export Report in CSV
                  </CSVLink> : null}
              </Col>
            </Row>&nbsp;
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                    scrollX
                    disableRetreatAfterSorting={true}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
