import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import ReactJsAlert from "reactjs-alert";
// import { Row, Col, Card, CardBody, Button } from "reactstrap";

import {
  Container,
  Row,
  Col,
  Tooltip,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Input,
  Form,
  Button,
  FormGroup,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import BaseInfo from "../ChooseDesign/baseInfo";

export default class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 1,
      tooltipOpen: false,
      result: [],
      data: [],
      monthDate: new Date(),
      modal_static: false,
      cardLoop: [1, 2, 3, 4],
      
      quizName: "",
      startDate: new Date(),
      endDate: new Date(),
      userId: "",
      userDetails: {},
      giftCard: [],
      giftCardSelected: [],
      img: {},
      imgUrl: "",
      refreshStatus: false,
      // know your customer
      previewKnow: false,
      previewData: {},
      resultKnow: [],
      // dataKnow: [],
      breadcrumbItems: [
        { title: "Question ", link: "#" },
        { title: "Add & Manage Question", link: "#" },
      ],
      alert: false,
      message: "",
      type: "",
      status: false,
      title: "",
      QuestionOptions:[],
      selectedOptionValue:"",
      planname:"",
      planprice:"",
      
      
    };
    // this.toggleTab = this.toggleTab.bind(this);
    // this.handleMonthChange = this.handleMonthChange.bind(this);
    // this.tog_CardModal = this.tog_CardModal.bind(this);
  }

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };
  
  
  componentDidMount() {
    this.checkUser();
    this.fetch()
  }


  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({ userId: user.id, userDetails: user });
      this.fetchQuestionOptions();
    } else {
    }
  };

  fetchQuestionOptions = async() => {
   
    let sendData ={
      "qstnid":parseInt(this.state.question_id),
    };

    console.log("SendDATA", sendData)

    let result = await HttpClient.requestData(
      "getoptionByqstn",
      "POST",
       sendData
    );
    console.log("QuestionOption", result);
    if (result && result.status > 0) {
      
      this.setState({
        QuestionOptions: result.data,
      });

      setTimeout(() => {
        console.log(this.state.QuestionOptions);
      }, 3000);

     

    }

  }

  fetchCard = async () => {
    let sendData = { owner_id: this.state.userId };
    let result = await HttpClient.requestData(
      "admin/gift-card/fetch-all",
      "POST",
      sendData
    );
    console.log("result", result);
    if (result && result.status > 0) {
      this.setState({ giftCard: result.data });
    } else {
    }
  };

  fetch = async () => {
    let sendData = {
     
    };

    console.log("FetchPriceData",sendData);
    let result = await HttpClient.requestData(
      "getprice",
      "POST",
      sendData
    );
    console.log("resultGetPrice", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({
        result: result.data,
        refreshStatus: !this.state.refreshStatus,
      });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
        
          title: element.name?element.name:null,
          price: element.price?element.price:null,
          
          

          action: (
            <>
          
          <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                }}
              >
                <i className="fa fa-edit" />
              </button>
             
            
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  async status(index,data, state, e) {
        console.log("result", index);
        let result = await HttpClient.requestData("status/"+data._id, "GET", data);
        console.log("result", result);
        if (result && result.status === true) {
          // this.FetchAll();
          let data = result.data;
          this.state.result[index] = data;
          this.setState({});
          this.UpdateState();
          // console.log("mask profileeeeee", this.state.result);
          // this.setState({
          //   result: result
          // });
        }
      }

    async UpdateState()
    {
      let data = [];
      let i = 1;
      this.state.result.forEach((element,index) => {
        

        let rows = {
          sl: i,
        
          question: element.question?element.question:null,
          option1: element.option1?element.option1:null,
          option2: element.option2?element.option2:null,
          option3: element.option3?element.option3:null,
          option4: element.option4?element.option4:null,
          action: (
            <>
          
              <button title="Delete" className="btn btn-danger mr-2" onClick={e => this.delete(element)}>
                <i className="fa fa-trash" />
              </button>
              {element.status== true ? (
              <Button
                className="btn-icon"
                color="success"
               onClick={e => this.status(index,element, element.status, e)}
              >
                {" "}
                <span className="btn-icon-label">
                  <i className="mdi mdi-emoticon-wink-outline mr-2"></i>
                </span>{" "}
                Active
              </Button>
            ) : (
              <Button
                className="btn-icon"
                color="danger"
               onClick={e => this.status(index,element, element.status, e)}
              >
                {" "}
                <span className="btn-icon-label">
                  <i className="mdi mdi-emoticon-cry-outline mr-2"></i>
                </span>{" "}
                Deactive
              </Button>
            )},
            </>
          ),
        };
        data.push(rows);
        this.setState({
          data: data
        });

    });
    }

 

  delete = async (item, index) => {
    let sendData = {
      id: item.id,
    };
    let result = await HttpClient.requestData(
      "delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        // this.setState({
        //   alert: true,
        //   message: "Deleted Succfully",
        //   type: "success",
        // });
        this.setState({
          type: "success",
          status: true,
          title: "Deleted Succfully",

        });
        // setTimeout(() => {
        //   this.setState({
        //     alert: false,
        //     message: "",
        //     type: "",
        //   });
        // }, 3000);
        this.fetch();
      }
    }
  };


  delete = async (item, index) => {
    let sendData = {
      id: item.id,
    };
    let result = await HttpClient.requestData(
      "admin/post/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {
   
    this.setState({
      edit_enable: true,
      uploaded_images:item.image,
      title: item.title,
      post: item.post,
      edit_id:item._id,
    selectedpostcat:item.postcatarr,
    
      
    });
    
  };

  blankChk = (index) => {
    if (
      this.state.question[index].answer != "" &&
      this.state.question[index].question != "" &&
      this.state.question[index].option1 != "" &&
      this.state.question[index].option2 != "" &&
      this.state.question[index].option3 != "" &&
      this.state.question[index].option4 != ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  optionChk = (index) => {
    if (
      this.state.question[index].answer == this.state.question[index].option1 ||
      this.state.question[index].answer == this.state.question[index].option2 ||
      this.state.question[index].answer == this.state.question[index].option3 ||
      this.state.question[index].answer == this.state.question[index].option4
    ) {
      return true;
    } else {
      return false;
    }
  };

  formValid = async () => {
    if (
      this.state.quizName != "" &&
      this.state.startDate != "" &&
      this.state.endDate != ""
    ) {
      const startDate = moment(this.state.startDate);
      const timeEnd = moment(this.state.endDate);
      const diff = timeEnd.diff(startDate);
      const diffDuration = moment.duration(diff);
      if (diffDuration.days() > 0) {
        let blank = this.blankChk(0);
        console.log("blank", blank);
        if (blank) {
          let option = this.optionChk(0);
          console.log("option", option);

          if (option) {
            // this.tog_CardModal();
          } else {
            alert("Answer Must Be In Options");
          }
        } else {
          alert("Please Add Atleast One Question.");
        }
      } else {
        alert("Invalid End Date Details.");
      }
    } else {
      alert("Please Fillup Details. ll");
    }
  };

  handlesubmit=async()=>{
    if (
      this.state.title == "" &&
      this.state.price == ""
    ) {

      // alert("Please Fillup Details");
     
      this.setState({
      
        type: "error",
        status: true,
        title:  "Please Fillup Details",

      });
      
    }
    else{

      let data = {
        name:this.state.planname,
        price:this.state.planprice,
      };

      console.log("SubmitDataPrice",data);
      let result = await HttpClient.requestData("addrpice", "POST", data);

      console.log("ResponseSubmitAddPrice",result);

      if (result && result.status) {
        this.setState({
          result:result.data,
          type: "success",
          status: true,
          title: "Successfully Submited.",

        });
        this.fetch();
        this.setState({
         
              planname:"",
              planprice:"",
        });
      } else {
       
          this.setState({
      
          type: "error",
          status: true,
          title: result.error,

        });
      }
      // setTimeout(() => {
      //   this.setState({
      //     alert: false,
      //     message: "",
      //     type: "",
      //   });
      // }, 3000);
    }

  }

  changeAnswer = async(e) => {
    const value = e.target.value;
    
    console.log(value);
    if(value == "Yes"){
      console.log("In",value);
      this.setState({lastquestion:true,opentextbox:true});
    }
    else{
      console.log("Infalse",value);
      this.setState({lastquestion:false,opentextbox:false});
    }
    this.setState({answerRadio:value});
  }
 
  preview = () => {};

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Plan Name",
          field: "title",
          sort: "asc",
          width: 270,
        },
        {
          label: "Price",
          field: "price",
          sort: "asc",
          width: 270,
        },
        

        
        // {
        //   label: "Action",
        //   field: "action",
        //   sort: "asc",
        //   width: 100,
        // },
      ],
      rows: this.state.data,
    };

    // const dataKnow = {
    //   columns: [
    //     {
    //       label: "Sl.",
    //       field: "sl",
    //       sort: "asc",
    //       width: 150,
    //     },
    //     {
    //       label: "Question",
    //       field: "quizName",
    //       sort: "asc",
    //       width: 270,
    //     },
    //     {
    //       label: "Action",
    //       field: "action",
    //       sort: "asc",
    //       width: 100,
    //     },
    //   ],
    //   rows: this.state.dataKnow,
    // };

    return (
      <React.Fragment>
        <div className="page-content">
        <ReactJsAlert
          type={this.state.type}
          title={this.state.title}
          status={this.state.status}
          Close={() => this.setState({ status: false })}
        />
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              
              <Col xl={12}>
                <div>
                  
                  <div
                    id="basic-pills-wizard"
                    className="twitter-bs-wizard"
                  >

                      <Form className="card p-3">
                        <h4></h4>
                        <Row>
                          <>
                            <Col lg="12">
                            <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Plan Name
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.planname}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ planname: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Price
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.planprice}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ planprice: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    </Col>
                         
                          </>
                       <Col lg="6">
                            <FormGroup className="mb-0">
                              <div className="button-items pt-4">
                                <Button
                                  color="primary"
                                  type="button"
                                  className="waves-effect waves-light mr-1"
                                  onClick={() => {
                                    this.handlesubmit();
                                  }}
                                >
                                  Submit{" "}
                                  <i className="ri-arrow-right-line align-middle ml-1"></i>
                                </Button>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                      <div className="card px-3 pt-0">
                       
                      </div>
                      
                   
                  </div>
                  
                </div>
              </Col>
              
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {this.state.activeTab == 1 ? (
                    <MDBDataTable responsive bordered data={data} />
                  ) : (
                    {/* <MDBDataTable responsive bordered data={dataKnow} /> */}
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
