import React, { useState, useEffect } from "react";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

//import ReactJsAlert from "reactjs-alert";

function Details(props) {
  const [data, setdata] = useState({})

  useEffect(() => {

    console.log("objecttt", props.data);

  }, []);



  const onCloseMethod = async () => {
    props.onCloseCallBack(false);
  };



  // const { selectedOption } = this.state;
  return (
    <div
      className={props.status ? "modal show fade" : "modal fade"}
      id="add-new-task-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="add-new-task-modalLabel"
      aria-hidden="true"
      style={{ display: props.status ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="add-new-task-modalLabel">
              View Details
            </h4>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => {
                onCloseMethod();
              }}
            >
              ×
            </button>
          </div>
          <div className="modal-body" style={{ height: "300px", overflowY: "auto" }}>
            <div className="px-2">


            </div>



            <div className="form-group row">

              <div className="col-md-4">
                <label htmlFor="task-title"> Name</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.firstName + " " + props.data.lastName}
                  readOnly
                />
              </div>


              <div className="col-md-8">
                <label htmlFor="task-title">Email</label>


                <input
                  style={{ height: "37px" }}
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data ? props.data.email : ""}
                  readOnly

                />
              </div>

              <div className="col-md-4">


                <label htmlFor="task-title">Country</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data ? props.data.country ? props.data.country.label : "" : ""}
                  readOnly
                />
              </div>



              <div className="col-md-4">
                <label htmlFor="task-title">Currency</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data ? props.data.currency : ""}
                  readOnly
                />
              </div>

              <div className="col-md-4">
                <label htmlFor="task-title">Priority</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data ? props.data.priority : ""}
                  readOnly
                />
              </div>






            </div>





            <div className="text-right">
              <button
                type="button"
                className="btn btn-warning mr-3"
                data-dismiss="modal"
                onClick={() => {
                  onCloseMethod();
                }}
              >
                Close
              </button>

            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}
export default Details;
