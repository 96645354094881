import React, { useState, useEffect } from "react";
import "./ind432a.css";

function Design_1(props) {
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     backgroundImage: this.props.backgroundImage,
  //     item: this.props.item,
  //     user: this.props.item.user,
  //     textPosition: this.props.textPosition ? this.props.textPosition : {},
  //     editText: false,
  //     title: "",
  //     changeBGColor: false,
  //     leftBGColor: "",
  //     activeIndex: 0,
  //   };
  // }
  // componentDidUpdate = () => {
  //   console.log("this.props.item.fontValue", this.props.item);
  // };
  // render() {
  const [backgroundImage, setbackgroundImage] = useState(props.backgroundImage);
  const [item, setitem] = useState(props.item);
  const [user, setuser] = useState(props.item.user);
  const [textPosition, settextPosition] = useState(props.textPosition ? props.textPosition : {});
  const [editText, seteditText] = useState(false);
  const [title, settitle] = useState('');
  const [changeBGColor, setchangeBGColor] = useState(false);
  const [leftBGColor, setleftBGColor] = useState("");
  const [activeIndex, setactiveIndex] = useState(0);

  useEffect(() => {
    setitem(props.item);
    setuser(props.item.user);
    console.log('props.item', props.item)
  }, [props.item])

  useEffect(() => {
    setitem(props.item);
    setuser(props.item.user);
    console.log('props.item', props.item)
  }, [props.refreshStatus])
  // const { textPosition } = this.state;
  return (
    <div className="designed_area w-100 h-100 bg-light relative">
      <div className="d-flex h-100 w-100">
        <div
          className="left_aside_icon"
          title="Click to change background color"
          style={{
            background: props.item.iconbgcolor,
          }}
          onClick={() => {
            setchangeBGColor(true)
          }}
        >
          <div className="d-flex flex-column">
            <div
              className="d-flex flex-column mb-3 bg-light rounded"
              style={{ cursor: "pointer" }}
              onClick={() => props.callBackIcons(true)}
            >
              <span className="m-auto text-center" style={{ height: "30px" }}>
                <i className="ri-add-line font-size-24"></i>
              </span>
              <span
                className="m-auto text-center font-weight-bold"
                style={{ lineHeight: 1.1 }}
              >
                Click to <br />
                  add icon
                </span>
            </div>
            {props.item.icons.map((item, index) => {
              return (
                <div className="d-flex flex-column mb-3 bg-light rounded">
                  <span className="m-auto text-center">

                    <img
                      src={item.iconUrl}
                      style={{ height: 40, width: 40 }}
                    />
                  </span>
                  <span
                    className="m-auto text-center font-weight-bold"
                    style={{ color: props.item.color }}
                  >
                    {item.iconName}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="right_aside_panel">
          {props.item.user.map((item, index) => {
            console.log('item user', item.icon)
            return (
              <div
                className="panel_item d-flex flex-column w-100 py-4"
                style={{
                  backgroundImage: `url(${item.bgImage})`,
                }}
              >
                <button className="custom_file_input">
                  Panel {index + 1} - click to add image
                    <input
                    type="file"
                    className="page_input_file"
                    title="Choose Background Image"
                    onChange={async (e) => {
                      props.uploadback(e.target.files[0], index);
                    }}
                  />
                </button>
                <div className="text_block py-2 px-0 relative w-100">
                  {editText && activeIndex == index ? (
                    <div className="d-flex mt-3">
                      <input
                        type="text"
                        className="form-control mr-2"
                        placeholder="Enter App Title..."
                        onChange={(e) => {
                          item.title = e.target.value;
                        }}
                        value={item.title}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={() => { seteditText(false) }}
                      >
                        Save
                        </button>
                    </div>
                  ) : (
                    <h5
                      style={{
                        left: textPosition.left,
                        right: textPosition.right,
                        top: textPosition.top,
                        bottom: textPosition.bottom,
                        color: props.item.textcolor
                          ? props.item.textcolor
                          : "#fff",
                        fontSize:
                          props.item.fontValue != ""
                            ? Number(props.item.fontValue)
                            : 14,
                        fontFamily: props.item.fontFamily
                          ? props.item.fontFamily
                          : "",
                      }}
                      onClick={() => {
                        seteditText(true);
                        setactiveIndex(index);
                      }
                      }
                    >
                      {title !== ""
                        ? title
                        : item.title}
                    </h5>
                  )}
                </div>
                {typeof (item.icon) != 'undefined' && item.icon.length > 0 ?
                  <div className="d-flex mb-2 mt-auto">
                    {item.icon.map((itemm, index) => {
                      return (

                        <div className="mx-2 text-center">

                          <img
                            src={itemm.iconUrl}
                            style={{ height: 30, width: 30 }}
                          />
                          <span
                            className="m-auto text-center font-weight-bold"
                            style={{ color: '#000' }}
                          >
                            {itemm.iconName}
                          </span>
                        </div>


                      )
                    })}

                  </div> : null}
                {typeof (item.icon) != 'undefined' && item.icon.length > 0 ?
                  <button
                    className="add2Icons bg-info"
                    onClick={() => props.callBackIconsNew(true, index)}
                  >
                    Click to add 2 icons
                  </button> : <button
                    className="add2Icons bg-info"
                    onClick={() => props.callBackIconsNew(true, index)}
                  >
                    Click to update icons
                  </button>}
              </div>
            );
          })}
        </div>
      </div>
      {/* bg-color change modal */}
      {changeBGColor ? (
        <div
          className={
            changeBGColor ? "modal fade show" : "modal fade"
          }
          id={"colorBGModal"}
          style={{
            display: changeBGColor ? "block" : "none",
          }}
        >
          <div className="modal-dialog mt-5 pt-5" role="document">
            <div className="modal-content">
              <div className="modal-header py-2">
                <h6 className="modal-title">Change Background Color</h6>
              </div>
              <div className="modal-body">
                <label for="color-picker">
                  <p className="mb-0 mr-3 text-dark">
                    Pick colors to change the background
                    </p>
                </label>
                <input
                  name="color-picker"
                  aria-label="icon-color-picker"
                  type="color"
                  value={leftBGColor}
                  onChange={(e) => {
                    setleftBGColor(e.target.value)
                  }

                  }
                />
                <button
                  type="button"
                  className="btn btn-primary btn-sm pull-right"
                  onClick={() => { setchangeBGColor(false) }}
                >
                  Save
                  </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Design_1
