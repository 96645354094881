import { MDBDataTableV5 } from 'mdbreact';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import HttpClient from '../../utils/HttpClient';

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { CSVLink } from 'react-csv';
const WithdrawlCommission = () => {

const {id} =useParams()

const [Data, setData] = useState([])
const [Result, setResult] = useState([])
const [breadcrumbItems,setbreadcrumbItems]= useState([
  { title: "Withdrawl Commission", link: "#" },
  { title: "View withdrawl Commission", link: "#" },
])
useEffect(() => {
    console.log(id,"iddd");
    fetchData()
}, [])
useEffect(() => {
  console.log(Data,"data");
  
}, [Data])

   const fetchData = async () => {
       let params = {}
        let result = await HttpClient.requestData("withdraw-history/" +id , "GET",params);
        console.log("seller single list", result);
        if (result && result.status) {
          let data = [];
          let i = 1;
          // this.setState({ result: result.data });
        setResult(result.data)
        result.data.forEach((element, index) => {
            let rows = {
              sl: i,
              order_id: element.order_id,
              seller_commission: "₹" + element.seller_commission,         
            };
            i++;
            data.push(rows);
          });
          
          setData(data)
        } else {
          setData([])
        }
      };

      const data = {
        columns: [
          {
            label: "Sl.",
            field: "sl",
  
            width: 150,
          },
  
          {
            label: "Order Id",
            field: "order_id",
  
            width: 270,
          },
  
          {
            label: "Commission",
            field: "seller_commission",
  
            width: 270,
          },
        ],
        rows: Data,
      };
      const header = [
        { label: "Sl.", key: "sl" },
        { label: "Order Id", key: "order_id" },
        { label: "Commission", key: "seller_commission" },
      ];
  return (
    <> 
    <div className="page-content">
    <Container fluid>
    <Breadcrumbs
              title={breadcrumbItems[0].title}
              breadcrumbItems={breadcrumbItems}
            />
             <Col xl={3}>
              {Data.length > 0 ? (
                <CSVLink
                  data={Data}
                  headers={header}
                  filename={"SellerCommission.csv"}
                  className="btn btn-success"
                >
                  Export Report in CSV
                </CSVLink>
              ) : null}
            </Col>
    <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  <MDBDataTableV5
                    responsive
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                  />
                </div>
              </Col>
            </Row>
    </Container>
    </div>
   
    </>
  )
}

export default WithdrawlCommission