import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import Details from "./details";
import SellerShop from "./sellerShop"
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Top Provider List", link: "#" },
        { title: "View Top Provider", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      title: "",
      points: [""],
      id: "",
      duration: 0,
      seller_comission: "",
      desc: "",
      priority: "",
      device: "All",
      devicefilter: ["App", "Web", "All"],
      from_date: "",
      to_date: "",
    };

  }

  componentDidMount = async () => {

    this.fetchData();
  };


  fetchData = async (val) => {
    let data = {

      // device_type: this.state.device == "All" ? "All" : val,
      datefrom: this.state.from_date,
      dateto: this.state.to_date,

    };
    console.log("data", data);
    let result = await HttpClient.requestData("topProviders", "POST", data);
    console.log("topProviders", result);
    if (result && result.status > 0) {
      let sorting = result.data.sort((a, b) => {
        // console.log(a.priority);
        if (a.priority === 0) return 1;        //Return 1 so that b goes first
        if (b.priority === 0) return -1;
        return a.priority - b.priority
      });
      // let sortingg= result.data?.sort((a, b) => (a.priority > b.priority ? 1 : -1))
      console.log("topProviders sorting---", sorting);
      let data = [];
      let i = 1;
      this.setState({ result: sorting });
      // console.log(this.state.pointarr);
      this.state.result.forEach((element, index) => {
        let revenew = element.sellerTotalComission - element.refundedAmtTotal;
        let positiveRevenew = Math.abs(revenew)
        let rows = {
          sl: i,

          username: element.firstName + " " + element.lastName,
          email: element.email,
          date: moment(element.start).format("MMMM Do YYYY"),
          about: element.about,
          city: element.city,
          devtype: element.device_type,
          sellerTotalComission: positiveRevenew,
          view: (
            <button
              title="Delete"
              className="btn btn-success mr-2"
              onClick={() => {
                this.setState({
                  activeid: element,
                });
                setTimeout(() => {
                  this.setState({
                    ismodalopen: true,
                    idneed: element._id
                  });
                }, 200);
              }}
            >
              View Details
            </button>
          ),
          seller_shop: (
            <Button
              className="btn-icon"
              color="primary"
              onClick={() => {
                this.setState({
                  activeid: element,
                });
                setTimeout(() => {
                  this.setState({
                    ismodalopen1: true,
                    sellerid: element._id
                  });
                }, 200);
              }}
            >
              {" "}
              <span className="btn-icon-label">
                {/* <i className="mdi mdi-emoticon-wink-outline mr-2"></i> */}
              </span>{" "}
              Seller Shop
            </Button>
          ),
          seller_service: (
            <Link
              to={{
                pathname:
                  "/seller-services/" + element._id,
                data: element, // your data array of objects
              }}
            >
              <Button
                className="btn-icon"
                color="primary"
              // onClick={(e) => this.status(index, element._id)}
              >
                {" "}
                <span className="btn-icon-label">
                  {/* <i className="mdi mdi-emoticon-wink-outline mr-2"></i> */}
                </span>{" "}
                Seller Services
              </Button>
            </Link>
          ),


        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  onCloseCallBack = (val) => {
    console.log("object", val);
    this.setState({
      ismodalopen: val,
    });
  };


  onCloseCallBack1 = (val) => {
    console.log("object", val);
    this.setState({
      ismodalopen1: val,
    });
  };



  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",

          width: 150,
        },

        {
          label: "User Name",
          field: "username",

          width: 270,
        },
        {
          label: "Email",
          field: "email",

          width: 270,
        },
        {
          label: "About",
          field: "about",

          width: 270,
        },
        {
          label: "City",
          field: "city",

          width: 270,
        },
        {
          label: "Total Revenue",
          field: "sellerTotalComission",

          width: 270,
        },
        {
          label: "View Details",
          field: "view",
          width: 100,
        },
        {
          label: "Seller Shop",
          field: "seller_shop",

          width: 170,
        },
        {
          label: "Seller Services",
          field: "seller_service",

          width: 100,
        },
        {
          label: "Device Type",
          field: "devtype",

          width: 100,
        },


      ],
      rows: this.state.data,
    };

    const header = [

      { label: "Sl.", key: "sl" },
      { label: "User Name", key: "username" },
      { label: "Email", key: "email" },
      { label: "About", key: "about" },
      { label: "City", key: "city" },
    ]
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {this.state.alert ? (
              <UncontrolledAlert
                color={this.state.type}
                className="alert-dismissible fade show"
                role="alert"
              >
                {this.state.type == "warning" ? (
                  <i className="mdi mdi-alert-outline mr-2"></i>
                ) : this.state.type == "success" ? (
                  <i className="mdi mdi-check-all mr-2"></i>
                ) : this.state.type == "danger" ? (
                  <i className="mdi mdi-block-helper mr-2"></i>
                ) : null}
                {this.state.message}
              </UncontrolledAlert>
            ) : null}
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        From Date
                      </Label>
                      <Col md={3}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.from_date}
                          id="example-text-input"
                          placeholder="Enter From Date"
                          onChange={(val) => {
                            this.setState({ from_date: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        To Date
                      </Label>
                      <Col md={3}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.to_date}
                          id="example-text-input"
                          placeholder="Enter To Date"
                          onChange={(val) => {
                            this.setState({
                              to_date: val.target.value,
                              enable: true,
                            });
                          }}
                        />
                      </Col>
                      <Col md={2}>
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.fetchData();
                          }}
                          disabled={!this.state.enable}
                        >
                          Search
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </Col>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={3}>
                {this.state.data.length > 0 ?
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"UserSearchList.csv"}
                    className="btn btn-success">
                    Export Report in CSV
                  </CSVLink> : null}
              </Col>
            </Row>&nbsp;
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                    scrollX
                    disableRetreatAfterSorting={true}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
