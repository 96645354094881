import React, { Component } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

//Import Charts
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";

class SalesAnalytics extends Component {
  state = {
    series: [42, 26, 15],
    options: {
      labels: ["Product A", "Product B", "Product C"],
      plotOptions: {
        pie: {
          donut: {
            size: "75%",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      colors: ["#5664d2", "#1cbb8c", "#eeb902"],
    },
  };
  render() {
    return (
      <React.Fragment>
        <Col lg={4}>
          <Card className="bg-danger">
            <CardBody>
              <div>
                <div className="text-center"
                  onClick={() =>
                  (window.location.href =
                    "https://admin.astrophy.com/subservice-category")
                  }
                >
                  <p className="mb-2 text-white">Total Service Category</p>
                  <h4 className="text-white">{this.props.totalcategory}</h4>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

export default SalesAnalytics;
