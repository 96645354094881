import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import { CSVLink } from "react-csv";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
import Select from "react-select";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import index from "../ChooseDesign";
import { Editor } from "@tinymce/tinymce-react";
import { useState, useEffect } from "react";

const ShowMore =({data})=>{
  const[show,setShow] =useState(true)
  // let data = "fglk;hjfdgl;khjfdglikjhcccccccccccccccccccccccc"
  useEffect(()=>{
console.log(show);
  },[show])
  return(
    <>
    {data.length > 100?
<div>
  {show == true?
  <>

{data.slice(0, 100)} <button onClick={()=>setShow(false) } className="btn btn-success btn-sm">Read More</button>
</>:<>
{data}
</>}
</div>:
<div>
{data}
</div>
}</>
  )
}

class FormElements extends Component {
  fileObj = [];
  fileArray = [];
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Blog", link: "#" },
        { title: "Add & Manage Blog", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      //   this page states
      category: [],
      categoryAll: [],
      subCategory: [],
      subCategoryAll: [],

      cat_id: "",
      pruduct_type: "",
      sub_cat_id: "",

      // selectedFiles: [],
      images: "",
      uploaded_images: [],

      selling_price: "",
      mrp: "",
      purchase_price: "",
      discount: "",
      availableSize: [],
      availableColor: [],

      optionGroupSize: [{ value: "Red", code: "" }],
      selectedMultiSize: [],

      showSelectColor: false,
      showSelect: false,
      colorRgb: "#4667CC",
      simple_color1: false,
      color: [{ code: "#4667CC", active: false }],
      description: "",
      delivery_type: "",
      delivery_time: "",
      preview: [],
      blogCatData: [],
      blog_cat: "",
      multiImages: [],
      refresh: false,
      content1: "",
      content2: "",
    };
    this.handleMulti = this.handleMulti.bind(this);
    this.onDragRgb = this.onDragRgb.bind(this);
  }
  onDragRgb(index, c1) {
    // alert(c1);
    this.state.color[index].code = c1;
    this.setState({ colorRgb: c1 });
  }

  handleMulti = (selectedMultiSize) => {
    this.setState({ selectedMultiSize });
    setTimeout(() => {
      console.log("selectedMulti", selectedMultiSize);
    });
  };

  componentDidMount = async () => {
    this.checkUser();
    this.fetchBlogCategory();
  };

  fetchBlogCategory = async () => {
    let result = await HttpClient.requestData("viewblogcategory", "GET");
    console.log("blog/Category", result);
    if (result && result.status) {
      this.setState({ blogCatData: result.data });
    }
  };

  fetchData = async () => {
    let result = await HttpClient.requestData("cms/Blog", "GET");
    console.log("cms/About", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        var dom = new DOMParser().parseFromString(element.content1, "text/html");
        var txt = dom.body.textContent;
        let rows = {
          sl: i,
          category: element.blog_category.length ? element.blog_category[0].category_name : "",
          content1: <ShowMore data={txt} />,
          heading: element.heading ? element.heading : "",
          image: (
            <img
              src={HttpClient.IMG_URL + element.image}
              width="100"
              height="100"
            />
          ),
          action: (
            <div className="d-flex">
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>{" "}
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  window.scrollTo(0,0)
                  this.edit(element, index);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </div>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  delete = async (item, index) => {
    let result = await HttpClient.requestData("cms/Blog/" + item._id, "DELETE");
    console.log(result);
    if (result && result.status) {
      this.setState({
        alert: true,
        message: "Deleted Successfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };
  edit = async (item, index) => {
    console.log("item", item);
    this.setState({
      edit_enable: true,
      edit_item_id: item._id,
      blog_cat: item.category_id ? item.category_id : "",
      content2: item.heading,
      content1: item.content1,
      images: HttpClient.IMG_URL + item.image,
      uploaded_images: [
        {
          preview: HttpClient.IMG_URL + item.image,
        },
      ],
    });
  };

  submit = async () => {
    if (this.state.content1 != "" && this.state.content2 != "" && this.state.blog_cat!= "") {
      if (this.state.uploaded_images.length > 0) {
        // alert("hji");
        this.setState({ loading: true });

        let result = null;

        if (this.state.edit_enable == false) {
          let data = new FormData();
          data.append("content1", this.state.content1);
          data.append("category_id", this.state.blog_cat);
          data.append("heading", this.state.content2.replace(/<[^>]+>/g, ""));

          data.append("image", this.state.images);

          result = await HttpClient.fileUplode("cms/Blog", "POST", data);
        } else {
          let data = new FormData();
          data.append("content1", this.state.content1);
          data.append("category_id", this.state.blog_cat);
          data.append("heading", this.state.content2.replace(/<[^>]+>/g, ""));

          data.append("image", this.state.images);
          data.append("blog_id", this.state.edit_item_id);
          console.log(data);
          result = await HttpClient.fileUplode("cms/Blog", "POST", data);
        }
        console.log(result);
        if (result && result.status) {
          this.setState({
            alert: true,
            message: this.state.edit_enable
              ? "Blog  Updated Successfully"
              : "Blog Inserted Successfully",

            type: "success",
            uploaded_images: [],
            images: "",
            content1: "",
            content2: "",
            blog_cat: "",
          });

          this.fetchData();
        } else {
          this.setState({
            alert: true,
            message:
              typeof result.error == "string"
                ? result.error
                : result.error[0].msg,
            type: "danger",
          });
        }
      } else {
        this.setState({
          alert: true,
          message: "Please Select Atleast One Image.",
          type: "warning",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  handleAcceptedFiles = (files) => {
    // console.log("object", event.target.files[0]);
    // setprofile(event.target.files[0]);
    let file = {
      preview: URL.createObjectURL(files[0]),
      formattedSize: this.formatBytes(files[0].size),
    };
    console.log("image", files[0]);
    let arr = [];
    arr.push(file);
    this.setState({
      images: files[0],
      uploaded_images: arr,
    });
    console.log("uploaded_images", this.state.uploaded_images);
  };

  async remove_image(item, index) {
    this.setState({
      images: {},
    });
    this.state.uploaded_images.pop(index);
    this.setState({});
  }

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  handleBlogCatChange = async (event) => {
    console.log(event.target.value);
    this.setState({ blog_cat: event.target.value });
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          width: 150,
        },
        {
          label: "Blog Category",
          field: "category",
          width: 270,
        },
        {
          label: "Heading",
          field: "heading",
          width: 270,
        },
        {
          label: "Content",
          field: "content1",
          width: 300,
        },
        {
          label: "Image",
          field: "image",
          width: 270,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };

    const header = [
      { label: "Sl.", key: "sl" },
      { label: "Content 1", key: "content1" },
      { label: "Heading", key: "heading" },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              {/* <BaseInfo /> */}
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Blog Category
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={this.handleBlogCatChange}
                          value={this.state.blog_cat}
                        >
                          <option value="">Select Blog Category</option>
                          {this.state.blogCatData.map((item, index) => {
                            return (
                              <option value={item._id} key={index}>
                                {item.category_name}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Heading
                      </Label>

                      <Col md={10}>
                        {/* <Input
                          className="form-control"
                          type="text"
                          value={this.state.content2}
                          id="example-text-input"
                          placeholder="Enter Heading"
                          onChange={(val) => {
                            this.setState({ content2: val.target.value });
                          }}
                        /> */}
                        {/* <CKEditor
                          editor={ClassicEditor}
                          data={this.state.content2}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            console.log({ event, editor, data });
                            this.setState({
                              content2: data,
                            });
                          }}
                          value={this.state.content2}
                        /> */}
                        <textarea
                          maxLength={100}
                          className="form-control"
                          onChange={(val) => {
                            this.setState({
                              content2: val.target.value,
                            });
                          }}
                          value={this.state.content2}
                        ></textarea>
                        <small style={{ color: "red" }}>
                          {" "}
                          *max 100 letters.{" "}
                        </small>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Content
                      </Label>
                      <Col md={10}>
                        {/* <CKEditor
                          editor={ClassicEditor}
                          data={this.state.content1}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            console.log({ event, editor, data });
                            this.setState({
                              content1: data,
                            });
                          }}
                          value={this.state.content1}
                        /> */}
                        <Editor
                          apiKey="15r14a210jticff5jycrmnqijiset548njfyasvp7vzdk3hg"
                          plugins="
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                            "
                          value={this.state.content1}
                          onEditorChange={(newValue, editor) => {
                            this.setState({ content1: newValue });
                          }}
                        />
                        {/* <textarea
                          maxLength={1000}
                          className="form-control"
                          onChange={(val) => {

                            this.setState({
                              content1: val.target.value,
                            });
                          }}

                          value={this.state.content1}
                        >

                        </textarea> */}
                        <small style={{ color: "red" }}>
                          {" "}
                          *max 1000 letters.{" "}
                        </small>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Image
                      </Label>
                      <Col md={10}>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            this.handleAcceptedFiles(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {this.state.uploaded_images.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        // alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Button
                                        className="btn-icon"
                                        color="danger"
                                        style={{ marginRight: 25 }}
                                        onClick={(e) => this.remove_image(f, i)}
                                      >
                                        {" "}
                                        <span className="btn-icon-label">
                                          <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                        </span>{" "}
                                        Remove
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                {/* <div>
                    <EcomPreview />
                  </div> */}
              </Col>
            </Row>
            <Row>
              <Col xl={3}>
                {this.state.data.length > 0 ? (
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"Blog.csv"}
                    className="btn btn-success"
                  >
                    Export Report in CSV
                  </CSVLink>
                ) : null}
              </Col>
            </Row>
            &nbsp;
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  <MDBDataTableV5
                    scrollX
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
