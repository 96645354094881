import React, { Component, useEffect, useState } from "react";
import Design_3 from "./design_splash";
import "./ind432a.css";

function Design3(props) {
  const [bgImage, setbgImage] = useState(props.bgImage);
  const [logo, setlogo] = useState(props.logo);
  const [title, settitle] = useState(props.title);
  const [state, setstate] = useState(props.state);
  const [styleVal, setstyleVal] = useState(props.valBR ? props.valBR : "");
  const [data, setdata] = useState(props.data);

  useEffect(() => {
    console.log("com bgImage", bgImage);
    console.log("com props.state", props.state);
  }, []);
  useEffect(() => {
    console.log("com bgImage", bgImage);
    console.log("com props.state", props.state);
  }, [props.state]);
  return (
    <div
      className="designed_area w-100 h-100 relative"
      style={{
        overflowY: "auto",
        borderRadius: styleVal,
        backgroundImage: `url(${props.bgImage})`,
      }}
    >
      <div className="d-flex w-100 h-100">
        <div className="my-auto mx-auto icon_contains" style={{
              background: props.data.iconbgcolor,
              width: "auto",
              maxWidth: "60%",
            }}>
          <div className="d-flex flex-wrap">
            {props.data.icons.map((item, index) => {
              return (
                <a
                  href="#"
                  className="icon_div"
                  style={{ backgroundColor: "#f1f5f7" }}
                >
                  {/* <i className="ri-facebook-box-fill icon_item"></i> */}
                  <img src={item.iconUrl} style={{ height: 40, width: 40 }} />
                </a>
              );
            })}
            
          </div>
        </div>
      </div>
    </div>
  );
}
export default Design3;
