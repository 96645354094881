import React, { Component } from "react";
import axios from "axios";

import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    FormGroup,
    Label,
    Input,
    CustomInput,
    Button,
    UncontrolledAlert,
    InputGroup,
    InputGroupAddon,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
// import EcomPreview from "./E-ComPreview/index";
import Select from "react-select";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import index from "../ChooseDesign";
import Dumy from "../../assets/images/dumy.png";
// import ElementMirror from "@fullcalendar/interaction/dnd/ElementMirror";
import { CSVLink } from "react-csv";

const header = [


    { label: "Name", key: "username" },
    { label: "Email", key: "email" },
    { label: "Mobile", key: "phone" },
    { label: "Report Against", key: "reportagainst" },
    { label: "Report Details", key: "report_details" },
    { label: "URL", key: "url" },
    { label: "Created On", key: "created_on" },



]


class FormElements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "All Grievance", link: "#" },
                { title: "Show All Grievance", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            // page states
            name: "",
            email: "",
            mobile: "",
            password: "",
            data: [],
            alert: false,
            message: "",
            type: "",
            result: [],
            from_date: "",
            to_date: "",

        };

    }

    componentDidMount = async () => {
        // this.checkUser();
        // this.fetchService();
        this.fetchData();

    };


    fetchData = async () => {
        let data = {
            // user_category: "User",
            datefrom: this.state.from_date,
            dateto: this.state.to_date,
          };

        let result = await HttpClient.requestData("grievance", "POST", data);
        console.log("grievance-----", result);
        if (result && result.status) {
            let data = [];
            let i = 1;
            let newArr = result.data.reverse();
            this.setState({ result: newArr });
            // console.log(this.state.pointarr);
            this.state.result.forEach((element, index) => {

                let rows = {
                    sl: i,
                    date: moment(element.date).format("DD/MM/YYYY"),
                    username: element.fullname,
                    email: element.email,
                    phone: element.phone,
                    reportagainst: element.report_against,
                    report_details: element.report_details,
                    created_on: moment(element.created_on).format("lll"),
                    url: element.url,
                    file: (
                        <a href={HttpClient.IMG_URL + element.file} target="_blank">
                        {/* <img
                          src={ element.file ? HttpClient.IMG_URL + element.file : Dumy}
                          alt="file"
                          width="100"
                          height="100"
                        /> */}
                        {element.file}
                        </a>
                      ),
                    // phone: element.phone,
                    // govt_name: element.govt_id_name,
                    // govt_id: element.govt_id,


                };
                i++;
                data.push(rows);
                // console.log("data", data);
            });
            this.setState({
                data: data,
            });
        } else {
            this.setState({
                data: [],
            });
        }


    };





    render() {
        const data = {
            columns: [
                {
                    label: "Sl.",
                    field: "sl",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Created On",
                    field: "created_on",
                    sort: "asc",
                    width: 270,
                },

                {
                    label: "Name",
                    field: "username",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Email",
                    field: "email",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Mobile",
                    field: "phone",
                    sort: "asc",
                    width: 270,
                },

                {
                    label: "Report Against",
                    field: "reportagainst",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Report Details",
                    field: "report_details",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Url",
                    field: "url",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "File",
                    field: "file",
                    sort: "asc",
                    width: 270,
                },
                // {
                //     label: "Date",
                //     field: "date",
                //     sort: "asc",
                //     width: 270,
                // },




                // {
                //     label: "Feedback Detail",
                //     field: "feedback_detail",
                //     sort: "asc",
                //     width: 270,
                // },


            ],
            rows: this.state.data,
        };
        return (
            <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {this.state.alert ? (
              <UncontrolledAlert
                color={this.state.type}
                className="alert-dismissible fade show"
                role="alert"
              >
                {this.state.type == "warning" ? (
                  <i className="mdi mdi-alert-outline mr-2"></i>
                ) : this.state.type == "success" ? (
                  <i className="mdi mdi-check-all mr-2"></i>
                ) : this.state.type == "danger" ? (
                  <i className="mdi mdi-block-helper mr-2"></i>
                ) : null}
                {this.state.message}
              </UncontrolledAlert>
            ) : null}
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        From Date
                      </Label>
                      <Col md={3}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.from_date}
                          id="example-text-input"
                          placeholder="Enter From Date"
                          onChange={(val) => {
                            this.setState({ from_date: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        To Date
                      </Label>
                      <Col md={3}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.to_date}
                          id="example-text-input"
                          placeholder="Enter To Date"
                          onChange={(val) => {
                            this.setState({
                              to_date: val.target.value,
                              enable: true,
                            });
                          }}
                        />
                      </Col>
                      <Col md={2}>
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.fetchData();
                          }}
                          disabled={!this.state.enable}
                        >
                          Search
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </Col>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={3}>
                {this.state.data.length > 0 ?
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"UserSearchList.csv"}
                    className="btn btn-success">
                    Export Report in CSV
                  </CSVLink> : null}
              </Col>
            </Row>&nbsp;
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                    scrollX
                    disableRetreatAfterSorting={true}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
        );
    }
}

export default FormElements;
