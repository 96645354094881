import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//Simple bar
import SimpleBar from "simplebar-react";

class RecentlyActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <Col lg={4}>
          <Card className="bg-primary">
            <CardBody>
              <div>
                <div
                  className="text-center"
                  onClick={() =>
                    (window.location.href =
                      "https://admin.astrophy.com/sellerlist")
                  }
                >
                  <p className="mb-2 text-white">Total Seller</p>
                  <h4 className="text-white">{this.props.totalseller}</h4>
                </div>
              </div>{" "}
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

export default RecentlyActivity;
