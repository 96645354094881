import React, { Component } from "react";
// MetisMenu
import MetisMenu from "metismenujs";
import { NavLink, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
//i18n
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: {},
    };
  }

  componentDidMount() {
    console.log("is called");
    this.initMenu();
    let user = reactLocalStorage.getObject("userData");
    this.setState({ userType: user });
    console.log("user", this.state.userType);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          {this.state.userType?.admin_type === "Admin" ? (
            <>
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title">{this.props.t("Menu")}</li>

                <li>
                  <NavLink to="/dashboard" className="waves-effect" activeClassName="active">
                    <i className="ri-dashboard-line"></i>

                    <span className="ml-1">{this.props.t("Dashboard")}</span>
                  </NavLink>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Sales Reports")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link  to="/orders_rev">
                        {this.props.t("Orders and Revenue")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/service-sales-report">
                        {this.props.t("Service Sales Report")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/sales-report">
                        {this.props.t("Product Sales Report")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/subrepo">
                        {this.props.t("Subscription Purchase History")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Query")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link  to="/user-querylist">
                        {this.props.t("User Query List")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/seller-querylist">
                        {this.props.t("Seller Query List")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Users Search")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link  to="/user-searchlist">
                        {this.props.t("User Search List")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Users")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link  to="/userlist">
                        {this.props.t("User List")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Sellers")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link  to="/approve-seller">
                        {this.props.t("Approve Seller")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/sellerlist">
                        {this.props.t("Seller List")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/topseller">
                        {this.props.t("Top Sellers")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/sellerbankdetails">
                        {this.props.t("Seller Bank Details")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/seller-priority">
                        {this.props.t("Manage Seller Priority")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Services")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link  to="/all-services">
                        {this.props.t("All services")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/allshop">
                        {this.props.t("All shop")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/servicebookinghistory">
                        {this.props.t("Service Booking History")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/service-refund-list">
                        {this.props.t("Service Refund List")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/seller-sellyesterday">
                        {this.props.t("Most Sold")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/servicerepo">
                        {this.props.t("Service Booking History by Date")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Products")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link  to="/product-add">
                        {this.props.t("Manage Product")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/orderHistory">
                        {this.props.t("Product Order History")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/new-product-order">
                        {this.props.t("New Product Order History")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/orderrepo">
                        {this.props.t("Product Order History By Date")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Coupons")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link  to="/manageCoupon">
                        {this.props.t("Manage Product Coupon")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/manageservCoupon">
                        {this.props.t("Manage Service Coupon")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Reviews")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link  to="/servicereviewlist">
                        {this.props.t("Service Review List")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/productreviewlist">
                        {this.props.t("Product Review List")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("CMS")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                  <li>
                      <NavLink activeClassName="active" to="/homebanner">
                        {this.props.t("Home Banner")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/aboutus">{this.props.t("About Us")}</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/about-faq">{this.props.t("About Faq")}</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/contact-info">{this.props.t("Contact Info")}</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/faq-cat">{this.props.t("FAQ Category")}</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/faq-subcat">
                        {this.props.t("FAQ Sub Category")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/faq">{this.props.t("FAQ")}</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/seller-faq">{this.props.t("Seller Faq")}</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/blog-category">{this.props.t("Blog Category")}</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/blog">{this.props.t("Blog")}</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/privacy">{this.props.t("Privacy")}</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/cookies">
                        {this.props.t("Cookies Policy")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/return">{this.props.t("Return Policy")}</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/condition">{this.props.t("Condition")}</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/safety-guide">
                        {this.props.t("Safety Guide")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/associates">
                        {this.props.t("Our Associated")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/achievement">{this.props.t("Achievements")}</NavLink>
                    </li>
                    {/* <li>
                      <NavLink activeClassName="active" to="/allreport">{this.props.t("All Report")}</NavLink>
                    </li> */}
                    {/* <li>
                      <NavLink activeClassName="active" to="/subscriber-list">{this.props.t("Subscriber List")}</NavLink>
                    </li> */}
                    {/* <li>
                      <NavLink activeClassName="active" to="/alllegalnotice">{this.props.t("All Legal Notice")}</NavLink>
                    </li> */}
                    {/* <li>
                      <NavLink activeClassName="active" to="/allgrievance">{this.props.t("All Grievance")}</NavLink>
                    </li> */}
                    
                    
                    
                    {/* <li>
                      <NavLink activeClassName="active" to="/customer-service">
                        {this.props.t("Customer Services")}
                      </NavLink>
                    </li> */}
                   
                    {/* <li>
                      <NavLink activeClassName="active" to="/feedback">{this.props.t("Feedback")}</NavLink>
                    </li> */}
                    {/* <li>
                      <NavLink activeClassName="active" to="/view-subscribe">
                        {this.props.t("View Subscribe")}
                      </NavLink>
                    </li> */}


                    {/* <li>
                      <NavLink  activeClassName="active"  to="/servicerepo">
                        {this.props.t("Service Booking History")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink  activeClassName="active"  to="/subrepo">
                        {this.props.t("Subscription Purchase History")}
                      </NavLink>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Forms")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link  to="/allreport">
                        {this.props.t("Report a Problem")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/subscriber-list">
                        {this.props.t("Subscriber List")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/alllegalnotice">
                        {this.props.t("All Legal Notice")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/allgrievance">
                        {this.props.t("All Grievance")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/customer-service">
                        {this.props.t("Customer Services")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/feedback">
                        {this.props.t("Feedback")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link className="has-arrow waves-effect">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Settings")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link  to="/processing-fee">
                        {this.props.t("Manage Processing Fee")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/subscription-plan">
                        {this.props.t("Manage Subscription")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/category">
                        {this.props.t("Manage Category")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/subservice-category">
                        {this.props.t("Manage Sub-Category")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/addcurrency">
                        {this.props.t("Manage Currency")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/refund-personnel">
                        {this.props.t("Personnel Register")}
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <li></li> */}
                {/* <li>
                  <NavLink activeClassName="active" to="/category" >
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Manage Category")}
                    </span>
                  </NavLink>
                </li> */}

                {/* <li>
                  <NavLink activeClassName="active" to="/service-category">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Services Category")}
                    </span>
                  </NavLink>
                </li> */}
                {/* <li>
                  <NavLink activeClassName="active" to="/subservice-category">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Manage Sub-Category")}
                    </span>
                  </NavLink>
                </li> */}
                {/* <li>
                  <NavLink activeClassName="active" to="/addcurrency">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Manage Currency")}
                    </span>
                  </NavLink>
                </li> */}

                {/* <li>
                  <NavLink activeClassName="active" to="/product-add">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Manage Product")}
                    </span>
                  </NavLink>
                </li> */}
                {/* <li>
                  <NavLink activeClassName="active" to="/allshop">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("All shop")}
                    </span>
                  </NavLink>
                </li> */}

                {/* <li>
                  <NavLink activeClassName="active" to="/all-services">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("All services")}
                    </span>
                  </NavLink>
                </li> */}

                {/* <li>
                  <NavLink activeClassName="active" to="/servicebookinghistory">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Service Booking History ")}
                    </span>
                  </NavLink>
                </li> */}
                {/* <li>
                  <NavLink activeClassName="active" to="/orderHistory">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Product Order History")}
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/new-product-order">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t(" New Product Order History")}
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/service-refund-list">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Service Refund List")}
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink activeClassName="active" to="/servicereviewlist">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Service Review List")}
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/productreviewlist">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Product Review List")}
                    </span>
                  </NavLink>
                </li>




                <li>
                  <NavLink activeClassName="active" to="/subscription-plan">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Manage Subscription")}
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink activeClassName="active" to="/manageCoupon">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Manage Product Coupon")}
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/manageservCoupon">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Manage Service Coupon")}
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/processing-fee">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Manage Processing Fee")}
                    </span>
                  </NavLink>
                </li> */}

                {/* <li>
                  <NavLink activeClassName="active" to="/subscription-history">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Subscription History")}
                    </span>
                  </NavLink>
                </li> */}
                {/* <li>
                  <NavLink activeClassName="active" to="/approve-seller">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Approve Seller")}
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink activeClassName="active" to="/sellerlist">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Seller List")}</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink activeClassName="active" to="/topseller">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Top Sellers")}</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink activeClassName="active" to="/seller-sellyesterday">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Most Sold")}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/sellerbankdetails">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Seller Bank Details")}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/sales-report">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Product Sales Report")}
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink activeClassName="active" to="/service-sales-report">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Service Sales Report")}
                    </span>
                  </NavLink>
                </li>


                <li>
                  <NavLink activeClassName="active" to="/seller-priority">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Manage Seller Priority")}
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/orders_rev">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Orders and Revenue")}
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink activeClassName="active" to="/userlist">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("User List")}</span>
                  </NavLink>
                </li> */}

                {/* <li>
                  <NavLink activeClassName="active" to="/contactlist">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Contact List")}</span>
                  </NavLink>
                </li> */}

                {/* <li>
                  <NavLink activeClassName="active" to="/user-querylist">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("User Query List")}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/seller-querylist">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Seller Query List")}</span>

                  </NavLink>
                </li> */}
                {/* <li>
                  <NavLink activeClassName="active" to="/ticket-raise">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Ticket Raise History")}
                    </span>
                  </NavLink>
                </li> */}

                {/* <li>
                  <NavLink activeClassName="active" to="/refund-personnel">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Personnel Register")}
                    </span>
                  </NavLink>
                </li> */}
                
                <li>
                  {/* <NavLink  activeClassName="active"  to="/approve-personnel">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Approve Personnel")}
                    </span>
                  </NavLink> */}
                </li>
                {/* <li>
                  <Link to="/chat-list">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Approved service by personnel")}</span>
                  </Link>
                </li> */}
                {/* <li>
                  <Link className="has-arrow waves-effect">
                    <i className="ri-mail-send-line"></i>
                    <span className="ml-1">{this.props.t("Reports")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link  to="/orderrepo">
                        {this.props.t("Product Order History")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/servicerepo">
                        {this.props.t("Service Booking History")}
                      </Link>
                    </li>
                    <li>
                      <Link  to="/subrepo">
                        {this.props.t("Subscription Purchase History")}
                      </Link>
                    </li>
                  </ul>
                </li> */}
               
              </ul>
            </>
          ) : (
            <>
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title">{this.props.t("Menu")}</li>

                <li>
                  <NavLink activeClassName="active" to="/dashboard" className="waves-effect">
                    <i className="ri-dashboard-line"></i>

                    <span className="ml-1">{this.props.t("Dashboard")}</span>
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink  activeClassName="active"  to="/refund-product-request">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Refund Product Request")}
                    </span>
                  </NavLink>
                </li> */}
                <li>
                  <NavLink activeClassName="active" to="/refund-service-request">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">
                      {this.props.t("Refund Service Request")}
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/chat-list">
                    <i className="ri-pencil-ruler-2-line"></i>
                    <span className="ml-1">{this.props.t("Approved service by personnel")}</span>
                  </NavLink>
                </li>
              </ul>
            </>
          )}
          {/* <li>
              <NavLink  activeClassName="active"  to="/#" className="has-arrow waves-effect">
                <i className="ri-slideshow-line"></i>
                <span className="ml-1">{this.props.t("Showroom")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink  activeClassName="active"  to="published-showroom">
                    {this.props.t("Published Showroom")}
                  </NavLink>
                </li>
                <li>
                  <NavLink  activeClassName="active"  to="proposed-showroom">
                    {this.props.t("Proposed Showroom")}
                  </NavLink>
                </li>
              </ul>
            </li> */}
          {/* <li>
              <NavLink  activeClassName="active"  to="/#" className="has-arrow waves-effect">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Design")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink  activeClassName="active"  to="startApp">{this.props.t("StartApp")}</NavLink>
                </li>
                <li>
                  <NavLink  activeClassName="active"  to="choose-design">
                    {this.props.t("Choose Your Design")}
                  </NavLink>
                </li>
                <li>
                  <NavLink  activeClassName="active"  to="create-app">{this.props.t("Create Your App")}</NavLink>
                </li>
                <li>
                  <NavLink  activeClassName="active"  to="welcome-screen">
                    {this.props.t("Welcome Screen")}
                  </NavLink>
                </li>
                <li>
                  <NavLink  activeClassName="active"  to="quizlist">{this.props.t("Gamify")}</NavLink>
                </li>
                <li>
                  <NavLink  activeClassName="active"  to="monitize">{this.props.t("Loyalty")}</NavLink>
                </li>
                <li>
                  <NavLink  activeClassName="active"  to="product-add">
                    {this.props.t("Products & Services")}
                  </NavLink>
                </li>
                <li>
                  <NavLink  activeClassName="active"  to="communicate">{this.props.t("Communicate")}</NavLink>
                </li>
                <li>
                  <NavLink  activeClassName="active"  to="user-chat">{this.props.t("User Chat")}</NavLink>
                </li>
              </ul>
            </li> */}
          {/* <li>
              <NavLink  activeClassName="active"  to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Category")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink  activeClassName="active"  to="/category">
                    {this.props.t("Add & Manage Category")}
                  </NavLink>
                </li>

              </ul>
            </li> */}

          {/* <li>
              <NavLink  activeClassName="active"  to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Sub Category")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink  activeClassName="active"  to="/sub-category">
                    {this.props.t("Add & Manage Sub Category")}
                  </NavLink>
                </li>

              </ul>
            </li> */}
          {/* <li>
              <NavLink  activeClassName="active"  to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Ecommerce")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink  activeClassName="active"  to="/category">
                    {this.props.t("Add & Manage Category")}
                  </NavLink>
                </li>
                <li>
                  <NavLink  activeClassName="active"  to="/sub-category">
                    {this.props.t("Add & Manage Sub Category")}
                  </NavLink>
                </li>

                <li>
                  <NavLink  activeClassName="active"  to="/product-add">
                    {this.props.t("Add & Manage Products & Services")}
                  </NavLink>
                </li>
                <li>
                  <NavLink  activeClassName="active"  to="/add-banner">
                    {this.props.t("Add & Manage Banner")}
                  </NavLink>
                </li>
              </ul>
              
            </li> */}

          {/* <li>
              <NavLink  activeClassName="active"  to="/quizlist">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Manage Question")}</span>
              </NavLink>
            </li>


            <li>
              <NavLink  activeClassName="active"  to="/manageprice">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Manage Price")}</span>
              </NavLink>
            </li> */}

          {/* <li>
              <NavLink  activeClassName="active"  to="/monitize">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Loyalty")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink  activeClassName="active"  to="/communicate">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Communicate")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink  activeClassName="active"  to="/know-your-customer">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">
                  {this.props.t("Know Your Customer")}
                </span>
              </NavLink>
            </li> */}
          {/* <li>
              <NavLink  activeClassName="active"  to="forum" className=" waves-effect">
                <i className="ri-calendar-2-line"></i>
                <span className="ml-1">{this.props.t("Forums")}</span>
              </NavLink>
            </li>
            <li>
              <NavLink  activeClassName="active"  to="subscrption" className=" waves-effect">
                <i className="ri-rss-fill"></i>
                <span className="ml-1">{this.props.t("Subscription")}</span>
              </NavLink>
            </li>

            <li>
              <NavLink  activeClassName="active"  to="directory" className=" waves-effect">
                <i className="ri-folder-user-line"></i>
                <span className="ml-1">{this.props.t("User Directory")}</span>
              </NavLink>
            </li> */}
          {/* <li>
              <NavLink  activeClassName="active"  to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Report")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink  activeClassName="active"  to="application">
                    {this.props.t("Product Order History")}
                  </NavLink>
                </li>
                <li>
                  <NavLink  activeClassName="active"  to="application">
                    {this.props.t("Service Booking")}
                  </NavLink>
                </li>
              </ul>
            </li> */}
          {/* <li>
              <NavLink  activeClassName="active"  to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Product")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink  activeClassName="active"  to="product-add">
                    {this.props.t("Add & Manage Product")}
                  </NavLink>
                </li>
              </ul>
            </li> */}
          {/* events */}

          {/* <li>
              <NavLink  activeClassName="active"  to="/#" className="has-arrow waves-effect">
                <i className="ri-folder-shared-fill"></i>
                <span className="ml-1">{this.props.t("Panels")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink  activeClassName="active"  to="share-panel">{this.props.t("Share Panel")}</NavLink>
                </li>
                <li>
                  <NavLink  activeClassName="active"  to="request-panel">
                    {this.props.t("Request Panel")}
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink  activeClassName="active"  to="/#" className="has-arrow waves-effect">
                <i className="ri-artboard-line"></i>
                <span className="ml-1">{this.props.t("Vision Board")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink  activeClassName="active"  to="timeline">
                    {this.props.t("Add & Manage Timeline")}
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink  activeClassName="active"  to="/#" className="has-arrow waves-effect">
                <i className="ri-message-2-line"></i>
                <span className="ml-1">{this.props.t("Chat")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink  activeClassName="active"  to="appowner-chat">
                    {this.props.t("App Owner Chat")}
                  </NavLink>
                </li>
              </ul>
            </li>  */}
          {/* <li className="menu-title">{this.props.t('Pages')}</li> */}

          {/* <li>
              <NavLink  activeClassName="active"  to="/#" className="has-arrow waves-effect">
                <i className="ri-share-line"></i>
                <span className="ml-1">{this.props.t("Multi Dropdown")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <NavLink  activeClassName="active"  to="/#">{this.props.t("Level 1.1")}</NavLink>
                </li>
                <li>
                  <NavLink  activeClassName="active"  to="/#" className="has-arrow">
                    {this.props.t("Level 1.2")}
                  </NavLink>
                  <ul className="sub-menu" aria-expanded="true">
                    <li>
                      <NavLink  activeClassName="active"  to="/#">{this.props.t("Level 2.1")}</NavLink>
                    </li>
                    <li>
                      <NavLink  activeClassName="active"  to="/#">{this.props.t("Level 2.2")}</NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> */}
          {/* <li className="menu-title">{this.props.t("OTT Menu")}</li> */}

          {/* <li>
              <NavLink  activeClassName="active"  to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("User")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="User">
                <li>
                  <NavLink  activeClassName="active"  to="/users">
                    {this.props.t("Manage User")}
                  </NavLink>
                </li>
                <li>
                  <NavLink  activeClassName="active"  to="/user-subscription">
                    {this.props.t("User Subscriotion")}
                  </NavLink>
                </li>
                
              </ul>
            </li>

            <li>
              <NavLink  activeClassName="active"  to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Types")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink  activeClassName="active"  to="/types">{this.props.t("Add & Manage Types")}</NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink  activeClassName="active"  to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Category")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink  activeClassName="active"  to="/jd3category">
                    {this.props.t("Add & Manage Category")}
                  </NavLink>
                </li>
              </ul>
            </li>

            <li>
              <NavLink  activeClassName="active"  to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Genre")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink  activeClassName="active"  to="/genre">{this.props.t("Add & Manage Genre")}</NavLink>
                </li>
              </ul>
            </li>

            <li>
              <NavLink  activeClassName="active"  to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Banner")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="Banner">
                <li>
                  <NavLink  activeClassName="active"  to="/banner">
                    {this.props.t("Add & Manage Banner")}
                  </NavLink>
                </li>
              </ul>
            </li>

            

            <li>
              <NavLink  activeClassName="active"  to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Add Videos")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="Videos">
                <li>
                  <NavLink  activeClassName="active"  to="/videos">
                    {this.props.t("Add & Manage Videos")}
                  </NavLink>
                </li>
              </ul>
            </li> */}

          {/* <li>
              <NavLink  activeClassName="active"  to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Series")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="Series">
                <li>
                  <NavLink  activeClassName="active"  to="/series">
                    {this.props.t("Add & Manage Series")}
                  </NavLink>
                </li>
              </ul>
            </li>

            <li>
              <NavLink  activeClassName="active"  to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Tv Shows")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="Tv Shows">
                <li>
                  <NavLink  activeClassName="active"  to="/tv-shows">
                    {this.props.t("Add & Manage Tv Shows")}
                  </NavLink>
                </li>
              </ul>
            </li>

            <li>
              <NavLink  activeClassName="active"  to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Events")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="Events">
                <li>
                  <NavLink  activeClassName="active"  to="/training">
                    {this.props.t("Add & Manage Events")}
                  </NavLink>
                </li>
              </ul>
            </li> */}

          {/* <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{this.props.t("JD3 Menu")}</li>
            
          </ul> */}
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withNamespaces()(SidebarContent))
);
