import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ReactLoading from "react-loading";
import "../../App.css"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  // Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import { CSVLink } from "react-csv";
import HttpClient from "./../../utils/HttpClient";
import index from "../ChooseDesign";
class FormElements extends Component {
  fileObj = [];
  fileArray = [];

  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: " New Product Order History", link: "#" },
        { title: "View New Product Order History", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      image_select: false,
      img_url: "",
      title: "",
      points: [""],
      id: "",
      duration: 0,
      seller_comission: "",
      type: "",
      desc: "",
      modal: false,
      arr: [],
      order: "",
      result1: [],
      cart: [],
      filter: "",
      ismodalopen: false,
      loading: false,
      activeid: {},
    };
    this.toggle = this.toggle.bind(this);
    this.handleDefault = this.handleDefault.bind(this);
  }

  onCloseCallBack = (val) => {
    console.log("object", val);
    this.setState({
      ismodalopen: val,
    });
  };
  handleChange = async (e) => {
    console.log(this.state.data);
    console.log(this.state.filter);
    let cart = this.state.data.filter(
      (item) => item.cart_data == this.state.filter
    );
    console.log(cart, "x");
    this.state.cart = cart;
    this.setState({});
  };
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  componentDidMount = async () => {
    this.checkUser();
    this.fetchData();
    console.log("pqr", this.state.result1);
  };

  handleDefault(date) {
    this.setState({ default_date: date });
  }

  fetchData = async () => {
    this.state.loading = true;
    this.setState({});
    let result = await HttpClient.requestData("newOrderlist", "GET");
    console.log("newOrderlist", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.state.loading = false;
      this.setState({});
      this.setState({ result: result.data });

      this.setState({ result1: result.user_data });

      this.state.result.forEach((element, index) => {
        let fname = element.firstname;
        let lname = element.lastname;
        let rows = {
          sl: i,
          date: moment(element.booking_date).format("MMMM Do YYYY"),

          order_id: element.order_id,

          user: fname + " " + lname,
          currency: element.currency ? element.currency : "",
          email: element.email ? element.email : "",
          price: "₹" + element.total_inr.toFixed(2),
          delivery_amount: element.cart_data.length ? element.cart_data[0].product_data[0].delivery_amount : 0,
          coupon_discount: element.coupon ? element.coupon.discount_value : 0,
          status:
            element.order_status == "processing" ? (
              // <button title="Processing" className="btn btn-secondary">
              //   Processing
              // </button>
              <h6 style={{color: "#007BFF"}}>Processing</h6>
              // Processing
            ) : element.order_status == "shipped" ? (
              // <button title="shipped" className="btn btn-success mr-2">
              //   Shipped
              // </button>
              <h6 style={{color: "#28A745"}}>Shipped</h6> 
            ) : element.order_status == "cancel" ? (
              // <button title="cancel" className="btn btn-danger mr-2">
              //   Cancelled
              // </button>
              <h6 style={{color: "#DC3545"}}>Cancelled</h6>
            ) : null,
          shipped: (
            <button
              title="Shipped"
              className="btn btn-primary"
              onClick={() => this.shipped(element)}
            >
              Shipped
            </button>
          ),
          cancel: (
            <button
              title="Cancel"
              className="btn btn-danger mr-2"
              onClick={() => this.cancel(element)}
            >
              Cancel
            </button>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  shipped = async (item, index) => {
    let sendData = {
      id: item._id,
      order_status: "shipped",
      email: item.email,
    };
    console.log("sendData",sendData);
    let result = await HttpClient.requestData(
      "setOrderStatus",
      "POST",
      sendData
    );
    console.log("status",result);
    if (result && result.status) {
      this.setState({
        alert: true,
        message: "Status Updated Successfully",
        type: "success",
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };
  cancel = async (item, index) => {
    let sendData = {
      id: item._id,
      order_status: "cancel",
      email: item.email,
    };
    let result = await HttpClient.requestData(
      "setOrderStatus",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.setState({
        alert: true,
        message: "Status Updated Successfully",
        type: "success",
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };

  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    console.log(sendData);
    let result = await HttpClient.requestData(
      "admin/ecom_banner/delete",
      "POST",
      sendData
    );
    console.log(result);
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Succfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };

  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/ecom_banner/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Succfully",
        type: "success",
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };
  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          width: 150,
        },
        {
          label: "Order ID",
          field: "order_id",
          width: 270,
        },
        {
          label: "User Name",
          field: "user",
          width: 270,
        },
        {
          label: "Email",
          field: "email",
          width: 270,
        },
        {
          label: "Order Date",
          field: "date",
          width: 270,
        },

        
        {
          label: "Product Currency",
          field: "currency",
          width: 270,
        },
        {
          label: "Price of Order",
          field: "price",

          width: 270,
        },
        {
          label: "Delivery Amount",
          field: "delivery_amount",

          width: 270,
        },
        {
          label: "Coupon Discount",
          field: "coupon_discount",

          width: 270,
        },
        {
          label: "Status",
          field: "status",
          width: 100,
        },
        {
          label: "Shipped",
          field: "shipped",
          width: 100,
        },
        {
          label: "Cancel",
          field: "cancel",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    const header = [
      { label: "Sl.", key: "sl" },
      { label: "User Name", key: "user" },
      { label: "Email", key: "email" },
      { label: "Phone", key: "phone" },
      { label: "Order Date", key: "date" },
      { label: "Order ID", key: "order_id" },
      { label: "Currency", key: "currency" },
      { label: "Item Total", key: "price" },
      { label: "Delivery Amount", key: "delivery_amount" },
      { label: "Coupon Discount", key: "coupon_discount" },
    ];
    return (
      <React.Fragment>
        {this.state.loading ? (
          <ReactLoading
          type="spokes"
          color="black"
          height={"5%"}
          width={"5%"}
          className="loader"
        />
        ) : (
           <div className="page-content">
           <Container fluid>
             <Breadcrumbs
               title={this.state.breadcrumbItems[0].title}
               breadcrumbItems={this.state.breadcrumbItems}
             />
             {/* <Row>
               <Col xs={12}>
                 <Card>
                   <CardBody></CardBody>
                 </Card>
               </Col>
             </Row> */}
             <Row>
               <Col xl={3}>
                 {this.state.data.length > 0 ? (
                   <CSVLink
                     data={this.state.data}
                     headers={header}
                     filename={"OrderHistory.csv"}
                     className="btn btn-success"
                   >
                     Export Report in CSV
                   </CSVLink>
                 ) : null}
               </Col>
             </Row>
             &nbsp;
             <Row>
               <Col xl={12}>
                 <div className="card p-3">
                   {/* <MDBDataTable responsive bordered data={data} /> */}
                   <MDBDataTableV5
                     hover
                     entriesOptions={[5, 20, 25]}
                     entries={5}
                     pagesAmount={4}
                     data={data}
                     scrollX
                     disableRetreatAfterSorting={true}
                   />
                 </div>
               </Col>
             </Row>
           </Container>
         </div>
        )}
       
      </React.Fragment>
    );
  }
}

export default FormElements;
