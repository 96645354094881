import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import TimePicker from "react-time-picker";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import Select from "react-select";

class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Timeline", link: "#" },
        { title: "Add & Manage Timeline", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      default_date: new Date(),
      details: "",
      link: "",
      price: "",
      optionGroup: [],
      selectedGroup: "",
      fileType: "",
      event_id: "",
      time: new Date(),
    };
    this.handleDefault = this.handleDefault.bind(this);
    this.handleSelectGroup = this.handleSelectGroup.bind(this);
  }

  componentDidMount = async () => {
    this.checkUser();
  };

  handleDefault(date) {
    this.setState({ default_date: date });
  }

  handleSelectGroup = (selectedGroup) => {
    this.setState({ selectedGroup, event_id: selectedGroup.value });
  };

  fetchEvents = async () => {
    let result = await HttpClient.requestData(
      "app-owner/event/all-events",
      "POST",
      { owner_id: Number(this.state.userId) }
    );
    if (result && result.status > 0) {
      let optionAppowner = {};
      optionAppowner.label = "All Events";
      optionAppowner.options = [];
      result.data.map((item, index) => {
        optionAppowner.options.push({
          label: item.name,
          value: item.id,
          owner_id: item.owner_id,
        });
      });

      console.log("optionAppowner", optionAppowner);
      let newOptions = [];
      newOptions.push(optionAppowner);
      this.setState({ optionGroup: newOptions });
    } else {
      this.setState({});
    }
  };

  fetchData = async () => {
    let result = await HttpClient.requestData(
      "app-owner/timeline/all-timeline",
      "POST",
      { owner_id: Number(this.state.userId) }
    );
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,
          name: element.name,
          details: element.details,
          date: moment(element.date).format('LL'),
          time: moment(element.time,"h:mm").format('h:mm:ss A'),
          img: (
            <div>
              <img
                src={element.img_url}
                alt="images"
                className="rounded avatar-md card-img"
              />
            </div>
          ),
          status: (
            <button
              title="Delete"
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                this.status(element, index);
              }}
            >
              {element.status ? "Active" : "Not Active"}
            </button>
          ),
          action: (
            <>
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData(
      "app-owner/advertise/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Succfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {

    this.setState({
      edit_enable: true,
      name: item.name,
      details: item.details,
      date: new Date(item.default_date),
      time:item.time,
      edit_item_id: item._id,
      fileType: item.fileType,
      img_url: item.img_url,
      image_select: true,
      event_id: item.event_id,
    });
  };
  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "app-owner/advertise/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Succfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };
  submit = async () => {
    if (
      this.state.name != "" &&
      this.state.img_url != "" &&
      this.state.default_date != "" &&
      this.state.time != "" &&
      this.state.fileType != "" &&
      this.state.image_select
    ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          name: this.state.name,
          details: this.state.details,
          date: this.state.default_date,
          time: this.state.time,

          fileType: this.state.fileType,
          owner_id: this.state.userId,
          img_url: this.state.img_url,
        };
        console.log("data", data);
        console.log("selectedGroup", this.state.selectedGroup);
        // return false;
        result = await HttpClient.requestData(
          "app-owner/timeline",
          "POST",
          data
        );
      } else {
        data = {
          name: this.state.name,
          details: this.state.details,
          date: this.state.default_date,
          time: this.state.time,
          fileType: this.state.fileType,
          owner_id: this.state.userId,
          img_url: this.state.img_url,
          edit_id: this.state.edit_item_id,
        };
        result = await HttpClient.requestData(
          "app-owner/timeline/update",
          "POST",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Timeline Updated Successfully"
            : "Timeline Added Successfully",
          type: "success",
          name: "",
          img_url: "",
          image_select: false,
          edit_enable: false,
          edit_item_id: "",
          selectedGroup: "",
          fileType: "",
          time:new Date(),
          default_date: new Date(),
          details:''
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (e) => {
    let file = e.target.files[0].type;
    let fileType = file.split("/")[0];
    // fileType = fileType[0];
    console.log("e", file.split("/")[0]);

    if (fileType == "image" || fileType == "video") {
      // return false;
      this.setState({ fileType: fileType });
      let dataSend = { buckate: true, buckate_name: "appIcon" };

      let result = await HttpClient.newFileUpload(
        "image-upload/timeline",
        e.target.files[0],
        dataSend
      );
      console.log("result", result);
      if (result && result.status) {
        this.setState({ img_url: result.url, image_select: true });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
    this.fetchEvents();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Title",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
            label: "Details",
            field: "details",
            sort: "asc",
            width: 270,
          },
          {
            label: "Date",
            field: "date",
            sort: "asc",
            width: 270,
          },
          {
            label: "Time",
            field: "time",
            sort: "asc",
            width: 270,
          },
        {
          label: "Image",
          field: "img",
          sort: "asc",
          width: 270,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Title
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.name}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ name: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Description
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="textarea"
                          value={this.state.details}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ details: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Date
                      </Label>
                      <Col md={4}>
                        <InputGroup>
                          <DatePicker
                            className="form-control"
                            selected={this.state.default_date}
                            onChange={this.handleDefault}
                          />
                        </InputGroup>
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Time
                      </Label>
                      <Col md={4}>
                        <InputGroup>
                          {/* <DatePicker
                            className="form-control"
                            selected={this.state.default_date}
                            onChange={this.handleDefault}
                          /> */}
                          <TimePicker
                            onChange={(val) => {
                              console.log("val", val);
                              if (val == null) {
                              } else {
                                this.setState({ time: val });
                              }
                            }}
                            value={this.state.time}
                            className="form-control"
                            name="Enter Time"
                          />
                        </InputGroup>
                      </Col>
                    </FormGroup>

                    {/* <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Event
                      </Label>
                      <Col md={10}>
                        <Select
                          value={this.state.selectedGroup}
                          onChange={this.handleSelectGroup}
                          options={this.state.optionGroup}
                          classNamePrefix="select2-selection"
                        />
                      </Col>
                    </FormGroup> */}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Image / Video
                      </Label>
                      <Col md={10}>
                        {this.state.image_select ? (
                          <div>
                            <img
                              src={this.state.img_url}
                              alt="images"
                              className="rounded avatar-md card-img"
                            />
                          </div>
                        ) : null}
                        <Button
                          color="danger"
                          type="button"
                          className="waves-effect waves-light customUploadBtn mr-1"
                        >
                          <i className="ri-add-fill align-middle mr-1"></i> Add
                          file
                          <input
                            type="file"
                            className="upload_input"
                            onChange={(e) => {
                              this.imageUpload(e);
                            }}
                          />
                        </Button>
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
