import React, { Component } from "react";
import "./myLoader.css";

export default class MyLoader extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="my_Loader">
        <div className="load_content">
          <div className="loading"></div>
          <h5 className="loadText">
            " {this.props.loader_text ? this.props.loader_text : "Hello World"}{" "}
            "
          </h5>
        </div>
      </div>
    );
  }
}
