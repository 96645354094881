import React, { Component ,useEffect,useState} from "react";
import "./ind432a.css";

function DesignSplash(props) {
//   constructor(props) {
//     super(props);

//     this.state = {
//       styleVal: this.props.valBR ? this.props.valBR : "",
//       logo: this.props.logo,
//       bgImage: this.props.bgImage,
//       title: this.props.title,
//     };
//   }

//   render() {
    // const { styleVal } = this.state;
    const [bgImage,setbgImage] = useState(props.bgImage);
    const [logo,setlogo] = useState(props.logo);
    const [title,settitle] = useState(props.title);
    const [state,setstate] = useState(props.state);
    const [styleVal,setstyleVal] = useState(props.valBR?props.valBR:"");

    useEffect(()=>{
        console.log('com bgImage',bgImage)
        console.log('com props.state',props.state)
    },[])
    useEffect(()=>{
        console.log('com bgImage',bgImage)
        console.log('com props.state',props.fontFamily)
    },[props])

    return (
      <div
        className="designed_area w-100 h-100 relative"
        style={{
          overflowY: "auto",
          borderRadius: styleVal,
          backgroundImage: `url(${bgImage})`,
        }}
      >
        <div className="d-flex flex-column justify-content-end h-100 px-3">
          <div className="text-center home_logo">
            <img src={logo} alt="logo" className="img-fluid" />
            <h3 className="text-light mt-3" style={{
              fontSize:props.fontValue,
              fontFamily:props.fontFamily
            }}>{title}</h3>
          </div>
        </div>
      </div>
    );
  }
// }
export default DesignSplash