import React, { Component, useRef } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
// import Details from "./details";
import { confirmAlert } from "react-confirm-alert";
import ReactLoading from "react-loading";
import "../../App.css";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  // Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "../../utils/HttpClient";
import { CSVLink } from "react-csv";
import index from "../ChooseDesign";
import { useState, useEffect } from "react";

const ShowMore = ({ data }) => {
  const [show, setShow] = useState(true)
  
  // let data = "fglk;hjfdgl;khjfdglikjhcccccccccccccccccccccccc"
 
  useEffect(() => {
    window.scrollTo(0, 0)
    console.log(show);
  }, [show])
  return (
    <>
      {data.length > 20 ?
        <div>
          {show == true ?
            <>

              {data.slice(0, 20)} <button onClick={() => setShow(false)} className="btn btn-success btn-sm">Read More</button>
            </> : <>
              {data}
            </>}
        </div> :
        <div>
          {data}
        </div>
      }</>
  )
}
class FormElements extends Component {
  fileObj = [];
  fileArray = [];

  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "All Shop", link: "#" },
        { title: "View All Shop", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      image_select: false,
      img_url: "",
      title: "",
      points: [""],
      id: "",
      duration: 0,
      seller_comission: "",
      type: "",
      desc: "",
      modal: false,
      arr: [],
      order: "",
      result1: [],
      cart: [],
      filter: "",
      ismodalopen: false,
      activeid: {},
      categoryAll: [],
      subcatarr: [],
      loading: false,
      from_date: "",
      to_date: "",
    };
  }

  componentDidMount = async () => {
    this.fetchData();
  };

  fetchData = async (id) => {
    let data = {
      datefrom: this.state.from_date,
      dateto: this.state.to_date,
    };
    this.state.loading = true;
    this.setState({});
console.log("datesend",data);
    let result = await HttpClient.requestData("shop", "POST",data);
    console.log("abc", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      this.state.loading = false;
      this.setState({});
      this.setState({ result: result.data });

      this.state.result.forEach((element, index) => {
        let fname = element.user_data && element.user_data.firstName;
        let lname = element.user_data && element.user_data.lastName;
        let rows = {
          sl: i,

          date: moment(element.created_on).format("MMMM Do YYYY"),

          username: fname + " " + lname,
          email: element.user_data ? element.user_data.email : "",
          date: moment(element.start).format("MMMM Do YYYY"),
          name: element.name,
          title: element.title,
          totalshopvisit: element.totalShopVisit,
          totalsale:
            (element.user_data
              ? element.user_data.hasOwnProperty("currency") &&
                element.user_data.currency == "INR"
                ? "₹"
                : element.user_data.hasOwnProperty("currency") &&
                  element.user_data.currency == "CAD"
                  ? "$"
                  : element.user_data.hasOwnProperty("currency") &&
                    element.user_data.currency == "USD"
                    ? "$"
                    : element.user_data.hasOwnProperty("currency") &&
                      element.user_data.currency == "	GBP"
                      ? "£"
                      : element.user_data.hasOwnProperty("currency") &&
                        element.user_data.currency == "EUR"
                        ? "€"
                        : ""
              : "") + element.totalShopSales,

          shopimage:
            element.shop_img != "" ? (
              <img
                src={HttpClient.IMG_URL + element.shop_img}
                alt="img"
                style={{ width: "100px", height: "100px", borderRadius: "6px" }}
              />
            ) : null,
          bannerimage:
            element.banner_img != "" ? (
              <img
                src={HttpClient.IMG_URL + element.banner_img}
                alt="img"
                style={{ width: "100px", height: "100px", borderRadius: "6px" }}
              />
            ) : null,
          desc: <ShowMore data={element.description} />,

          status: (
            <button
              title={
                element.status
                  ? "Deactivate(it will temporary suspend the seller shop)"
                  : "activate"
              }
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                if (element.status) {
                  this.deactivatestatus(element._id);
                } else {
                  this.activatestatus(element._id);
                }
              }}
            >
              {element.status ? "Activated" : "Dectivated"}
            </button>
          ),
          action: (
            <>
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>
            </>
          ),
          view: (
            <button
              title="View Details"
              className="btn btn-success mr-2"
              onClick={() => {
                this.setState({
                  activeid: element,
                });
                setTimeout(() => {
                  this.setState({
                    ismodalopen: true,
                    idneed: element._id,
                  });
                }, 200);
              }}
            >
              View Details
            </button>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteapi(item),
        },
        {
          label: "No",
          // onClick: () => alert("Click No"),
        },
      ],
    });
  };

  deleteapi = async (item) => {
    let result = await HttpClient.requestData("shop/" + item._id, "DELETE");
    console.log(result);
    if (result && result.status) {
      this.setState({
        alert: true,
        message: "Shop Deleted Successfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      title: item.name,
      price: item.price,
      edit_item_id: item._id,
      seller_comission: item.seller_comission,
      duration: item.duration,
      id: item.id,
      type: item.type,
    });
  };
  deactivatestatus = async (id) => {
    console.log("id", id);
    let result = await HttpClient.requestData("shop-deactivate/" + id, "PUT");
    if (result && result.status) {
      console.log(result);
      this.setState({
        alert: true,
        message: "Status Updated Successfully",
        type: "success",
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };

  activatestatus = async (id) => {
    console.log("id", id);
    let result = await HttpClient.requestData("shop-activate/" + id, "PUT");
    if (result && result.status) {
      console.log(result);
      this.setState({
        alert: true,
        message: "Status Updated Successfully",
        type: "success",
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };

  onCloseCallBack = (val) => {
    console.log("object", val);
    this.setState({
      ismodalopen: val,
    });
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          width: 100,
        },
        {
          label: "User Name",
          field: "username",
          width: 200,
        },
        {
          label: "Email",
          field: "email",
          width: 200,
        },

        {
          label: "Shop Name",
          field: "name",
          width: 200,
        },
        {
          label: "Shop Title",
          field: "title",
          width: 200,
        },
        {
          label: "Shop Image",
          field: "shopimage",
          width: 200,
        },
        {
          label: "Banner Image",
          field: "bannerimage",
          width: 200,
        },
        {
          label: "Total Shop View",
          field: "totalshopvisit",
          width: 200,
        },
        {
          label: "Total Sale",
          field: "totalsale",
          width: 200,
        },
        {
          label: "Date",
          field: "date",
          width: 200,
        },

        {
          label: "Description",
          field: "desc",
          width: 200,
        },
        {
          label: "Status",
          field: "status",

          width: 100,
        },
        {
          label: "Action",
          field: "action",
          width: 100,
        },

        // {
        //     label: "View Details",
        //     field: "view",
        //     width: 100,
        // },
      ],
      rows: this.state.data,
    };

    const header = [
      { label: "Sl.", key: "sl" },
      { label: "Shop Name", key: "name" },
      { label: "Shop Title", key: "title" },
      { label: "Shop Image", key: "shopimage" },
      { label: "Banner Image", key: "bannerimag" },
      { label: "Description", key: "desc" },
      { label: "Details", key: "details" },
      { label: "Pageview", key: "pageview" },
      { label: "Hashtag", key: "hashtag" },
      { label: "Personalize", key: "personalize" },
      { label: "Date", key: "date" },
    ];

    const handlePageChange =()=>{
      console.log("page change");
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {this.state.alert ? (
              <UncontrolledAlert
                color={this.state.type}
                className="alert-dismissible fade show"
                role="alert"
              >
                {this.state.type == "warning" ? (
                  <i className="mdi mdi-alert-outline mr-2"></i>
                ) : this.state.type == "success" ? (
                  <i className="mdi mdi-check-all mr-2"></i>
                ) : this.state.type == "danger" ? (
                  <i className="mdi mdi-block-helper mr-2"></i>
                ) : null}
                {this.state.message}
              </UncontrolledAlert>
            ) : null}
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        From Date
                      </Label>
                      <Col md={3}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.from_date}
                          id="example-text-input"
                          placeholder="Enter From Date"
                          onChange={(val) => {
                            this.setState({ from_date: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        To Date
                      </Label>
                      <Col md={3}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.to_date}
                          id="example-text-input"
                          placeholder="Enter To Date"
                          onChange={(val) => {
                            this.setState({
                              to_date: val.target.value,
                              enable: true,
                            });
                          }}
                        />
                      </Col>
                      <Col md={2}>
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.fetchData();
                          }}
                          disabled={!this.state.enable}
                        >
                          Search
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </Col>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={3}>
                {this.state.data.length > 0 ?
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"UserSearchList.csv"}
                    className="btn btn-success">
                    Export Report in CSV
                  </CSVLink> : null}
              </Col>
            </Row>&nbsp;
            <Row >
              <Col xl={12}>
                <div className="card p-3">
                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  <MDBDataTableV5
                    hover
                    onPageChange={handlePageChange}
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                    scrollX
                    scrollY
                    disableRetreatAfterSorting={true}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
