import React, { useState, useCallback } from "react";
import ReactDOM from "react-dom";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./canvasUtil";
import "./imgCrop.css";

export default function ImgCrop(props) {
  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [seletedFile, setseletedFile ]= useState(null);


  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );
      console.log("donee", { croppedImage });
      setCroppedImage(croppedImage);
      // props.callBackCrop(false, props.id, croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, rotation]);

  const saveCroppedImg = (croppedImage) => {
    // let croppedImage = croppedImage;
    props.callBackCrop(false, props.id, croppedImage,seletedFile);
  };

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
      setseletedFile(file);
    }
  };
  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
      console.log(file, "agsfhjagsjdhagsdjads");
    });
  };

  return (
    <div className="modal-content">
      <div className="modal-header py-2">
        <h5 className="modal-title">Choose Logo</h5>
      </div>
      <div className="modal-body">
        <div>
          {imageSrc ? (
            <div>
              <h6 className="mb-3">
                ZOOM-IN, ZOOM-OUT, MOVE or CROP to resize your image
              </h6>
              <div className="imageCrop mb-3">
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  rotation={rotation}
                  zoom={zoom}
                  aspect={4 / 4}
                  onCropChange={setCrop}
                  onRotationChange={setRotation}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
                <div className="imgPrev">
                  <img
                    src={croppedImage}
                    alt="img"
                    onClose={onClose}
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div className="imgUpload text-left">
            <input type="file" onChange={onFileChange} accept="image/*" />
          </div>
        </div>
      </div>
      {imageSrc ? (
        <div className="modal-footer py-2">
          <button onClick={showCroppedImage} className="btn btn-primary">
            Crop Image
          </button>
          <button
            onClick={() => saveCroppedImg(croppedImage)}
            className="btn btn-success"
          >
            Save Changes
          </button>
        </div>
      ) : null}
    </div>
  );
}
