import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Details from "./details";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  // Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "../../utils/HttpClient";
import { CSVLink } from "react-csv";
import index from "../ChooseDesign";
class FormElements extends Component {
  fileObj = [];
  fileArray = [];

  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "All Services", link: "#" },
        { title: "View All Services", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      image_select: false,
      img_url: "",
      title: "",
      points: [""],
      id: "",
      duration: 0,
      seller_comission: "",
      type: "",
      desc: "",
      modal: false,
      arr: [],
      order: "",
      result1: [],
      cart: [],
      filter: "",
      ismodalopen: false,
      activeid: {},
      categoryAll: [],
      subcatarr: [],
    };
  }

  componentDidMount = async () => {
    this.fetchCat();
    this.fetchData();
  };

  fetchCat = async () => {
    let result = await HttpClient.requestData("category/Category", "GET");
    if (result && result.status > 0) {
      this.setState({ categoryAll: result.data });
    }
  };
  handleChange1 = async (event) => {
    this.setState({ cat_id: event.target.value });

    this.fetchsubcat(event.target.value);
  };

  fetchsubcat = async (id) => {
    console.log("id", id);
    let result = await HttpClient.requestData("service/" + id, "GET");
    console.log("subCat", result);
    if (result && result.status) {
      this.setState({ result: result.data });
      let arr = [];
      this.state.result.forEach((element, index) => {
        let dom = {
          id: element._id,
          name: element.name,
        };
        arr.push(dom);
        console.log("arr", arr);
      });
      this.setState({
        subcatarr: arr,
      });
    }
  };
  handleChange2 = async (event) => {
    this.setState({ subcat_id: event.target.value });

    this.fetchData(event.target.value);
  };

  fetchData = async (id) => {
    let data = {
      subcat_id: id,
    };
    console.log("data", data);
    let result = await HttpClient.requestData(
      "services-by-category",
      "POST",
      data
    );
    console.log("abc", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      // console.log("xyz", this.state.result);
      this.setState({ result1: result.user_data });
      // console.log("xyz", this.state.result1);

      // console.log(this.state.pointarr);
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,

          date: moment(element.created_on).format("MMMM Do YYYY"),

          catname: element.cat_name,
          subcatname: element.subcat_name,
          servname: element.name,
          serviceimage:
            element.image != null && element.image.length > 0 ? (
              <img
                src={HttpClient.IMG_URL + element.image[0]}
                alt="img"
                style={{ width: "100px", height: "100px", borderRadius: "6px" }}
              />
            ) : null,
          price: "₹" + element.price.toFixed(2),
          shopname: element.shop_data ? element.shop_data.name : "",
          pageview: element.pageViews,
          details: element.details,
          hashtag: element.hashtags,
          personalize: element.personalization,
          totalSalesValue: element.totalSalesValue,
          status: (
            <button
              title={element.status ? "Deactivate" : "activate"}
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                if (element.status) {
                  this.deactivatestatus(element._id);
                } else {
                  this.activatestatus(element._id);
                }
              }}
            >
              {element.status ? "Activated" : "Dectivated"}
            </button>
          ),
          view: (
            <button
              title="View Details"
              className="btn btn-success mr-2"
              onClick={() => {
                this.setState({
                  activeid: element,
                });
                setTimeout(() => {
                  this.setState({
                    ismodalopen: true,
                    idneed: element._id,
                  });
                }, 200);
              }}
            >
              View Details
            </button>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    console.log(sendData);
    let result = await HttpClient.requestData(
      "admin/ecom_banner/delete",
      "POST",
      sendData
    );
    console.log(result);
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Succfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      title: item.name,
      price: item.price,
      edit_item_id: item._id,
      seller_comission: item.seller_comission,
      duration: item.duration,
      id: item.id,
      type: item.type,
    });
  };
  deactivatestatus = async (id) => {
    let result = await HttpClient.requestData(
      "shop-service-deactivate/" + id,
      "PUT"
    );
    if (result && result.status) {
      this.setState({
        alert: true,
        message: "Status Updated Successfully",
        type: "success",
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData(this.state.subcat_id);
    }
  };

  activatestatus = async (id) => {
    let result = await HttpClient.requestData(
      "shop-service-activate/" + id,
      "PUT"
    );
    if (result && result.status) {
      this.setState({
        alert: true,
        message: "Status Updated Successfully",
        type: "success",
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData(this.state.subcat_id);
    }
  };

  submit = async () => {
    if (
      this.state.title != "" &&
      this.state.price != "" &&
      this.state.duration != "" &&
      this.state.seller_comission != "" &&
      this.state.type != ""
    ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          name: this.state.title,
          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData("subscription", "POST", data);
      } else {
        data = {
          name: this.state.title,
          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData(
          "subscription/" + this.state.edit_item_id,
          "PUT",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Subscription Updated Successfully"
            : "Subscription Added Succfully",

          type: "success",

          image_select: false,
          img_url: "",
          title: "",
          type: "",
          seller_comission: "",
          duration: "",
          price: "",
          desc: "",
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (e) => {
    let file = e.target.files[0].type;
    let fileType = file.split("/")[0];
    // fileType = fileType[0];
    console.log("e", file.split("/")[0]);

    if (fileType == "image" || fileType == "video") {
      // return false;
      this.setState({ fileType: fileType });
      let dataSend = { buckate: true, buckate_name: "appIcon" };

      let result = await HttpClient.newFileUpload(
        "image-upload/movie",
        e.target.files[0],
        dataSend
      );
      console.log("result", result);
      if (result && result.status) {
        this.setState({ img_url: result.url, image_select: true });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
  };

  onCloseCallBack = (val) => {
    console.log("object", val);
    this.setState({
      ismodalopen: val,
    });
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          width: 100,
        },
        {
          label: "Category Name",
          field: "catname",
          width: 150,
        },
        {
          label: "SubCategory Name",
          field: "subcatname",
          width: 150,
        },
        {
          label: "Shop Name",
          field: "shopname",
          width: 150,
        },
        {
          label: "Service Name",
          field: "servname",
          width: 150,
        },
        {
          label: "Image",
          field: "serviceimage",
          width: 150,
        },
        {
          label: "price",
          field: "price",
          width: 100,
        },
        {
          label: "Pageview",
          field: "pageview",
          width: 100,
        },
        {
          label: "Total Sale Value",
          field: "totalSalesValue",
          width: 200,
        },

        {
          label: "Date",
          field: "date",
          width: 150,
        },
        {
          label: "Status",
          field: "status",
          width: 100,
        },

        {
          label: "View Details",
          field: "view",
          width: 100,
        },
      ],
      rows: this.state.data,
    };

    const header = [
      { label: "Sl.", key: "sl" },
      { label: "Category Name", key: "catname" },
      { label: "SubCategory Name", key: "subcatname" },
      { label: "Service Name", key: "servname" },
      { label: "Price", key: "price" },
      { label: "Pageview", key: "pageview" },
      { label: "Details", key: "details" },
      { label: "Pageview", key: "pageview" },
      { label: "Hashtag", key: "hashtag" },
      { label: "Personalize", key: "personalize" },
      { label: "Date", key: "date" },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {/* <Row>
              <Col xs={12}>
                <Card>
                  <CardBody></CardBody>
                </Card>
              </Col>
            </Row> */}

            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {this.state.alert ? (
                    <UncontrolledAlert
                      color={this.state.type}
                      className="alert-dismissible fade show"
                      role="alert"
                    >
                      {this.state.type == "warning" ? (
                        <i className="mdi mdi-alert-outline mr-2"></i>
                      ) : this.state.type == "success" ? (
                        <i className="mdi mdi-check-all mr-2"></i>
                      ) : this.state.type == "danger" ? (
                        <i className="mdi mdi-block-helper mr-2"></i>
                      ) : null}
                      {this.state.message}
                    </UncontrolledAlert>
                  ) : null}
                  <Row>
                    <Col xl={3}>
                      {this.state.data.length > 0 ? (
                        <CSVLink
                          data={this.state.data}
                          headers={header}
                          filename={"ServiceBookingHistory.csv"}
                          className="btn btn-success"
                        >
                          Export Report in CSV
                        </CSVLink>
                      ) : null}
                    </Col>
                  </Row>
                  &nbsp;
                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  <Row>
                    <Col xl={12}>
                      <div className="card p-3">
                        <FormGroup row>
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Category
                          </Label>
                          <Col md={10}>
                            <select
                              className="form-control"
                              onChange={this.handleChange1}
                              value={this.state.cat_id}
                            >
                              <option value="">Select Category</option>
                              {this.state.categoryAll.map((item, index) => {
                                return (
                                  <option value={item._id} key={index}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Sub Category
                          </Label>
                          <Col md={10}>
                            <select
                              className="form-control"
                              onChange={this.handleChange2}
                              value={this.state.subcat_id}
                            >
                              <option value="">Select SubCategory</option>
                              {this.state.subcatarr.map((item, index) => {
                                return (
                                  <option value={item.id} key={index}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </Col>
                        </FormGroup>
                        <MDBDataTableV5
                          hover
                          scrollX
                          entriesOptions={[5, 20, 25]}
                          entries={5}
                          pagesAmount={4}
                          data={data}
                        />
                      </div>
                    </Col>
                  </Row>
                  {this.state.ismodalopen ? (
                    <Details
                      status={true}
                      onCloseCallBack={this.onCloseCallBack}
                      data={this.state.activeid}
                      id={this.state.idneed}
                    />
                  ) : null}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
