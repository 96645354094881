import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import StartApp from "../pages/ChooseDesign/startApp";
import ChooseDesign from "../pages/ChooseDesign/index";
import CreateApp from "../pages/CreateApp/index";
import WelcomeScreen from "../pages/ChooseDesign/welcomeScreen";
import Quizlist from "../pages/QuizList/index";
import MoreQuestion from "../pages/AddMoreQuestions/index";
import Hobby from "../pages/Hobby/index";
import Updatehobby from "../pages/Updatehobby/index";

import KnowYourCustomer from "../pages/QuizList/knowCustomer";

import Monitize from "../pages/Monitize/index";
import Communicate from "../pages/Communicate/index";
import Forums from "../pages/Forums/index";
import Showroom from "../pages/Showroom/index";
import Directory from "../pages/Directory/index";

// Subscrption
import Subscrption from "../pages/Subscription/index";

// Application
import App from "../pages/App/index";

import Category from "./../pages/Category/index";
import SubCategory from "./../pages/Category/subCategory";

// Product

//jd3 category
import Jdcategory from "./../pages/Jdcategory/index";
import Genre from "./../pages/Jdcategory/genre";

import Product from "./../pages/Product/index";
import ProductAdd from "./../pages/Product/create";

import ProductRepo from "./../pages/Reports/ProductOrder";

import ServiceRepo from "./../pages/Reports/ServiceBooking";
import SubRepo from "./../pages/Reports/SubscriptionHistory";

//Add banner

import Addbanner from "./../pages/Addbanner";

//Add banner

import AddSubscription from "./../pages/subscriptionplan";

// Events

import Events from "./../pages/Event";

// Advertisement

import Advertisement from "./../pages/Advertise";

// Message

import UserChat from "./../pages/Message/user";
import ApponwerChat from "./../pages/Message/appowner";

// Profile

import Profile from "./../pages/Profile/index";

// Timeline

import Timeline from "./../pages/Timeline";

import sharePanel from "./../pages/Timeline/share";
import requestPanel from "./../pages/Timeline/request";
import Faq from "./../pages/Support&Faq/faq";

import Banner from "./../pages/Baner/index";

import Test from "../pages/Test/index";

import Video from "./../pages/Video/index";
import Series from "./../pages/Series/index";
import Episodes from "./../pages/Series/episodes";

import Tv from "./../pages/Tv/index";
import Training from "./../pages/jd3Event/index";

import AllVideos from "./../pages/Series/allTypes";

import ManageTypes from "./../pages/Jdcategory/type";

import Users from "./../pages/User";

import Price from "../pages/Price/index";

import UserSubscription from "./../pages/User/subscription";
import Subscribtionhistory from "./../pages/Subscriptionhistory";
import Userlist from "./../pages/Userlist";
import Sellerlist from "./../pages/Sellerlist";
import ApproveSeller from "./../pages/ApproveSeller"
import ApprovePersonnel from "./../pages/ApprovePersonnel"
import ServiceCategory from "./../pages/ServiceCategory";
import SubserviceCategory from "./../pages/SubserviceCategory";

import ManageCoupon from "./../pages/ManageCoupon/index";
import ManageServCoupon from "./../pages/ManageCoupon/servcoupon";

import OrderHistory from "./../pages/OrderHistory/index";

import NewProductOrder from "./../pages/Product/newProduct";

import Servicebookinghistory from "./../pages/Servicebookinghistory/index";

import TicketRaise from "./../pages/TicketRaise";

import ContactList from "./../pages/ContactUs/index";

import AboutUs from "../pages/AboutUs/index";

import Blog from "../pages/AboutUs/blog";

import FaqCat from "../pages/AboutUs/faqcat";

import FaqSubCat from "../pages/AboutUs/faqsubcat";

import FAQ from "../pages/AboutUs/faq";

import Privacy from "../pages/AboutUs/privacy";

import Cookies from "../pages/AboutUs/cookie";

import Return from "../pages/AboutUs/return";

import SafttyGide from "../pages/AboutUs/saftty";

import Condition from "../pages/AboutUs/condition";

import SellerContactUs from "../pages/AboutUs/sellerContactUs";

import ViewSubscribe from "../pages/AboutUs/viewSubscribe";

import CustomerService from "../pages/AboutUs/customerServices";

import Associates from "../pages/AboutUs/associate";

import HomeBanner from "../pages/AboutUs/homebanner";

import Achivment from "../pages/AboutUs/achivment";

import Feedback from "../pages/AboutUs/feedback";

import ProcessingFee from "../pages/ProcessingFee";

import SingleSeller from "../pages/Sellerlist/single";

import MostSellYesterday from "../pages/Sellerlist/sellYesterday"

import SellerServices from "../pages/Sellerlist/sellerServices";

import SellerCommission from "../pages/Sellerlist/sellerCommission";

import PaymentHistory from "../pages/Sellerlist/paymentHistory";

import SellerPriority from "../pages/SellerPriority/index";

import TopSeller from "../pages/TopSeller";

import UserQuery from "../pages/Userquery";
import UserSearch from "../pages/UserSearch";
import AllSubscriber from "../pages/AllSubscrib";
import RefundPersonnel from "../pages/RefundPersonnel";
import ChatList from "../pages/ChatList"
import RefundProductRequest from "../pages/RefundProductRequest"
import RefundServiceRequest from "../pages/RefundServiceRequest"
import SalesReport from "../pages/salesreport";
import ContactInfo from "../pages/AboutUs/contact";
import AboutFaq from "../pages/AboutUs/aboutfaq";
import BlogCategory from "../pages/AboutUs/blogCategory";
import SellerFaq from "../pages/AboutUs/sellerFaq";
import ServiceSalesReport from "../pages/salesreport/servicesalesreport";

import Addcurrency from "../pages/addcurrency";

import ServiceRefundList from "../pages/refundServices";
import AllReport from "../pages/Allreport";
import AllLegalNotice from "../pages/AllLegalNotice";
import AllGrevience from "../pages/Allgrevience";
import SellerBankDetails from "../pages/SellerBank";

import OrdersRevenue from "../pages/ordersandrevenue"
import Allservices from "../pages/All services"
import Allshop from "../pages/Allshop"

import ServiceReviewlist from "../pages/reviewlist/service"
import ProductReviewlist from "../pages/reviewlist/product"



// Pages Calendar
// import Calendar from "../pages/Calendar/Calendar";

// Pages Component
// import Chat from "../pages/Chat/Chat";

//Ecommerce Pages
// import Products from "../pages/Ecommerce/Products";
// import ProductDetail from "../pages/Ecommerce/ProductDetail";
// import Orders from "../pages/Ecommerce/Orders";
// import Customers from "../pages/Ecommerce/Customers";
// import Cart from "../pages/Ecommerce/Cart";
// import CheckOut from "../pages/Ecommerce/CheckOut";
// import Shops from "../pages/Ecommerce/Shops";
// import AddProduct from "../pages/Ecommerce/AddProduct";

//Email
// import EmailInbox from "../pages/Email/email-inbox";
// import EmailRead from "../pages/Email/email-read";

// Charts
// import ChartApex from "../pages/Charts/Apexcharts";
// import ChartjsChart from "../pages/Charts/ChartjsChart";
// import SparklineChart from "../pages/Charts/SparklineChart";
// import ChartsKnob from "../pages/Charts/charts-knob";

// Maps
// import MapsGoogle from "../pages/Maps/MapsGoogle";
// import MapsVector from "../pages/Maps/MapsVector";

//Icons
// import RemixIcons from "../pages/Icons/RemixIcons";
// import MaterialDesign from "../pages/Icons/MaterialDesign";
// import DripiIcons from "../pages/Icons/DripiIcons";
// import FontAwesome from "../pages/Icons/FontAwesome";

//Utility
// import StarterPage from "../pages/Utility/StarterPage";
// import Maintenance from "../pages/Utility/Maintenance";
// import CommingSoon from "../pages/Utility/CommingSoon";
// import Timeline from "../pages/Utility/Timeline";
// import FAQs from "../pages/Utility/FAQs";
// import Pricing from "../pages/Utility/Pricing";
// import Error404 from "../pages/Utility/Error404";
// import Error500 from "../pages/Utility/Error500";

// Forms
import FormElements from "../pages/Forms/FormElements";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
// import FormXeditable from "../pages/Forms/FormXeditable";

//Ui
// import UiAlert from "../pages/Ui/UiAlert";
// import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
// import UiCarousel from "../pages/Ui/UiCarousel";
// import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import withdrawlCommission from "../pages/Sellerlist/WithdrawlCommission";
import WithdrawlCommission from "../pages/Sellerlist/WithdrawlCommission";
// import UiGrid from "../pages/Ui/UiGrid";
// import UiImages from "../pages/Ui/UiImages";
// import UiLightbox from "../pages/Ui/UiLightbox";
// import UiModal from "../pages/Ui/UiModal";
// import UiProgressbar from "../pages/Ui/UiProgressbar";
// import UiSweetAlert from "../pages/Ui/UiSweetAlert";
// import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
// import UiTypography from "../pages/Ui/UiTypography";
// import UiVideo from "../pages/Ui/UiVideo";
// import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
// import UiRating from "../pages/Ui/UiRating";
// import UiRangeSlider from "../pages/Ui/UiRangeSlider";
// import UiNotifications from "../pages/Ui/ui-notifications";
// import UIRoundSlider from "../pages/Ui/UIRoundSlider";

//Tables
// import BasicTables from "../pages/Tables/BasicTables";
// import DatatableTables from "../pages/Tables/DatatableTables";
// import ResponsiveTables from "../pages/Tables/ResponsiveTables";
// import EditableTables from "../pages/Tables/EditableTables";

// Inner Authentication
// import Login1 from "../pages/AuthenticationInner/Login";
// import Register1 from "../pages/AuthenticationInner/Register";
// import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";

//Kanban Board
// import KanbanBoard from "../pages/KanbanBoard/index";

const authProtectedRoutes = [
  //Kanban Board
  // { path: "/apps-kanban-board", component : KanbanBoard },

  // Tables
  // { path: "/tables-basic", component: BasicTables },
  // { path: "/tables-datatable", component: DatatableTables },
  // { path: "/tables-responsive", component: ResponsiveTables },
  // { path: "/tables-editable", component: EditableTables },

  // Ui
  // { path: "/ui-alerts", component: UiAlert },
  // { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  // { path: "/ui-carousel", component: UiCarousel },
  // { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  // { path: "/ui-grid", component: UiGrid },
  // { path: "/ui-images", component: UiImages },
  // { path: "/ui-lightbox", component: UiLightbox },
  // { path: "/ui-modals", component: UiModal },
  // { path: "/ui-progressbars", component: UiProgressbar },
  // { path: "/ui-sweet-alert", component: UiSweetAlert },
  // { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  // { path: "/ui-typography", component: UiTypography },
  // { path: "/ui-video", component: UiVideo },
  // { path: "/ui-session-timeout", component: UiSessionTimeout },
  // { path: "/ui-rating", component: UiRating },
  // { path: "/ui-rangeslider", component: UiRangeSlider },
  // { path: "/ui-notifications", component: UiNotifications },
  // { path : "/ui-roundslider", component : UIRoundSlider },

  // Forms
  { path: "/form-elements", component: FormElements },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-mask", component: FormMask },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },
  // { path: "/form-xeditable", component: FormXeditable },

  //Utility
  // { path: "/pages-starter", component: StarterPage },
  // { path: "/pages-timeline", component: Timeline },
  // { path: "/pages-faqs", component: FAQs },
  // { path: "/pages-pricing", component: Pricing },

  //Icons
  // { path: "/icons-remix", component: RemixIcons },
  // { path: "/icons-materialdesign", component: MaterialDesign },
  // { path: "/icons-dripicons", component: DripiIcons },
  // { path: "/icons-fontawesome", component: FontAwesome },

  // Maps
  // { path: "/maps-google", component: MapsGoogle },
  // { path: "/maps-vector", component: MapsVector },

  //Charts
  // { path: "/charts-apex", component: ChartApex },
  // { path: "/charts-chartjs", component: ChartjsChart },
  // { path: "/charts-sparkline", component: SparklineChart },
  // { path: "/charts-knob", component: ChartsKnob },

  //Email
  // { path: "/email-inbox", component: EmailInbox },
  // { path: "/email-read", component: EmailRead },

  //Ecommerce
  // { path: "/ecommerce-products", component: Products },
  // { path: "/ecommerce-product-detail", component: ProductDetail },
  // { path: "/ecommerce-orders", component: Orders },
  // { path: "/ecommerce-customers", component: Customers },
  // { path: "/ecommerce-cart", component: Cart },
  // { path: "/ecommerce-checkout", component: CheckOut },
  // { path: "/ecommerce-shops", component: Shops },
  // { path: "/ecommerce-add-product", component: AddProduct },

  //chat
  // { path: "/apps-chat", component: Chat },

  //calendar
  // { path: "/calendar", component: Calendar },

  { path: "/dashboard", component: Dashboard },
  { path: "/proposed-showroom", component: Showroom },
  { path: "/published-showroom", component: Showroom },
  { path: "/startApp", component: StartApp },
  { path: "/choose-design", component: ChooseDesign },
  { path: "/create-app", component: CreateApp },
  { path: "/welcome-screen", component: WelcomeScreen },
  { path: "/communicate", component: Communicate },
  { path: "/monitize", component: Monitize },
  { path: "/know-your-customer", component: KnowYourCustomer },
  { path: "/quizlist", component: Quizlist },
  { path: "/hobbies", component: Hobby },
  { path: "/updatehobby:id", component: Updatehobby },
  { path: "/forum", component: Forums },

  { path: "/directory", component: Directory },
  // subs
  { path: "/subscrption", component: Subscrption },
  // app
  { path: "/application", component: App },

  // product
  { path: "/category", component: Category },
  { path: "/sub-category", component: SubCategory },
  { path: "/jd3category", component: Jdcategory },
  { path: "/genre", component: Genre },
  { path: "/product", component: Product },
  { path: "/product-add", component: ProductAdd },
  { path: "/add-banner", component: Addbanner },
  { path: "/add-banner", component: Addbanner },

  //repo
  { path: "/orderrepo", component: ProductRepo },
  { path: "/servicerepo", component: ServiceRepo },
  { path: "/subrepo", component: SubRepo },

  { path: "/contactlist", component: ContactList },
  { path: "/aboutus", component: AboutUs },
  { path: "/processing-fee", component: ProcessingFee },

  { path: "/blog", component: Blog },

  { path: "/faq-cat", component: FaqCat },
  { path: "/faq-subcat", component: FaqSubCat },

  { path: "/faq", component: FAQ },

  { path: "/privacy", component: Privacy },

  { path: "/return", component: Return },

  { path: "/condition", component: Condition },

  { path: "/view-subscribe", component: ViewSubscribe },

  { path: "/seller-querylist", component: SellerContactUs },

  { path: "/customer-service", component: CustomerService },

  { path: "/safety-guide", component: SafttyGide },

  { path: "/associates", component: Associates },

  { path: "/homebanner", component: HomeBanner },

  { path: "/achievement", component: Achivment },

  { path: "/feedback", component: Feedback },

  { path: "/cookies", component: Cookies },

  { path: "/seller-single/:id", component: SingleSeller },

  { path: "/seller-sellyesterday", component: MostSellYesterday },

  { path: "/seller-services/:id", component: SellerServices },

  { path: "/seller-commission/:id", component: SellerCommission },

  { path: "/withdrawl-commission/:id", component: WithdrawlCommission },

  { path: "/payment-history/:id", component: PaymentHistory },

  //add subscription plan
  { path: "/subscription-plan", component: AddSubscription },
  { path: "/subscription-history", component: Subscribtionhistory },
  { path: "/userlist", component: Userlist },
  { path: "/sellerlist", component: Sellerlist },
  { path: "/approve-seller", component: ApproveSeller },
  // { path: "/approve-personnel", component: ApprovePersonnel },
  { path: "/seller-priority", component: SellerPriority },
  { path: "/service-category", component: ServiceCategory },
  { path: "/subservice-category", component: SubserviceCategory },

  { path: "/manageCoupon", component: ManageCoupon },
  { path: "/manageservCoupon", component: ManageServCoupon },

  { path: "/orderHistory", component: OrderHistory },
  { path: "/new-product-order", component: NewProductOrder },
  { path: "/servicebookinghistory", component: Servicebookinghistory },
  { path: "/ticket-raise", component: TicketRaise },

  { path: "/all-services", component: Allservices },

  // Events
  { path: "/events", component: Events },

  { path: "/test", component: Test },

  // MoreQuestion
  { path: "/morequestion:id", component: MoreQuestion },

  // Advertise
  { path: "/advertisement", component: Advertisement },

  // share panel

  { path: "/share-panel", component: sharePanel },
  { path: "/request-panel", component: requestPanel },
  // Timeline
  { path: "/timeline", component: Timeline },


  // Topseller
  { path: "/topseller", component: TopSeller },

  // messahe
  { path: "/user-chat", component: UserChat },
  { path: "/appowner-chat", component: ApponwerChat },

  // Profile
  { path: "/faq", component: Faq },
  { path: "/profile", component: Profile },

  // Banner

  { path: "/banner", component: Banner },

  { path: "/manageprice", component: Price },

  // Videos

  { path: "/movies", component: Video },

  { path: "/series", component: Series },
  { path: "/episodes/:id", component: Episodes },

  { path: "/tv-shows", component: Tv },
  { path: "/training", component: Training },
  { path: "/videos", component: AllVideos },

  { path: "/types", component: ManageTypes },

  // Users

  { path: "/users", component: Users },
  { path: "/user-subscription", component: UserSubscription },

  { path: "/user-querylist", component: UserQuery },
  { path: "/user-searchlist", component: UserSearch },

  { path: "/subscriber-list", component: AllSubscriber },

  { path: "/refund-personnel", component: RefundPersonnel },

  { path: "/refund-product-request", component: RefundProductRequest },

  { path: "/refund-service-request", component: RefundServiceRequest },

  { path: "/chat-list", component: ChatList },

  { path: "/sales-report", component: SalesReport },
  { path: "/service-sales-report", component: ServiceSalesReport },
  { path: "/contact-info", component: ContactInfo },
  { path: "/about-faq", component: AboutFaq },
  { path: "/blog-category", component: BlogCategory },
  { path: "/seller-faq", component: SellerFaq },

  { path: "/addcurrency", component: Addcurrency },
  { path: "/service-refund-list", component: ServiceRefundList },

  { path: "/allreport", component: AllReport },

  { path: "/allshop", component: Allshop },
  { path: "/alllegalnotice", component: AllLegalNotice },
  { path: "/allgrievance", component: AllGrevience },
  { path: "/sellerbankdetails", component: SellerBankDetails },
  { path: "/orders_rev", component: OrdersRevenue },
  { path: "/servicereviewlist", component: ServiceReviewlist },
  { path: "/productreviewlist", component: ProductReviewlist },


  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/auth-lock-screen", component: AuthLockScreen },

  // Authentication Inner
  // { path: "/auth-login", component: Login1 },
  // { path: "/auth-register", component: Register1 },
  // { path: "/auth-recoverpw", component: ForgetPwd1 },

  // { path: "/pages-maintenance", component: Maintenance },
  // { path: "/pages-comingsoon", component: CommingSoon },
  // { path: "/pages-404", component: Error404 },
  // { path: "/pages-500", component: Error500 },
];

export { authProtectedRoutes, publicRoutes };
