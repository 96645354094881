import React, { useState, useEffect } from "react";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

//import ReactJsAlert from "reactjs-alert";

function Details(props) {
  const [data, setdata] = useState({})

  useEffect(() => {

    console.log("objecttt", props.data);
    fetchData();
  }, [props.data]);



  const onCloseMethod = async () => {
    props.onCloseCallBackseller(false);
  };

  const fetchData = async () => {
    console.log("props.id", props.id);
    let result = await HttpClient.requestData("servicedetails", "GET");
    console.log("abc", result);
    if (result && result.status) {
      if (result.data.length > 0) {
        let arr = result.data.filter(it => it._id == props.id);
        console.log("gh", arr[0].servicecart_data.seller_data);
        setdata(arr[0].servicecart_data.seller_data);
      }
      else {

      }

    }
  }

  // const { selectedOption } = this.state;
  return (
    <div
      className={props.status ? "modal show fade" : "modal fade"}
      id="add-new-task-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="add-new-task-modalLabel"
      aria-hidden="true"
      style={{ display: props.status ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="add-new-task-modalLabel">
              Seller Details
            </h4>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => {
                onCloseMethod();
              }}
            >
              ×
            </button>
          </div>
          <div className="modal-body" style={{ height: "360px", overflowY: "auto" }}>
            <div className="px-2">

            </div>


            <div className="d-md-flex  my-2  ">

              <div className="order_pic ">
                <img
                  src={HttpClient.IMG_URL + data.image}
                  alt="img"
                  style={{ width: "100px", height: "100px", borderRadius: "6px" }}
                />

              </div>
              <div className="px-3 py-2" >
                {/* <h4>{item.servicename}</h4>
                <h6>₹ {item.price}</h6> */}
                {/* <p>Quantity: {item.qty} </p> */}
              </div>

            </div>


            <div className="form-group row">

              <div className="col-md-4">
                <label htmlFor="task-title">Seller Name</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={data.firstName + " " + data.lastName}
                  readOnly
                />
              </div>


              <div className="col-md-8">
                <label htmlFor="task-title">Email</label>


                <input
                  style={{ height: "37px" }}
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={data ? data.email : ""}
                  readOnly

                />
              </div>

              <div className="col-md-4">


                <label htmlFor="task-title">About</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={data.about ? data.about : ""}
                  readOnly
                />
              </div>

              <div className="col-md-4">


                <label htmlFor="task-title">Priority</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={data.priority}
                  readOnly
                />
              </div>



              <div className="col-md-4">
                <label htmlFor="task-title">Country</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={data.country ? data.country.label : ""}
                  readOnly
                />
              </div>



            </div>


            <div className="text-right">
              <button
                type="button"
                className="btn btn-warning mr-3"
                data-dismiss="modal"
                onClick={() => {
                  onCloseMethod();
                }}
              >
                Close
              </button>

            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}
export default Details;
