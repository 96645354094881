import React, { Component } from "react";
import "./ind432a.css";

export default class design_3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // backgroundImage: this.props.backgroundImage,
      // item: this.props.item,
      // user: this.props.item.user,
      loop: [1,2,3,4,5,6]
    };
  }

  render() {
    return (
      <div
        className="designed_area w-100 h-100 relative"
        style={{
          backgroundImage: `url(https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg)`,
        }}
      >
        <div className="d-flex w-100 h-100">
          <div className="my-auto mx-auto icon_contains">
            <div className="d-flex flex-wrap">
              {this.state.loop.map((item, index) => {
                return (
                  <a
                    href="#"
                    className="icon_div"
                    style={{ backgroundColor: "#f1f5f7" }}
                  >
                    <i className="ri-facebook-box-fill icon_item"></i>
                    {/* <img src={item.iconUrl} style={{height:40,width:40}} /> */}
                  </a>
                );
              })}
              {/* <a href="#" className="icon_div">
                <i className="ri-facebook-box-fill icon_item"></i>
              </a>
              <a href="#" className="icon_div">
                <i className="ri-messenger-fill icon_item"></i>
              </a>
              <a href="#" className="icon_div">
                <i className="ri-reactjs-line icon_item"></i>
              </a>
              <a href="#" className="icon_div">
                <i className="ri-whatsapp-line icon_item"></i>
              </a>
              <a href="#" className="icon_div">
                <i className="ri-twitter-fill icon_item"></i>
              </a>
              <a href="#" className="icon_div">
                <i className="ri-twitch-line icon_item"></i>
              </a>
              <a href="#" className="icon_div">
                <i className="ri-instagram-line icon_item"></i>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
