import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withNamespaces } from "react-i18next";
import HttpClient from "../../../utils/HttpClient";

// users
// import dumy from "../../../assets/images/users/dumy.jpg";
import dumy from "../../../assets/images/dumyy.jpg"

import { reactLocalStorage } from "reactjs-localstorage";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      arr: ""
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount = () => {
    this.checkUser();
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    // console.log("profile", user);
    if (user != null && Object.keys(user).length > 0) {
      this.setState({ userId: user._id });
      console.log(this.state.userId);
      this.fetchUser();
    } else {
    }
  };

  fetchUser = async () => {
    let sendData = {};
    // console.log("sendData", sendData);
    let result = await HttpClient.requestData(
      "get-profile/" + this.state.userId,
      "GET",
      sendData
    );
    console.log("userdata", result);
    if (result && result.status) {
      this.setState({
        arr: result.data.image != null ? HttpClient.IMG_URL + result.data.image : dumy,
      });
    } else {
    }
  };

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  render() {
    let username = "Admin";
    let profileImg =
      this.state.arr;
    //    if(localStorage.getItem("authUser"))
    //    {
    //         const obj = JSON.parse(localStorage.getItem("authUser"));
    //         const uNm = obj.email.split("@")[0];
    //         username = uNm.charAt(0).toUpperCase() + uNm.slice(1);
    //    }

    // checkUser = async () =>{
    let user = reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      const uNm = user.email.split("@")[0];
      username = uNm.charAt(0).toUpperCase() + uNm.slice(1);
      // profileImg = user.image;
      // console.log('user',user);
      // return true;
    } else {
      // return false;
    }
    // }

    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block user-dropdown"
        >
          <DropdownToggle
            tag="button"
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
          >
            <img
              className="rounded-circle header-profile-user mr-1"
              src={profileImg}
              alt="Header Avatar"
            />
            <span className="d-none d-xl-inline-block ml-1 text-transform">
              {username}
            </span>
            <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem href="/profile">
              <i className="ri-user-line align-middle mr-1"></i>{" "}
              {this.props.t("Profile")}
            </DropdownItem>
            {/* <DropdownItem href="#"><i className="ri-wallet-2-line align-middle mr-1"></i> {this.props.t('My Wallet')}</DropdownItem>
                                <DropdownItem className="d-block" href="#"><span className="badge badge-success float-right mt-1">11</span><i className="ri-settings-2-line align-middle mr-1"></i> {this.props.t('Settings')}</DropdownItem>
                                <DropdownItem href="#"><i className="ri-lock-unlock-line align-middle mr-1"></i> {this.props.t('Lock screen')}</DropdownItem>
                                <DropdownItem divider /> */}
            <DropdownItem
              className="text-danger"
              onClick={() => {
                reactLocalStorage.setObject("userData", null);
                window.location.href = "/login";
              }}
            >
              <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>{" "}
              {this.props.t("Logout")}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(ProfileMenu);
