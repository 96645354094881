import React, { Component } from "react";
import Preview from "../Component/preview";
import classnames from "classnames";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Tooltip,
  Card,
  CardBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Input,
  Form,
  Button,
  FormGroup,
  InputGroup,
  UncontrolledAlert,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import Breadcrumbs from "../../components/Common/Breadcrumb";


export default class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 1,
      tooltipOpen: false,
      monthDate: new Date(),
      mediaType: "",
      media_url: "",
      message: "",

      alert: false,
      messagee: "",
      type: "",
      edit_status: false,

      userId: "",
      userDetails: {},
      result:{},
      title:'',
      breadcrumbItems: [
        { title: "Communicate ", link: "#" },
        { title: "Add & Manage Communicate", link: "#" },
    ],
    };
    this.toggleTab.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
  }
  handleMonthChange(date) {
    this.setState({ monthDate: date });
  }
  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeTab: tab,
        });
      }
    }
  }

  imageUpload = async (file) => {
    // alert("upload");
    let dataSend = { buckate: true, buckate_name: "appIcon" };
    let result = await HttpClient.newFileUpload(
      "image-upload/notification",
      file,
      dataSend
    );
    return result;
  };

  formSubmit = async () => {
    let data = {
      msg: this.state.message,
      type: this.state.activeTab == 1 ? "inspiration" : "notification",
      media_url: this.state.media_url,
      media_type: this.state.mediaType,
      title:this.state.title
    };
    console.log('data',data);
    let result = await HttpClient.requestData(
      "admin/communication",
      "POST",
      data
    );
    if (result && result.status) {
      this.setState({
        alert: true,
        messagee: this.state.edit_status
          ? "Successfully Submited."
          : "Successfully Updated.",
        type: "success",
        message: "",
        media_url: "",
        mediaType: "",
        title:""
      });
    } else {
      this.setState({
        alert: true,
        messagee: "Something Went Wrong. Try Again",
        type: "error",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        messagee: "",
        type: "",
      });
    }, 3000);
  };

  async componentDidMount() {
    this.checkUser();
  }

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({ userId: user.id, userDetails: user });
      // this.checkExesisting(user.id);
    } else {
    }
  };

  checkExesisting = async (id) => {
    let sendData = { owner_id: id };
    let result = await HttpClient.requestData(
      "app-owner/application/check-pending",
      "POST",
      sendData
    );
    console.log("result check-pending", result);
    if (result && result.status > 0) {
      this.setState({
        result: result.data[0],
      });
    }
  }

  // communication
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
          <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xl={12}>
                {/* <BaseInfo /> */}
                {this.state.alert ? (
                  <UncontrolledAlert
                    color={this.state.type}
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.type == "warning" ? (
                      <i className="mdi mdi-alert-outline mr-2"></i>
                    ) : this.state.type == "success" ? (
                      <i className="mdi mdi-check-all mr-2"></i>
                    ) : this.state.type == "danger" ? (
                      <i className="mdi mdi-block-helper mr-2"></i>
                    ) : null}
                    {this.state.messagee}
                  </UncontrolledAlert>
                ) : null}
              </Col>
              <Col xl={12}>
                {/* <div>
                  <h3 className="title-editor py-1 text-center">
                    Your communication{" "}
                    <i
                      className="fa fa-info-circle display_tooltip"
                      id="TooltipInfo"
                    />
                    <Tooltip
                      placement="top"
                      isOpen={this.state.tooltipOpen}
                      target="TooltipInfo"
                      toggle={this.toggle}
                      autohide={false}
                    >
                      Create Your inspiration & notification
                    </Tooltip>
                  </h3>
                </div> */}
                <div>
                  <Card>
                    <CardBody>
                      <div
                        id="basic-pills-wizard"
                        className="twitter-bs-wizard"
                      >
                        <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === 1,
                              })}
                              onClick={() => {
                                this.toggleTab(1);
                              }}
                            >
                              <span className="step-number">01</span>
                              <span className="step-title">
                                Create inspiration
                              </span>
                              <span className="step-title">
                                with image and video
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === 2,
                              })}
                              onClick={() => {
                                this.toggleTab(2);
                              }}
                            >
                              <span className="step-number">02</span>
                              <span className="step-title">
                                Create notification
                              </span>
                              <span className="step-title">
                                without image and video
                              </span>
                            </NavLink>
                          </NavItem>
                          {/* <NavItem>
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === 3,
                              })}
                              onClick={() => {
                                this.toggleTab(3);
                              }}
                            >
                              <span className="step-number">03</span>
                              <span className="step-title">Confirm</span>
                            </NavLink>
                          </NavItem> */}
                        </ul>
                        <TabContent
                          activeTab={this.state.activeTab}
                          className="twitter-bs-wizard-tab-content"
                        >
                          <TabPane tabId={1}>
                            <Form>
                              <Row>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label for="basicpill-address-input1">
                                      Title
                                    </Label>
                                    <textarea
                                      id="basicpill-address-input1"
                                      className="form-control"
                                      rows="5"
                                      placeholder="Enter your title here..."
                                      onChange={(val) => {
                                        this.setState({
                                          title: val.target.value,
                                        });
                                      }}
                                      value={this.state.title}
                                    ></textarea>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label for="basicpill-address-input1">
                                      Describe Message
                                    </Label>
                                    <textarea
                                      id="basicpill-address-input1"
                                      className="form-control"
                                      rows="5"
                                      placeholder="Enter your message here..."
                                      onChange={(val) => {
                                        this.setState({
                                          message: val.target.value,
                                        });
                                      }}
                                      value={this.state.message}
                                    ></textarea>
                                  </FormGroup>
                                </Col>

                                <Col lg="6">
                                  {this.state.mediaType != "" &&
                                  this.state.mediaType == "image" ? (
                                    <FormGroup>
                                      <Label for="loyalty-input1">
                                        Add Image
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="file"
                                        defaultValue=""
                                        id="loyalty-input"
                                        placeholder="Enter Add Image..."
                                        onChange={async (val) => {
                                          let file = val.target.files[0];
                                          let result = await this.imageUpload(
                                            file
                                          );
                                          if (result && result.status) {
                                            this.setState({
                                              media_url: result.url,
                                            });
                                          }
                                        }}
                                      />
                                    </FormGroup>
                                  ) : (
                                    <FormGroup>
                                      <Label for="message-input1">
                                        Add Video
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="file"
                                        defaultValue=""
                                        id="message-input1"
                                        placeholder="Select Add Video..."
                                        onChange={async (val) => {
                                          let file = val.target.files[0];
                                          let result = await this.imageUpload(
                                            file
                                          );
                                          if (result && result.status) {
                                            this.setState({
                                              media_url: result.url,
                                            });
                                          }
                                        }}
                                      />
                                    </FormGroup>
                                  )}
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label for="message-input1">
                                      Select Media
                                    </Label>

                                    <select
                                      className="form-control"
                                      onChange={(val) => {
                                        this.state.mediaType = val.target.value;
                                        this.setState({});
                                      }}
                                      value={this.state.mediaType}
                                    >
                                      <option value="">Select Media</option>
                                      <option value="video">Video</option>
                                      <option value="image">Image</option>
                                    </select>
                                  </FormGroup>
                                </Col>
                                {/* <Col lg="6">
                                  <FormGroup className="mb-1">
                                    <Label>Shedule Date</Label>
                                    <InputGroup>
                                      <DatePicker
                                        selected={this.state.monthDate}
                                        className="form-control"
                                        onChange={this.handleMonthChange}
                                        showMonthDropdown
                                      />
                                    </InputGroup>
                                   
                                   
                                  </FormGroup>
                                </Col> */}
                                <Col lg="12" style={{ marginTop: 10 }}>
                                  <FormGroup className="mb-4">
                                    <div className="button-items">
                                      <Button
                                        color="primary"
                                        type="button"
                                        className="waves-effect waves-light mr-1"
                                        onClick={() => {
                                          this.formSubmit();
                                        }}
                                      >
                                        Live Now{" "}
                                        <i className="ri-arrow-right-line align-middle ml-2"></i>
                                      </Button>
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Form>
                          </TabPane>
                          <TabPane tabId={2}>
                            <Form>
                              <Row>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label for="basicpill-address-input1">
                                      Title
                                    </Label>
                                    <textarea
                                      id="basicpill-address-input1"
                                      className="form-control"
                                      rows="5"
                                      placeholder="Enter your title here..."
                                      onChange={(val) => {
                                        this.setState({
                                          title: val.target.value,
                                        });
                                      }}
                                      value={this.state.title}
                                    ></textarea>
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <Label for="basicpill-address-input1">
                                      Describe Notification
                                    </Label>
                                    <textarea
                                      id="basicpill-address-input1"
                                      className="form-control"
                                      rows="5"
                                      placeholder="Enter your notification here..."
                                      value={this.state.message}
                                      onChange={(val) => {
                                        this.setState({
                                          message: val.target.value,
                                        });
                                      }}
                                    ></textarea>
                                  </FormGroup>
                                </Col>
                                {/* <Col lg="6">
                                  <FormGroup className="mb-1">
                                    <Label>Shedule Notification</Label>
                                    <InputGroup>
                                      <DatePicker
                                        selected={this.state.monthDate}
                                        className="form-control"
                                        onChange={this.handleMonthChange}
                                        showMonthDropdown
                                      />
                                    </InputGroup>
                                    
                                    
                                  </FormGroup>
                                </Col> */}
                                <Col lg="12">
                                  <FormGroup className="mb-4">
                                    <div className="button-items">
                                      <Button
                                        color="primary"
                                        type="button"
                                        className="waves-effect waves-light mr-1"
                                        onClick={() => {
                                          this.formSubmit();
                                        }}
                                      >
                                        Live Now{" "}
                                        <i className="ri-arrow-right-line align-middle ml-2"></i>
                                      </Button>
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Form>
                          </TabPane>

                          <TabPane tabId={3}>
                            <div className="row justify-content-center">
                              <Col lg="6">
                                <div className="text-center">
                                  <div className="mb-4">
                                    <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                                  </div>
                                  <div>
                                    <h5>Confirmed</h5>
                                    <p className="text-muted">
                                      Lorem ipsum dolor sit amet consiquetive
                                      nessir ayis ipsum dolor
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Col>
              {/* <Col xl={4}>
                <div></div>
              </Col> */}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
