import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Payment from "./payment";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Most Sell Made Yesterday", link: "#" },
        { title: "View Most Sell Made Yesterday", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},

      image_select: false,
      img_url: "",
      booking_total: "",
      title: "",
      points: [""],
      id: "",
      duration: 0,
      seller_comission: "",
      type: "",
      desc: "",
      activeid: {},
      ismodalopen: false,
      from_date: "",
      to_date: "",
    };
    this.handleDefault = this.handleDefault.bind(this);
  }

  componentDidMount = async () => {
    // this.checkUser();
    this.fetchData();
  };

  handleDefault(date) {
    this.setState({ default_date: date });
  }




  fetchData = async () => {
    var today = moment().format("YYYY-MM-DD")
    var previousDay = moment().subtract(1, "days").format("YYYY-MM-DD");
    console.log("previousDay", previousDay)
    let data = {

      last_date: previousDay,
      // this_date: today
    };
    console.log("day", data);
    let result = await HttpClient.requestData("most-sales-last-day-per-seller", "POST", data);
    console.log("result most", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      result.data.forEach((element, index) => {
        console.log(element);
        let fname = element.service_data && element.service_data.seller_data.firstName;
        let lname = element.service_data && element.service_data.seller_data.lastName;
        let rows = {
          sl: i,
          date: moment(element.service_data && element.service_data.created_on).format("MMMM Do YYYY"),
          sellername: fname + " " + lname,
          email: element.service_data && element.service_data.seller_data ? element.service_data.seller_data.email : "",
          service: element.service_data ? element.service_data.name : "",
          img: < img
            src={HttpClient.IMG_URL + element.service_data.image[0]}
            alt="img"
            style={{ width: "100px", height: "100px", borderRadius: "6px" }
            }
          />,
          catname: element.service_data ? element.service_data.cat_name : "",
          subcatname: element.service_data ? element.service_data.subcat_name : "",
          price: element.service_data ? "₹" + element.service_data.price : "",
          currency: element.service_data ? element.service_data.currency : "",
          category: element.service_data ? element.service_data.cat_name : "",
          sub_category: element.service_data ? element.service_data.subcat_name : "",
          date: moment(element.booking_date).format("MMMM Do YYYY"),
          most_view: "₹" + element.totalSalesValue,

          view: (
            <button
              title="View Details"
              className="btn btn-success mr-2"
              onClick={() => {
                this.setState({
                  activeid: element,
                });
                setTimeout(() => {
                  this.setState({
                    ismodalopen: true,
                  });
                }, 200);
              }}
            >
              View Details
            </button>
          ),
        };
        i++;
        data.push(rows);
        console.log("data", data)
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",

          width: 150,
        },

        {
          label: "Seller Name",
          field: "sellername",

          width: 270,
        },
        {
          label: "Email",
          field: "email",

          width: 270,
        },
        {
          label: "Category Name",
          field: "catname",

          width: 270,
        },
        {
          label: "SubCategory Name",
          field: "subcatname",

          width: 270,
        },
        {
          label: "Service",
          field: "service",

          width: 270,
        },
        {
          label: "Image",
          field: "img",

          width: 270,
        },
        {
          label: "Price",
          field: "price",

          width: 270,
        },
        // {
        //   label: "Date",
        //   field: "date",

        //   width: 270,
        // },
        {
          label: "Currency",
          field: "currency",

          width: 270,
        },
        {
          label: "Total sales of services",
          field: "most_view",

          width: 270,
        },


      ],
      rows: this.state.data,
    };

    const header = [
      { label: "Sl.", key: "sl" },
      { label: "Seller Name", key: "sellername" },
      { label: "Email", key: "email" },
      { label: "Services", key: "service" },
      { label: "Price", key: "price" },
      { label: "Currency", key: "currency" },
      { label: "Category", key: "category" },
      { label: "Sub Category", key: "sub_category" },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {/* <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        From Date
                      </Label>
                      <Col md={3}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.from_date}
                          id="example-text-input"
                          placeholder="Enter From Date"
                          onChange={(val) => {
                            this.setState({ from_date: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        To Date
                      </Label>
                      <Col md={3}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.to_date}
                          id="example-text-input"
                          placeholder="Enter To Date"
                          onChange={(val) => {
                            this.setState({
                              to_date: val.target.value,
                              enable: true,
                            });
                          }}
                        />
                      </Col>
                      <Col md={2}>
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                          disabled={!this.state.enable}
                        >
                          Search
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </Col>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row> */}
            {/* {this.state.ismodalopen ? (
              <Payment
                status={true}
                onCloseCallBack={this.onCloseCallBack}
                sucess={this.sucess}
                data={this.state.activeid}
              />
            ) : null} */}
            <Col xl={3}>
              {this.state.data.length > 0 ? (
                <CSVLink
                  data={this.state.data}
                  headers={header}
                  filename={"SellerComissionList.csv"}
                  className="btn btn-success"
                >
                  Export Report in CSV
                </CSVLink>
              ) : null}
            </Col>
            &nbsp;
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    scrollX
                    pagesAmount={4}
                    data={data}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
