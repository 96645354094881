import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Details from "./details";
import AllDetails from "./alldetails";
import ReactLoading from "react-loading";
import "../../App.css"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  // Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "../../utils/HttpClient";
import { CSVLink } from "react-csv";
import index from "../ChooseDesign";
class FormElements extends Component {
  fileObj = [];
  fileArray = [];

  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Service Booking History", link: "#" },
        { title: "View Service Booking History", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      image_select: false,
      img_url: "",
      title: "",
      points: [""],
      id: "",
      duration: 0,
      seller_comission: "",
      type: "",
      desc: "",
      modal: false,
      arr: [],
      order: "",
      result1: [],
      cart: [],
      filter: "",
      ismodalopen: false,
      ismodalopen1: false,
      loading: false,
      activeid: {},
      from_date: "",
      to_date: "",
    };
    this.toggle = this.toggle.bind(this);
    this.handleDefault = this.handleDefault.bind(this);
  }

  onCloseCallBack = (val) => {
    console.log("object", val);
    this.setState({
      ismodalopen1: val,
    });
  };

  onCloseCallBack1 = (val) => {
    console.log("object", val);
    this.setState({
      ismodalopen1: false,
    });
  };
  handleChange = async (e) => {
    console.log(this.state.data);
    console.log(this.state.filter);
    let cart = this.state.data.filter(
      (item) => item.cart_data == this.state.filter
    );
    console.log(cart, "x");
    this.state.cart = cart;
    this.setState({});
  };
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  componentDidMount = async () => {
    this.checkUser();
    this.fetchData();
    console.log("pqr", this.state.result1);
  };

  handleDefault(date) {
    this.setState({ default_date: date });
  }

  fetchData = async () => {
    let data = {
      datefrom: this.state.from_date,
      dateto: this.state.to_date,
    };
    this.state.loading = true;
    this.setState({});
    console.log("send",data);
    let result = await HttpClient.requestData("servicedetails", "POST",data);
    console.log("abc", result);
    if (result && result.status) {
      let data = [];
      let i = 1;
      this.state.loading = false;
      this.setState({});
      this.setState({ result: result.data });
      // console.log("xyz", this.state.result);
      this.setState({ result1: result.user_data });
      // console.log("xyz", this.state.result1);

      // console.log(this.state.pointarr);
      this.state.result.forEach((element, index) => {
        let fname = element.firstname;
        let lname = element.lastname;
        let rows = {
          sl: i,

          date: moment(element.booking_date).format("lll"),

          order_id: element.order_id,

          user:
            fname +
            " " +
            lname,
          email: element.email? element.email : "",
          phone: element.phone ? element.phone : "",
          total: "₹" + element.total_inr.toFixed(2),
          processingFee:element.servicecart_data[0]?.processing && element.servicecart_data[0]?.processing + "%",
          remainingAmount:` ₹ ${(element.total_inr - (element.servicecart_data[0]?.processing ?((element.total_inr* element.servicecart_data[0]?.processing)/100):0)).toFixed(2)}`,
          // servicename: element.servicecart_data ? element.servicecart_data.servicename : "",
          // serviceimage:
          //   < img
          //     src={HttpClient.IMG_URL + element.servicecart_data.image}
          //     alt="img"
          //     style={{ width: "100px", height: "100px", borderRadius: "6px" }
          //     }
          //   />,
          // sellername: fname + " " + lname,
          // selleremail: element.servicecart_data.seller_data.email,
          // price: element.total,
          // type: element.paymenttype,
          status: (
            <button
              title={element.status ? "Confirmed" : "Pending"}
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
            >
              {element.status ? "Confirmed" : "Pending"}
            </button>
          ),
          view: (
            <button
              title="Seller Details"
              className="btn btn-success mr-2"
              onClick={() => {
                this.setState({
                  activeid: element,
                });
                setTimeout(() => {
                  this.setState({
                    ismodalopen: true,
                    idneed: element._id
                  });
                }, 200);
              }}
            >
              Seller Details
            </button>
          ),
          viewall: (
            <button
              title="View Details"
              className="btn btn-success mr-2"
              onClick={() => {
                this.setState({
                  activeid: element,
                  ismodalopen1: true,
                  idneed: element._id
                });

              }}
            >
              View Details
            </button>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    console.log(sendData);
    let result = await HttpClient.requestData(
      "admin/ecom_banner/delete",
      "POST",
      sendData
    );
    console.log(result);
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Succfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      title: item.name,
      price: item.price,
      edit_item_id: item._id,
      seller_comission: item.seller_comission,
      duration: item.duration,
      id: item.id,
      type: item.type,
    });
  };
  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/ecom_banner/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Succfully",
        type: "success",
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };

  submit = async () => {
    if (
      this.state.title != "" &&
      this.state.price != "" &&
      this.state.duration != "" &&
      this.state.seller_comission != "" &&
      this.state.type != ""
    ) {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          name: this.state.title,
          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData("subscription", "POST", data);
      } else {
        data = {
          name: this.state.title,
          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData(
          "subscription/" + this.state.edit_item_id,
          "PUT",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Subscription Updated Successfully"
            : "Subscription Added Succfully",

          type: "success",

          image_select: false,
          img_url: "",
          title: "",
          type: "",
          seller_comission: "",
          duration: "",
          price: "",
          desc: "",
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (e) => {
    let file = e.target.files[0].type;
    let fileType = file.split("/")[0];
    // fileType = fileType[0];
    console.log("e", file.split("/")[0]);

    if (fileType == "image" || fileType == "video") {
      // return false;
      this.setState({ fileType: fileType });
      let dataSend = { buckate: true, buckate_name: "appIcon" };

      let result = await HttpClient.newFileUpload(
        "image-upload/movie",
        e.target.files[0],
        dataSend
      );
      console.log("result", result);
      if (result && result.status) {
        this.setState({ img_url: result.url, image_select: true });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          width: 100,
        },
        {
          label: "Order Id",
          field: "order_id",
          width: 150,
        },
        {
          label: "Order Date",
          field: "date",
          width: 150,
        },
        {
          label: "User/Client Name",
          field: "user",
          width: 150,
        },
        {
          label: "Email",
          field: "email",
          width: 250,
        },
        {
          label: "Phone",
          field: "phone",
          width: 250,
        },

        {
          label: "Total",
          field: "total",
          width: 250,
        },
        // {
        //   label: "Processing Fee",
        //   field: "processingFee",
        //   width: 250,
        // },
        {
          label: "Remaining Amount",
          field: "remainingAmount",
          width: 250,
        },
        // {
        //   label: "Seller Details",
        //   field: "view",
        //   width: 100,
        // },
        {
          label: "View Details",
          field: "viewall",
          width: 100,
        },
      ],
      rows: this.state.data,
    };

    const header = [

      { label: "Sl.", key: "sl" },
      { label: "Order Id", key: "order_id" },
      { label: "Order Date", key: "date" },      
      { label: "User/Client Name", key: "user" },
      { label: "Email", key: "email" },
      { label: "Phone", key: "phone" },
      { label: "Total", key: "total" },
      // { label: "Service Name", key: "servicename" },
      // { label: "Service Image", key: "serviceimage" },
    ]
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {this.state.alert ? (
              <UncontrolledAlert
                color={this.state.type}
                className="alert-dismissible fade show"
                role="alert"
              >
                {this.state.type == "warning" ? (
                  <i className="mdi mdi-alert-outline mr-2"></i>
                ) : this.state.type == "success" ? (
                  <i className="mdi mdi-check-all mr-2"></i>
                ) : this.state.type == "danger" ? (
                  <i className="mdi mdi-block-helper mr-2"></i>
                ) : null}
                {this.state.message}
              </UncontrolledAlert>
            ) : null}
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        From Date
                      </Label>
                      <Col md={3}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.from_date}
                          id="example-text-input"
                          placeholder="Enter From Date"
                          onChange={(val) => {
                            this.setState({ from_date: val.target.value });
                          }}
                        />
                      </Col>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        To Date
                      </Label>
                      <Col md={3}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.to_date}
                          id="example-text-input"
                          placeholder="Enter To Date"
                          onChange={(val) => {
                            this.setState({
                              to_date: val.target.value,
                              enable: true,
                            });
                          }}
                        />
                      </Col>
                      <Col md={2}>
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.fetchData();
                          }}
                          disabled={!this.state.enable}
                        >
                          Search
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </Col>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={3}>
                {this.state.data.length > 0 ?
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"UserSearchList.csv"}
                    className="btn btn-success">
                    Export Report in CSV
                  </CSVLink> : null}
              </Col>
            </Row>&nbsp;
            <Row>
                  <Col xl={12}>
                    <div className="card p-3">
                      {this.state.ismodalopen1 ? (
                        <AllDetails
                          status={true}
                          onCloseCallBack={this.onCloseCallBack}
                          data={this.state.activeid}
                        />
                      ) : null}
                      {/* <MDBDataTable responsive bordered data={data} /> */}
                      <MDBDataTableV5
                        hover
                        entriesOptions={[5, 20, 25]}
                        entries={5}
                        pagesAmount={4}
                        data={data}
                        scrollX
                        disableRetreatAfterSorting={true}
                      />
                    </div>
                  </Col>
                </Row>
          
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
