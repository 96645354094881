import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import { CSVLink } from "react-csv";
import HttpClient from "./../../utils/HttpClient";
import { Link } from "react-router-dom";
import Details from "./details";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Seller Priority", link: "#" },
        { title: "Manage and View Seller Priority", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      title: "",
      points: [""],
      id: "",
      duration: 0,
      seller_comission: "",
      desc: "",
      priority: "",
      seller: [],
      sellerName: "",
    };
    this.handleDefault = this.handleDefault.bind(this);
  }

  componentDidMount = async () => {
    this.checkUser();
    this.fetchData();
    this.fetchSeller();
  };

  handleDefault(date) {
    this.setState({ default_date: date });
  }

  fetchSeller = async () => {
    let data = {

      device_type: ""

    }
    let result = await HttpClient.requestData("sellerlist", "POST", data);
    console.log("sellerlist", result);
    if (result && result.status) {
      this.setState({ seller: result.data });
    } else {
    }
  };

  fetchData = async () => {
    let data = {

      device_type: ""

    }
    console.log("data", data);
    let result = await HttpClient.requestData("sellerlist", "POST", data);
    console.log("seller List", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      // console.log(this.state.pointarr);
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: i,

          username: element.firstName + " " + element.lastName,
          email: element.email,
          date: moment(element.start).format("MMMM Do YYYY"),
          priority: element.priority,
          view: (
            <button
              title="View Details"
              className="btn btn-success mr-2"
              onClick={() => {
                this.setState({
                  activeid: element,
                });
                setTimeout(() => {
                  this.setState({
                    ismodalopen: true,
                    idneed: element._id
                  });
                }, 200);
              }}
            >
              View Details
            </button>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };
  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    console.log(sendData);
    let result = await HttpClient.requestData(
      "admin/ecom_banner/delete",
      "POST",
      sendData
    );
    console.log(result);
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Succfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };

  handleSellerName = async (event) => {
    this.setState({ sellerName: event.target.value });
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      title: item.name,
      price: item.price,
      edit_item_id: item._id,
      seller_comission: item.seller_comission,
      duration: item.duration,
      id: item.id,
      type: item.type,
    });
  };
  status = async (item, id) => {
    let sendData = {};
    let result = await HttpClient.requestData(
      "user/set-status/" + id,
      "PUT",
      sendData
    );
    console.log("result", result);
    if (result && result.status) {
      this.fetchData();
    }
  };
  submit = async () => {
    if (this.state.sellerName != "" && this.state.priority != "") {
      let data = null;
      let result = null;

      if (this.state.edit_enable == false) {
        data = {
          seler_id: this.state.sellerName,
          priority: this.state.priority,
        };
        console.log(data);
        result = await HttpClient.requestData("Priority", "POST", data);
        console.log("priority set", result);
      } else {
        data = {
          name: this.state.title,
          seller_comission: Number(this.state.seller_comission),
          duration: Number(this.state.duration),
          price: Number(this.state.price),
          type: this.state.type,
          description: this.state.desc,
        };
        console.log(data);
        result = await HttpClient.requestData(
          "subscription/" + this.state.edit_item_id,
          "PUT",
          data
        );
      }
      console.log("result", result);
      if (result && result.status) {
        this.setState({
          alert: true,
          message: this.state.edit_enable
            ? "Priority Updated Successfully"
            : "Priority Added Successfully",

          type: "success",

          image_select: false,
          img_url: "",
          title: "",
          // type: "",
          sellerName: "",
          priority: "",
          seller_comission: "",
          duration: "",
          price: "",
          desc: "",
        });

        this.fetchData();
      } else {
        this.setState({
          alert: true,
          message:
            typeof result.error == "string"
              ? result.error
              : result.error[0].msg,
          type: "danger",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (e) => {
    let file = e.target.files[0].type;
    let fileType = file.split("/")[0];
    // fileType = fileType[0];
    console.log("e", file.split("/")[0]);

    if (fileType == "image" || fileType == "video") {
      // return false;
      this.setState({ fileType: fileType });
      let dataSend = { buckate: true, buckate_name: "appIcon" };

      let result = await HttpClient.newFileUpload(
        "image-upload/movie",
        e.target.files[0],
        dataSend
      );
      console.log("result", result);
      if (result && result.status) {
        this.setState({ img_url: result.url, image_select: true });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  onCloseCallBack = (val) => {
    console.log("object", val);
    this.setState({
      ismodalopen: val,
    });
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",

          width: 150,
        },
        {
          label: "Seller Name",
          field: "username",

          width: 270,
        },
        {
          label: "Email",
          field: "email",

          width: 270,
        },
        {
          label: "Date",
          field: "date",

          width: 270,
        },
        {
          label: "Priority",
          field: "priority",

          width: 100,
        },
        {
          label: "View Details",
          field: "view",

          width: 170,
        },

      ],
      rows: this.state.data,
    };

    const header = [

      { label: "Sl.", key: "sl" },
      { label: "User Name", key: "username" },
      { label: "Email", key: "email" },
      { label: "Date", key: "date" },
      { label: "Priority", key: "priority" },
    ]

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                  {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type === "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type === "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type === "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Seller Name
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={this.handleSellerName}
                          value={this.state.sellerName}
                        >
                          <option value="">Select Seller Name</option>
                          {this.state.seller.map((item, index) => {
                            return (
                              <option value={item._id} key={index}>
                                {item.firstName + " " + item.lastName}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Priority
                      </Label>
                      <Col md={10}>
                        <input
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder=""
                          onChange={(val) => {
                            this.setState({ priority: val.target.value });
                          }}
                          value={this.state.priority}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xl={3}>
                {this.state.data.length > 0 ?
                  <CSVLink
                    data={this.state.data}
                    headers={header}
                    filename={"SellerPriority.csv"}
                    className="btn btn-success">
                    Export Report in CSV
                  </CSVLink> : null}
              </Col>
            </Row>&nbsp;

            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                  />
                </div>
              </Col>
            </Row>
            {this.state.ismodalopen ? (
              <Details
                status={true}
                onCloseCallBack={this.onCloseCallBack}
                data={this.state.activeid}
                id={this.state.idneed}
              />
            ) : null}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
