import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import ReactJsAlert from "reactjs-alert";
// import { Row, Col, Card, CardBody, Button } from "reactstrap";

import {
  Container,
  Row,
  Col,
  Tooltip,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Input,
  Form,
  Button,
  FormGroup,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import BaseInfo from "../ChooseDesign/baseInfo";

export default class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 1,
      tooltipOpen: false,
      result: [],
      data: [],
      monthDate: new Date(),
      modal_static: false,
      cardLoop: [1, 2, 3, 4],

      quizName: "",
      startDate: new Date(),
      endDate: new Date(),
      userId: "",
      userDetails: {},
      giftCard: [],
      giftCardSelected: [],
      //   img: {},
      imgUrl: "",
      refreshStatus: false,
      // know your customer
      previewKnow: false,
      previewData: {},
      resultKnow: [],
      // dataKnow: [],
      breadcrumbItems: [
        { title: "Hobbies ", link: "#" },
        { title: "Add & Manage hobbies", link: "#" },
      ],
      hobbyFormData: {
        hobby_category: "",
        hobby_desc: "",
        categoryphoto: {},
      },
      alert: false,
      message: "",
      type: "",
      status: false,
      title: "",
      QuestionOptions: [],
      selectedOptionValue: "",
      hobby_category: "",
      hobby_desc: "",
      categoryphoto: {},

      planprice: "",

      //   img:""
    };
    // this.toggleTab = this.toggleTab.bind(this);
    // this.handleMonthChange = this.handleMonthChange.bind(this);
    // this.tog_CardModal = this.tog_CardModal.bind(this);
  }

  componentDidMount = async () => {
    this.fetchData();
  };

  fetchData = async () => {
    let apiResult = await HttpClient.requestData("/viewhobby", "GET");
    // console.log("HOBBIES", apiResult.data);
    if (apiResult && apiResult.status > 0) {
      let data = [];
      //   let i = 1;
      this.setState({
        result: apiResult.data.filter((item) => item.is_deleted == false),
      });
      this.state.result.forEach((element, index) => {
        let rows = {
          sl: index + 1,
          hobby_category: element.hobby_category,
          // hobby_desc: element.hobby_desc.substr(1, 10),

          action: (
            <>
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={(e) => this.delete(element)}
              >
                <i className="fa fa-trash" />
              </button>
              <Link to={"/updatehobby" + element.id}>
                <Button
                  className="btn-icon"
                  color="success"
                  // onClick={() => {
                  //   window.location.href = "/updatehobby" + element.id;
                  // }}
                >
                  {" "}
                  Update
                </Button>
              </Link>
            </>
          ),
        };
        // i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  handleSubmit = async () => {
    // console.log(this.state.hobbyFormData);
    if (this.state.hobby_categoryv != "" && this.state.hobby_desc != "") {
      let data = new FormData();

      data.append("hobby_category", this.state.hobby_category);
      data.append("hobby_desc", this.state.hobby_desc);
      // data.append("image", this.state.categoryphoto);

      let result = await HttpClient.fileUplodeDynamic("addhobby", "POST", data);
      if (result && result.status) {
        this.fetchData();
        this.setState({
          type: "success",
          status: true,
          title: "Hobby Added Succesfully",
        });
      } else {
        this.setState({
          type: "error",
          status: true,
          title: "Something Went Wrong",
        });
      }
    } else {
      this.setState({
        type: "warning",
        status: true,
        title: "Please Fillup Details",
      });
    }
  };

  delete = async (item, index) => {
    let sendData = {
      hobby_category: item.hobby_category,
    };
    console.log("sendData", sendData);
    let result = await HttpClient.requestData("deletehobby", "POST", sendData);
    console.log("result", result);
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      console.log("index", index);
      if (index > -1) {
        let arr = this.state.result.splice(index, 1);
        console.log("result", arr);
        this.setState({
          type: "success",
          status: true,
          title: "Deleted Succfully",
          // result:this.state.result
        });

        this.fetchData();
      }
    }
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "Hobby Category",
          field: "hobby_category",
          sort: "asc",
          width: 270,
        },
        // {
        //   label: "Description",
        //   field: "hobby_desc",
        //   sort: "asc",
        //   width: 270,
        // },

        // {
        //   label: "Image",
        //   field: "categoryphoto",
        //   sort: "asc",
        //   width: 270,
        // },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <ReactJsAlert
            type={this.state.type}
            title={this.state.title}
            status={this.state.status}
            Close={() => this.setState({ status: false })}
          />
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xl={12}>
                <div>
                  <div id="basic-pills-wizard" className="twitter-bs-wizard">
                    <Form className="card p-3">
                      <h4></h4>
                      <Row>
                        <>
                          <Col lg="12">
                            <FormGroup row>
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Hobby Category
                              </Label>
                              <Col md={10}>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={this.state.hobby_category}
                                  id="example-text-input"
                                  onChange={(val) => {
                                    console.log(val.target.value);
                                    this.setState({
                                      hobby_category: val.target.value,
                                    });
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Description
                              </Label>
                              <Col md={10}>
                                <textarea
                                  id="question-input1"
                                  className="form-control"
                                  rows="2"
                                  placeholder="Write small description..."
                                  value={this.state.hobby_desc}
                                  onChange={(val) => {
                                    // this.state.description = val.target.value;
                                    this.setState({
                                      hobby_desc: val.target.value,
                                    });
                                  }}
                                ></textarea>
                              </Col>
                            </FormGroup>
                            {/* <FormGroup row>
                              <Label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Image
                              </Label>
                              <Col md={10}>
                                <input
                                  type="file"
                                  className="upload_input"
                                  onChange={this.imageUpload}
                                />
                              </Col>
                            </FormGroup> */}
                          </Col>
                        </>
                        <Col lg="6">
                          <FormGroup className="mb-0">
                            <div className="button-items pt-4">
                              <Button
                                color="primary"
                                type="button"
                                className="waves-effect waves-light mr-1"
                                onClick={() => {
                                  this.handleSubmit();
                                }}
                              >
                                Submit{" "}
                                <i className="ri-arrow-right-line align-middle ml-1"></i>
                              </Button>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                    <div className="card px-3 pt-0"></div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {this.state.activeTab == 1 ? (
                    <MDBDataTable responsive bordered data={data} />
                  ) : (
                    {
                      /* <MDBDataTable responsive bordered data={dataKnow} /> */
                    }
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
