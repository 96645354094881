import React, { useState, useEffect } from "react";
import HttpClient from "../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

//import ReactJsAlert from "reactjs-alert";

function Details(props) {
  const [data, setdata] = useState([])
  const [name, setname] = useState("");
  const [image, setimage] = useState("");
  const userDataFrom = props.data.user_data[0]
  const username = props.data.user_data[0].firstName + " " + props.data.user_data[0].lastName;

  useEffect(() => {

    console.log("objecttt", props.data);
    fetchData();
  }, [props.data]);



  const onCloseMethod = async () => {
    props.onCloseCallBack(false);
  };

  const fetchData = async () => {
    let result = await HttpClient.requestData("servicedetails", "GET");
    console.log("abc", result);
    if (result && result.status) {
      if (result.data.length > 0) {
        let arr = result.data.filter(it => it._id == props.id);
        console.log("gh", arr[0]);
        let data = [arr[0].servicecart_data[0] ]
        console.log(data)
        setdata(data);
        // setname(arr[0].user_data[0].firstName + " " + arr[0].user_data[0].lastName);

      }

    }
  }

  // const { selectedOption } = this.state;
  return (
    <div
      className={props.status ? "modal show fade" : "modal fade"}
      id="add-new-task-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="add-new-task-modalLabel"
      aria-hidden="true"
      style={{ display: props.status ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="add-new-task-modalLabel">
              View Details
            </h4>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => {
                onCloseMethod();
              }}
            >
              ×
            </button>
          </div>
          <div className="modal-body" style={{ height: "300px", overflowY: "auto" }}>
            <div className="px-2">

            </div>


            {data.map((item, index) => {
              return (<>
                <div className="d-md-flex  my-2  ">

                  <div className="order_pic ">
                    <img
                      src={HttpClient.IMG_URL + item.image}
                      alt="img"
                      style={{ width: "100px", height: "100px", borderRadius: "6px" }}
                    />
                  </div>
                  <div className="px-3 py-2" >
                    <h4>{item.servicename}</h4>
                    <h6>₹ {item.price}</h6>
                    <h6>{item.seller_data.firstName + " " + item.seller_data.lastName}</h6>
                    <h6> {item.seller_data.email}</h6>
                    {/* <p>Quantity: {item.qty} </p> */}
                  </div>

                </div>
              </>);
            })}


            <div className="form-group row">

              <div className="col-md-4">
                <label htmlFor="task-title">User Name</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.user_data ? username : ""}
                  readOnly
                />
              </div>


              <div className="col-md-8">
                <label htmlFor="task-title">Email</label>


                <input
                  style={{ height: "37px" }}
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.user_data ? props.data.user_data[0].email : ""}
                  readOnly

                />
              </div>

              <div className="col-md-4">


                <label htmlFor="task-title">Country</label>

                <textarea
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.user_data.length > 0 && props.data.user_data[0].country ? props.data.user_data[0].country.label : ""}
                  readOnly
                >
                </textarea>
              </div>
              <div className="col-md-4">
                <label htmlFor="task-title">City</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data.user_data.length > 0 ? props.data.user_data[0].city : ""}
                  readOnly
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="task-title">order id</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={props.data ? props.data._id : ""}
                  readOnly
                />
              </div>

              <div className="col-md-4">


                <label htmlFor="task-title">Date</label>

                <input
                  type="text"
                  className="bg-white font-18 font-weight-semibold form-control text-dark"
                  id="task-title"
                  value={moment(props.data.servicecart_data.length > 0 && props.data.servicecart_data[0].booking_date).format("MMMM Do YYYY")}
                  readOnly
                />
              </div>



              {/* <div className="col-md-4">
                                <label htmlFor="task-title">Service Name</label>

                                <input
                                    type="text"
                                    className="bg-white font-18 font-weight-semibold form-control text-dark"
                                    id="task-title"
                                    value={props.data.servicecart_data ? props.data.servicecart_data.servicename : ""}
                                    readOnly
                                />
                            </div> */}



            </div>


            <div className="text-right">
              <button
                type="button"
                className="btn btn-warning mr-3"
                data-dismiss="modal"
                onClick={() => {
                  onCloseMethod();
                }}
              >
                Close
              </button>

            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}
export default Details;
