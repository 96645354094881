import React, { Component } from "react";
import "./ind432a.css";
import ImgCrop from "../../components/ImageCrop/imgCrop";
import HttpClient from "./../../utils/HttpClient";

export default class design_2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backgroundImage: this.props.backgroundImage,
      item: this.props.item,
      user: this.props.item.user,
      cropresult: "",
      cropImg: false,
      btmBGColor: "",
      changeBGColor: false,
      seletedFile: {},
    };
  }
  componentDidMount() {
    console.log("this.props.item.user", this.props.item);
  }
  cropHandle = (id) => {
    this.setState((prevState) => ({
      cropImg: {
        ...prevState.cropImg,
        [id]: !prevState.cropImg[id],
      },
    }));
  };
  callBackCrop = (val, id, cropresult, file) => {
    console.log(file);
    this.setState((prevState) => ({
      cropImg: {
        ...prevState.cropImg,
        [id]: val,
      },
      cropresult: cropresult,
      seletedFile: file,
    }));

    setTimeout(() => {
      this.props.cropCallBack(id, cropresult, file);
    }, 200);
  };
  render() {
    return (
      <div
        className="designed_area w-100 h-100 bg-light relative"
        style={{
          backgroundImage: `url(${this.props.item.backgroundImage})`,
        }}
      >
        <div className="d-flex flex-column h-100 overflow-auto">
          {/* item  */}
          {this.props.item.user.map((item, index) => {
            return (
              <div
                className="item_panel d-flex w-100 py-3 my-2"
                style={{
                  backgroundImage: `url(${item.bgImage})`,
                }}
                key={index}
              >
                <div className="my-auto ml-auto">
                  <div className="d-flex design_356 relative">
                    <div
                      onClick={() => this.cropHandle(index)}
                      title="Upload logo"
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={item.avatar}
                        alt="avatar"
                        className="rounded-circle avatar-lg my-auto mr-2 shadow"
                      />
                    </div>

                    <div>
                      <div
                        className="text_block py-2 px-2"
                        style={{
                          backgroundColor: this.props.item.titlebg
                            ? this.props.item.titlebg
                            : "#343a40",
                        }}
                      >
                        <h5
                          className=""
                          style={{
                            color: this.props.item.titlecolor
                              ? this.props.item.titlecolor
                              : "#fff",
                            fontFamily: this.props.item.fontFamily
                              ? this.props.item.fontFamily
                              : "",
                            fontSize:
                              this.props.item.fontValue != ""
                                ? Number(this.props.item.fontValue)
                                : 16,
                          }}
                        >
                          {item.title}
                        </h5>
                      </div>
                      <div
                        className="text_block py-1 px-2"
                        style={{
                          backgroundColor: this.props.item.namebg
                            ? this.props.item.namebg
                            : "#fff",
                        }}
                      >
                        <h6
                          style={{
                            color: this.props.item.namecolor
                              ? this.props.item.namecolor
                              : "#000",
                            fontFamily: this.props.item.fontFamily
                              ? this.props.item.fontFamily
                              : "",
                            fontSize:
                              this.props.item.fontValue != "" &&
                              Number(this.props.item.fontValue) > 15
                                ? Number(this.props.item.fontValue) - 2
                                : 14,
                          }}
                        >
                          {item.name}
                        </h6>
                        <span
                          style={{
                            color: this.props.item.namecolor
                              ? this.props.item.namecolor
                              : "#000",
                            fontFamily: this.props.item.fontFamily
                              ? this.props.item.fontFamily
                              : "",
                            fontSize:
                              this.props.item.fontValue != "" &&
                              Number(this.props.item.fontValue) > 15
                                ? Number(this.props.item.fontValue) - 2
                                : 14,
                          }}
                        >
                          {item.position}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* image crop modal */}
                  <div
                    className={
                      this.state.cropImg[index]
                        ? "modal fade show"
                        : "modal fade"
                    }
                    key={index}
                    id={"imgCropBox" + index}
                    tabindex="-1"
                    style={{
                      display: this.state.cropImg[index] ? "block" : "none",
                    }}
                  >
                    <div className="modal-dialog mt-5 pt-5" role="document">
                      <ImgCrop callBackCrop={this.callBackCrop} id={index} />
                    </div>
                  </div>
                </div>
                <input
                  type="file"
                  className="page_input_file"
                  title="Choose Background Image"
                />
              </div>
            );
          })}

          {/* bottom icon's area */}
          <div
            className="mb-0 mt-auto mx-auto w-100"
            style={{
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
              background: this.props.item.iconbgcolor,
              // background: this.props.item.iconbgcolor
            }}
            title="Click to change background color"
            onClick={() => this.setState({ changeBGColor: true })}
          >
            <div className="d-flex w-100 px-3">
              {this.props.item.icons.map((item, index) => {
                return (
                  <div className="m-auto" key={index}>
                    <img src={item.iconUrl} style={{ height: 40, width: 40 }} />
                  </div>
                );
              })}
            </div>
          </div>
          {/* bg-color change modal */}
          {this.state.changeBGColor ? (
            <div
              className={
                this.state.changeBGColor ? "modal fade show" : "modal fade"
              }
              id={"colorBGModal"}
              style={{
                display: this.state.changeBGColor ? "block" : "none",
              }}
            >
              <div className="modal-dialog mt-5 pt-5" role="document">
                <div className="modal-content">
                  <div className="modal-header py-2">
                    <h6 className="modal-title">Change Background Color</h6>
                  </div>
                  <div className="modal-body">
                    <label for="color-picker">
                      <p className="mb-0 mr-3 text-dark">
                        Pick colors to change the background
                      </p>
                    </label>
                    <input
                      name="color-picker"
                      aria-label="icon-color-picker"
                      type="color"
                      value={this.state.btmBGColor}
                      onChange={(e) =>
                        this.setState({
                          btmBGColor: e.target.value,
                        })
                      }
                    />
                    <button
                      type="button"
                      className="btn btn-primary btn-sm pull-right"
                      onClick={() => this.setState({ changeBGColor: false })}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
