import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import Dropzone from "react-dropzone";
import BaseInfo from "../ChooseDesign/baseInfo";
import HttpClient from "./../../utils/HttpClient";
import Select from "react-select";

// import EcomPreview from "./E-ComPreview/index";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Event", link: "#" },
        { title: "Add & Manage Event", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      email: "",
      mobile: "",
      password: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      userId: "",
      userDetails: {},
      //   this page states
      category: [],
      categoryAll: [],
      subCategory: [],
      subCategoryAll: [],

      cat_id: "",
      pruduct_type: "",
      sub_cat_id: "",

      selectedFiles: [],
      images: [],
      uploaded_images: [],

      sell_price: "",
      purchase_price: "",
      discount: "",
      optionGroup: [],
      selectedMulti: [],
      director: "",
      duration: "",
      desc: "",

      trailerPlaylists: "",
      trailer: "",

      videoUrl: "",
      url: "",
      uploading_trailer: false,
      uploading_movie: false,
      cast: "",
      year: "",
    };
    this.handleMulti = this.handleMulti.bind(this);
  }
  handleMulti = (selectedMulti) => {
    this.setState({ selectedMulti });
  };
  componentDidMount = async () => {
    this.checkUser();
    this.fetchCat();
  };

  fetchCat = async () => {
    let result = await HttpClient.requestData("admin/jdcategory", "GET");
    console.log("result jdcategory", result);
    if (result && result.status > 0) {
      this.setState({ categoryAll: result.data });

      let optionAppowner = {};
      optionAppowner.label = "Categories";
      optionAppowner.options = [];
      result.data.map((item, index) => {
        optionAppowner.options.push({
          label: item.name,
          value: item.id,
        });
      });

      let newOptions = [];
      newOptions.push(optionAppowner);

      this.setState({ optionGroup: newOptions });
    }
  };

  fetchData = async () => {
    let result = await HttpClient.requestData("admin/movies/get-all", "POST", {
      type: 2104281329322989,
    });
    // console.log("result", result);
    if (result && result.status > 0) {
      let data = [];
      let i = 1;
      this.setState({ result: result.data });
      this.state.result.forEach((element, index) => {
        let genre = "";

        if (element.genre) {
          genre = element.genre.reduce(
            (acc, curr) => `${acc}${curr.label} | `,
            ""
          );
          genre = genre.substring(0, genre.length - 2);
          console.log("genre", genre);
        }
        let rows = {
          sl: i,
          name: element.name,
          img:
            element.img.length > 0 ? (
              <div>
                <img
                  src={element.img[0].preview}
                  alt="images"
                  className="rounded avatar-md card-img"
                />
              </div>
            ) : null,
          director: element.director ? element.director : "",
          cast: element.cast ? element.cast : "",
          year: element.year ? element.year : "",

          category: element.categoryData ? element.categoryData.name : "",

          genre: genre,

          status: (
            <button
              title="Delete"
              className={
                element.status ? "btn btn-success mr-2" : "btn btn-danger mr-2"
              }
              onClick={() => {
                this.status(element, index);
              }}
            >
              {element.status ? "Active" : "Not Active"}
            </button>
          ),
          action: (
            <>
              <button
                title="Delete"
                className="btn btn-danger mr-2"
                onClick={() => {
                  this.delete(element, index);
                }}
              >
                <i className="fa fa-trash" />
              </button>
              <button
                title="Edit"
                className="btn btn-primary"
                onClick={() => {
                  this.edit(element, index);
                }}
              >
                <i className="fa fa-edit" />
              </button>
            </>
          ),
        };
        i++;
        data.push(rows);
      });
      this.setState({
        data: data,
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  delete = async (item, index) => {
    let sendData = {
      id: item._id,
    };
    let result = await HttpClient.requestData(
      "admin/movies/delete",
      "POST",
      sendData
    );
    if (result && result.status) {
      let index = this.state.result.indexOf(item);
      if (index > -1) {
        this.state.result.splice(index, 1);
        this.setState({
          alert: true,
          message: "Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);
        this.fetchData();
      }
    }
  };
  edit = async (item, index) => {
    this.state.edit_enable = true;
    this.state.name = item.name;
    this.state.desc = item.desc;
    this.state.duration = item.duration;
    this.state.director = item.director;
    this.state.cat_id = item.cat_id;
    this.state.cast = item.cast;
    this.state.selectedMulti = item.genre;
    this.state.year = item.year;
    this.state.trailer = item.trailer;
    this.state.url = item.url;
    this.state.img_url = item.img;
    this.state.image_select = true;
    this.state.edit_item_id = item._id;
    this.state.uploaded_images = item.img;
    this.state.videoUrl = item.videoUrl;
    this.setState({});
    console.log(this.state.uploaded_images);
    console.log(this.state.name);
    console.log(this.state.desc);
    console.log(this.state.duration);
    console.log(this.state.director);
    console.log(this.state.cat_id);
    console.log(this.state.cast);
    console.log(this.state.trailer);
    console.log(this.state.url);
  };
  status = async (item, index) => {
    let sendData = {
      id: item._id,
      status: !item.status,
    };
    let result = await HttpClient.requestData(
      "admin/movies/status",
      "POST",
      sendData
    );
    if (result && result.status) {
      this.state.result.splice(index, 1);
      this.setState({
        alert: true,
        message: "Status Updated Succfully",
        type: "success",
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
      this.fetchData();
    }
  };
  submit = async () => {
    if (
      this.state.name != "" &&
      this.state.director != "" &&
      this.state.uploaded_images.length > 0 &&
      this.state.videoUrl != ""
    ) {
      if (this.state.uploaded_images.length > 0) {
        this.setState({ loading: true });

        let data = null;
        let result = null;
        // return false;
        if (this.state.edit_enable == false) {
          data = {
            name: this.state.name,
            director: this.state.director,
            desc: this.state.desc,
            duration: this.state.duration,
            img: this.state.uploaded_images,
            cat_id: Number(this.state.cat_id),
            genre: this.state.selectedMulti,
            videoUrl: this.state.videoUrl,
            url: this.state.url,
            trailer: this.state.trailer,
            trailerUrl: this.state.trailerPlaylists,
            type: 2104281329322989,
            cast: this.state.cast,
            year: this.state.year,
          };

          console.log("data", data);
          result = await HttpClient.requestData("admin/movies", "POST", data);
        } else {
          data = {
            edit_id: this.state.edit_item_id,
            name: this.state.name,
            director: this.state.director,
            desc: this.state.desc,
            duration: this.state.duration,
            img: this.state.uploaded_images,
            cat_id: Number(this.state.cat_id),
            genre: this.state.selectedMulti,
            videoUrl: this.state.videoUrl,
            url: this.state.url,
            trailer: this.state.trailer,
            trailerUrl: this.state.trailerPlaylists,
            type: 2104281329322989,
            cast: this.state.cast,
            year: this.state.year,
          };

          console.log("data", data);
          result = await HttpClient.requestData(
            "admin/movies/update",
            "POST",
            data
          );
        }

        if (result && result.status) {
          this.setState({
            alert: true,
            message: this.state.edit_enable
              ? "Event Updated Successfully"
              : "Event Added Succfully",
            type: "success",
            name: "",
            cat_id: "",
            sub_cat_id: "",
            desc: "",
            pruduct_type: "",
            sell_price: "",
            purchase_price: "",
            discount: "",
            uploaded_images: [],
            images: [],
            selectedFiles: [],
            edit_enable: false,
            edit_item_id: "",
            selectedMulti: [],
          });

          this.fetchData();
        } else {
          this.setState({
            alert: true,
            message:
              typeof result.error == "string"
                ? result.error
                : result.error[0].msg,
            type: "danger",
          });
        }
      } else {
        this.setState({
          alert: true,
          message: "Please Select Atleast One Image.",
          type: "warning",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Please Fill Up All Details.",
        type: "warning",
      });
    }
    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  imageUpload = async (file, index) => {
    // console.log("e", file);

    let dataSend = { buckate: false };

    let result = await HttpClient.newFileUpload(
      "image-upload/movie",
      file,
      dataSend
    );
    console.log("result", result);
    if (result && result.status) {
      this.state.uploaded_images.push({ preview: result.url });
      if (this.state.images.length - 1 == index) {
        setTimeout(() => {
          this.setState({ loading: false });
        }, 200);
      }
      this.setState({});
    }
  };

  handleAcceptedFiles = (files) => {
    // let data = [];
    // let file = {
    //   preview: URL.createObjectURL(files[0]),
    //   formattedSize: this.formatBytes(files[0].size),
    //   // image:files,
    // };
    // let file2 = {
    //   image: files,
    // };

    // let file3 = files;
    // console.log("data", file);
    // this.state.selectedFiles.push(file);
    // this.state.images.push(files[0]);
    this.imageUpload(files[0]);
    // console.log("data", this.state.images);
    this.setState({});

    // console.log('files',files)
    // files.map(file =>
    //   Object.assign(file, {
    //     preview: URL.createObjectURL(file),
    //     formattedSize: this.formatBytes(file.size)
    //   })
    // );

    // this.setState({ selectedFiles: files });
  };

  async remove_image(item, index) {
    // this.state.selectedFiles.pop(index);
    // this.state.images.pop(index);
    this.state.uploaded_images.pop(index);
    this.setState({});
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({
        userId: user.id,
        userDetails: user,
      });
    } else {
    }
    this.fetchData();
  };

  handleChange = async (event) => {
    let filterSub = this.state.subCategoryAll.filter(
      (item) => item.category_id == event.target.value
    );
    this.setState({ cat_id: event.target.value, subCategory: filterSub });
  };

  uploadVideo = async (video, type) => {
    if (type == "Trailer") {
      this.setState({ uploading_trailer: true });
    } else {
      this.setState({ uploading_movie: true });
    }

    let dataSend = { buckate: false };
    let result = await HttpClient.videoUpload(
      "video-upload/video",
      video,
      dataSend
    );
    if (result && result.status) {
      if (type == "Trailer") {
        this.setState({
          trailerPlaylists: result.playlists,
          trailer: result.data.url,
          uploading_trailer: false,
        });
      } else {
        this.setState({
          videoUrl: result.playlists,
          url: result.data.url,
          uploading_movie: false,
        });
      }
    }
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",
          sort: "asc",
          width: 150,
        },
        {
          label: "App",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Image",
          field: "img",
          sort: "asc",
          width: 270,
        },
        {
          label: "Director",
          field: "director",
          sort: "asc",
          width: 270,
        },

        {
          label: "Category",
          field: "category",
          sort: "asc",
          width: 270,
        },
        {
          label: "Cast",
          field: "cast",
          sort: "asc",
          width: 270,
        },
        {
          label: "Year",
          field: "year",
          sort: "asc",
          width: 270,
        },
        {
          label: "Genre",
          field: "genre",
          sort: "asc",
          width: 270,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              {/* <BaseInfo /> */}
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Event Name
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.name}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ name: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-search-input"
                        className="col-md-2 col-form-label"
                      >
                        Description
                      </Label>
                      <Col md={10}>
                        <textarea
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder=""
                          onChange={(val) => {
                            this.setState({ desc: val.target.value });
                          }}
                          value={this.state.desc}
                        ></textarea>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Director
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.director}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ director: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Duration
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="number"
                          value={this.state.duration}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ duration: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Category
                      </Label>
                      <Col md={10}>
                        <select
                          className="form-control"
                          onChange={(event) => {
                            this.setState({
                              cat_id: event.target.value,
                            });
                          }}
                          value={this.state.cat_id}
                        >
                          <option value="">Select Category</option>
                          {this.state.categoryAll.map((item, index) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                        </select>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Cast
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.cast}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ cast: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Year
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="date"
                          value={this.state.year}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ year: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Tag
                      </Label>
                      <Col md={10}>
                        <Select
                          isMulti={true}
                          options={this.state.optionGroup}
                          values={this.state.selectedMulti}
                          onChange={
                            // (values) => this.setValues(values)
                            this.handleMulti
                          }
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Upload Trailer
                      </Label>
                      <Col md={6}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={async (val) => {
                            this.uploadVideo(val.target.files[0], "Trailer");

                            // let file = URL.createObjectURL(val.target.files[0]);
                            // console.log('file', file)
                            // this.setState({ trailer: file });
                          }}
                        />
                      </Col>
                      <Col md={4}>
                        {this.state.uploading_trailer ? (
                          <img
                            src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                            style={{ height: 150, width: 150 }}
                          />
                        ) : (
                          <>
                            {this.state.trailer != "" ? (
                              <video
                                width="100%"
                                controls
                                className="rounded-lg"
                              >
                                <source
                                  src={this.state.trailer}
                                  type="video/mp4"
                                />
                              </video>
                            ) : null}
                          </>
                        )}
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Upload Event
                      </Label>
                      <Col md={6}>
                        <Input
                          className="form-control"
                          type="file"
                          id="example-text-input"
                          onChange={(val) => {
                            this.uploadVideo(val.target.files[0], "Movie");

                            // let file = URL.createObjectURL(val.target.files[0]);
                            // console.log('file', file)
                            // this.setState({ url: file });
                          }}
                        />
                      </Col>
                      <Col md={4}>
                        {this.state.uploading_movie ? (
                          <img
                            src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                            style={{ height: 150, width: 150 }}
                          />
                        ) : (
                          <>
                            {this.state.url != "" ? (
                              <video
                                width="100%"
                                controls
                                className="rounded-lg"
                              >
                                <source src={this.state.url} type="video/mp4" />
                              </video>
                            ) : null}
                          </>
                        )}
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Event Thumbnail
                      </Label>
                      <Col md={10}>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            this.handleAcceptedFiles(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {this.state.uploaded_images.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Button
                                        className="btn-icon"
                                        color="danger"
                                        style={{ marginRight: 25 }}
                                        onClick={(e) => this.remove_image(f, i)}
                                      >
                                        {" "}
                                        <span className="btn-icon-label">
                                          <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                        </span>{" "}
                                        Remove
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                {/* <div>
                    <EcomPreview />
                  </div> */}
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTable responsive bordered data={data} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
