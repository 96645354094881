import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Input,
  Form,
  Tooltip,
  Button,
  FormGroup,
  UncontrolledAlert,
} from "reactstrap";
import HttpClient from "./../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import BaseInfo from "../ChooseDesign/baseInfo";
import KycPreview from "./KycPreview/index";
import { MDBDataTable } from "mdbreact";
import Breadcrumbs from "../../components/Common/Breadcrumb";


export default class KnowCustomer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      que: "",
      tooltipOpen: false,
      opt: [{ img: "", option: "", imageSelect: false }],
      design: "",
      userId: "",
      userDetails: {},
      type: "",
      message: "",
      alert: false,
      dataKnow: [],
      breadcrumbItems: [
        { title: "Know Your Customer ", link: "#" },
        { title: "Add & Manage Know Your Customer", link: "#" },
      ],
      alert: false,
      message: "",
      type: "",
    };
  }
  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };
  componentDidMount = async () => {
    await this.checkUser();
  };

  checkUser = async () => {
    let user = await reactLocalStorage.getObject("userData");
    if (user != null && Object.keys(user).length > 0) {
      this.setState({ userId: user.id, userDetails: user });
      // this.fetch();
      // this.fetchCard();
    } else {
    }
  };

  imageUpload = async (file) => {
    let dataSend = { buckate: true, buckate_name: "appIcon" };
    let result = await HttpClient.newFileUpload(
      "image-upload/appIcon",
      file,
      dataSend
    );

    return result;
  };

  fromSubmit = async () => {
    if (this.state.que != "") {
      if (this.state.design != "") {
        if (this.state.opt.length > 0) {
          let data = {
            design: this.state.design,
            question: this.state.que,
            option: this.state.opt,
          };
          let result = await HttpClient.requestData(
            "admin/know-your-customer",
            "POST",
            data
          );
          if (result && result.status) {
            this.props.calbackKnow();
            this.setState({
              alert: true,
              message: "Submited successfully.",
              type: "success",
              design: "",
              que: "",
              option: [{ img: "", option: "", imageSelect: false }],
            });
          } else {
            this.setState({
              alert: true,
              message: "Server busy. Try again later.",
              type: "danger",
            });
          }
        } else {
          this.setState({
            alert: true,
            message: "Please select atleast one option.",
            type: "warning",
          });
        }
      } else {
        this.setState({
          alert: true,
          message: "Please select design.",
          type: "warning",
        });
      }
    } else {
      this.setState({
        alert: true,
        message: "Question is required.",
        type: "warning",
      });
    }

    setTimeout(() => {
      this.setState({
        alert: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
          <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              {/* <BaseInfo /> */}

              <Col xl={12}>
                {/* <div>
                  <h3 className="title-editor py-1 text-center">
                    Know Your Customer{" "}
                    <i
                      className="fa fa-info-circle display_tooltip"
                      id="TooltipInfo"
                    />
                    <Tooltip
                      placement="top"
                      isOpen={this.state.tooltipOpen}
                      target="TooltipInfo"
                      toggle={this.toggle}
                      autohide={false}
                    >
                      Know Your Customer with these steps
                    </Tooltip>
                  </h3>
                </div> */}
                <div className="card p-3">
                  <h4 className="mb-3">
                    Know Your Customer with some questions
                  </h4>
                  <Row>
                    <Col lg="12">
                      {this.state.alert ? (
                        <UncontrolledAlert
                          color={this.state.type}
                          className="alert-dismissible fade show"
                          role="alert"
                        >
                          {this.state.type == "warning" ? (
                            <i className="mdi mdi-alert-outline mr-2"></i>
                          ) : this.state.type == "success" ? (
                            <i className="mdi mdi-check-all mr-2"></i>
                          ) : this.state.type == "danger" ? (
                            <i className="mdi mdi-block-helper mr-2"></i>
                          ) : null}
                          {this.state.message}
                        </UncontrolledAlert>
                      ) : null}
                      <FormGroup>
                        <Label for="question-input1">Describe Question</Label>
                        <textarea
                          id="question-input1"
                          className="form-control"
                          rows="2"
                          placeholder="Describe your question here..."
                          value={this.state.que}
                          onChange={(val) => {
                            this.setState({ que: val.target.value });
                          }}
                        ></textarea>
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Design
                        </Label>
                        <Col md={10}>
                          <select
                            className="form-control"
                            onChange={(event) => {
                              this.setState({ design: event.target.value });
                            }}
                            value={this.state.design}
                          >
                            <option value="">Select Design</option>
                            <option value="1">Design 1</option>
                            <option value="2">Design 2</option>
                            <option value="3">Design 3</option>
                          </select>
                        </Col>
                      </FormGroup>
                    </Col>

                    {this.state.opt.map((item, index) => {
                      return (
                        <Col lg="6">
                          <FormGroup>
                            <Label for="answer-input1">Describe Option</Label>
                            <Input
                              className="form-control"
                              type="text"
                              defaultValue=""
                              id="answer-input1"
                              placeholder="Enter your answer..."
                              value={this.state.opt[index].option}
                              onChange={(val) => {
                                this.state.opt[index].option = val.target.value;
                                this.setState({});
                              }}
                            />
                          </FormGroup>
                          {item.imageSelect ? (
                            <div>
                              <img
                                src={
                                  item.img
                                    ? item.img
                                    : "https://i.pinimg.com/originals/46/e3/ac/46e3ac9f52ad9af667f32e79f081e03a.jpg"
                                }
                                alt="images"
                                className="rounded avatar-md card-img"
                              />
                            </div>
                          ) : null}
                          {this.state.design == "1" ||
                          this.state.design == "2" ? (
                            <Button
                              color="danger"
                              type="button"
                              className="waves-effect waves-light customUploadBtn my-3"
                            >
                              <i className="ri-add-fill align-middle mr-1"></i>{" "}
                              Add image
                              <input
                                type="file"
                                onChange={async (e) => {
                                  let result = await this.imageUpload(
                                    e.target.files[0]
                                  );
                                  if (result && result.status) {
                                    this.state.opt[index].imageSelect = true;
                                    this.state.opt[index].img = result.url;
                                    this.setState({});
                                    console.log("result", result);
                                  }
                                }}
                                className="upload_input"
                              />
                            </Button>
                          ) : null}
                        </Col>
                      );
                    })}
                    <Col lg="6">
                      <FormGroup className="mb-0">
                        <div className="button-items pt-4 mt-1 mb-3">
                          <Button
                            color="secondary"
                            type="button"
                            className="waves-effect waves-light mr-1"
                            onClick={() => {
                              if (this.state.design != "") {
                                let newOnj = {
                                  img: "",
                                  option: "",
                                  imageSelect: false,
                                };
                                this.state.opt.push(newOnj);
                                this.setState({});
                              } else {
                                this.setState({
                                  alert: true,
                                  message: "Please select design.",
                                  type: "warning",
                                });
                              }
                              setTimeout(() => {
                                this.setState({
                                  alert: false,
                                  message: "",
                                  type: "",
                                });
                              }, 3000);
                            }}
                          >
                            Add More Options{" "}
                            <i className="ri-add-fill align-middle ml-1"></i>
                          </Button>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="pager wizard twitter-bs-wizard-pager-link">
                    <Button
                      color="primary"
                      type="button"
                      className="waves-effect waves-light mr-3"
                      onClick={() => {
                        this.fromSubmit();
                      }}
                    >
                      Submit{" "}
                      <i className="ri-arrow-right-line align-middle ml-1"></i>
                    </Button>
                    <Button
                      color="success"
                      type="button"
                      className="waves-effect waves-light mr-1"
                      onClick={() => {
                        let data = {
                          owner_id: this.state.userId,
                          design: this.state.design,
                          question: this.state.que,
                          option: this.state.opt,
                        };
                        this.props.previewBtn(data);
                      }}
                    >
                      Preview{" "}
                      <i className="ri-arrow-right-line align-middle ml-1"></i>
                    </Button>
                  </div>
                </div>
              </Col>
              {/* <Col xl={4}>
                <div className="pb-5 mb-5">
                  <KycPreview
                    preview={() => {
                      this.preview();
                    }}
                    data={this.state.previewKnow ? this.state.previewData : {}}
                    previewKnow={this.state.previewKnow}
                    refreshStatus={this.state.refreshStatus}
                  />
                </div>
              </Col> */}
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                    <MDBDataTable responsive bordered data={this.state.dataKnow} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
